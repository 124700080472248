import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrbyHeaderLabel, OrbyTable } from 'orby-ui/src';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../../redux/selectors/user.selectors';
import { useNavigate } from 'react-router-dom';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from 'orby-ui/src/components/table/table-utils';
import {
  listWorkflowErrorSelector,
  processWorkflowErrorSelector,
  updatedWorkflowSelector,
  workflowListSelector,
  workflowsLoadingSelector,
  workflowsTotalSizeSelector,
} from '../../../../redux/selectors/workflow.selectors';
import {
  UpdateWorkflowRequest,
  DeleteWorkflowRequest,
  Workflow,
} from 'protos/pb/v1alpha2/workflows_service';
import {
  WORKFLOW_ACTION_MENU_ITEM,
  buildRequest,
  downloadYamlFile,
  getWorkflowActionItem,
  getWorkflowActionMenuCell,
  getWorkflowAppsCell,
  getWorkflowCreatorCell,
  getWorkflowModeCell,
  getWorkflowNameCell,
  getWorkflowNameColumnWidth,
  getWorkflowTimeCell,
  getWorkflowTimeWidth,
  handleWorkflowPageChange,
  handleWorkflowsRowSelectionChange,
  handleYamlEditWorkflow,
  isSFTPToJsonWorkflow,
} from './workflow-api-automation-helper';
import {
  updateWorkflowAction,
  deleteWorkflowAction,
  listWorkflowAction,
  listWorkflowErrorAction,
} from '../../../../redux/actions/workflow.action';
import { toastService } from '../../../../services/ToastService';
import WorkflowDeletionModal from '../../../../pages/workflow-creation/WorkflowDeletionModal';
import TaskCopyModal from '../../../../components/workflow-table/TaskCopyModal/TaskCopyModal';
import TaskCopySuccessConfirmation from '../../../../components/workflow-table/TaskCopyModal/TaskCopySuccessConfirmation';
import { getIdFromResourceName } from '../../../../utils/WorkflowUtils';
import {
  DateRange,
  FEATURE_FLAGS,
  TASKS_TAB_INDEX,
  WORKFLOW_RESOURCE_NAMES,
} from '../../../../utils/constants';
import UploadYamlComponent from '../../../../components/workflow-table/UploadYaml/UploadYaml';
import { validateYamlWorkflow } from '../../../../utils/yamlParserValidation';
import jsYaml from 'js-yaml';
import { getFeatureFlagsForOrgAndUserSelector } from '../../../../redux/selectors/feature_flags.selectors';
import { isFeatureFlagEnabled } from '../../../FeatureFlags/FeatureFlagUtils';
import ShareWorkflowModal from '../../../../components/workflow-table/ShareWorkflowModal/ShareWorkflowModal';
import { getApplicationNames } from '../../../../utils/helpers';
import { blockedWorkflowResourceNamesSelector } from '../../../../redux/selectors/taskV2.selectors';
import { GetBlockedWorkflowExecutionStatisticsRequest } from 'protos/pb/v1alpha2/workflow_executions_service';
import { getBlockedWorkflowExecutionStatisticsAction } from '../../../../redux/actions/taskV2.action';

export interface Props {
  displayNamePrefix: string;
  selectedUsers: Array<string>;
  isAdminView: boolean;
  isWorkflowAdminView: boolean;
  tableWidth: number;
  selectedApps: string[];
  selectedModes: string[];
  selectedTime: string;
  customRange: DateRange;
}

const WorkflowApiAutomationTab: React.FC<Props> = ({
  displayNamePrefix,
  isAdminView,
  selectedUsers,
  isWorkflowAdminView,
  tableWidth,
  selectedApps,
  selectedModes,
  selectedTime,
  customRange,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(loggedInUserSelector);
  const workflows: Workflow[] = useSelector(workflowListSelector);
  const listWorkflowError = useSelector(listWorkflowErrorSelector);
  const updatedWorkflow = useSelector(updatedWorkflowSelector);
  const workflowError = useSelector(processWorkflowErrorSelector);

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const totalSize = useSelector(workflowsTotalSizeSelector) ?? 0;
  const loading = useSelector(workflowsLoadingSelector);

  const blockedWorkflowResourceNames = useSelector(
    blockedWorkflowResourceNamesSelector,
  );

  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(
    null,
  );

  // YAML
  const [isYamlModalOpen, setIsYamlModalOpen] = useState({
    open: false,
    isEdit: true,
  });
  const [yamlFileError, setYamlFileError] = useState<string | null>(null);

  const [page, setPage] = useState(DEFAULT_FIRST_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const pagedWorkflows = workflows.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage,
  );

  const [updationTriggered, setUpdationTriggered] = useState(false);
  const [deletionTriggered, setDeletionTriggered] = useState(false);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [showTaskCopyConfirmModal, setShowTaskCopyConfirmModal] =
    useState(false);

  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

  // share workflow
  const [isShareWorkflowModalOpen, setIsShareWorkflowModalOpen] =
    useState(false);

  // feature flags
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const isRbacEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.RBAC, featureFlags);

  /**
   * ACTION MENU
   */
  const actionMenuOpen = Boolean(actionMenuAnchorEl);
  const handleActionMenuClick = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    workflow: Workflow,
  ) => {
    setSelectedWorkflow(workflow);
    setActionMenuAnchorEl(event.currentTarget);
  };

  /**
   * HANDLE ACTION MENU CLOSE
   */
  const handleActionMenuClose = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type?: string,
  ) => {
    event.stopPropagation();
    if (type && selectedWorkflow) {
      if (type === WORKFLOW_ACTION_MENU_ITEM.DELETE) {
        setIsDeletionModalOpen(true);
      } else if (type === WORKFLOW_ACTION_MENU_ITEM.EDIT) {
        const id = getIdFromResourceName(selectedWorkflow.name as string);
        if (id) {
          navigate(`/workflow/${id}/update`);
        } else {
          toastService.showError('Something went wrong', {
            position: 'top-right',
          });
        }
      } else if (type === WORKFLOW_ACTION_MENU_ITEM.COPY) {
        setIsCopyModalOpen(true);
      } else if (type === WORKFLOW_ACTION_MENU_ITEM.DOWNLOAD) {
        downloadYamlFile(selectedWorkflow);
      } else if (type === WORKFLOW_ACTION_MENU_ITEM.UPLOAD) {
        setIsYamlModalOpen({ open: true, isEdit: true });
      } else if (type === WORKFLOW_ACTION_MENU_ITEM.SHARE) {
        setIsShareWorkflowModalOpen(true);
      }
    }
    setActionMenuAnchorEl(null);
  };

  const handleDelete = (deleteReason: string) => {
    const req = DeleteWorkflowRequest.create({
      name: selectedWorkflow?.name,
      deletedReason: deleteReason,
    });
    dispatch(deleteWorkflowAction(req));
    setDeletionTriggered(true);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setIsDeletionModalOpen(false);
    setSelectedWorkflow(null);
  };

  const handleShareWorkflow = (workflowAdmins: string[], message: string) => {
    const req: UpdateWorkflowRequest = {};
    req.workflow = { ...selectedWorkflow! };
    req.workflow.admins = workflowAdmins;
    req.workflow.sendAdminEmail = true;
    req.workflow.adminEmailMessage = message;
    req.fieldMask = ['admins'];
    dispatch(updateWorkflowAction(req));
    setIsShareWorkflowModalOpen(false);
    setUpdationTriggered(true);
    setSelectedWorkflow(null);
  };

  /**
   * FETCH WORKFLOWS
   * @param pageNumber
   * @param pageSize
   * @param refresh
   */
  const listWorkflows = (
    pageNumber: number,
    pageSize: number,
    refresh: boolean,
  ) => {
    let status = 'is_template=false';
    if (!(isAdminView || isWorkflowAdminView)) {
      return (status += ',status=status_enabled');
    }
    const req = buildRequest({
      status: status,
      navigate,
      displayNamePrefix,
      orgResourceName: selectedOrgInfo!.orgResourceName as string,
      selectedApps,
      selectedUsers,
      selectedModes,
      selectedTime,
      customRange,
      isWorkflowAdmin: isWorkflowAdminView,
    });
    req.pageNumber = pageNumber;
    req.pageSize = pageSize;
    dispatch(listWorkflowAction(req, refresh));
  };

  const getBlockedWorkflows = () => {
    const req = GetBlockedWorkflowExecutionStatisticsRequest.create({
      parent: selectedOrgInfo?.orgResourceName,
    });
    dispatch(getBlockedWorkflowExecutionStatisticsAction(req));
  };

  /**
   * REFRESH PAGE
   */
  const refreshPage = () => {
    setPage(DEFAULT_FIRST_PAGE);
    listWorkflows(DEFAULT_FIRST_PAGE, rowsPerPage, true);
    getBlockedWorkflows();
  };

  /**
   * HANDLE YAML FILE CHANGE
   */
  const handleYamlFileChange = (file: File | undefined) => {
    setYamlFileError(null);
    if (file && selectedWorkflow && user) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const yamlContent = e.target?.result as string;
          // Convert the yaml content to JSON
          const jsonData = jsYaml.load(yamlContent);
          if (jsonData) {
            /**
             * VALIDATIONS FOR YAML FILE
             */
            validateYamlWorkflow(jsonData);
            handleYamlEditWorkflow({
              jsonData,
              selectedWorkflow,
              setYamlFileError,
              user,
              dispatch,
              navigate,
            });
          } else {
            // Handle the case where the YAML file is invalid
            setYamlFileError('Invalid YAML file');
            return;
          }
        } catch (error: any) {
          // Handle any errors that occur during parsing
          setYamlFileError(error.message || 'Error parsing YAML');
          return;
        }
      };
      reader.readAsText(file);
    } else {
      setYamlFileError('File is required.');
      return;
    }
  };

  /**
   * HANDLE REVIEW TASKS CLICK
   */
  const handleReviewTasksClick = (workflow: Workflow) => {
    navigate(
      `/tasks?${WORKFLOW_RESOURCE_NAMES}=${workflow.name}&tab=${TASKS_TAB_INDEX.PENDING_TAB}`,
    );
  };

  /**
   * USE EFFECTS
   */
  useEffect(() => {
    refreshPage();
  }, []);

  useEffect(() => {
    refreshPage();
  }, [
    displayNamePrefix,
    selectedApps,
    selectedModes,
    selectedTime,
    customRange,
    selectedUsers,
    isAdminView,
    isWorkflowAdminView,
  ]);

  useEffect(() => {
    if (listWorkflowError) {
      toastService.showError(listWorkflowError.message, {
        position: 'top-right',
      });
      dispatch(listWorkflowErrorAction());
    }
  }, [listWorkflowError]);

  useEffect(() => {
    if (updatedWorkflow && (updationTriggered || deletionTriggered)) {
      if (updationTriggered) {
        toastService.showSuccess(`Workflow is updated successfully`, {
          position: 'top-right',
        });
      } else {
        toastService.showSuccess(`Workflow is deleted successfully`, {
          position: 'top-right',
        });
      }
      // TODO: change this logic when batch deletion is supported
      if (totalSize > workflows.length) {
        refreshPage();
      }
    }
  }, [updatedWorkflow]);

  useEffect(() => {
    if (workflowError && (updationTriggered || deletionTriggered)) {
      // revert back the name since its not saved successfully
      toastService.showError(workflowError.message, {
        position: 'top-right',
      });
    }
  }, [workflowError]);

  return (
    <>
      <OrbyTable
        onTableRowClick={(data) => {
          const selectedWorkflow = data as Workflow;
          const id = getIdFromResourceName(selectedWorkflow.name as string);
          navigate(`/workflow/${id}/update`);
        }}
        tableWidth={tableWidth}
        isDataLoading={loading}
        noDataMessage={
          displayNamePrefix
            ? `No results for "${displayNamePrefix}"`
            : 'There are no workflows available.'
        }
        tableContainerSx={{
          width: 'auto',
          overflowX: 'auto',
        }}
        colSx={[
          {
            width: `${getWorkflowNameColumnWidth(tableWidth, 280)}px`,
            maxWidth: `${getWorkflowNameColumnWidth(tableWidth, 280)}px`,
            minWidth: `${getWorkflowNameColumnWidth(tableWidth, 280)}px`,
          },
          {
            width: '174px',
            maxWidth: '174px',
            minWidth: '174px',
          },
          {
            width: '142px',
            maxWidth: '142px',
            minWidth: '142px',
          },
          {
            width: '217px',
            minWidth: '217px',
            maxWidth: '217px',
          },
          {
            width: `${getWorkflowTimeWidth(tableWidth, 80)}px`,
            maxWidth: `${getWorkflowTimeWidth(tableWidth, 80)}px`,
            minWidth: `${getWorkflowTimeWidth(tableWidth, 80)}px`,
          },
          {
            width: '69px',
            maxWidth: '69px',
            minWidth: '69px',
          },
        ]}
        tableId='workflow-api-automation-table'
        showCheckBox={false}
        isCheckBoxDisabled={true}
        cursor='pointer'
        /**
         * PAGINATION
         */
        pagination={{
          rowsPerPage,
          currentPage: page,
          totalRows: totalSize,
          setCurrentPage: (pageNumber: number) =>
            handleWorkflowPageChange({
              workflows,
              pageNumber,
              page,
              rowsPerPage,
              setPage,
              listWorkflows,
            }),
          setRowsPerPage: (rows: number) =>
            handleWorkflowsRowSelectionChange({
              rowsNumber: rows,
              setPage,
              setRowsPerPage,
              listWorkflows,
            }),
        }}
        /**
         * TABLE HEADER ROWS
         */
        headerRows={[
          <OrbyHeaderLabel
            key={'workflow-header-workflow-name'}
            label='Workflow Name'
          />,
          <OrbyHeaderLabel key={'workflow-header-apps'} label='Apps' />,
          <OrbyHeaderLabel key={'workflow-header-mode'} label='Mode' />,
          <OrbyHeaderLabel key={'workflow-header-creator'} label='Creator' />,
          <OrbyHeaderLabel
            key={'workflow-header-time-modified'}
            label='Time Modified'
          />,
          <OrbyHeaderLabel key={'workflow-header-actions'} label='' />,
        ]}
        /**
         * TABLE ROWS
         */
        dataRows={pagedWorkflows.map((workflow) => {
          return {
            title: workflow.displayName!,
            id: workflow.name!,
            row: workflow,
            cells: [
              getWorkflowNameCell(
                workflow,
                displayNamePrefix,
                workflow.name
                  ? blockedWorkflowResourceNames.indexOf(workflow.name) !== -1
                  : false,
                handleReviewTasksClick,
              ),
              getWorkflowAppsCell(getApplicationNames(workflow)),
              getWorkflowModeCell(workflow),
              getWorkflowCreatorCell(workflow),
              getWorkflowTimeCell(workflow),
              getWorkflowActionMenuCell(
                Workflow.create(workflow),
                handleActionMenuClick,
              ),
            ],
          };
        })}
      />

      {/* WORKFLOW ACTION MENU */}
      {selectedWorkflow &&
        getWorkflowActionItem(
          actionMenuAnchorEl,
          actionMenuOpen,
          isSFTPToJsonWorkflow(selectedWorkflow),
          isRbacEnabled,
          handleActionMenuClose,
        )}

      {/* WORKFLOW DELETION MODAL */}
      <WorkflowDeletionModal
        open={isDeletionModalOpen}
        handleClose={handleCloseModal}
        onSubmit={handleDelete}
      />

      {/* TASK COPY MODAL */}
      {selectedWorkflow && (
        <TaskCopyModal
          open={isCopyModalOpen}
          setOpen={() => {
            setIsCopyModalOpen(false);
            setSelectedWorkflow(null);
          }}
          workflow={selectedWorkflow}
          setShowTaskCopyConfirmModal={setShowTaskCopyConfirmModal}
        />
      )}
      <TaskCopySuccessConfirmation
        showTaskCopyConfirmModal={showTaskCopyConfirmModal}
        setShowTaskCopyConfirmModal={setShowTaskCopyConfirmModal}
      />

      {selectedWorkflow && (
        <UploadYamlComponent
          yamlFileError={yamlFileError}
          handleFileChange={handleYamlFileChange}
          open={isYamlModalOpen.open}
          isEdit={isYamlModalOpen.isEdit}
          setYamlFileError={setYamlFileError}
          setOpen={(open) => {
            setIsYamlModalOpen({
              open: open,
              isEdit: true,
            });
            setYamlFileError(null);
          }}
        />
      )}

      {/* SHARE WORKFLOW MODAL */}
      {selectedWorkflow && (
        <ShareWorkflowModal
          open={isShareWorkflowModalOpen}
          setOpen={() => {
            setIsShareWorkflowModalOpen(false);
            setSelectedWorkflow(null);
          }}
          workflow={selectedWorkflow}
          onSubmit={handleShareWorkflow}
        />
      )}
    </>
  );
};

export default React.memo(WorkflowApiAutomationTab);
