import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { OrbyTypography } from 'orby-ui/src';
import SchedulerDatePicker from './SchedulerDatePicker';
import SchedulerTimeInput from './SchedulerTimeInput';
import SchedulerTimezoneSelector from './SchedulerTimezoneSelector';
import WorkflowSchedulerModalFooter from './WorkflowSchedulerModalFooter';
import { useDispatch, useSelector } from 'react-redux';
import {
  createWorkflowSchedule,
  updateWorkflowSchedule,
} from '../../../../redux/actions/workflow_details.constants';
import {
  validateStartDate,
  validateTime,
  VALIDATION_ERROR_MESSAGE,
} from '../utils/helper';
import { getWorkflowScheduleSelector } from '../../../../redux/selectors/workflow_details.selectors';
import { ScheduleScheduleStatus } from 'protos/pb/v1alpha1/schedule';
import moment from 'moment-timezone';
import { DateMessage } from 'protos/google/type/date';

interface Props {
  handleClose: () => void;
  workflowId: string;
  orgId: string;
  refreshDataHandler?: () => void;
}

const ScheduleOneTime: React.FC<Props> = ({
  handleClose,
  workflowId,
  orgId,
  refreshDataHandler = () => null,
}) => {
  const [startDate, setStartDate] = useState<DateMessage | null>(null);
  const [dateError, setDateError] = useState<string | null>(null);
  const [scheduledTimes, setScheduledTimes] = useState<
    { hours: string; minutes: string }[]
  >([]);
  const [timeError, setTimeError] = useState<string | null>(null);
  const [timezone, setTimezone] = useState<string>('');
  const [currentInputValue, setCurrentInputValue] = useState<{
    hours: string;
    minutes: string;
  } | null>(null);

  const dispatch = useDispatch();

  const editingSchedule = useSelector(getWorkflowScheduleSelector);

  const validateForm = () => {
    let isValid = true;
    const dateError = validateStartDate(startDate);
    if (dateError) {
      setDateError(dateError);
      isValid = false;
    }

    const timeValidationError = validateTime({
      hours: currentInputValue?.hours,
      minutes: currentInputValue?.minutes,
    });

    if (scheduledTimes.length === 0 && !timeValidationError) {
      setTimeError(VALIDATION_ERROR_MESSAGE.TIME);
      isValid = false;
    }
    if (scheduledTimes.length > 1) {
      setTimeError(VALIDATION_ERROR_MESSAGE.ONE_TIME_ALLOWED);
      isValid = false;
    }
    if (!timezone) {
      isValid = false;
    }

    // Check if the entered date + time + timezone has passed
    if (
      startDate &&
      timezone &&
      (scheduledTimes.length > 0 || currentInputValue)
    ) {
      const time = scheduledTimes[0] || currentInputValue;
      const scheduledDateTime = moment.tz(
        {
          year: startDate.year,
          month: startDate.month! - 1,
          date: startDate.day,
          hour: parseInt(time.hours),
          minute: parseInt(time.minutes),
        },
        timezone,
      );

      if (scheduledDateTime.isBefore(moment())) {
        setDateError('The scheduled date and time has already passed.');
        setTimeError('The scheduled date and time has already passed.');
        isValid = false;
      }
    }

    return isValid;
  };

  // Here we are formatting the request to be sent to the backend as per create or update API
  const requestFormatter = () => {
    const request = {
      schedule: {
        id: editingSchedule?.id,
        orgId,
        workflowId,
        timezoneName: timezone,
        startDate: startDate || undefined,
        status: editingSchedule?.status ?? ScheduleScheduleStatus.ACTIVE,
        config: {
          schedulePattern: {
            runTimes: scheduledTimes?.length
              ? scheduledTimes.map((time) => ({
                  hours: parseInt(time.hours),
                  minutes: parseInt(time.minutes),
                }))
              : [
                  {
                    hours: parseInt(currentInputValue!.hours),
                    minutes: parseInt(currentInputValue!.minutes),
                  },
                ],
            recurrencePattern: {
              noRecurrence: {},
            },
          },
          interval: 1,
        },
      },
    };

    return request;
  };

  const handleSave = () => {
    if (validateForm()) {
      dispatch(createWorkflowSchedule(requestFormatter(), refreshDataHandler));
      handleClose();
    }
  };

  useEffect(() => {
    if (editingSchedule?.id) {
      setStartDate(editingSchedule.startDate || null);
      setDateError(null);
      setScheduledTimes(
        editingSchedule.config?.schedulePattern?.runTimes?.map((time) => ({
          hours: time?.hours?.toString() || '',
          minutes: time?.minutes?.toString() || '',
        })) || [],
      );
      setTimeError(null);
      setTimezone(editingSchedule.timezoneName || '');
      setCurrentInputValue(null);
    }
  }, [editingSchedule]);

  const handleUpdate = () => {
    if (validateForm()) {
      dispatch(updateWorkflowSchedule(requestFormatter(), refreshDataHandler));
      handleClose();
    }
  };

  return (
    <>
      <Box>
        <OrbyTypography size='sm' color='grey-700' sx={{ marginBottom: '6px' }}>
          Start date
        </OrbyTypography>
        <SchedulerDatePicker
          date={startDate}
          setDate={setStartDate}
          dateError={dateError}
          setDateError={setDateError}
        />
      </Box>
      <Box>
        <OrbyTypography size='sm' color='grey-700' sx={{ marginBottom: '6px' }}>
          Time
        </OrbyTypography>
        <SchedulerTimeInput
          scheduledTimes={scheduledTimes}
          setScheduledTimes={setScheduledTimes}
          getCurrentInputValue={setCurrentInputValue}
          timeError={timeError}
          setTimeError={setTimeError}
        />
      </Box>
      <Box>
        <OrbyTypography size='sm' color='grey-700' sx={{ marginBottom: '6px' }}>
          Time zone
        </OrbyTypography>
        <SchedulerTimezoneSelector
          timezone={timezone}
          setTimezone={setTimezone}
        />
      </Box>

      <WorkflowSchedulerModalFooter
        setOpen={handleClose}
        handleSave={() => {
          if (editingSchedule?.id) {
            handleUpdate();
          } else {
            handleSave();
          }
        }}
      />
    </>
  );
};

export default ScheduleOneTime;
