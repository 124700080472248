import { all, call, put, takeLatest } from 'redux-saga/effects';
import { userService } from '../../services/UserService';
import { UserActionType } from '../actions/actions.constants';
import {
  addUserToOrganizationCompletedAction,
  addUserToOrganizationErrorAction,
  fetchLoggedInUserCompletedAction,
  fetchLoggedInUserErrorAction,
  listUsersCompletedAction,
  listUsersErrorAction,
  setSelectedOrgInfo,
  updateGoogleTokenAction,
  updateGoogleTokenCompletedAction,
  updateGoogleTokenErrorAction,
  updatePasswordCompletedAction,
  updatePasswordErrorAction,
  updateUserCompletedAction,
  updateUserErrorAction,
  getUserPermissionsCompletedAction,
  getUserPermissionsErrorAction,
} from '../actions/user.action';
import {
  AddUserToOrganizationRequest,
  GetResponse,
  ListUsersRequest,
  UpdateGoogleTokenRequest,
  UpdatePasswordRequest,
  UpdateRequest,
  GetUserPermissionsRequest,
} from 'protos/pb/v1alpha1/users_service';
import { OrgInfo, User } from 'protos/pb/v1alpha1/user';
import { storageService } from '../../services/StorageService';
import { getGoogleAuthCode } from '../../utils/helpers';
import { unauthenticatedErrorAction } from '../actions/auth.action';
import store from '../store';
import { isFeatureFlagEnabled } from '../../pages/FeatureFlags/FeatureFlagUtils';
import { FEATURE_FLAGS } from '../../utils/constants';

export function* getLoggedInUserSaga(): any {
  try {
    const resp: GetResponse = yield call(userService.getLoggedInUser);
    const orgResourceName: string | undefined = yield call(
      storageService.getStoredOrgResourceName,
    );
    const orgInfos = resp.user?.orgInfos;
    if (orgInfos?.length) {
      if (!orgResourceName) {
        yield call(
          storageService.setStoredOrgResourceName,
          orgInfos[0].orgResourceName as string,
        );
        yield put(setSelectedOrgInfo(orgInfos[0]));
      } else {
        const orgInfo: OrgInfo = orgInfos.find(
          (o) => o.orgResourceName === orgResourceName,
        ) as OrgInfo;
        yield put(setSelectedOrgInfo(orgInfo));
      }
    }
    yield put(fetchLoggedInUserCompletedAction(resp.user as User));
  } catch (e: any) {
    yield put(
      fetchLoggedInUserErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );

    yield put(
      unauthenticatedErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

export function* updateUserSaga(data: {
  type: UserActionType;
  payload: UpdateRequest;
}): any {
  try {
    const { response, error } = yield call(
      userService.updateUser,
      data.payload,
    );
    if (response) {
      yield put(updateUserCompletedAction(response.user));
    } else {
      yield put(updateUserErrorAction(error));
    }
  } catch (error) {
    yield put(updateUserErrorAction(error as Error));
  }
}

export function* updatePasswordSaga(data: {
  type: UserActionType;
  payload: UpdatePasswordRequest;
}): any {
  try {
    const { response, error } = yield call(
      userService.updatePassword,
      data.payload,
    );
    if (response) {
      yield put(updatePasswordCompletedAction(response.message));
    } else {
      yield put(updatePasswordErrorAction(error));
    }
  } catch (error) {
    yield put(updatePasswordErrorAction(error as string));
  }
}

export function* listUsersSaga(data: {
  type: UserActionType;
  payload: ListUsersRequest;
  refresh: boolean;
}): any {
  try {
    const { response, error } = yield call(userService.listUsers, data.payload);
    if (response) {
      yield put(
        listUsersCompletedAction(
          response.users,
          response.nextPageToken,
          response.totalSize,
          data.refresh,
        ),
      );
    } else {
      yield put(listUsersErrorAction(error));
    }
  } catch (error) {
    yield put(listUsersErrorAction(error as Error));
  }
}

export function* addUserToOrganizationSaga(data: {
  type: UserActionType;
  payload: AddUserToOrganizationRequest;
}): any {
  try {
    const { response, error } = yield call(
      userService.addUserToOrganization,
      data.payload,
    );
    if (response) {
      yield put(addUserToOrganizationCompletedAction(response));
    } else {
      yield put(addUserToOrganizationErrorAction(error));
    }
  } catch (error) {
    yield put(addUserToOrganizationErrorAction(error as Error));
  }
}

export function* updateGoogleToken(
  data: ReturnType<typeof updateGoogleTokenAction>,
): any {
  try {
    // get the google auth code
    const googleResponse: Awaited<ReturnType<typeof getGoogleAuthCode>> =
      yield call(getGoogleAuthCode, data.payload.scope);

    const {
      response,
      error,
    }: Awaited<ReturnType<typeof userService.updateGoogleToken>> = yield call(
      userService.updateGoogleToken,
      UpdateGoogleTokenRequest.create({
        googleAuthorizationCode: googleResponse.code,
      }),
    );
    const featureFlags =
      store.getState().featureFlags.featureFlagsForOrgAndUser;
    const isCookieEnabled = isFeatureFlagEnabled(
      FEATURE_FLAGS.COOKIE,
      featureFlags,
    );
    // Set the google scope in the local storage
    if (googleResponse.scope) {
      yield call(storageService.setStoredGoogleScope, googleResponse.scope);
    }
    // set the google token in the local storage
    if (response?.googleToken?.accessToken) {
      if (!isCookieEnabled) {
        yield call(storageService.setStoredGoogleToken, {
          accessToken: response.googleToken.accessToken,
          accessTokenExpiresAt: response.googleToken.expiry!,
        });
      }

      yield put(updateGoogleTokenCompletedAction());
    } else {
      yield put(updateGoogleTokenErrorAction(error));
    }
  } catch (e: any) {
    yield put(
      updateGoogleTokenErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

export function* getUserPermissionsSaga(data: {
  type: UserActionType;
  payload: OrgInfo;
}): any {
  try {
    const { response, error } = yield call(
      userService.getUserPermissions,
      GetUserPermissionsRequest.create({
        orgResourceName: data.payload.orgResourceName,
      }),
    );

    if (response) {
      yield put(getUserPermissionsCompletedAction(response.permittedActions));
    } else {
      yield put(getUserPermissionsErrorAction(error));
    }
  } catch (e: any) {
    yield put(
      getUserPermissionsErrorAction(
        (e?.errors && e.errors[0]?.message) || e?.message,
      ),
    );
  }
}

function* userSaga() {
  yield all([
    takeLatest(UserActionType.FETCH_LOGGED_IN_USER, getLoggedInUserSaga),
    takeLatest(UserActionType.UPDATE_USER, updateUserSaga),
    takeLatest(UserActionType.UPDATE_PASSWORD, updatePasswordSaga),
    takeLatest(UserActionType.LIST_USERS, listUsersSaga),
    takeLatest(
      UserActionType.ADD_USER_TO_ORGANIZATION,
      addUserToOrganizationSaga,
    ),
    takeLatest(UserActionType.UPDATE_GOOGLE_TOKEN, updateGoogleToken),
    takeLatest(UserActionType.SET_SELECTED_ORG_INFO, getUserPermissionsSaga),
  ]);
}

export default userSaga;
