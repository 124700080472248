import React from 'react';
import { OrbyBodyLabel, OrbyColorPalette } from 'orby-ui/src';
import { Field } from 'protos/pb/v1alpha1/field';
import { formatDate } from '../../../utils/helpers';
import { ReactComponent as MoveIcon } from '../../../static/icons/move.svg';
import { SxProps, Theme } from '@mui/material';

export const getIcon = (): JSX.Element => {
  return <MoveIcon width={'10px'} height={'18px'} />;
};

export const getLineItemQuantity = (fields?: Field[]): JSX.Element => {
  let quantity = '';

  if (fields?.length) {
    const field = fields?.find((f) => f.name === 'line item/quantity');
    if (field) {
      quantity = field.value?.text || '';
    }
  }

  return <TextCell text={quantity} />;
};

export const getLineItemAmount = (fields?: Field[]): JSX.Element => {
  let amount = '';

  if (fields?.length) {
    const amountField = fields?.find((f) => f.name === 'line item/amount');
    if (amountField) {
      amount = amountField.value?.text || '';
    }
  }

  return <TextCell text={amount} />;
};

export const getLineItemTax = (fields?: Field[]): JSX.Element => {
  let taxRate = '';

  if (fields?.length) {
    const taxField = fields?.find((f) => f.name === 'line item/tax rate');
    if (taxField) {
      taxRate = taxField.value?.text || '';
    }
  }

  return <TextCell text={taxRate} />;
};

export const getLineItemUnitCost = (fields?: Field[]): JSX.Element => {
  let unitCost = '';

  if (fields?.length) {
    const costField = fields?.find((f) => f.name === 'line item/unit cost');
    if (costField) {
      unitCost = costField.value?.text || '';
    }
  }

  // try normalizing the value to two decimal digits, since the default value
  // has four decimal digits by default.
  if (unitCost) {
    const value = parseFloat(unitCost);
    if (!isNaN(value)) {
      unitCost = value.toFixed(2);
    }
  }

  return (
    <TextCell
      text={unitCost}
      boxSx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    />
  );
};

export function TextCell(props: { text: string; boxSx?: SxProps<Theme> }) {
  return (
    <OrbyBodyLabel
      title={props.text}
      label={props.text}
      fontSize='xs'
      fontWeight='medium'
      color={OrbyColorPalette['grey-600']}
      boxSx={props.boxSx}
    />
  );
}

export const getLineItemDescription = (fields?: Field[]): JSX.Element => {
  let description = '';

  if (fields?.length) {
    const costField = fields?.find((f) => f.name === 'line item/description');
    if (costField) {
      description = costField.value?.text || '';
    }
  }

  return <TextCell text={description} />;
};

export const getLineItemDate = (fields?: Field[]): JSX.Element => {
  let date = '';

  if (fields?.length) {
    const dateField = fields?.find((f) => f.name === 'line item/date');
    if (dateField) {
      date = dateField.value?.text || '';
    }
  }

  return (
    <OrbyBodyLabel
      title={date}
      key={date}
      label={formatDate(new Date(date), 'DD/MM/YYYY')}
      fontSize='xs'
      fontWeight='medium'
      color={OrbyColorPalette['grey-600']}
      boxSx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    />
  );
};

export function getLineNumberFromFields(fields?: Field[]): string {
  if (fields?.length) {
    const lineNoField = fields?.find((f) => f.name === 'line item/line number');
    if (lineNoField) {
      return lineNoField.value?.text || '';
    }
  }
  return '';
}

export const getPOLineNumber = (fields?: Field[]): JSX.Element => {
  const lineNo = getLineNumberFromFields(fields);

  return <TextCell text={lineNo} />;
};

export const getCaapsColumnWidth = (index: number) => {
  let width;

  switch (index) {
    case 0:
      width = '56px';
      break;
    case 1:
      width = '180px';
      break;
    case 2:
      width = '97px';
      break;
    case 3:
      width = '84px';
      break;
    case 4:
      width = '70px';
      break;
  }

  return `${width} !important`;
};

export const getJdeColumnWidth = (index: number) => {
  let width;

  switch (index) {
    case 0:
      width = '200px';
      break;
    case 1:
      width = '100px';
      break;
    case 2:
      width = '71px';
      break;
    case 3:
      width = '72px';
      break;
    case 4:
      width = '44px';
      break;
  }

  return `${width} !important`;
};
