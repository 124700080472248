import dayjs, { Dayjs } from 'dayjs';
import { SchemaEntity } from '../pages/workflow-creation/step-contents/ExtractSchemaDefinition';

import {
  GmailLabel,
  GmailLabelColor,
} from 'protos/automation_mining/ontology/data_models';
import { Connector, Operator } from 'protos/pb/v1alpha2/connector';

import {
  DeleteBatchTasksRequest,
  DeleteBatchTasksResponse,
  Task,
  TaskobsoleteReason,
} from 'protos/pb/v1alpha2/tasks_service';
import { EntityDataType } from 'protos/pb/v1alpha2/workflow_steps_params';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import { v4 as uuidv4 } from 'uuid';

export const ORBY_AI_ORG = 'Orby AI';

// Use this to configure all the feature flags
export const FEATURE_FLAGS = {
  TABLE_DEBUG_LAYOUT: 'table_debug_layout',
  WEB_APP_INTEGRATION: 'WEB_APP_INTEGRATION',
  NESTED_HITL: 'nested hitl',
  RBAC: 'rbac',
  RECORD_WORKFLOW: 'record_workflow',
  SCHEDULE_WORKFLOW: 'schedule_workflow',
  COOKIE: 'cookie',
  SECRETS_UI_CHANGE: 'secrets_ui_change',
  ADVANCED_WORKFLOW_EDITOR: 'advanced_workflow_editor',
  TASK_REVIEW_ACTIVITY_MONITOR: 'task_review_activity_monitor',
};

export const TASK_DECLINED_REASON = {
  [TaskobsoleteReason.LARGE_DOCUMENT_SIZE]: 'Oversized file',
  [TaskobsoleteReason.FAILED_AFTER_ACCEPTED]: 'Failed to update Google Sheets',
  [TaskobsoleteReason.INVALID_DOCUMENT_MIME_TYPE]:
    'Unsupported file types. Only PDF is supported',
  [TaskobsoleteReason.TEMPORAL_WORKFLOW_NOT_FOUND]:
    'Workflow template not found',
  [TaskobsoleteReason.TEMPORAL_WORKFLOW_FAILED]: 'Internal error',
  [TaskobsoleteReason.NO_AVAILABLE_USERS]:
    'All users reached the maximum pending tasks',
  [TaskobsoleteReason.LOW_DOCUMENT_CLASSIFICATION_SCORE]:
    'Mismatched document type',
  [TaskobsoleteReason.UNMATCHED_CLASSIFICATION]: 'Mismatched document type',
  [TaskobsoleteReason.FAILED_EXECUTION_ENGINE]: 'Internal error',
  [TaskobsoleteReason.MAX_WORKFLOW_LIMIT_REACHED]:
    'Workflow reached the maximum pending tasks',
  [TaskobsoleteReason.OBSOLETE_REASON_UNSPECIFIED]: 'Unspecified reason',
  [TaskobsoleteReason.FAILED_TO_CREATE_TASK]: 'Internal error',
  [TaskobsoleteReason.FILE_DECRYPTION_FAILED]: 'File decryption failed',
  [TaskobsoleteReason.WRONG_FILE_FORMAT]:
    'Wrong file format. Need an encrypted file',
};

// THIS IS USED FOR MICROSOFT PERSONAL ACCOUNTS FOR FILE PICKER
export const MICROSOFT_CONSUMER_URL =
  'https://login.microsoftonline.com/consumers';
export const MICROSOFT_COMMON_URL = 'https://login.microsoftonline.com/common';

export const MICROSOFT_USER_INFO_TYPE = {
  PERSONAL: 'personal',
  BUSINESS: 'business',
};

export const GDRIVE_SFTP_MAPPING_COLUMNS = ['file name', 'document type'];
export const GMAIL_MAPPING_COLUMNS = [
  'subject',
  'time',
  'from',
  'document type',
];
// GOOGLE SCOPES
export const GOOGLE_DEFAULT_SCOPE = 'email profile';
export const GOOGLE_DRIVE_SCOPE = 'https://www.googleapis.com/auth/drive';
export const GOOGLE_GMAIL_SCOPE =
  'https://www.googleapis.com/auth/gmail.modify';

// MICROSOFT SCOPES
export const MICROSOFT_DEFAULT_SCOPE = ['User.Read', 'Sites.Read.All'];
export const MICROSOFT_OUTLOOK_SCOPE = [
  'Mail.ReadWrite',
  'MailboxSettings.ReadWrite',
];
export const MICROSOFT_DRIVE_SCOPE = ['Files.ReadWrite'];

export const ORBYAI_UNKNOWN = 'OrbyAI_Unknown';
export const IS_PROD = process.env.REACT_APP_ENV === 'production';
export const IS_PRE_PROD = process.env.REACT_APP_ENV === 'preproduction';
export const IS_DEV = process.env.REACT_APP_ENV === 'development';
export const IS_LOCAL = process.env.REACT_APP_ENV === 'local';

export const CLASSIFICATION_SCHEMA_DOCUMENT_TYPE = 'document type';

export enum EMAIL_DEFAULT_ENTITIES {
  SUBJECT = 'subject',
  TIME = 'time',
  FROM = 'from',
}

// REF- https://orby-ai.atlassian.net/browse/OA-2006
export enum EntityFilter {
  NEED_ATTENTION = 'NEED_ATTENTION',
  PREDICTED = 'PREDICTED',
  REVIEWED = 'REVIEWED',
}

export const PADDING_BW_PDF_PAGES = 8;

export enum AutomationProgressStatus {
  DEFAULT,
  ACCEPTING_TASK,
  DECLINING_TASK,
}

/**
 * This is the default confidence score that we use to filter the entities
 * which are predicted by ML but have low confidence score and hence
 * are show under NEED ATTENTION filter to the user
 */
export const DEFAULT_CONFIDENCE_SCORE = 0.85;

// WIDTH OF FLOATING MODAL USED IN REVIEW PAGE
export const FLOATING_MODAL_WIDTH = 350;

export const DEFAULT_TABLE_MODAL_WIDTH = 900;

export const TABLE_MODAL_MIN_WIDTH = 450;
export const TABLE_MODAL_MIN_HEIGHT = 300;

// ROWS PER PAGE AT BOTTOM OF TABLE
export const ROWS_PER_PAGE = [5, 10, 15, 20];
export const WORKFLOW_TEMPLATE_ROWS_PER_PAGE = [5, 10];

export const REVIEW_PAGE_MIN_ZOOM = 0.2;
export const DEFAULT_REVIEW_PAGE_ZOOM = 0.4;
export const REVIEW_PAGE_MAX_ZOOM = 1.6;
export const REVIEW_PAGE_ZOOM_IN_VALUE = 0.1;

export const DRAWER_WIDTH_COLLAPSED = 80;
export const DRAWER_WIDTH_EXPANDED = 240;

// When there is no text segment info, we use a box of these dimensions by default
export const DEFAULT_BOX_WIDTH = 120;
export const DEFAULT_BOX_HEIGHT = 50;

export const REVIEW_PAGE_TOP_BAR_HEIGHT = 80;
export const REVIEW_PAGE_TOP_MARGIN = 8;

export const TEXT_SEGMENT_FIELD_ID = 'text-segment-field';

export const STEP_CONTENT_WIDTH = 1056;
export const STEP_CONTENT_LAST_STEP_WIDTH = 1006;

export const MAX_FILES_ALLOWED_TO_BE_UPLOADED_IN_BATCH = 20;

export const ELLIPSIS_STYLE = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const TASKS_TAB_INDEX = {
  PENDING_TAB: 0,
  COMPLETED_TAB: 1,
  DECLINED_TAB: 2,
};

export const EXECUTIONS_TAB_INDEX = {
  UI_AUTOMATIONS: 0,
  API_AUTOMATIONS: 1,
};

export const FileName = 'file name';
export const defaultEntityIdPrefix = 'default-entity-';
export const getDefaultEntityId = () => defaultEntityIdPrefix + uuidv4();

export const emailDefaultEntities: SchemaEntity[] = [
  {
    id: getDefaultEntityId(),
    entityName: EMAIL_DEFAULT_ENTITIES.SUBJECT,
    normalizationType: EntityDataType.ENTITY_TYPE_UNSPECIFIED,
  },
  {
    id: getDefaultEntityId(),
    entityName: EMAIL_DEFAULT_ENTITIES.TIME,
    normalizationType: EntityDataType.ENTITY_TYPE_UNSPECIFIED,
  },
  {
    id: getDefaultEntityId(),
    entityName: EMAIL_DEFAULT_ENTITIES.FROM,
    normalizationType: EntityDataType.ENTITY_TYPE_UNSPECIFIED,
  },
];

export const fileNameEntity: SchemaEntity[] = [
  {
    id: getDefaultEntityId(),
    entityName: FileName,
    normalizationType: EntityDataType.ENTITY_TYPE_UNSPECIFIED,
  },
];
export const SELECTED_TAB = 'tab';
// Filter key to filter execution based on display name
export const NAME_PREFIX = 'name_prefix';
// Filter key to filter/search users based on email or full_name
export const NAME_EMAIL_PREFIX = 'name_email_prefix';
// Filter key to filter tasks based on display name
export const DISPLAY_NAME_PREFIX = 'display_name_prefix';
// Filter key to filter tasks based on workflow resource name(s)
export const WORKFLOW_RESOURCE_NAMES = 'workflow_resource_names';
// Filter key to filter tasks based on workflow apps(s)
export const WORKFLOW_APPS = 'application';
// Filter key to filter tasks based on workflow mode(s)
export const WORKFLOW_MODES = 'mode';
// Filter key to filter dashboard stats based on user resource name
export const USER_RESOURCE_NAMES = 'user_resource_names';
// Filter key to filter dashboard stats based on period (7d, yesterday etc)
export const PERIOD = 'period';
// Filter key to filter dashboard stats based on start_date
export const START_DATE = 'start_date';
// Filter key to filter dashboard stats based on end_date
export const END_DATE = 'end_date';
// Filter key to filter tasks based on display name
export const ROLE_FILTER_PREFIX = 'role';

//Filter to store current page number
export const PAGE_NO = 'page_no';

// Filter key to store current page size
export const PAGE_SIZE = 'page_size';

// Filter key to filter tasks based on status
export const API_EXECUTION_STATUS_FILTER = 'api_execution_status_filter';

// Filter key to filter tasks based on status
export const UI_EXECUTION_STATUS_FILTER = 'ui_execution_status_filter';

// filter key to filter tasks based on assignment / reviewers
export const USERNAMES = 'usernames';

// filter key to filter ui automation workflows based on / reviewers
export const USER_IDS = 'userIds';

// If this is added in usernames filter, then Backend returns all tasks that are unassigned
export const ORBY_UNASSIGNED = 'OrbyUnassigned';

export interface SelectedExtractedField {
  id: string;
  entityName: string;
  parent: string | undefined;
}

export const CLASSIFICATION_LABEL_SELECT = 'classification_label_select';

// Grouped operator values for strings
export const stringOperatorValues = [
  { value: Operator.EXISTS, label: 'Exists' },
  { value: Operator.DOES_NOT_EXIST, label: 'Does not exist' },
  { value: Operator.EQUAL, label: 'Equal to' },
  { value: Operator.CONTAINS, label: 'Contains' },
];

// Grouped operator values for classification labels
export const classificationOperatorValues = [
  { value: Operator.EQUAL, label: 'Equal to' },
  { value: Operator.CONTAINS, label: 'Contains' },
];

// Grouped operator values for numbers and dates
export const numberDateOperatorValues = [
  { value: Operator.EXISTS, label: 'Exists' },
  { value: Operator.DOES_NOT_EXIST, label: 'Does not exist' },
  { value: Operator.EQUAL, label: 'Equal to' },
  { value: Operator.LESS_THAN, label: 'Less than' },
  { value: Operator.LESS_THAN_EQUAL, label: 'Less than or equal to' },
  { value: Operator.GREATER_THAN, label: 'Greater than' },
  {
    value: Operator.GREATER_THAN_EQUAL,
    label: 'Greater than or equal to',
  },
];

export const extractionOperators = [Operator.EXISTS, Operator.DOES_NOT_EXIST];

export const defaultDateRange = {
  startDate: dayjs().startOf('day'),
  endDate: dayjs(),
};

export enum DateOptionValues {
  TODAY = '1d',
  YESTERDAY = '2d',
  LAST_WEEK = '7d',
  LAST_MONTH = '30d',
  CUSTOM_RANGE = 'cr',
}

export const dateOptions = [
  { label: 'Today', value: DateOptionValues.TODAY },
  { label: 'Yesterday', value: DateOptionValues.YESTERDAY },
  { label: 'Last 7 days', value: DateOptionValues.LAST_WEEK },
  { label: 'Last 30 days', value: DateOptionValues.LAST_MONTH },
  { label: 'Custom range', value: DateOptionValues.CUSTOM_RANGE },
];

export enum WorkflowType {
  'extraction',
  'classification',
}

export type DateRange = { startDate: Dayjs; endDate: Dayjs };

export type Filter = { label: string; value: string };

export type TimeRange = { start: number; end: number };

export enum ArchivedResourceType {
  TASK = 'task',
  WORKFLOW = 'workflow',
  CONNECTOR = 'connector',
}

export enum ActionType {
  SINGLE = 'single',
  BATCH = 'batch',
}

export type FilteredResource = Task | Workflow | Connector;

export const ArchivedResourceFilters = {
  [ArchivedResourceType.TASK]: 'All Tasks',
  [ArchivedResourceType.WORKFLOW]: 'All Workflows',
  [ArchivedResourceType.CONNECTOR]: 'All Connectors',
};

export enum DeleteTaskType {
  PENDING = 'pending',
  PENDING_ADDITIONAL = 'pending_additional',
  COMPLETED = 'completed',
  SYSTEM_DECLINED = 'system_declined',
  USER_DECLINED = 'user_declined',
}

export interface DeleteTaskActionPayload {
  req: DeleteBatchTasksRequest;
  deleteType: DeleteTaskType;
}

export interface DeleteTaskActionCompletedPayload {
  res: DeleteBatchTasksResponse;
  deleteType: DeleteTaskType;
}

export const invalidNotesEntityNameMsg =
  'The "*" symbol should only be present at the beginning of the entity name';
export const invalidCharactersInEntityNameMsg =
  'Entity name can only contain letters, numbers, spaces " ", notes "*", underscores "_", or dashes "-"';

export enum DeclinedTaskTabs {
  SYSTEM_DECLINED = 'system_declined',
  USER_DECLINED = 'user_declined',
}

export enum AssignmentMode {
  UNASSIGNED = 'unassigned',
  ASSIGNED = 'assigned',
}

export const selectedTasksPermissionErrorMsg =
  'You don’t have permissions to perform this action on some of the selected tasks';

export const ENABLE_PASSWORD_BASED_LOGIN = 'enablePasswordBasedLogin';

export const ISO_8601_DATE_FORMAT = 'YYYY-MM-DD';

export const invalidCharactersClassificationMsg =
  'Entity name can only contain letters, numbers, spaces " ", underscores "_", or dashes "-"';

export const enum EntityTypeString {
  ENTITY_TYPE_ANNOTATION = 'ENTITY_TYPE_ANNOTATION',
  ENTITY_TYPE_TEXT = 'ENTITY_TYPE_TEXT',
  ENTITY_TYPE_NESTED = 'ENTITY_TYPE_NESTED',
}

export enum ADMIN_TASK_FILTER {
  ALL_TASKS = 'All tasks', // ALL TASKS (ALL UNASSIGNED TASKS + ASSIGNED TASK)
  UNASSIGNED_ONLY = 'Unassigned only', // ALL UNASSIGNED TASKS
  REVIEWERS_ONLY = 'Reviewers only', // TASKS ASSIGNED TO SELECTED REVIEWERS
  UNASSIGNED_WITH_REVIEWERS = 'Unassigned with users', // ALL UNASSIGNED TASKS + TASKS ASSIGNED TO SELECTED REVIEWERS
}

export enum USER_TASK_FILTER {
  ALL_TASKS = 'All tasks', // ALL UNASSIGNED TASKS + ALL TASKS ASSIGNED TO USER
  UNASSIGNED_ONLY = 'Unassigned only', // ALL UNASSIGNED TASKS OF THE WORKFLOW USER IS PART OF
  ASSIGNED_TO_ME = 'Assigned to me', // ALL TASKS ASSIGNED TO USER
}

export enum AuthPlatform {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
  PASSWORD = 'password',
  SAML = 'saml',
}

export enum ReassignmentOptions {
  DEFAULT = 'default', // default value
  UNASSIGN = 'Unassign',
  ASSIGN_TO_MYSELF = 'Assign to me',
}

export const colorMap = {
  None: 'black',
  preset0: '#FF0000',
  preset1: '#FFA500',
  preset2: '#A52A2A',
  preset3: '#FFFF00',
  preset4: '#008000',
  preset5: '#008080',
  preset6: '#808000',
  preset7: '#0000FF',
  preset8: '#800080',
  preset9: '#931B46',
  preset10: '#767676',
  preset11: '#484848',
  preset12: '#808080',
  preset13: '#A9A9A9',
  preset14: '#000000',
  preset15: '#8B0000',
  preset16: '#FF8C00',
  preset17: '#5C3317',
  preset18: '#CCCC00',
  preset19: '#006400',
  preset20: '#004C4C',
  preset21: '#556B2F',
  preset22: '#00008B',
  preset23: '#4B0082',
  preset24: '#4C112A',
  customDefinedInbox: '344054',
};

export const hexToRgba = (hex: string, opacity: number) => {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 digits
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

export const customOutlookInbox = GmailLabel.create({
  id: 'outlook-all-emails',
  name: 'All Emails',
  color: GmailLabelColor.create({
    textColor: 'customDefinedInbox',
    backgroundColor: '#DDDDDD',
  }),
});

export const SAML_REDIRECT_STATUS_KEY = 'is_saml_redirect';

export const MAX_SIMPLE_ENTITIES = 48;
export const MAX_NESTED_ENTITIES = 32;

// The limit is enforced at server side through GRPC MaxRecvMsgSize().
export const MAX_PROTO_SIZE_IN_BYTES = 1024 * 1024 * 100;

export const LOW_CONFIDENCE_THRESHOLD = 20;
export const HIGH_CONFIDENCE_THRESHOLD = 80;
export const MAX_SCORE_FOR_LOW_CONFIDENCE = 0.8;
export const SAMPLE_ACTIONS_TO_REVIEW = 0.1;

export const MIN_TABLE_POPOVER_CELL_WITH = 180;

export const REFRESH_BLOCKED_EXECUTION_INTERVAL_MS = 30000;

export const STORAGE_KEYS = {
  SESSION_ID: 'session-id',
};

/**
 * Timeout duration for on-screen task review activity in milliseconds (1 minute)
 */
export const ON_SCREEN_TASK_REVIEW_ACTIVITY_TIMEOUT = 60 * 1000; // 1 min

/**
 * Timeout duration for off-screen task review activity in milliseconds (5 seconds)
 */
export const OFF_SCREEN_TASK_REVIEW_ACTIVITY_TIMEOUT = 5 * 1000;
