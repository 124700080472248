import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box } from '@mui/material';
import OrbyTypography from '../../../typography/OrbyTypography';
import OrbySelect from '../../../select/OrbySelect';
import OrbyMenuItem from '../../../menuItem/OrbyMenuItem';
import React from 'react';
import OrbyColorPalette from '../../../colors/ColorPalette';
import OrbyPageBox from './OrbyPageBox';
import { DEFAULT_FIRST_PAGE, ROWS_PER_PAGE } from '../../table-utils';

interface OrbyPaginationProps {
  rowsPerPage: number;
  totalSize?: number;
  page: number;
  justifyContent?: 'center' | 'end';
  onRowsPerPageChange?: (rows: number) => void;
  onPageChange: (page: number) => void;
  ariaLabel?: string;
  isShowSelectFilter?: boolean;
}

const OrbyPagination: React.FC<OrbyPaginationProps> = ({
  rowsPerPage,
  totalSize = 0,
  page,
  justifyContent = 'end',
  onRowsPerPageChange,
  onPageChange,
  isShowSelectFilter = true,
}) => {
  const totalPages = Math.ceil(totalSize / rowsPerPage);
  const currentPageFirstItemIndex = (page - 1) * rowsPerPage + 1;
  const currentPageLastItemIndex = page * rowsPerPage;

  return (
    <Box
      display={'flex'}
      component={'nav'}
      role='navigation'
      aria-label='Pagination Navigation'
      justifyContent={justifyContent}
      alignItems={'center'}
      marginTop={'16px'}
    >
      {totalSize > rowsPerPage && (
        <>
          <Box
            role='region'
            aria-label={`You’re currently on page ${
              page
            } out of total ${totalPages} page`}
          >
            <Box aria-hidden={true}>
              <OrbyTypography
                sx={{
                  marginRight: '12px',
                }}
                componentType={'span'}
                color='#545454'
              >
                {currentPageFirstItemIndex}-
                {currentPageLastItemIndex > totalSize
                  ? totalSize || rowsPerPage
                  : currentPageLastItemIndex}
                {` of `} {totalSize || rowsPerPage}
              </OrbyTypography>
            </Box>
          </Box>
          <OrbyPageBox
            ariaLabel='Click to navigate to previous page.'
            bgColor={OrbyColorPalette['white-0']}
            component={'button'}
            isIcon={true}
            title={
              <KeyboardArrowLeft
                sx={{
                  // marginTop: '2px',
                  cursor:
                    page <= DEFAULT_FIRST_PAGE ? 'not-allowed' : 'pointer',
                }}
              />
            }
            disabled={page <= DEFAULT_FIRST_PAGE}
            onClick={(e) => {
              // The reason for doing e.preventDefault is to disable the default behavior of the button
              // When formik is used, the default behavior of the button is to submit the form unless the type is set to button
              e.preventDefault();
              onPageChange(page - 1);
            }}
          />
          <OrbyPageBox
            bgColor={OrbyColorPalette['white-0']}
            ariaLabel={`Page ${page}`}
            role='region'
            title={page}
            selected
            isIcon={false}
          />
          <OrbyPageBox
            bgColor={OrbyColorPalette['white-0']}
            component={'button'}
            ariaLabel='Click to navigate to next page.'
            isIcon={true}
            title={
              <KeyboardArrowRight
                sx={{
                  marginTop: '2px',
                  cursor:
                    totalPages === 0 || page >= totalPages
                      ? 'not-allowed'
                      : 'pointer',
                }}
              />
            }
            disabled={totalPages === 0 || page >= totalPages}
            onClick={(e) => {
              // The reason for doing e.preventDefault is to disable the default behavior of the button
              // When formik is used, the default behavior of the button is to submit the form unless the type is set to button
              e.preventDefault();
              onPageChange(page + 1);
            }}
          />
        </>
      )}
      {isShowSelectFilter && (
        <Box
          sx={{
            marginLeft: '16px',
          }}
        >
          <OrbySelect
            renderValue={() => `${rowsPerPage} rows/page`}
            onChange={(event) => {
              onRowsPerPageChange?.(event.target.value as number);
            }}
            width='200px'
            value={rowsPerPage}
          >
            {ROWS_PER_PAGE.map((row) => (
              <OrbyMenuItem
                width='200px'
                key={row}
                title={`${row} rows/page`}
                value={row}
                isSelected={rowsPerPage === row}
              />
            ))}
          </OrbySelect>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(OrbyPagination);
