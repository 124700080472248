import { Box, Stack, styled } from '@mui/material';
import { OrbyButton, OrbyTabs } from 'orby-ui/src';
import { WorkflowVariable } from 'protos/pb/v1alpha1/orbot_action';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '../../../utils/helpers';
import { canEditWorkflow } from '../../../utils/rbac';
import { getWorkflowVariables } from 'workflow-utils/src/v2/workflow';
import Frame from './Frame';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../redux/selectors/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { GetWorkflowRequest } from 'protos/pb/v1alpha1/orbot_service';
import {
  fetchWorkflow,
  saveWorkflow,
  setHasUnsavedChanges,
  updateWorkflowTitle,
} from '../../../redux/actions/workflow_details.constants';
import { workflowAllDetailsSelector } from '../../../redux/selectors/workflow_details.selectors';
import { Status } from '../../../redux/reducers/workflow_detail.reducer';
import Settings from './Settings';
import Workflow from './Workflow';
import FullPageLoader from '../../../components/FullPageLoader';
import { EXTENSION_ID } from 'extension/src/constants';
import ScheduleWorkflow from './ScheduleWorkflow';
import { isFeatureFlagEnabled } from '../../FeatureFlags/FeatureFlagUtils';
import { FEATURE_FLAGS } from '../../../utils/constants';
import { getFeatureFlagsForOrgAndUserSelector } from '../../../redux/selectors/feature_flags.selectors';
import { UserOrgRole } from 'protos/pb/v1alpha1/user';
import { allSecretBlocksSetSelector } from '../../../redux/selectors/workflow_secrets.selectors';
import AdvancedWorkflowEditor from './AdvancedWorkflowEditor';

interface TabOption {
  label: string;
  id: string;
}

interface Props {
  sideDrawerCollapse: boolean;
}

// This function is for getting the tab options for the workflow details page and modifying the tabs according to the user's role
// Also it adjusts the tab id as per the number of tabs
const getTabOptions = (
  isScheduleWorkflowEnabled: boolean,
  isAdvancedEditorEnabled: boolean,
  userRole?: UserOrgRole,
): TabOption[] => {
  let tabOptions: TabOption[] = [
    {
      label: 'Workflow Steps',
      id: '0',
    },
  ];

  const userCanEditWorkflow = canEditWorkflow(userRole);
  if (userCanEditWorkflow) {
    tabOptions.push({
      label: 'Review Mode',
      id: '1',
    });
    if (isScheduleWorkflowEnabled) {
      tabOptions.push({
        label: 'Schedule',
        id: '2',
      });
    }

    if (isAdvancedEditorEnabled) {
      tabOptions.push({
        label: 'Advanced Editor',
        id: '3',
      });
    }
  }

  tabOptions = tabOptions.map((tab, index) => ({
    ...tab,
    id: index.toString(),
  }));
  return tabOptions;
};

const Container = styled(Box)(() => ({
  padding: '40px',
}));

const WorkflowDetails: React.FC<Props> = ({ sideDrawerCollapse }) => {
  const { workflow_id: workflowId } = useParams<{
    workflow_id: string;
  }>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Information about the current logged in user
  const user = useSelector(loggedInUserSelector);

  // All the information of workflow details
  const workflowStore = useSelector(workflowAllDetailsSelector);

  // All information of secrets this workflow uses
  const allSecretBlocksSet = useSelector(allSecretBlocksSetSelector);

  // Sanity check: All secret IDs should be in ID form before running

  // Getting the org id
  const orgInfo = useSelector(selectedOrgInfoSelector)!;
  const orgId = orgInfo?.orgResourceName!.replace(
    'organizations/',
    '',
  ) as string;

  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const isScheduleWorkflowEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.SCHEDULE_WORKFLOW,
    featureFlags,
  );

  const isAdvacedEditorEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ADVANCED_WORKFLOW_EDITOR,
    featureFlags,
  );

  // Local states
  const [workflowVariables, setWorkflowVariables] = useState<
    WorkflowVariable[]
  >([]);
  const [selectedTab, setSelectedTab] = useState<string>('0');

  // Request const for fetching the workflow
  const fetchWorkflowRequest: GetWorkflowRequest = useMemo(() => {
    return {
      workflowId: workflowId,
      orgId,
    };
  }, [workflowId]);

  // Getting the role of the user for the current org
  const getRole = useCallback(() => {
    for (const orgInfo of user?.orgInfos || []) {
      if (orgInfo.orgResourceName?.replace('organizations/', '') === orgId) {
        return orgInfo.role;
      }
    }
    return undefined;
  }, [user?.orgInfos?.length, orgId]);

  const canUserEditWorkflow = canEditWorkflow(getRole());

  const tabOptions =
    getTabOptions(
      isScheduleWorkflowEnabled,
      isAdvacedEditorEnabled,
      getRole(),
    ) || [];

  const selectedOption = tabOptions.find((option) => option.id === selectedTab);

  useEffect(() => {
    // Setting the hasUnsavedChanges to false when the workflow is loaded
    dispatch(setHasUnsavedChanges(false));
  }, []);

  // TODO: chrome.runtime would be defined if any extension allow us to connect,
  //       we need better ways to check if Orby extension is installed.
  const canRunWorkflow = !!chrome.runtime;

  useEffect(() => {
    if (!workflowId) return;
    dispatch(fetchWorkflow(fetchWorkflowRequest));
  }, [workflowId]);

  useEffect(() => {
    if (!workflowStore?.workflow?.id) return;
    setWorkflowVariables(getWorkflowVariables(workflowStore.workflow!));
  }, [workflowStore?.workflow?.id]);

  // The below is for setting the tabs according to the user's role
  useEffect(() => {
    if (selectedTab !== undefined) {
      if (selectedOption) {
        let path;
        switch (selectedOption.label) {
          case 'Workflow Steps':
            path = 'definition';
            break;
          case 'Review Mode':
            path = 'settings';
            break;
          case 'Schedule':
            path = 'schedule';
            break;
          case 'Advanced Editor':
            path = 'advanced-editor';
            break;
          default:
            path = 'definition';
            break;
        }
        navigate(
          {
            pathname: `/workflow/${workflowId}/${path}`,
          },
          { replace: true },
        );
      }
    }
  }, [selectedTab, workflowId, tabOptions.length]);

  useEffect(() => {
    const path = window.location.pathname;
    if (path.endsWith('/definition')) {
      setSelectedTab(
        tabOptions.find((tab) => tab.label === 'Workflow Steps')?.id || '0',
      );
    } else if (path.endsWith('/settings')) {
      setSelectedTab(
        tabOptions.find((tab) => tab.label === 'Review Mode')?.id || '0',
      );
    } else if (path.endsWith('/schedule')) {
      setSelectedTab(
        tabOptions.find((tab) => tab.label === 'Schedule')?.id || '0',
      );
    } else if (path.endsWith('/advanced-editor')) {
      setSelectedTab(
        tabOptions.find((tab) => tab.label === 'Advanced Editor')?.id || '0',
      );
    } else {
      setSelectedTab(
        tabOptions.find((tab) => tab.label === 'Workflow Steps')?.id || '0',
      );
    }
  }, [window.location.pathname, tabOptions.length]);

  const updateWorkflowVariable = (key: string, value: string) => {
    setWorkflowVariables((prev) =>
      prev.filter(({ key: k }) => k !== key).concat({ key, value }),
    );
  };

  const runWorkflow = () => {
    if (chrome.runtime) {
      chrome.runtime.sendMessage(EXTENSION_ID, {
        type: 'runWorkflow',
        workflowId,
      });
    }
  };

  if (workflowStore.status === Status.Loading || workflowStore.loading) {
    return <FullPageLoader />;
  }

  const renderTabContent = () => {
    switch (selectedOption?.label) {
      case 'Review Mode':
        return <Settings workflow={workflowStore.workflow} />;
      case 'Schedule':
        return (
          <ScheduleWorkflow
            workflow={workflowStore.workflow}
            sideDrawerCollapse={sideDrawerCollapse}
            isSecretsSet={allSecretBlocksSet}
          />
        );
      case 'Advanced Editor':
        return <AdvancedWorkflowEditor workflow={workflowStore.workflow} />;
      case 'Workflow Steps':
      default:
        return (
          <Workflow
            workflow={workflowStore.workflow}
            isEditable={canUserEditWorkflow}
            workflowVariables={workflowVariables}
            updateWorkflowVariable={updateWorkflowVariable}
            isTemplate={false}
          />
        );
    }
  };

  return (
    <Frame
      title={workflowStore?.workflow?.displayName || ''}
      enableLogoBack
      onTitleChange={
        canUserEditWorkflow
          ? (text: string) => dispatch(updateWorkflowTitle(text))
          : undefined
      }
      description={
        workflowStore?.workflow?.lastUpdateTime &&
        `Saved ${formatDate(workflowStore?.workflow?.lastUpdateTime)}`
      }
      headerChildren={
        <Box>
          <OrbyTabs
            tabs={tabOptions}
            selectedTab={+selectedTab}
            setSelectedTab={(tabNumber) => setSelectedTab(`${tabNumber}`)}
          />
        </Box>
      }
      action={
        <Stack direction='row' spacing={2}>
          {canUserEditWorkflow && (
            <OrbyButton
              sx={{ height: '36px', borderColor: '#0000001F' }}
              variant='monochrome-outline'
              disabled={
                !workflowStore.hasUnsavedChanges ||
                workflowStore.invalidateChanges.size > 0
              }
              label='Save'
              onClick={() =>
                dispatch(saveWorkflow(workflowStore.workflow!, orgId))
              }
            />
          )}

          {canRunWorkflow && (
            <OrbyButton
              sx={{ height: '36px', borderColor: '#0000001F' }}
              variant='monochrome-outline'
              onClick={runWorkflow}
              disabled={
                workflowStore.hasUnsavedChanges ||
                (isFeatureFlagEnabled(
                  FEATURE_FLAGS.SECRETS_UI_CHANGE,
                  featureFlags,
                ) &&
                  !allSecretBlocksSet)
              }
              label={'Run'}
            />
          )}
        </Stack>
      }
      hasUnsavedChanges={workflowStore.hasUnsavedChanges}
      onSave={() => dispatch(saveWorkflow(workflowStore.workflow!, orgId))}
      onDiscard={() => dispatch(fetchWorkflow(fetchWorkflowRequest))}
    >
      <Container>{renderTabContent()}</Container>
    </Frame>
  );
};

export default WorkflowDetails;
