import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import { ReactComponent as AddButtonIcon } from '../../static/icons/add-button-plus.svg';

import {
  OrbyButton,
  OrbyColorPalette,
  OrbyHeaderLabel,
  OrbyTable,
  OrbyTextField,
  OrbyTypography,
} from 'orby-ui/src';
import {
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
  ROLE_FILTER_PREFIX,
  WORKFLOW_RESOURCE_NAMES,
  FEATURE_FLAGS,
  NAME_EMAIL_PREFIX,
} from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  listUsersErrorSelector,
  loadingUsersSelector,
  selectedOrgInfoSelector,
  usersListSelector,
  usersTotalSizeSelector,
} from '../../redux/selectors/user.selectors';
import { User } from 'protos/pb/v1alpha1/user';
import {
  USER_MENU_ACTION_TYPE,
  buildTeamsRequest,
  getUserActionMenu,
  getUserActionMenuCell,
  getUserCell,
  getUserRoleCell,
  getUserStatusCell,
  getUserWorkflowCell,
  getWorkflowCellWidth,
  handleTeamsPageChange,
  handleTeamsPageRowSelectionChange,
} from './TeamsHelper';
import {
  listUsersAction,
  listUsersErrorAction,
} from '../../redux/actions/user.action';
import { useNavigate } from 'react-router-dom';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from 'orby-ui/src/components/table/table-utils';
import { toastService } from '../../services/ToastService';
import { getFontSize } from 'orby-ui/src/components/typography/typography-utils';
import AddUserModal from './component/AddUserModal';
import DeleteUserModal from './component/DeleteUserModal';
import WorkflowFilter from '../../pages/Tasks/component/WorkflowFilter';
import { getWorkflowIdsFromParamStringV2 } from '../Utils/taskV2Utils';
import RolesFilter from './component/RolesFilter';
import { isFeatureFlagEnabled } from '../FeatureFlags/FeatureFlagUtils';
import { getFeatureFlagsForOrgAndUserSelector } from '../../redux/selectors/feature_flags.selectors';

interface Props {
  sideDrawerCollapse: boolean;
}

const Teams: React.FC<Props> = ({ sideDrawerCollapse }) => {
  const dispatch = useDispatch();
  const urlSearchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const users: User[] = useSelector(usersListSelector);
  const totalSize = useSelector(usersTotalSizeSelector) ?? 0;
  const listUsersError = useSelector(listUsersErrorSelector);
  const loadingUsers = useSelector(loadingUsersSelector);

  const [page, setPage] = useState(DEFAULT_FIRST_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [selectedUser, setSelectedUser] = useState<User | undefined>();

  const [addUserOpen, setAddUserOpen] = useState(false);
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);

  const [actionMenuAnchorEl, setActionMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  // USED FOR SEARCHING EXECUTION BY NAME
  const [displayNamePrefix, setDisplayNamePrefix] = useState(
    urlSearchParams.get(NAME_EMAIL_PREFIX) ?? '',
  );

  // WORKFLOW FILTER
  const [selectedWorkflows, setSelectedWorkflows] = useState(
    getWorkflowIdsFromParamStringV2(
      urlSearchParams.get(WORKFLOW_RESOURCE_NAMES) ?? '',
    ),
  );

  // ROLES FILTER
  const [selectedRole, setSelectedRole] = useState(
    urlSearchParams.get(ROLE_FILTER_PREFIX) ?? '',
  );

  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const isRbacEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.RBAC, featureFlags);

  const pagedUsers = users.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  /**
   * FETCH USERS
   * @param pageNumber
   * @param pageSize
   * @param refresh
   */
  const listUsers = (
    pageNumber: number,
    pageSize: number,
    refresh: boolean,
  ) => {
    const req = buildTeamsRequest({
      navigate,
      displayNamePrefix,
      orgResourceName: selectedOrgInfo!.orgResourceName!,
      selectedWorkflows,
      selectedRole,
    });
    req.pageNumber = pageNumber;
    req.pageSize = pageSize;
    dispatch(listUsersAction(req, refresh));
  };

  useEffect(() => {
    if (listUsersError) {
      toastService.showError(listUsersError.message, {
        position: 'top-right',
      });
      dispatch(listUsersErrorAction(undefined));
    }
  }, [listUsersError]);

  /**
   * ACTION MENU
   */
  const handleActionMenuClick = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    user: User,
  ) => {
    setSelectedUser(user);
    setActionMenuAnchorEl(event.currentTarget);
  };

  /**
   * HANDLE ACTION MENU CLOSE
   */
  const handleActionMenuClose = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type?: string,
  ) => {
    event.stopPropagation();
    if (type && selectedUser) {
      if (type === USER_MENU_ACTION_TYPE.DELETE) {
        setDeleteUserOpen(true);
      } else if (type === USER_MENU_ACTION_TYPE.EDIT) {
        setAddUserOpen(true);
      }
    } else {
      setSelectedUser(undefined);
    }
    setActionMenuAnchorEl(null);
  };

  /**
   * GET WIDTH OF THE TABLE
   */
  const getTableWidth = () => {
    const sideNavWidth = sideDrawerCollapse
      ? DRAWER_WIDTH_COLLAPSED
      : DRAWER_WIDTH_EXPANDED;
    const PADDING = 48 * 2;

    return window.innerWidth - sideNavWidth - PADDING;
  };

  /**
   * REFRESH PAGE
   */
  const refreshPage = () => {
    setPage(DEFAULT_FIRST_PAGE);
    listUsers(DEFAULT_FIRST_PAGE, rowsPerPage, true);
  };

  /**
   * USE EFFECTS
   */
  useEffect(() => {
    if (selectedOrgInfo) {
      refreshPage();
    }
  }, [selectedOrgInfo]);

  useEffect(() => {
    refreshPage();
  }, [displayNamePrefix, selectedWorkflows, selectedRole]);

  return (
    <>
      <Box paddingX={'48px'} paddingY={'48px'}>
        {/* PAGE HEADER */}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          width={'100%'}
          paddingBottom={'10px'}
        >
          <Box>
            <OrbyTypography size={'display-sm'} weight={'medium'}>
              Teams
            </OrbyTypography>
          </Box>
          {/* ADD USER */}
          <Box>
            <OrbyButton
              onClick={() => setAddUserOpen(true)}
              ariaLabel='Add a team member'
              size='large'
              variant='primary-contained'
              label='Add'
              startIcon={
                <AddButtonIcon
                  style={{ fontSize: `${getFontSize('md')}!important` }}
                />
              }
            />
          </Box>
        </Box>

        {/* TEAMS FILTER */}
        <Box
          position={'sticky'}
          top={0}
          bgcolor={OrbyColorPalette['white-0']}
          zIndex={1}
          paddingTop={'14px'}
          paddingBottom={'24px'}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'flex-start'}
            width={'100%'}
            gap={'8px'}
          >
            {/* SEARCH USERS BY NAME OR EMAIL */}
            <Box>
              <OrbyTextField
                width={'300px'}
                tabIndex={0}
                value={displayNamePrefix}
                name={'search-by-name-or-email'}
                disabled={false}
                fontSize='14px'
                placeholder='Search by name or email'
                startAdornment={<GridSearchIcon fontSize='medium' />}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setDisplayNamePrefix(event.target.value)
                }
              />
            </Box>
            {/* WORKFLOW FILTER */}
            <Box>
              <WorkflowFilter
                width='160px'
                menuWidth='320px'
                selectedWorkflows={selectedWorkflows}
                setSelectedWorkflows={setSelectedWorkflows}
              />
            </Box>
            {/* ROLES FILTER */}
            <Box>
              <RolesFilter
                width='160px'
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
                isRbacEnabled={isRbacEnabled}
              />
            </Box>
          </Box>
        </Box>

        {/* USERS TABLE */}
        <Box>
          <OrbyTable
            tableWidth={getTableWidth()}
            cursor={'unset'}
            isDataLoading={loadingUsers}
            noDataMessage={
              displayNamePrefix
                ? `No results for "${displayNamePrefix}"`
                : 'There are no users available'
            }
            tableContainerSx={{
              width: 'auto',
              overflowX: 'auto',
            }}
            colSx={[
              {
                width: '270px',
                maxWidth: '270px',
                minWidth: '270px',
              },
              {
                width: '90px',
                maxWidth: '90px',
                minWidth: '90px',
              },
              {
                width: `${getWorkflowCellWidth(getTableWidth(), 400)}px`,
                maxWidth: `${getWorkflowCellWidth(getTableWidth(), 400)}px`,
                minWidth: `${getWorkflowCellWidth(getTableWidth(), 400)}px`,
              },
              {
                width: '180px',
                maxWidth: '180px',
                minWidth: '180px',
              },
              {
                width: '69px',
                maxWidth: '69px',
                minWidth: '69px',
              },
            ]}
            tableId='ui-automation-table'
            showCheckBox={false}
            isCheckBoxDisabled={false}
            /**
             * PAGINATION
             */
            pagination={{
              rowsPerPage,
              currentPage: page,
              totalRows: totalSize,
              setCurrentPage: (pageNumber: number) =>
                handleTeamsPageChange({
                  users,
                  pageNumber,
                  page,
                  rowsPerPage,
                  setPage,
                  listUsers,
                }),
              setRowsPerPage: (rows: number) =>
                handleTeamsPageRowSelectionChange({
                  rowsNumber: rows,
                  setPage,
                  setRowsPerPage,
                  listUsers,
                }),
            }}
            /**
             * TABLE HEADER ROWS
             */
            headerRows={[
              <OrbyHeaderLabel key={'teams-users'} label='Users' />,
              <OrbyHeaderLabel key={'teams-role'} label='Role' />,
              <OrbyHeaderLabel key={'teams-workflow'} label='Workflow' />,
              <OrbyHeaderLabel key={'teams-status'} label='Status' />,
              <OrbyHeaderLabel key={'teams-actions'} label='' />,
            ]}
            /**
             * TABLE ROWS
             */
            dataRows={pagedUsers.map((user) => {
              return {
                title: user.id!,
                id: user.id!,
                row: user,
                cells: [
                  getUserCell(user),
                  getUserRoleCell(user, selectedOrgInfo!),
                  getUserWorkflowCell(user, getTableWidth()),
                  getUserStatusCell(user),
                  getUserActionMenuCell(user, handleActionMenuClick),
                ],
              };
            })}
          />
        </Box>
      </Box>

      {/* TEAMS ACTION MENU */}
      {getUserActionMenu(actionMenuAnchorEl, handleActionMenuClose)}

      {/* ADD/EDIT USER MODAL */}
      <AddUserModal
        open={addUserOpen}
        user={selectedUser}
        setSelectedUser={setSelectedUser}
        edit={!!selectedUser}
        setOpen={setAddUserOpen}
        onSuccess={() => {
          // refresh the list upon creation
          setPage(0);
          listUsers(1, rowsPerPage, true);
        }}
        isRbacEnabled={isRbacEnabled}
      />

      {/* DELETE USER */}
      {selectedUser && (
        <DeleteUserModal
          open={deleteUserOpen}
          setOpen={setDeleteUserOpen}
          user={selectedUser}
          onSuccess={() => {
            // refresh the list upon deletion
            setPage(0);
            listUsers(1, rowsPerPage, true);
          }}
          setSelectedUser={setSelectedUser}
        />
      )}
    </>
  );
};

export default React.memo(Teams);
