import { Tab, Tabs } from '@mui/material';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import React from 'react';

const SchedulerTabs = ({
  tabs,
  selectedTab,
  setSelectedTab,
}: {
  tabs: any[];
  selectedTab: number;
  setSelectedTab: (tabIndex: number) => void;
}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Tabs
      TabIndicatorProps={{
        style: {
          display: 'none',
        },
      }}
      sx={{
        backgroundColor: OrbyColorPalette['grey-100'],
        borderRadius: '8px',
        padding: '4px',
        minHeight: '44px',
        height: '44px',
        '& .MuiButtonBase-root': {
          height: '36px',
          padding: '6px 16px',
          minHeight: '36px',
        },
      }}
      value={selectedTab}
      onChange={handleChange}
      variant='fullWidth'
    >
      {tabs.map((tab, index) => (
        <Tab
          sx={{
            textTransform: 'none',
            cursor: 'pointer',
            borderRadius: '6px',
            backgroundColor:
              selectedTab === index
                ? OrbyColorPalette['white-0']
                : 'transparent',
          }}
          tabIndex={index}
          title={tab.label}
          aria-label={tab.label}
          key={tab.id}
          label={
            <OrbyTypography
              size='md'
              weight='medium'
              color={
                selectedTab === index
                  ? OrbyColorPalette['grey-900']
                  : OrbyColorPalette['grey-500']
              }
            >
              {tab.label}
            </OrbyTypography>
          }
          id={`tab-${tab.id}`}
        />
      ))}
    </Tabs>
  );
};

export default SchedulerTabs;
