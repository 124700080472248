import React, { useEffect, useState } from 'react';
import {
  styled,
  BoxProps,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Collapse,
  badgeClasses,
  iconButtonClasses,
  Avatar,
  Tooltip,
} from '@mui/material';

import { grey } from '@mui/material/colors';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Chip, { chipClasses } from '@mui/material/Chip';
import { ActionObject } from 'workflow-utils/src/types';
import { getClickableWidgetInfo } from 'workflow-utils/src/helper';
import {
  getNumStepsForActions,
  getNumStepsForAction,
} from 'workflow-utils/src/v2/helper';
import { useDispatch, useSelector } from 'react-redux';
import { ItemDescription } from '../../ExecutionHistory/tabs/ui-automations/details/component/Styled';
import { ScreenChip } from '../../ExecutionHistory/tabs/ui-automations/details/component/Chip';
import { WidgetType } from '../../../redux/reducers/workflow_detail.reducer';

import IfCondition from './IfCondition';
import GotoActionDescription from './GotoActionDescription';
import DropdownWidget from './DropdownWidget';
import { OrbyBadge, OrbyColorPalette } from 'orby-ui/src';
import {
  addActionAfter,
  addFalseConditionAction,
  removeAction,
} from '../../../redux/actions/workflow_details.constants';
import SetValueActionDescription from './SetValueActionDescription';
import LinkedWorkflow from './LinkedWorkflow';
import FlagKeywordsDescription from './FlagKeywordsDescription';
import ClickableWidget from './Clickable';
import PlainDescription from './PlainDescription';
import {
  containFlagKeywordsAction,
  containControlFlow,
  containConditionAction,
  containClickAction,
  containGotoAction,
  containSetValueAction,
  containCreateTaskAction,
  containExtractFieldsAction,
} from 'workflow-utils/src/v2/action-classifier';
import { allowAddingElseBlock } from 'workflow-utils/src/v2/workflow';
import { cumulativeSum } from 'workflow-utils/src/helper';
import { workflowSecretSelector } from '../../../redux/selectors/app.selectors';
import SecretModal from './SecretModal';
import { loggedInUserSelector } from '../../../redux/selectors/user.selectors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SecretDeleteModal from './SecretDeleteModal';
import { deleteSecretBlockAction } from '../../../redux/actions/workflow_secrets.actions';
import {
  getSecretBlockScope,
  isSecretBlockAuthorized,
} from '../../../utils/SecretsUtils';
import { SecretBlock, SecretScope } from 'protos/pb/v1alpha1/secret_manager';
import UserCard from '../../../components/UserCard';
import { isFeatureFlagEnabled } from '../../FeatureFlags/FeatureFlagUtils';
import { FEATURE_FLAGS } from '../../../utils/constants';
import { getFeatureFlagsForOrgAndUserSelector } from '../../../redux/selectors/feature_flags.selectors';
import ExtractEntityDescription from './ExtractEntityDescription';

interface IActionGroupProps extends BoxProps {
  action: ActionObject;
  startIndex: number;
  onAddStep: () => void;
  onRemoveStep: () => void;
  onAddElseStep: () => void;
  isEditable: boolean;
  isTemplate: boolean;
  processId?: string;
  traceIndices?: number[];
}

const RowContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
}));

const ColumnContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  flex: 1,
}));

const ActionContainer = styled(ColumnContainer)(({ theme }) => ({
  paddingLeft: theme.spacing(8),
  margin: 0,
  width: '100%',
}));

const BranchBox = styled(RowContainer)(({ theme }) => ({
  gap: theme.spacing(1),
  width: '100%',
}));

const StyledActionItem = styled(ColumnContainer)({
  padding: 0,
  margin: 0,
  overflow: 'hidden',
  [`& .${badgeClasses.badge}`]: {
    position: 'static',
    transform: 'none',
    marginRight: '4px',
  },
});

interface DescriptionContainerProps {
  isMenuOpen?: boolean;
}

const DescriptionContainer = styled(RowContainer, {
  shouldForwardProp(propName) {
    return propName !== 'isMenuOpen';
  },
})<DescriptionContainerProps>(({ isMenuOpen }) => {
  const hoverStyle = {
    '& .widget-container': {
      backgroundColor: grey[50],
    },
    [`& .${iconButtonClasses.root}, & .${chipClasses.root}`]: {
      visibility: 'visible',
    },
  };
  const normalStyle = {
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '10px',
    '&:hover': hoverStyle,
  };
  return isMenuOpen ? { ...normalStyle, ...hoverStyle } : normalStyle;
});

const ScreenShot = styled(Box)<BoxProps>(() => ({
  width: '100%',
  borderRadius: '20px',
  padding: '32px 44px',
  backgroundColor: '#FAFAFA',
}));

const renderControlFlowActions = (
  action: ActionObject,
  startIndex: number,
  isEditable: boolean,
  isTemplate: boolean,
  processId?: string,
) => {
  if (action.foreach) {
    return (
      <ForeachAction
        action={action}
        startIndex={startIndex}
        isEditable={isEditable}
        isTemplate={isTemplate}
        processId={processId}
      />
    );
  }
  if (action.condition) {
    return (
      <ConditionAction
        action={action}
        startIndex={startIndex}
        isEditable={isEditable}
        isTemplate={isTemplate}
        processId={processId}
      />
    );
  }
};

interface IForeachActionProps {
  action: ActionObject;
  isEditable: boolean;
  isTemplate: boolean;
  startIndex: number;
  processId?: string;
}

const ForeachAction: React.FC<IForeachActionProps> = ({
  action,
  isEditable,
  isTemplate,
  startIndex,
  processId,
}) => {
  const dispatch = useDispatch();

  const [subActions, setSubActions] = useState(
    action.foreach?.loopActions || [],
  );

  useEffect(() => {
    setSubActions(action?.foreach?.loopActions || []);
  }, [action.foreach?.loopActions]);

  const handleAddStep = (action: ActionObject) => {
    dispatch(addActionAfter(action.id!, processId!));
  };

  const handleRemoveStep = (action: ActionObject) => {
    dispatch(removeAction(action.id!, processId!));
  };

  const addElseConditionAction = (action: ActionObject) => {
    dispatch(addFalseConditionAction(action.id!, processId!));
  };

  const startIndices = cumulativeSum(subActions.map(getNumStepsForAction)).map(
    (i) => i + startIndex,
  );

  return (
    <ActionContainer key={startIndex}>
      {subActions
        .filter((a) => !a.jsFunction)
        .map((a, i) => (
          <ActionItem
            key={i}
            action={a}
            startIndex={startIndices[i]}
            onAddStep={() => handleAddStep(a)}
            onRemoveStep={() => handleRemoveStep(a)}
            onAddElseStep={() => addElseConditionAction(a)}
            isEditable={isEditable}
            isTemplate={isTemplate}
            processId={processId}
          />
        ))}
    </ActionContainer>
  );
};

interface IConditionActionProps {
  action: ActionObject;
  isEditable: boolean;
  isTemplate: boolean;
  startIndex: number;
  processId?: string;
}

const ConditionAction: React.FC<IConditionActionProps> = ({
  action,
  isEditable,
  isTemplate,
  startIndex,
  processId,
}) => {
  const dispatch = useDispatch();

  const [trueActions, setTrueActionGroups] = useState(
    action.condition!.thenActions || [],
  );
  const [falseActionGroups, setFalseActionGroups] = useState(
    action.condition!.elseActions || [],
  );

  useEffect(() => {
    setTrueActionGroups(action.condition!.thenActions || []);
  }, [action?.condition?.thenActions]);

  useEffect(() => {
    setFalseActionGroups(action?.condition!.elseActions || []);
  }, [action?.condition?.elseActions]);

  const handleAddStep = (action: ActionObject) => {
    dispatch(addActionAfter(action.id!, processId!));
  };

  const handleRemoveStep = (action: ActionObject) => {
    dispatch(removeAction(action.id!, processId!));
  };

  const addElseConditionActionGroup = (action: ActionObject) => {
    // TODO: test this, not sure if it works
    dispatch(addFalseConditionAction(action.id!, processId!));
  };

  const numTrueSteps = getNumStepsForActions(trueActions);
  return (
    <ActionContainer key={startIndex}>
      <BranchBox>
        <ColumnContainer>
          {trueActions
            ?.filter((a) => !a.jsFunction)
            ?.map((a, i) => (
              <ActionItem
                key={i}
                action={a}
                startIndex={startIndex + i}
                onAddStep={() => handleAddStep(a)}
                onRemoveStep={() => handleRemoveStep(a)}
                onAddElseStep={() => addElseConditionActionGroup(a)}
                isEditable={isEditable}
                isTemplate={isTemplate}
                processId={processId}
              />
            ))}
        </ColumnContainer>
      </BranchBox>
      {(falseActionGroups || []).length > 0 && (
        <>
          <DescriptionContainer>
            <ItemDescription sx={{ ml: '26px' }}>Otherwise</ItemDescription>
          </DescriptionContainer>
          <BranchBox>
            <ColumnContainer>
              {falseActionGroups
                ?.filter((a) => !a.jsFunction)
                ?.map((a, i) => (
                  <ActionItem
                    key={i}
                    action={a}
                    startIndex={numTrueSteps + startIndex + i}
                    onAddStep={() => handleAddStep(a)}
                    onRemoveStep={() => handleRemoveStep(a)}
                    onAddElseStep={() => addElseConditionActionGroup(a)}
                    isEditable={isEditable}
                    isTemplate={isTemplate}
                    processId={processId}
                  />
                ))}
            </ColumnContainer>
          </BranchBox>
        </>
      )}
    </ActionContainer>
  );
};

const renderDescription = (
  action: ActionObject,
  isEditable: boolean,
  hasRecording: boolean,
  processId?: string,
): JSX.Element => {
  if (containConditionAction(action)) {
    return (
      <IfCondition
        action={action}
        isEditable={isEditable}
        processId={processId}
      />
    );
  } else if (containClickAction(action)) {
    const description = action.description || '';
    const clickableWidgetInfo = getClickableWidgetInfo(description);

    if (clickableWidgetInfo) {
      return (
        <ClickableWidget
          action={action}
          clickableWidgetInfo={clickableWidgetInfo}
          isEditable={isEditable}
          hasRecording={hasRecording}
          processId={processId}
        />
      );
    }
  } else if (containGotoAction(action)) {
    return (
      <GotoActionDescription
        action={action}
        isEditable={isEditable}
        processId={processId}
      />
    );
  } else if (containSetValueAction(action)) {
    return (
      <SetValueActionDescription
        action={action}
        isEditable={isEditable}
        processId={processId}
      />
    );
  } else if (containCreateTaskAction(action)) {
    return <LinkedWorkflow action={action} />;
  } else if (containFlagKeywordsAction(action)) {
    return (
      <FlagKeywordsDescription
        action={action}
        isEditable={isEditable}
        processId={processId}
      />
    );
  } else if (containExtractFieldsAction(action)) {
    return <ExtractEntityDescription action={action} isEditable={isEditable} />;
  }
  return <PlainDescription action={action} />;
};

const renderWidget = (
  action: ActionObject,
  processId?: string,
): JSX.Element => {
  const widget = action.widget!;
  switch (widget) {
    case WidgetType.WidgetSelector:
      return <DropdownWidget action={action} processId={processId} />;
    case WidgetType.Click:
      return <></>; // TODO: implement UX Design, for now, use Editing Clickable Widget as default
    default:
      return <PlainDescription action={action} />;
  }
};

const ActionItem: React.FC<IActionGroupProps> = ({
  action,
  startIndex,
  onAddStep,
  onRemoveStep,
  onAddElseStep,
  isEditable,
  isTemplate,
  processId,
  ...props
}: IActionGroupProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isScreenShotOpen, setIsScreenShotOpen] = useState(false);
  const [isSecretModalOpen, setIsSecretModalOpen] = useState(false);
  const [editingOverride, setEditingOverride] = useState(true);

  const user = useSelector(loggedInUserSelector);

  const { workflowSecretStore } = useSelector(workflowSecretSelector);
  const hostURL = Object.entries(workflowSecretStore).find(([, item]) =>
    item.actionIDs.has(action.id!),
  )?.[0];

  // Undefined if there is no asociated secret block

  const secretBlock = hostURL
    ? workflowSecretStore[hostURL]?.secretBlock
    : undefined;
  const isSecretBlockCreated = hostURL
    ? workflowSecretStore[hostURL]?.isCreated
    : false;

  const isMenuOpen = Boolean(anchorEl);
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const addStepAndCloseMenu = () => {
    onAddStep();
    closeMenu();
  };

  const addAddElseStepAndCloseMenu = () => {
    onAddElseStep();
    closeMenu();
  };

  const removeStepAndCloseMenu = () => {
    onRemoveStep();
    closeMenu();
  };

  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);

  const hasControlFlow = containControlFlow(action!);
  const imgUrl = !hasControlFlow && action.screenshot?.url;
  const hasRecording = !!imgUrl;

  return (
    <StyledActionItem {...props}>
      <ColumnContainer>
        <DescriptionContainer sx={{ gap: 3 }} isMenuOpen={!!anchorEl}>
          <RowContainer sx={{ gap: '12px', alignItems: 'center' }}>
            <OrbyBadge
              size={'medium'}
              badgeName={`${startIndex + 1}`}
              textColor={OrbyColorPalette['purple-600']}
              backgroundColor={OrbyColorPalette['grey-100']}
              textSx={{
                fontSize: '0.75rem',
              }}
              boxSx={{
                height: '32px',
              }}
            />
            {action.widget
              ? renderWidget(action, processId)
              : renderDescription(action, isEditable, hasRecording, processId)}
            {isFeatureFlagEnabled(
              FEATURE_FLAGS.SECRETS_UI_CHANGE,
              featureFlags,
            ) &&
              secretBlock &&
              !isTemplate && (
                <>
                  {isSecretBlockCreated &&
                  isSecretBlockAuthorized(secretBlock) ? (
                    <Box
                      sx={{
                        position: 'relative',
                        display: 'inline-flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <Tooltip
                        title={
                          <FloatingSecretDetailsTooltip
                            secretBlock={secretBlock}
                            onEdit={(editOverride: boolean) => {
                              setEditingOverride(editOverride);
                              setIsSecretModalOpen(true);
                            }}
                          />
                        }
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: 'transparent',
                              '& .MuiTooltip-arrow': {
                                color: 'white',
                              },
                              maxWidth: 'none',
                            },
                          },
                        }}
                        arrow
                        placement='top'
                      >
                        <Avatar
                          sx={{
                            width: 24,
                            height: 24,
                            fontSize: '0.75rem',
                            bgcolor: OrbyColorPalette['purple-600'],
                          }}
                          src={user?.profileImageUrl}
                        />
                      </Tooltip>
                    </Box>
                  ) : (
                    <Chip
                      label={'Add account info'}
                      size='small'
                      onClick={() => {
                        setIsSecretModalOpen(true);
                      }}
                      sx={{
                        fontSize: '0.75rem',
                        height: '24px',
                        backgroundColor: OrbyColorPalette['error-100'],
                        color: OrbyColorPalette['grey-700'],
                        '&:hover': {
                          backgroundColor: OrbyColorPalette['error-200'],
                        },
                      }}
                    />
                  )}
                </>
              )}
          </RowContainer>
          {imgUrl && (
            <ScreenChip
              sx={{ visibility: 'hidden' }}
              isOpen={isScreenShotOpen}
              label='1 screen'
              onClick={() => setIsScreenShotOpen(!isScreenShotOpen)}
            />
          )}
          {isEditable && (
            <IconButton
              sx={{ visibility: 'hidden' }}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MoreHorizIcon />
            </IconButton>
          )}
        </DescriptionContainer>
        {imgUrl && (
          <Collapse sx={{ width: '100%' }} in={isScreenShotOpen}>
            <ScreenShot>
              <img src={imgUrl} width='100%' alt='' />
            </ScreenShot>
          </Collapse>
        )}
        {hasControlFlow &&
          renderControlFlowActions(
            action,
            startIndex + 1,
            isEditable,
            isTemplate,
            processId,
          )}
      </ColumnContainer>
      {isEditable && (
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={closeMenu}
          // transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <MenuItem onClick={addStepAndCloseMenu}>Add Step</MenuItem>
          <MenuItem onClick={removeStepAndCloseMenu}>Remove Step</MenuItem>
          {/* If condition actions has only true action, allow user to add false action (else block) */}
          {allowAddingElseBlock(action) && (
            <MenuItem onClick={addAddElseStepAndCloseMenu}>
              Add otherwise
            </MenuItem>
          )}
          {/* <MenuItem onClick={deleteAllSteps}>Delete All Steps</MenuItem> */}
        </Menu>
      )}
      {secretBlock && (
        <SecretModal
          open={isSecretModalOpen}
          setOpen={setIsSecretModalOpen}
          secretBlock={secretBlock}
          isSecretBlockCreated={isSecretBlockCreated}
          editingOverride={editingOverride}
        />
      )}
    </StyledActionItem>
  );
};

interface FloatingSecretDetailsTooltipProps {
  secretBlock: SecretBlock;
  onEdit: (editOverride: boolean) => void;
}

const FloatingSecretDetailsTooltip = ({
  secretBlock,
  onEdit,
}: FloatingSecretDetailsTooltipProps) => {
  return (
    <Box
      sx={{
        bgcolor: 'white',
        borderRadius: 1,
        p: 2,
        gap: 4,
        display: 'flex',
        flexDirection: 'row',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      {getSecretBlockScope(secretBlock) === SecretScope.ORG &&
        secretBlock?.secretInfos?.[0].hasOverride && (
          <FloatingSecretDetailsTooltipRow
            isPublic={true}
            // TODO: Allow delete if user is an admin
            allowDelete={false}
            secretBlock={secretBlock}
            onEdit={() => onEdit(false)}
          />
        )}
      {getSecretBlockScope(secretBlock) === SecretScope.PRIVATE && (
        <FloatingSecretDetailsTooltipRow
          isPublic={false}
          allowDelete={true}
          secretBlock={secretBlock}
          onEdit={() => onEdit(true)}
        />
      )}
    </Box>
  );
};

interface FloatingSecretDetailsTooltipRowProps {
  isPublic: boolean;
  allowDelete: boolean;
  secretBlock: SecretBlock;
  onEdit: () => void;
}

// TODO: Remove this flag once delete works
const ALLOW_DELETE = false;

const FloatingSecretDetailsTooltipRow = ({
  isPublic,
  allowDelete,
  secretBlock,
  onEdit,
}: FloatingSecretDetailsTooltipRowProps) => {
  const [isSecretDeleteModalOpen, setIsSecretDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(loggedInUserSelector);

  const handleDelete = () => {
    setIsSecretDeleteModalOpen(false);
    dispatch(deleteSecretBlockAction({ secretBlock }));
  };

  return (
    <>
      <Box
        sx={{
          gap: 2,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <UserCard
            email={user?.email || ''}
            fullName={user?.fullName || ''}
            imageUrl={user?.profileImageUrl || ''}
          />
          <Chip
            label={isPublic ? 'Public' : 'Private'}
            size='small'
            sx={{
              backgroundColor: OrbyColorPalette['grey-100'],
              color: OrbyColorPalette['grey-700'],
              fontSize: '0.6rem',
              height: '20px',
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {allowDelete && ALLOW_DELETE && (
            <IconButton
              size='small'
              sx={{ p: 0 }}
              onClick={() => setIsSecretDeleteModalOpen(true)}
            >
              <DeleteIcon fontSize='small' />
            </IconButton>
          )}
          <IconButton size='small' sx={{ p: 0 }} onClick={onEdit}>
            <EditIcon fontSize='small' />
          </IconButton>
        </Box>
        <SecretDeleteModal
          open={isSecretDeleteModalOpen}
          handleClose={() => setIsSecretDeleteModalOpen(false)}
          onSubmit={handleDelete}
        />
      </Box>
    </>
  );
};

export default ActionItem;
