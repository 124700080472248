import React, { useState } from 'react';
import { Box, styled } from '@mui/material';
import { OrbyButtonGroup } from 'orby-ui/src';
import { ReconcileItems } from 'protos/pb/v1alpha1/actionprocessing';
import PdfRenderer from './PdfRenderer';

// Styled Components
const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '12px',
  paddingLeft: '27px',
  paddingRight: '28px',
  paddingBottom: '20px',
  alignItems: 'flex-start',
  width: '100%',
  height: '100%',
}));

const TabsContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}));

const TabContent = styled(Box)(() => ({
  marginTop: '20px',
  width: '100%',
  overflow: 'auto',
}));

const StyledImage = styled(Box)(() => ({
  marginLeft: '8px',
  marginRight: '9px',
}));

interface Props {
  reconcileItems: ReconcileItems | undefined;
}

const HITLSystemReference: React.FC<Props> = ({ reconcileItems }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  if (!reconcileItems) {
    return null;
  }

  // TODO: Now the order is hardcoded in extension "reconcile.ts"
  // We should change it to be more flexible when we have the second reconcile use case.
  const caapsPdfUri = reconcileItems.target!.documents?.[0]?.document!.gcsUri;
  const caapsFormUri = reconcileItems.target!.documents?.[1]?.document!.gcsUri;
  const jde1TableUri = reconcileItems.source!.documents?.[0]?.document!.gcsUri;
  const tabs = [
    {
      id: 'caaps-form',
      label: 'CAAPS form',
      children: (
        <StyledImage>
          <img src={caapsFormUri} alt='CAAPS Form' />
        </StyledImage>
      ),
    },
    {
      id: 'invoice-pdf',
      label: 'Invoice PDF',
      children: (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            marginLeft: '8px',
            marginRight: '9px',
          }}
        >
          <PdfRenderer gcsUri={caapsPdfUri} />
        </Box>
      ),
    },
    {
      id: 'jde',
      label: 'JDE1',
      children: (
        <StyledImage>
          <img src={jde1TableUri} alt='JDE1 Table' />
        </StyledImage>
      ),
    },
  ];

  return (
    <Container id='system-reference'>
      {/* Tabs */}
      <TabsContainer>
        <OrbyButtonGroup
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={tabs}
        />
      </TabsContainer>

      {/* Render the selected tab's content */}
      <TabContent>{tabs[selectedTab]?.children}</TabContent>
    </Container>
  );
};

export default HITLSystemReference;
