import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  Box,
  CircularProgress,
  // FormControl,
  // RadioGroup,
  // FormControlLabel,
  // Radio,
  // Box,
} from '@mui/material';
import {
  Close as CloseIcon,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import CustomTypography from '../../../components/CustomTypography';
import { OrbyButton } from 'orby-ui/src';
import {
  Secret,
  SecretBlock,
  SecretInfo,
  SecretScope,
  UpdateSecretBlockRequest,
} from 'protos/pb/v1alpha1/secret_manager';
import {
  createSecretBlockAction,
  updateSecretBlockAction,
} from '../../../redux/actions/workflow_secrets.actions';
import { useDispatch } from 'react-redux';
import { secretsService } from '../../../services/SecretsService';
import { isSecretBlockAuthorized } from '../../../utils/SecretsUtils';

interface SecretModalProps {
  open: boolean;
  setOpen: (x: boolean) => void;
  secretBlock: SecretBlock;
  isSecretBlockCreated: boolean;

  // True if we are editing the override. If it is false, then we are editing
  // the public secret. Should be true for everyone except admins, as normal
  // users can only edit their own override.
  editingOverride?: boolean;
}

const textLikePassword = (s: string) => {
  return (
    s.toLowerCase().includes('password') ||
    s.toLowerCase().includes('secret') ||
    s.toLowerCase().includes('token') ||
    s.toLowerCase().includes('key')
  );
};

const prettifyDisplayName = (str: string): string => {
  return str
    .split('_')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const SecretModal: FC<SecretModalProps> = ({
  open,
  setOpen,
  secretBlock,
  isSecretBlockCreated,
  // editingOverride = true,
}) => {
  const [secretValues, setSecretValues] = useState<Record<string, string>>({});
  const [showPasswords, setShowPasswords] = useState<Record<string, boolean>>(
    {},
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [isPublic] = useState<boolean>(false);

  useEffect(() => {
    const initializeSecretValues = async () => {
      setLoading(true);
      try {
        const initialSecretValues: Record<string, string> = {};
        const initialShowPasswords: Record<string, boolean> = {};

        await Promise.all(
          (secretBlock.secretInfos || []).map(
            async (secretInfo: SecretInfo) => {
              const { response, error } = await secretsService.getSecret({
                id: secretInfo.secret?.id || '',
                orgId: secretBlock.orgId,
              });
              if (response) {
                if (response.secret?.value) {
                  initialSecretValues[secretInfo.secret?.id || ''] =
                    response.secret?.value || '';
                  initialShowPasswords[secretInfo.secret?.id || ''] = false;
                } else {
                  console.error(
                    'No value found for secret:',
                    secretInfo.secret?.id,
                  );
                }
              }
              if (error) {
                console.error('Error getting secret:', error);
              }
            },
          ),
        );

        setSecretValues(initialSecretValues);
        setShowPasswords(initialShowPasswords);
      } catch (error) {
        console.error('Error initializing secret values:', error);
      } finally {
        setLoading(false);
      }
    };

    if (open && isSecretBlockCreated && isSecretBlockAuthorized(secretBlock)) {
      initializeSecretValues();
    }
  }, [open, secretBlock, isSecretBlockCreated]);

  const dispatch = useDispatch();
  const handleInputChange = (key: string, value: string) => {
    if (key === '') {
      console.error('Key shouldnt be empty');
    }
    setSecretValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleTogglePassword = (id: string) => {
    setShowPasswords((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleSave = () => {
    // If secret block is already created, just update the secret values

    // Create the secret block if it wasn't created
    // If the secret block has not been created yet, then the id stores the
    // identifier (e.g. #username, #password) which, when the hashtag is dropped,
    // is the display name.
    if (!isSecretBlockCreated) {
      const secretBlockToCreate: SecretBlock = {
        ...secretBlock,
        secretInfos: secretBlock.secretInfos?.map((info) => ({
          ...info,
          secret: {
            scope: isPublic ? SecretScope.ORG : SecretScope.PRIVATE,
            orgId: secretBlock.orgId,
            displayName: info.secret?.id?.replace('#', ''),
            value: secretValues[info.secret?.id || ''],
          },
        })),
      };
      dispatch(createSecretBlockAction({ secretBlock: secretBlockToCreate }));
      // onSave(updatedSecretBlock);
    } else {
      const req: UpdateSecretBlockRequest = {
        orgId: secretBlock.orgId,
        blockName: secretBlock.blockName,
        secrets: secretBlock.secretInfos?.map((info) => {
          const secret: Secret = {
            value: secretValues[info.secret?.id || ''],
            orgId: secretBlock.orgId,
          };

          // If the workflow secretValue is in template form, then drop the hashtag
          // and use the displayName to call updateSecretBlock API.
          // If workflow secretValue is in real form, then use the id to call
          // updateSecretBlock API.
          if (info.secret?.id?.startsWith('#')) {
            secret.displayName = info.secret?.id?.replace('#', '');
          } else {
            secret.id = info.secret?.id;
          }

          return secret;
        }),
      };

      dispatch(updateSecretBlockAction({ req }));
    }

    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setSecretValues({});
  };

  const isFormValid = () => {
    return secretBlock.secretInfos?.every((secretInfo: SecretInfo) => {
      const id = secretInfo.secret?.id || '';
      return secretValues[id] && secretValues[id].trim() !== '';
    });
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          width: '480px',
          borderRadius: '12px',
        },
        id: 'secret-modal',
        'aria-labelledby': 'secret-modal',
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle
        padding={'24px !important'}
        sx={{
          backgroundColor: '#FFFFFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <CustomTypography
          weight={600}
          sx={{
            textAlign: 'left',
            textJustify: 'center',
            color: '#101828',
          }}
          size={'20px'}
        >
          {`Set your account info for ${secretBlock.blockName}`}
        </CustomTypography>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{ color: '#101828' }}
        >
          <CloseIcon fontSize={'medium'} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: '24px !important', pt: '0px !important' }}>
        {loading ? (
          <Box
            display={'flex'}
            justifyContent={'center'}
            height={'20%'}
            alignItems={'center'}
          >
            <CircularProgress />
          </Box>
        ) : (
          secretBlock.secretInfos?.map((secretInfo: SecretInfo, index) => {
            const isPassword =
              textLikePassword(secretInfo.secret?.displayName || '') ||
              textLikePassword(secretInfo.secret?.id || '') ||
              index > 0;
            const id = secretInfo.secret?.id || '';

            const displayName = isSecretBlockCreated
              ? prettifyDisplayName(secretInfo.secret?.displayName || '')
              : prettifyDisplayName(id.replace('#', ''));

            return (
              <div key={id}>
                <Typography variant='subtitle1'>{displayName}</Typography>
                <TextField
                  fullWidth
                  label={displayName}
                  type={isPassword && !showPasswords[id] ? 'password' : 'text'}
                  value={secretValues[id] || ''}
                  onChange={(e) => handleInputChange(id, e.target.value)}
                  margin='normal'
                  inputProps={{
                    autocomplete: 'new-password',
                  }}
                  InputProps={
                    isPassword
                      ? {
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={() => handleTogglePassword(id)}
                                edge='end'
                              >
                                {showPasswords[id] ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }
                      : undefined
                  }
                />
              </div>
            );
          })
        )}
        {/* {isAdmin && !is&& (
          <FormControl component='fieldset' sx={{ mt: 2, mb: 1 }}>
            <RadioGroup
              aria-label='secret-scope'
              name='secret-scope'
              value={isPublic ? 'public' : 'private'}
              onChange={(e) => setIsPublic(e.target.value === 'public')}
            >
              <FormControlLabel
                value='private'
                control={<Radio />}
                label={
                  <Box>
                    <Typography variant='body1'>Private account</Typography>
                    <Typography variant='caption' color='text.secondary'>
                      Only you can use this in the related workflow
                    </Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value='public'
                control={<Radio />}
                label={
                  <Box>
                    <Typography variant='body1'>Public account</Typography>
                    <Typography variant='caption' color='text.secondary'>
                      Everyone in your organization can use this account
                    </Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
        )} */}
      </DialogContent>
      <DialogActions sx={{ p: '24px !important', pt: '0px !important' }}>
        <OrbyButton
          variant='monochrome-outline'
          label='Cancel'
          onClick={handleClose}
        />
        <OrbyButton
          variant='primary-contained'
          label='Save'
          onClick={handleSave}
          disabled={!isFormValid()}
        />
      </DialogActions>
    </Dialog>
  );
};

export default SecretModal;
