import {
  GetSecretRequest,
  SecretBlock,
  SecretScope,
} from 'protos/pb/v1alpha1/secret_manager';
import { secretsService } from '../services/SecretsService';

/**
 * Edit: UNUSED. There was confusion about the requirements for UI and this function
 * is now unused.
 *
 * The identifying value of a secret block store is either the username or email
 * This is the value we display in the UI to the user. The public value is the org-scoped
 * value, while the private is the user's own.
 *
 * The function takes in a secretBlock and then makes the necessary API calls to get
 * the identifying values.
 *
 * TODO: Don't hardcode username or email, but maybe we should have a primary identifier for a secret block?
 *
 * @param secretBlock
 * @returns {
 *  publicIdentifyingValue: string;
 *  privateIdentifyingValue: string;
 * } - Either of the identifying values must be set.
 */
export const getIdentifyingValues = async (secretBlock: SecretBlock) => {
  // First find out the secret ID which holds the identifying value
  const secretInfoFound = secretBlock.secretInfos?.find(
    (info) => info.secret?.displayName === 'username' || 'email',
  );

  if (!secretInfoFound) {
    throw new Error(
      `No identifying value found for secret block ${secretBlock.blockName}`,
    );
  }

  const secretID = secretInfoFound.secret?.id;
  const hasOverride = secretInfoFound.hasOverride;

  // Fetch the secret
  const req: GetSecretRequest = {
    orgId: secretBlock.orgId,
    id: secretID,
  };

  const { response, error } = await secretsService.getSecret(req);
  if (error || !response) {
    throw error;
  }

  if (response.secret?.value) {
    // If a secret only has a public value, then we can just return that.
    if (response.secret.scope === SecretScope.ORG) {
      return { publicIdentifyingValue: response.secret.value };
    } else if (response.secret.scope === SecretScope.PRIVATE) {
      if (hasOverride) {
        // If there is an override, we want to fetch the public value and return that too
        const publicReq: GetSecretRequest = {
          orgId: secretBlock.orgId,
          id: secretID,
          returnPublicValue: true,
        };
        const { response: publicResponse, error: publicError } =
          await secretsService.getSecret(publicReq);
        if (publicError || !publicResponse) {
          throw publicError;
        }
        return {
          privateIdentifyingValue: response.secret.value,
          publicIdentifyingValue: publicResponse.secret?.value,
        };
      } else {
        return { privateIdentifyingValue: response.secret.value };
      }
    }
  }
  throw new Error(`No value found for secret ${secretID}`);
};

// If the function errors out, then return private scope.
export const getSecretBlockScope = (secretBlock: SecretBlock) => {
  if (secretBlock.secretInfos?.length === 0) {
    console.error(
      `No secrets found for secret block ${secretBlock.blockName}. Defaulting scope to private.`,
    );
    return SecretScope.PRIVATE;
  }

  const scope = secretBlock.secretInfos?.[0].secret?.scope;
  if (!scope) {
    console.error(
      `No scope found for secret block ${secretBlock.blockName}. Defaulting to private.`,
    );
  }
  return scope;
};

// Check if user has set values in secret block (is authorized)
// Right now, checks to make sure that all secrets in a block are set (user
// has provided overrides to all of them). This requirement might change in the
// future if we want to support empty fields.
export const isSecretBlockAuthorized = (secretBlock: SecretBlock) => {
  const scope = getSecretBlockScope(secretBlock);

  // Public scope should always return true
  return scope === SecretScope.PRIVATE
    ? secretBlock.secretInfos?.every((info) => info.hasOverride) ?? false
    : true;
};
