import { SettingsOutlined, WarningRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import CustomTextField from '../../../components/CustomTextField';
import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import sheetIcon from '../../../static/icons/sheetIcon.svg';
import excelIcon from '../../../static/icons/spreadsheet.svg';
import docIcon from '../../../static/icons/Docs.svg';
import { Form, useFormik, FormikProvider, FormikValues } from 'formik';
import useDrivePicker from 'react-google-drive-picker';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import * as Yup from 'yup';
import { storageService } from '../../../services/StorageService';
import { useDispatch, useSelector } from 'react-redux';
import { loggedInUserSelector } from '../../../redux/selectors/user.selectors';
import CustomTypography from '../../../components/CustomTypography';
import {
  getActionIndex,
  getApplicationNames,
  getCustomSheetsListView,
  withGoogleLoginScopeCheck,
  withMicrosoftLoginScopeCheck,
} from '../../../utils/helpers';
import { ApplicationName } from '../../../utils/protos/enums';
import {
  GOOGLE_DRIVE_SCOPE,
  MICROSOFT_DRIVE_SCOPE,
} from '../../../utils/constants';
import { Sheets } from 'protos/automation_mining/ontology/data_models';
import { onedriveFilePickerService } from '../../../services/OnedriveFilePickerService';
import { notification } from 'antd';
import { useCheckCookieFeatureFlagEnabled } from '../../../hooks/useCheckCookieFeatureFlagEnabled';

interface CreateSystemActionContentProps {
  workflow: Workflow;
  onSubmit: (values: FormikValues, isNextClicked: boolean) => void;
  edit?: boolean;
  formId: string;
  previousClicked: boolean;
}

const CreateSystemActionContent: FC<CreateSystemActionContentProps> = ({
  workflow,
  onSubmit,
  edit = false,
  formId,
  previousClicked,
}) => {
  const [openPicker] = useDrivePicker();
  const [api, contextHolder] = notification.useNotification();
  const user = useSelector(loggedInUserSelector);
  const gsheetsIndex = getActionIndex(workflow, ApplicationName.GoogleSheets);
  const msExcelIndex = getActionIndex(workflow, ApplicationName.MSExcel);
  const sheet: Sheets | undefined = useMemo(() => {
    if (!workflow.steps!.length) return undefined;

    if (
      gsheetsIndex.stepIndex !== undefined &&
      gsheetsIndex.actionIndex !== undefined
    ) {
      return workflow.steps![gsheetsIndex.stepIndex].actions!.length
        ? workflow.steps![gsheetsIndex.stepIndex].actions![
            gsheetsIndex.actionIndex
          ]?.gsheets?.action?.sheets
        : undefined;
    }

    if (
      msExcelIndex.stepIndex !== undefined &&
      msExcelIndex.actionIndex !== undefined
    ) {
      return workflow.steps![msExcelIndex.stepIndex].actions!.length
        ? workflow.steps![msExcelIndex.stepIndex].actions![
            msExcelIndex.actionIndex
          ]?.spreadsheet?.action?.sheets
        : undefined;
    }

    return undefined;
  }, [gsheetsIndex, msExcelIndex]);

  const isGSheetApplication = useMemo(() => {
    return getApplicationNames(workflow).includes(ApplicationName.GoogleSheets);
  }, [workflow]);

  const [connected, setConnected] = useState(!!sheet?.path);
  const [pickerCloseClicked, setPickerCloseClicked] = useState(false);
  const dispatch = useDispatch();
  const isLoginCookieEnabled = useCheckCookieFeatureFlagEnabled();

  const formik = useFormik({
    initialValues: {
      sheet_url: sheet?.path,
      file_id: sheet?.id,
    },
    validationSchema: Yup.object({
      sheet_url: Yup.string().required(
        isGSheetApplication
          ? 'Google Sheets URL is required'
          : 'Excel workbook URL is required',
      ),
    }),
    onSubmit: (values) => onSubmit(values, true),
  });

  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setTouched,
    values,
  } = formik;
  const handleOpenPicker = async () => {
    if (!edit) {
      if (gsheetsIndex.stepIndex) {
        withGoogleLoginScopeCheck(
          dispatch,
          GOOGLE_DRIVE_SCOPE,
          openDrivePicker,
        );
      } else {
        withMicrosoftLoginScopeCheck(
          dispatch,
          MICROSOFT_DRIVE_SCOPE,
          openSpreadsheetPicker,
        );
      }
    }
  };

  const openWarning = (error: string) => {
    api.warning({
      message: 'Notification',
      description: error,
      placement: 'topRight',
      duration: null,
    });
  };

  const openError = (error: string) => {
    api.error({
      message: 'Notification',
      description: error,
      placement: 'topRight',
      duration: null,
    });
  };

  const openSpreadsheetPicker = async () => {
    try {
      const result = await onedriveFilePickerService.openFilePicker();
      if (result) {
        setFieldValue('sheet_url', result.url);
        setFieldValue('file_id', result.id);
        setTouched({ sheet_url: false });
        setConnected(true);
      }
    } catch (e: any) {
      if (e.errorCode === 'popup_window_error') {
        openWarning('Please allow popup to open in the browser');
      } else {
        openError(e);
      }
    }
  };

  const openDrivePicker = async () => {
    if (!user || !CLIENT_ID) return;
    const googleToken = await storageService.getStoredGoogleToken(
      user.email!,
      isLoginCookieEnabled,
    );
    // GET CUSTOM SHEETS LIST VIEW
    const customSheetsListView = getCustomSheetsListView();
    openPicker({
      clientId: CLIENT_ID,
      developerKey: '',
      disableDefaultView: true,
      token: googleToken.accessToken,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      customViews: [customSheetsListView],
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
          setPickerCloseClicked(true);
        }
        if (data.docs && data.docs.length) {
          let id = data.docs[0].id;
          const url = data.docs[0].url;
          if (!id) {
            // url: https://docs.google.com/spreadsheets/d/1uUW4KFeicqbmP6wSJfRe3Bgp479xLlViqVWcYMJl_BY/edit#gid=0
            id = new URL(url).pathname.split('/')[3];
          }
          setFieldValue('sheet_url', url);
          setFieldValue('file_id', id);
          setTouched({ sheet_url: false });
          setConnected(true);
        }
      },
    });
  };

  useEffect(() => {
    if (pickerCloseClicked) {
      setTimeout(() => {
        setPickerCloseClicked(false);
      }, 100);
    }
  }, [pickerCloseClicked]);

  // Save the form values when the user clicks previous button.
  // This allows avoiding validations on the form, as the values are saved for later use.
  useEffect(() => {
    if (previousClicked) {
      onSubmit(formik.values, false);
    }
  }, [previousClicked]);

  return (
    <>
      {contextHolder}
      <FormikProvider value={formik}>
        <Form id={formId} autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Box width={'100%'} marginY={'16px'} marginX={'34px'}>
            <Box display={'flex'} gap={'50px'}>
              <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
                <Box>
                  <CustomTextField
                    label='Application'
                    name='application'
                    size='medium'
                    color={'#DDDBDA'}
                    startAdornment={
                      <img
                        alt={
                          isGSheetApplication
                            ? ApplicationName.GoogleSheets
                            : ApplicationName.MSExcel
                        }
                        style={{
                          marginRight: '6px',
                          height: '19px',
                          width: '19px',
                        }}
                        src={isGSheetApplication ? sheetIcon : excelIcon}
                      />
                    }
                    endAdornment={
                      !connected && (
                        <WarningRounded
                          sx={{ marginRight: '8px', color: '#fe8b01' }}
                        />
                      )
                    }
                    value={
                      isGSheetApplication
                        ? ApplicationName.GoogleSheets
                        : ApplicationName.MSExcel
                    }
                    disabled
                  />
                </Box>
                <Box>
                  <CustomTextField
                    label='Action'
                    name='action'
                    size='medium'
                    color={'#DDDBDA'}
                    startAdornment={
                      <img
                        alt='Document.'
                        style={{ marginRight: '2px' }}
                        src={docIcon}
                      />
                    }
                    value={'Add a new row'}
                    disabled
                  />
                </Box>
                <Box>
                  <CustomTextField
                    ariaLabel={
                      isGSheetApplication
                        ? 'Google Sheets URL.'
                        : 'Excel workbook URL'
                    }
                    label={
                      isGSheetApplication
                        ? 'Google Sheets URL'
                        : 'Excel workbook URL'
                    }
                    name='sheet_url'
                    size='medium'
                    onClick={handleOpenPicker}
                    onKeyUp={(event) => {
                      if (
                        !values.sheet_url &&
                        !pickerCloseClicked &&
                        event.key === 'Enter'
                      ) {
                        handleOpenPicker();
                      }
                    }}
                    startAdornment={
                      <img
                        alt={isGSheetApplication ? 'Google Sheet' : 'MS Excel'}
                        style={{
                          marginRight: '6px',
                          height: '19px',
                          width: '19px',
                        }}
                        src={isGSheetApplication ? sheetIcon : excelIcon}
                      />
                    }
                    value={''}
                    extraProps={getFieldProps('sheet_url')}
                    error={Boolean(touched.sheet_url && errors.sheet_url)}
                    helperText={touched.sheet_url && errors.sheet_url}
                    disabled={edit}
                    color={edit ? '#DDDBDA' : undefined}
                    required={true}
                    hasDescription={true}
                    autoFocus={!edit}
                    readOnly
                  />
                </Box>
                {/*<Box>*/}
                {/*  <CustomCheckboxDropdown*/}
                {/*    value={selected}*/}
                {/*    options={['1', '2', '4']}*/}
                {/*    onChange={(selected) => {*/}
                {/*      setSelected(selected)*/}
                {/*    }}*/}
                {/*    startAdornment={*/}
                {/*      <img*/}
                {/*        style={{ marginRight: '2px', marginLeft: '-4px' }}*/}
                {/*        src={docIcon}*/}
                {/*      />*/}
                {/*    }*/}
                {/*    label={'Choose mapping columns'}*/}
                {/*  />*/}
                {/*</Box>*/}
              </Box>
              <Box
                bgcolor={'#E3FCEF'}
                borderRadius={'10px'}
                height={'fit-content'}
                padding={'16px'}
                display={'flex'}
                gap={'10px'}
                marginRight={5}
                marginTop={'20px'}
                width={'360px'}
              >
                <SettingsOutlined sx={{ color: '#3BA755' }} />
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={'8px'}
                  paddingRight={'10px'}
                >
                  <span id='sheet_url'>
                    <CustomTypography color='#545454' size='14px'>
                      A few new tabs {edit ? 'has been' : 'will be'} created in
                      the provided spreadsheet, for example &quot;general&quot;
                      tab for non-nested entities, and &quot;parent entity&quot;
                      for nested entities starting with the &quot;parent
                      entity/&quot; prefix.{' '}
                      {!edit
                        ? 'If tabs with same names already exist in the spreadsheet, workflow creation will fail.'
                        : ''}
                    </CustomTypography>
                    <CustomTypography
                      sx={{ marginTop: '10px' }}
                      color='#545454'
                      size='14px'
                    >
                      Extracted entity will be written to the provided
                      spreadsheet&apos;s new tabs.
                    </CustomTypography>
                    {!edit && (
                      <CustomTypography
                        sx={{ marginTop: '10px' }}
                        color='#545454'
                        size='14px'
                      >
                        This field cannot be changed after workflow creation.
                      </CustomTypography>
                    )}
                  </span>
                </Box>
              </Box>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
};

export default memo(CreateSystemActionContent);
