import React from 'react';
import CustomModal from '../../../components/CustomModal';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';

interface Props {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  loading?: boolean;
  description?: string;
}

const ScheduleEditConfirmModal: React.FC<Props> = ({
  open,
  handleClose,
  onSubmit,
  loading = false,
  description = '',
}) => {
  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      onPrimaryClick={() => onSubmit()}
      isPrimaryLoading={loading}
      primaryDisabled={loading}
      secondaryDisabled={loading}
      heading={'Edit the schedule?'}
      containerSx={{
        width: '156px',
        paddingTop: 0,
        paddingBottom: '24px',
      }}
      primaryButtonSx={{
        width: '138px',
      }}
      secondaryButtonSx={{
        width: '138px',
      }}
      content={
        <OrbyTypography
          size='sm'
          color={OrbyColorPalette['grey-700']}
          weight='medium'
        >
          {description}
        </OrbyTypography>
      }
      primaryLabel={'Continue editing'}
      secondaryLabel={'Cancel'}
    />
  );
};

export default React.memo(ScheduleEditConfirmModal);
