import React, { useEffect, useState, useRef } from 'react';
import * as pdfjs from 'pdfjs-dist';
import { RenderParameters } from 'pdfjs-dist/types/src/display/api';
import { Box } from '@mui/material';
pdfjs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.mjs');

interface PdfRendererProps {
  gcsUri: string | undefined;
}

const pdfCache: Map<string, pdfjs.PDFDocumentProxy> = new Map();

const getCachedPdf = async (
  gcsUri: string,
): Promise<pdfjs.PDFDocumentProxy> => {
  if (pdfCache.has(gcsUri)) {
    return pdfCache.get(gcsUri)!;
  }
  const pdf = await pdfjs.getDocument(gcsUri).promise;
  pdfCache.set(gcsUri, pdf);
  return pdf;
};

const PdfRenderer: React.FC<PdfRendererProps> = ({ gcsUri }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const canvasRefs = useRef<{ [key: number]: HTMLCanvasElement | null }>({});

  useEffect(() => {
    const renderAllPages = async () => {
      try {
        if (!gcsUri) {
          return;
        }
        const pdf = await getCachedPdf(gcsUri); // Use cached PDF if available
        const totalPages = pdf.numPages;
        setNumPages(totalPages);

        for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
          const page = await pdf.getPage(pageNumber);
          const viewport = page.getViewport({ scale: 1 });

          const canvas = canvasRefs.current[pageNumber];
          if (!canvas) {
            console.error(`Canvas for page ${pageNumber} not found`);
            continue;
          }

          const context = canvas.getContext('2d');
          if (!context) {
            console.error('Canvas context not available');
            continue;
          }

          canvas.width = viewport.width;
          canvas.height = viewport.height;

          const renderContext: RenderParameters = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;
        }
      } catch (err) {
        console.error('Error rendering PDF:', err);
      }
    };

    renderAllPages();
  }, [gcsUri]);

  return (
    <Box flexDirection='column'>
      {Array.from({ length: numPages }, (_, i) => i + 1).map((pageNumber) => (
        <canvas
          key={pageNumber}
          ref={(el) => (canvasRefs.current[pageNumber] = el)}
          style={{
            display: 'block',
            margin: '10px auto',
            border: '1px solid #ccc',
          }}
        />
      ))}
    </Box>
  );
};

export default PdfRenderer;
