import React from 'react';
import {
  getExecutionStatusesFJoinedByDash,
  getWorkflowIdsJoinedByDashV2,
} from '../../pages/Utils/taskV2Utils';
import { NavigateFunction } from 'react-router-dom';
import {
  API_EXECUTION_STATUS_FILTER,
  EXECUTIONS_TAB_INDEX,
  NAME_PREFIX,
  SELECTED_TAB,
  UI_EXECUTION_STATUS_FILTER,
  USERNAMES,
  WORKFLOW_RESOURCE_NAMES,
} from '../../utils/constants';
import {
  ListWorkflowTasksRequest,
  ListWorkflowTasksRequestFilter,
} from 'protos/pb/v1alpha1/orbot_service';
import {
  ListWorkflowExecutionFilter,
  ListWorkflowExecutionsRequest,
  WorkflowExecution,
  WorkflowExecutionStatus,
} from 'protos/pb/v1alpha2/workflow_executions_service';
import { Box, Menu, MenuItem } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Check } from '@mui/icons-material';

import { OrbyBadge, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { WorkflowExecutionStatusEnum } from './tabs/api-automations/api-table-body-helpers';
import { WorkflowTaskStatusEnum } from './tabs/ui-automations/ui-table-body-helpers';
import { WorkflowTaskStatus } from 'protos/pb/v1alpha1/orbot_workflow';
import { DEFAULT_FIRST_PAGE } from 'orby-ui/src/components/table/table-utils';

export const ALL_USERS_VALUE = {
  label: 'All users',
  value: 'All users',
};

export const buildUiRequest = ({
  navigate,
  selectedWorkflows,
  selectedUsers,
  uiExecutionStatusFilters,
  displayNamePrefix,
  orgResourceName,
}: {
  navigate: NavigateFunction;
  selectedWorkflows: string[];
  selectedUsers: string[];
  uiExecutionStatusFilters: string[];
  displayNamePrefix: string;
  orgResourceName: string;
}) => {
  const req: ListWorkflowTasksRequest = ListWorkflowTasksRequest.fromJSON({
    filter: getListUiWorkflowExecutionFilter(
      navigate,
      selectedWorkflows,
      selectedUsers,
      uiExecutionStatusFilters,
      displayNamePrefix,
    ),
    orgId: orgResourceName,
  });
  return req;
};

export const buildApiRequest = ({
  navigate,
  selectedWorkflows,
  displayNamePrefix,
  apiExecutionStatusFilters,
  orgResourceName,
}: {
  navigate: NavigateFunction;
  selectedWorkflows: string[];
  displayNamePrefix: string;
  apiExecutionStatusFilters: string[];
  orgResourceName: string;
}) => {
  const req: ListWorkflowExecutionsRequest =
    ListWorkflowExecutionsRequest.fromJSON({
      filter: getListApiWorkflowExecutionFilter(
        navigate,
        selectedWorkflows,
        displayNamePrefix,
        apiExecutionStatusFilters,
      ),
      parent: orgResourceName,
    });
  return req;
};

export const getListUiWorkflowExecutionFilter = (
  navigate: NavigateFunction,
  selectedWorkflows: string[],
  selectedUsers: string[],
  uiExecutionStatusFilters: string[],
  displayNamePrefix: string,
) => {
  const filter: ListWorkflowTasksRequestFilter = {};
  const searchParams = new URLSearchParams();
  const existingParams = new URLSearchParams(location.search);

  // SEARCH FILTER
  if (displayNamePrefix) {
    filter.variablePrefix = displayNamePrefix;
    searchParams.append(NAME_PREFIX, displayNamePrefix);
  }
  // WORKFLOW FILTER
  if (
    selectedWorkflows.length &&
    !selectedWorkflows.includes('all-workflows')
  ) {
    filter.workflowResourceNames = selectedWorkflows.map(
      (workflow) => `orbot_workflows/${workflow}`,
    );
    searchParams.append(
      WORKFLOW_RESOURCE_NAMES,
      getWorkflowIdsJoinedByDashV2(selectedWorkflows) || '',
    );
  }
  // USER FILTER
  if (selectedUsers.length) {
    filter.userIds = selectedUsers;
    searchParams.append(
      USERNAMES,
      getWorkflowIdsJoinedByDashV2(selectedUsers) || '',
    );
  }
  // STATUS FILTER
  if (uiExecutionStatusFilters.length) {
    filter.statuses = uiExecutionStatusFilters.map(
      (status) => status as unknown as WorkflowTaskStatus,
    );
    searchParams.append(
      UI_EXECUTION_STATUS_FILTER,
      getExecutionStatusesFJoinedByDash(uiExecutionStatusFilters) || '',
    );
  }

  // GET THE TAB
  const selectedTab =
    existingParams.get(SELECTED_TAB) || EXECUTIONS_TAB_INDEX.UI_AUTOMATIONS;
  navigate({
    search: `?${searchParams.toString()}&${SELECTED_TAB}=${selectedTab}`,
  });
  return filter;
};

export const getListApiWorkflowExecutionFilter = (
  navigate: NavigateFunction,
  selectedWorkflows: string[],
  displayNamePrefix: string,
  apiExecutionStatusFilters: string[],
) => {
  const filter: ListWorkflowExecutionFilter = {};
  const searchParams = new URLSearchParams();
  const existingParams = new URLSearchParams(location.search);

  // SEARCH FILETER
  if (displayNamePrefix) {
    filter.namePrefix = displayNamePrefix;
    searchParams.append(NAME_PREFIX, displayNamePrefix);
  }
  // WORKFLOW FILTER
  if (
    selectedWorkflows.length &&
    !selectedWorkflows.includes('all-workflows')
  ) {
    filter.workflowResourceNames = selectedWorkflows;
    searchParams.append(
      WORKFLOW_RESOURCE_NAMES,
      getWorkflowIdsJoinedByDashV2(selectedWorkflows) || '',
    );
  }
  // STATUS FILTER
  if (apiExecutionStatusFilters.length) {
    filter.statuses = apiExecutionStatusFilters.map(
      (status) => status as unknown as WorkflowExecutionStatus,
    );
    searchParams.append(
      API_EXECUTION_STATUS_FILTER,
      getExecutionStatusesFJoinedByDash(apiExecutionStatusFilters) || '',
    );
  }

  // GET THE TAB
  const selectedTab =
    existingParams.get(SELECTED_TAB) || EXECUTIONS_TAB_INDEX.UI_AUTOMATIONS;
  navigate({
    search: `?${searchParams.toString()}&${SELECTED_TAB}=${selectedTab}`,
  });

  return filter;
};

/**
 *  HANDLE ROWS SELECTION CHANGE
 */
export const handleExecutionsRowSelectionChange = ({
  rowsNumber,
  setPage,
  setRowsPerPage,
  listExecutions,
  setSelectedExecutions,
}: {
  rowsNumber: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  listExecutions: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
  setSelectedExecutions?: (execution: WorkflowExecution[]) => void;
}) => {
  setPage(DEFAULT_FIRST_PAGE);
  // Refresh is needed when rows per page is changes to fetch fresh data
  setRowsPerPage(rowsNumber);
  listExecutions(DEFAULT_FIRST_PAGE, rowsNumber, true);
  setSelectedExecutions?.([]);
};

export const getExecutionStatusDropdown = ({
  statusAnchorEl,
  setStatusAnchorEl,
  executionStatusFilters,
}: {
  statusAnchorEl: HTMLElement | null;
  setStatusAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  executionStatusFilters: string[];
}) => {
  return (
    <Box
      display={'flex'}
      tabIndex={0}
      role='button'
      alignItems={'center'}
      justifyContent={'center'}
      sx={{
        cursor: 'pointer',
      }}
      onClick={(e) => {
        setStatusAnchorEl(e.currentTarget);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setStatusAnchorEl(e.currentTarget);
        }
      }}
    >
      <OrbyTypography
        color={OrbyColorPalette['blue-900']}
        size={'sm'}
        weight={'semibold'}
      >
        {getStatusDropdownValue(executionStatusFilters)}
      </OrbyTypography>
      {statusAnchorEl ? (
        <ArrowDropUpIcon
          sx={{ color: OrbyColorPalette['blue-900'] }}
          fontSize='medium'
        />
      ) : (
        <ArrowDropDownIcon
          sx={{ color: OrbyColorPalette['blue-900'] }}
          fontSize='medium'
        />
      )}
    </Box>
  );
};

export const EXECUTION_ALL_STATUS = {
  label: 'All statuses',
  value: 'all-statuses',
};

export const getExecutionStatusFilterMenu = ({
  statusAnchorEl,
  setStatusAnchorEl,
  handleExecutionStatusSelect,
  executionStatusFilters,
  isApiExecution,
}: {
  statusAnchorEl: HTMLElement | null;
  setStatusAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  executionStatusFilters: string[];
  handleExecutionStatusSelect: (status: string) => void;
  isApiExecution: boolean;
}) => {
  return (
    <Menu
      id='basic-menu'
      anchorEl={statusAnchorEl}
      autoFocus={false}
      open={!!statusAnchorEl}
      onClose={() => {
        setStatusAnchorEl(null);
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          border: `1px solid ${OrbyColorPalette['grey-100']}`,
          boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
          overflow: 'visible',
          borderRadius: '8px',
          minWidth: '208px',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem
        selected={executionStatusFilters.length === 0}
        onClick={() => {
          handleExecutionStatusSelect(EXECUTION_ALL_STATUS.value);
        }}
        disableRipple={true}
        sx={{
          height: '44px',
          background: OrbyColorPalette['white-0'],
          '&:hover': {
            background: OrbyColorPalette['grey-50'],
          },
          '&.Mui-selected': {
            backgroundColor: `${OrbyColorPalette['grey-50']}`,
          },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <OrbyTypography
          size='md'
          weight='medium'
          color={OrbyColorPalette['grey-900']}
        >
          {EXECUTION_ALL_STATUS.label}
        </OrbyTypography>
        {executionStatusFilters.length === 0 && (
          <Check sx={{ color: OrbyColorPalette['purple-700'] }} />
        )}
      </MenuItem>

      {Object.values(
        isApiExecution ? WorkflowExecutionStatusEnum : WorkflowTaskStatusEnum,
      ).map((executionStatus) => {
        return (
          <MenuItem
            selected={executionStatusFilters.includes(
              executionStatus.value.toString(),
            )}
            onClick={() => {
              handleExecutionStatusSelect(executionStatus.value.toString());
            }}
            disableRipple={true}
            sx={{
              height: '44px',
              '&:hover': {
                background: OrbyColorPalette['grey-50'],
              },
              '&.Mui-selected': {
                backgroundColor: `${OrbyColorPalette['grey-50']}`,
              },
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            key={executionStatus.label}
          >
            <OrbyBadge
              startIcon={
                <CircleIcon
                  sx={{
                    width: '6px',
                    height: '6px',
                    color: executionStatus.dotColor,
                  }}
                />
              }
              backgroundColor={executionStatus.backgroundColor}
              textColor={executionStatus.color}
              size='small'
              badgeName={executionStatus.label}
            />
            {executionStatusFilters.includes(
              executionStatus.value.toString(),
            ) && <Check sx={{ color: OrbyColorPalette['purple-700'] }} />}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export const getStatusDropdownValue = (
  executionStatusFilters: string[],
): string => {
  if (!executionStatusFilters.length) {
    return EXECUTION_ALL_STATUS.label;
  } else {
    return `${executionStatusFilters.length} ${executionStatusFilters.length === 1 ? 'status' : 'statuses'} selected`;
  }
};
