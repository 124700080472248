import { Box, Button } from '@mui/material';
import React from 'react';
import TimeInput from '../../../../components/TimeInput';
import { OrbyBadge, OrbyColorPalette } from 'orby-ui/src';
import CloseIcon from '@mui/icons-material/Close';
import { formatTime, VALIDATION_ERROR_MESSAGE } from '../utils/helper';

interface SchedulerTimeInputProps {
  scheduledTimes: { hours: string; minutes: string }[];
  setScheduledTimes: (times: { hours: string; minutes: string }[]) => void;
  timeError: string | null;
  setTimeError: (error: string | null) => void;
  getCurrentInputValue: ({
    hours,
    minutes,
  }: {
    hours: string;
    minutes: string;
  }) => void;
}

const SchedulerTimeInput: React.FC<SchedulerTimeInputProps> = ({
  scheduledTimes,
  setScheduledTimes,
  timeError,
  setTimeError,
  getCurrentInputValue,
}) => {
  const handleNewTimeInput = (newTime: { hours: string; minutes: string }) => {
    setScheduledTimes([...scheduledTimes, newTime]);
  };

  return (
    <>
      <TimeInput
        getCurrentInputValue={getCurrentInputValue}
        onEnter={(newTime) => handleNewTimeInput(newTime)}
        additionalValidator={() =>
          !scheduledTimes.length ? VALIDATION_ERROR_MESSAGE.TIME : ''
        }
        error={timeError}
        setError={setTimeError}
      />

      {scheduledTimes?.length ? (
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
            mt: '12px',
            flexWrap: 'wrap',
          }}
        >
          {scheduledTimes.map((time, index) => (
            <OrbyBadge
              key={index}
              size='small'
              textColor={OrbyColorPalette['grey-700']}
              backgroundColor={OrbyColorPalette['grey-100']}
              badgeName={formatTime(time.hours, time.minutes)}
              endIcon={
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setScheduledTimes(
                      scheduledTimes.filter((_, i) => i !== index),
                    );
                  }}
                  sx={{
                    minWidth: '12px',
                    padding: '0px',
                  }}
                  size='small'
                >
                  <CloseIcon
                    sx={{
                      color: OrbyColorPalette['grey-500'],
                      fontSize: '12px',
                      cursor: 'pointer',
                    }}
                  />
                </Button>
              }
              boxSx={{
                borderRadius: '4px',
                background: OrbyColorPalette['grey-100'],
                color: OrbyColorPalette['grey-700'],
                padding: '2px 8px 2px 10px',
              }}
              textSx={{
                fontSize: '14px',
                fontWeight: '500',
              }}
            ></OrbyBadge>
          ))}
        </Box>
      ) : null}
    </>
  );
};

export default SchedulerTimeInput;
