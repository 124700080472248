import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { FieldGroupMatch, Item } from 'protos/pb/v1alpha1/actionprocessing';
import { Task, TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import {
  calculateGroupings,
  calculateUpdatedItem,
  getReconcileData,
} from '../../Utils/taskV2Utils';
import { Grouping, GroupingItem, GroupingResult, Rematch } from './Rematch';
import {
  getIcon,
  getLineItemAmount,
  getLineItemDate,
  getLineItemDescription,
  getLineItemQuantity,
  getLineItemTax,
  getLineItemUnitCost,
  getLineNumberFromFields,
  getPOLineNumber,
  TextCell,
} from './HITLdetails-helper';
import { OrbyColorPalette } from 'orby-ui/src';
import { getJDE1Updates } from 'workflow-templates/src/templates/jll/jde1/utils';
import { getCaapsUpdates } from 'workflow-templates/src/templates/jll/caaps/utils';
import { updateMatches } from '../../../redux/actions/review_task.action';
import { useDispatch } from 'react-redux';

const Row = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '18px',
  alignItems: 'center',
});

type CellAlign = 'center' | 'right';

const Cell = styled(Box, { shouldForwardProp: (prop) => prop !== 'align' })<{
  align?: CellAlign;
}>(({ align }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingRight: '2px',
  ...(align && {
    justifyContent: align === 'center' ? 'center' : 'end',
  }),
}));

interface Header {
  title: string;
  align?: CellAlign;
  sx: {
    minWidth: number;
    width?: string;
    pl?: string;
    pr?: string;
  };
}

const sourceHeaders: Header[] = [
  { title: 'Line', align: 'center', sx: { minWidth: 50, pl: '12px' } },
  { title: 'Description', sx: { minWidth: 160, width: '40%' } },
  { title: 'Amount', align: 'right', sx: { minWidth: 80 } },
  { title: 'Tax rate', align: 'right', sx: { minWidth: 72 } },
  { title: 'Qty', align: 'right', sx: { minWidth: 80 } },
  { title: 'Unit cost', align: 'right', sx: { minWidth: 80, pr: '12px' } },
];

const targetHeaders: Header[] = [
  { title: '', sx: { minWidth: 30, pl: '12px' } },
  { title: 'Line', align: 'center', sx: { minWidth: 30 } },
  { title: 'Description', sx: { minWidth: 160, width: '40%' } },
  { title: 'Service date', sx: { minWidth: 100 } },
  { title: 'Amount', align: 'right', sx: { minWidth: 80 } },
  { title: 'Tax rate', align: 'right', sx: { minWidth: 72, pr: '12px' } },
];

interface Props {
  isExpanded: boolean;
  taskData?: Task;
}

const HITLExtractedDataReview: React.FC<Props> = ({ taskData }) => {
  const dispatch = useDispatch();
  const groupings = initialGroupings(taskData);

  const onMatchChange = (groupingResult: GroupingResult) => {
    const matches = groupingResultToMatches(groupingResult);
    const data = getReconcileData(taskData);
    if (!data) {
      return;
    }
    dispatch(
      updateMatches({
        matches,
        stepIndex: data.stepIndex,
      }),
    );
  };

  if (!groupings) {
    return null;
  }

  return (
    <Box width='1200px'>
      <Rematch
        leftTitle='Extracted invoice from CAAPS'
        rightTitle='Extracted PO from JDE1'
        leftHeader={getHeader(targetHeaders)}
        rightHeader={getHeader(sourceHeaders)}
        matchGroupings={groupings?.matchGroupings}
        leftUnmatchedTitle='Unmatched invoice line items'
        rightItemMinWidth={sourceHeaders
          .map((h) => h.sx.minWidth)
          .reduce((a, b) => a + b, 0)}
        onChange={onMatchChange}
        disabled={taskData?.status === TaskSTATUS.COMPLETED}
      />
    </Box>
  );
};

const groupingResultToMatches = (
  groupingResult: GroupingResult,
): FieldGroupMatch[] => {
  const matches: FieldGroupMatch[] = [];
  for (const grouping of groupingResult.matchGroupings) {
    if (grouping.rightItems.length === 0) {
      grouping.leftItems.forEach((item) => {
        const idArr = item.id.split('-');
        matches.push({
          unmatchedTarget: {
            index: parseInt(idArr[idArr.length - 1]),
          },
        });
      });
    } else {
      grouping.rightItems.forEach((item) => {
        const idArr = item.id.split('-');
        const sourceIndex = parseInt(idArr[idArr.length - 1]);
        if (grouping.leftItems.length === 0) {
          matches.push({
            unmatchedSource: {
              index: sourceIndex,
            },
          });
        } else {
          grouping.leftItems.forEach((item) => {
            const idArr = item.id.split('-');
            const targetIndex = parseInt(idArr[idArr.length - 1]);
            matches.push({
              match: {
                sourceIndex: sourceIndex,
                targetIndex: targetIndex,
              },
            });
          });
        }
      });
    }
  }
  return matches;
};

const initialGroupings = (taskData?: Task): GroupingResult | undefined => {
  const data = getReconcileData(taskData);
  if (!data) {
    return undefined;
  }
  const { source, target, reconcileResult } = data;
  return getGroupingResultFromMatches(
    reconcileResult.fieldGroupMatches!,
    source,
    target,
  );
};

const getGroupingResultFromMatches = (
  matches: FieldGroupMatch[],
  source: Item,
  target: Item,
): GroupingResult => {
  const jde1Updates = getJDE1Updates(matches, [source!, target]);
  const caapsUpdates = getCaapsUpdates(matches, source);
  const updatedSourceItem = calculateUpdatedItem(source!, jde1Updates);
  const updatedTargetItem = calculateUpdatedItem(target, caapsUpdates);

  return getGroupingResult(matches, updatedSourceItem, updatedTargetItem);
};

function parseLineNumber(value: string): number | undefined {
  const n = parseInt(value);
  return Number.isInteger(n) ? n : undefined;
}

function getSourceRowItem(source: Item, sourceIndex: number): GroupingItem {
  return {
    id: `source-${sourceIndex}`,
    matchingId: parseLineNumber(
      getLineNumberFromFields(source.fieldGroups?.[sourceIndex].fields),
    ),
    content: (
      <Row>
        <Cell align={sourceHeaders[0].align} sx={sourceHeaders[0].sx}>
          {getPOLineNumber(source.fieldGroups?.[sourceIndex].fields)}
        </Cell>
        <Cell align={sourceHeaders[1].align} sx={sourceHeaders[1].sx}>
          {getLineItemDescription(source.fieldGroups?.[sourceIndex].fields)}
        </Cell>
        <Cell align={sourceHeaders[2].align} sx={sourceHeaders[2].sx}>
          {getLineItemAmount(source.fieldGroups?.[sourceIndex].fields)}
        </Cell>
        <Cell align={sourceHeaders[3].align} sx={sourceHeaders[3].sx}>
          {getLineItemTax(source.fieldGroups?.[sourceIndex].fields)}
        </Cell>
        <Cell align={sourceHeaders[4].align} sx={sourceHeaders[4].sx}>
          {getLineItemQuantity(source.fieldGroups?.[sourceIndex].fields)}
        </Cell>
        <Cell align={sourceHeaders[5].align} sx={sourceHeaders[5].sx}>
          {getLineItemUnitCost(source.fieldGroups?.[sourceIndex].fields)}
        </Cell>
      </Row>
    ),
  };
}

function getTargetRowItem(target: Item, targetIndex: number): GroupingItem {
  return {
    id: `target-${targetIndex}`,
    content: (
      <Row>
        <Cell align={targetHeaders[0].align} sx={targetHeaders[0].sx}>
          {getIcon()}
        </Cell>
        <Cell align={targetHeaders[1].align} sx={targetHeaders[1].sx}>
          {/* invoice line number should be 1-based */}
          <TextCell text={(targetIndex + 1).toString()} />
        </Cell>
        <Cell align={targetHeaders[2].align} sx={targetHeaders[2].sx}>
          {getLineItemDescription(target.fieldGroups?.[targetIndex].fields)}
        </Cell>
        <Cell align={targetHeaders[3].align} sx={targetHeaders[3].sx}>
          {getLineItemDate(target.fieldGroups?.[targetIndex].fields)}
        </Cell>
        <Cell align={targetHeaders[4].align} sx={targetHeaders[4].sx}>
          {getLineItemAmount(target.fieldGroups?.[targetIndex].fields)}
        </Cell>
        <Cell align={targetHeaders[5].align} sx={targetHeaders[5].sx}>
          {getLineItemTax(target.fieldGroups?.[targetIndex].fields)}
        </Cell>
      </Row>
    ),
  };
}

const getGroupingResult = (
  matches: FieldGroupMatch[],
  source: Item,
  target: Item,
): GroupingResult => {
  const aggregatedMapping = calculateGroupings(matches);

  const matchGroupings: Grouping[] = [];
  for (const { sourceIndex, targetIndices } of aggregatedMapping) {
    const grouping: Grouping = {
      leftItems: targetIndices.map((targetIndex) =>
        getTargetRowItem(target, targetIndex),
      ),
      rightItems:
        sourceIndex !== undefined
          ? [getSourceRowItem(source, sourceIndex)]
          : [],
    };
    matchGroupings.push(grouping);
  }

  const reorderedGroupings = [
    ...matchGroupings.filter((g) => g.leftItems.length !== 0),
    ...matchGroupings.filter((g) => g.leftItems.length === 0),
  ];

  return {
    matchGroupings: reorderedGroupings,
  };
};

const getHeader = (headers: Header[]) => {
  return (
    <Row>
      {headers.map((col) => (
        <Cell align={col.align} key={col.title} sx={col.sx}>
          <Typography
            variant='body2'
            fontSize='12px'
            fontWeight={600}
            sx={{ color: OrbyColorPalette['grey-700'] }}
          >
            {col.title}
          </Typography>
        </Cell>
      ))}
    </Row>
  );
};

export default HITLExtractedDataReview;
