import { InputAdornment, Popover, TextField } from '@mui/material';
import React, { FC, memo, useEffect } from 'react';
import { EntityInfo } from '../../../../../../../redux/reducers/review_task.reducer';
import TableModalChildMenu from './TableModalChildMenu';
import { OrbyColorPalette } from 'orby-ui/src';
import { useDispatch, useSelector } from 'react-redux';
import { updateEntityInfoForTableAnnotationAction } from '../../../../../../../redux/actions/review_task.action';
import { detectUndoRedoKeyPress } from '../../../../../../../utils/helpers';
import { selectedEntityIdsForAnnotationSelector } from '../../../../../../../redux/selectors/review_task.selectors';

interface Props {
  onCopy: () => void;
  onPaste: () => void;
  enablePaste: boolean;
  anchorEl: HTMLElement;
  selectedCell: EntityInfo | undefined;
  setAnchorEl: (x: HTMLElement | null) => void;
  selectNextCell: () => void;
}

const TableTextPopover: FC<Props> = ({
  onCopy,
  onPaste,
  enablePaste,
  anchorEl,
  selectedCell,
  setAnchorEl,
  selectNextCell: openNextCell,
}) => {
  const dispatch = useDispatch();
  const selectedEntityIdsForAnnotation = useSelector(
    selectedEntityIdsForAnnotationSelector,
  );
  const open = Boolean(anchorEl);
  const selectedTableCell = document.getElementById(
    selectedCell ? `cell-${selectedCell.id}` : '',
  );
  const rect = selectedTableCell?.getBoundingClientRect();

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    const newEntityInfo = {
      ...selectedCell,
    };
    if (selectedCell?.isExtra) {
      newEntityInfo.extraEntityText = text;
    } else {
      newEntityInfo.entityText = text;
    }
    dispatch(
      updateEntityInfoForTableAnnotationAction(newEntityInfo as EntityInfo),
    );
  };

  useEffect(() => {
    // Close the popover if the currently active cell is no longer in the selectedEntityIdsForAnnotation
    // This scenario occurs exclusively during undo/redo operations, as it's not possible
    // to select another cell while the text popover is active under normal circumstances
    if (
      selectedCell &&
      !selectedEntityIdsForAnnotation.includes(selectedCell.id)
    ) {
      setAnchorEl(null);
    }
  }, [selectedEntityIdsForAnnotation]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      transitionDuration={0}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      slotProps={{
        paper: {
          sx: {
            boxShadow: 'none',
            borderRadius: '0px !important',
            padding: '1px',
            width: `${anchorEl?.clientWidth + 3}px !important`,
            top: `${rect?.top && rect.top - 1}px !important`,
            left: `${rect?.left && rect.left - 1}px !important`,
          },
        },
      }}
    >
      <TextField
        autoFocus
        onFocus={(e) => {
          const length = e.target.value.length;
          e.target.setSelectionRange(length, length);
        }}
        onKeyDown={(e) => {
          if (detectUndoRedoKeyPress(e)) {
            // prevents built-in undo/redo for this text field
            e.preventDefault();
          } else {
            e.stopPropagation();
            if (e.key === 'Tab') {
              setAnchorEl(null);
            }
            // Check if 'Enter' pressed without 'Shift' key
            if (!e.shiftKey && e.key === 'Enter') {
              e.preventDefault();
              openNextCell();
            }
          }
        }}
        variant='outlined'
        fullWidth
        multiline
        value={
          selectedCell?.isExtra
            ? selectedCell?.extraEntityText
            : selectedCell?.entityText
        }
        onChange={handleValueChange}
        InputProps={{
          endAdornment: selectedCell?.isExtra ? undefined : (
            <InputAdornment position='end'>
              <TableModalChildMenu
                cell={selectedCell!}
                onCopy={onCopy}
                onPaste={onPaste}
                enablePaste={enablePaste}
                closeTextPopover={() => setAnchorEl(null)}
                showSmall
              />
            </InputAdornment>
          ),
          sx: {
            borderRadius: '0px',
            minHeight: '30px',
            paddingY: '2px',
            paddingX: '8px',
            fontSize: '14px',
            '& .MuiInputBase-input': {
              borderRadius: '0px',
            },
            '& fieldset': {
              borderRadius: '0px',
              borderColor: `${OrbyColorPalette['blue-700']} !important`,
            },
          },
        }}
      />
    </Popover>
  );
};

export default memo(TableTextPopover);
