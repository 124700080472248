import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';

import { getFontSize } from 'orby-ui/src/components/typography/typography-utils';
import {
  OrbyButton,
  OrbyColorPalette,
  OrbyTabs,
  OrbyTextField,
  OrbyTypography,
} from 'orby-ui/src';

import { getValidDateFilter, isAdmin } from '../../utils/helpers';
import {
  DISPLAY_NAME_PREFIX,
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
  DateRange,
  PERIOD,
  SELECTED_TAB,
  WORKFLOW_APPS,
  WORKFLOW_MODES,
  WORKFLOW_RESOURCE_NAMES,
  FEATURE_FLAGS,
} from '../../utils/constants';
import {
  getUserIdsFromParamStringV2,
  getUsersFromParamStringV2,
  getWorkflowFromParamString,
  getWorkflowIdsFromParamStringV2,
} from '../Utils/taskV2Utils';
import { useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
  userPermissionsSelector,
} from '../../redux/selectors/user.selectors';

import { ReactComponent as AddButtonIcon } from '../../static/icons/add-button-plus.svg';
import { useIsOrgChanged } from '../../hooks/useIsOrgChanged';
import WorkflowApiAutomationTab from './tabs/ApiAutomation/WorkflowApiAutomationTab';
import UserFilter from '../../pages/Tasks/component/UserFilter';
import AppsFilter, { APP_TYPE_OTHER } from './components/AppsFilter';
import ModesFilter from './components/ModesFilter';
import TimeFilter from '../../components/TimeFilter/TimeFilter';
import { useNavigate } from 'react-router-dom';
import ConnectorsTab from './tabs/Connectors/ConnectorsTab';
import WorkflowFilter from '../../pages/Tasks/component/WorkflowFilter';
import { ALL_CREATOR_VALUE } from '../../pages/Tasks/TaskHelpers';
import WorkflowUiAutomationTab from './tabs/UiAutomation/WorkflowUiAutomationTab';
import { getFeatureFlagsForOrgAndUserSelector } from '../../redux/selectors/feature_flags.selectors';
import { isFeatureFlagEnabled } from '../FeatureFlags/FeatureFlagUtils';

interface Props {
  sideDrawerCollapse: boolean;
}

export const WORKFLOW_PAGE = {
  UI_AUTOMATION_TAB_INDEX: 0,
  API_AUTOMATION_TAB_INDEX: 1,
  CONNECTOR_TAB_INDEX: 2,
};

const MyWorkflows: React.FC<Props> = ({ sideDrawerCollapse }) => {
  const urlSearchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const isOrgChanged = useIsOrgChanged(selectedOrgInfo?.orgResourceName || '');
  const user = useSelector(loggedInUserSelector);

  // rbac enabled
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const isRbacEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.RBAC, featureFlags);
  // user permissions
  const userPermissions = useSelector(userPermissionsSelector);
  const isAdminView = isAdmin(selectedOrgInfo?.role);
  const canCreateWorkflow =
    isAdminView || (isRbacEnabled && userPermissions.createWorkflow);
  const canListConnectors =
    isAdminView || (isRbacEnabled && userPermissions.listConnectors);

  const [selectedTab, setSelectedTab] = useState(
    parseInt(
      urlSearchParams.get(SELECTED_TAB) ||
        `${WORKFLOW_PAGE.UI_AUTOMATION_TAB_INDEX}`,
    ),
  );

  // USED FOR SEARCHING TASK BY NAME
  const [displayNamePrefix, setDisplayNamePrefix] = useState(
    urlSearchParams.get(DISPLAY_NAME_PREFIX) ?? '',
  );
  // FILTER BY USER
  const [selectedUsers, setSelectedUsers] = useState<Array<string>>(
    getUsersFromParamStringV2(urlSearchParams),
  );

  // FILTER BY USER
  const [selectedUsersUiAuomtation, setSelectedUsersUiAuomtation] = useState<
    Array<string>
  >(getUserIdsFromParamStringV2(urlSearchParams));

  // WORKFLOW FILTER
  const [selectedWorkflows, setSelectedWorkflows] = useState(
    getWorkflowIdsFromParamStringV2(
      urlSearchParams.get(WORKFLOW_RESOURCE_NAMES) ?? '',
    ),
  );

  // FILTER BY APPS
  const [selectedApps, setSelectedApps] = useState<Array<string>>(
    getWorkflowFromParamString(urlSearchParams.get(WORKFLOW_APPS) ?? ''),
  );

  // FILTER BY MODES
  const [selectedModes, setSelectedModes] = useState<Array<string>>(
    getWorkflowFromParamString(urlSearchParams.get(WORKFLOW_MODES) ?? ''),
  );

  // FILTER BY TIME
  const [selectedTime, setSelectedTime] = useState<string>(
    urlSearchParams.get(PERIOD) ?? '',
  );

  const [customRange, setCustomRange] = useState<DateRange>(
    () => getValidDateFilter(urlSearchParams).range,
  );

  /**
   * GET WIDTH OF THE TABLE
   */
  const getTableWidth = () => {
    const sideNavWidth = sideDrawerCollapse
      ? DRAWER_WIDTH_COLLAPSED
      : DRAWER_WIDTH_EXPANDED;
    const PADDING = 48 * 2;

    return window.innerWidth - sideNavWidth - PADDING;
  };

  /**
   * HANDLE TAB CHANGE
   */
  const handleTabChange = (tabIndex: number) => {
    const existingParams = new URLSearchParams(location.search);
    if (existingParams.has(SELECTED_TAB)) {
      existingParams.delete(SELECTED_TAB);
    }
    existingParams.append(SELECTED_TAB, tabIndex.toString());
    navigate({ search: existingParams.toString() });
    setSelectedTab(tabIndex);
  };

  const tabs = [
    {
      id: 'ui-automations-tab',
      label: 'UI Automations',
      children: (
        <WorkflowUiAutomationTab
          displayNamePrefix={displayNamePrefix}
          selectedUsers={selectedUsersUiAuomtation}
          selectedApps={selectedApps}
          selectedModes={selectedModes}
          selectedTime={selectedTime}
          customRange={customRange}
          tableWidth={getTableWidth()}
        />
      ),
    },
    {
      id: 'api-automations-tab',
      label: 'API Automations',
      children: (
        <WorkflowApiAutomationTab
          displayNamePrefix={displayNamePrefix}
          selectedUsers={selectedUsers}
          isAdminView={isAdminView}
          isWorkflowAdminView={
            !isAdminView && isRbacEnabled && userPermissions.listWorkflows
          }
          selectedApps={selectedApps}
          selectedModes={selectedModes}
          selectedTime={selectedTime}
          customRange={customRange}
          tableWidth={getTableWidth()}
        />
      ),
    },
    ...(canListConnectors
      ? [
          {
            id: 'connectors-automations-tab',
            label: 'Connectors',
            children: (
              <ConnectorsTab
                displayNamePrefix={displayNamePrefix}
                selectedUsers={selectedUsers}
                selectedTime={selectedTime}
                selectedWorkflows={selectedWorkflows}
                customRange={customRange}
                tableWidth={getTableWidth()}
              />
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (isOrgChanged.isOrgChanged) {
      setDisplayNamePrefix('');
      setSelectedUsers([]);
    }
  }, [isOrgChanged]);

  return (
    <>
      <Box paddingX={'48px'} paddingY={'48px'}>
        {/* PAGE HEADER */}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          width={'100%'}
          paddingBottom={'10px'}
          alignItems={'center'}
        >
          <Box>
            <OrbyTypography size={'display-sm'} weight={'medium'}>
              Workflows
            </OrbyTypography>
          </Box>
          {/* ADD TASK */}
          <Box display={'flex'} gap={'24px'}>
            {/* CREATE CONNECTOR */}
            {canListConnectors && (
              <OrbyButton
                onClick={() => navigate('/workflow/connector/create')}
                size='large'
                variant='primary-outline'
                label='Create a Connector'
                startIcon={
                  <AddButtonIcon
                    style={{
                      fill: OrbyColorPalette['blue-700'],
                      fontSize: `${getFontSize('md')}!important`,
                    }}
                  />
                }
              />
            )}
            {/* CREATE WORKFLOW */}
            {canCreateWorkflow && (
              <OrbyButton
                onClick={() => navigate('/workflow/template')}
                size='large'
                variant='primary-contained'
                label='Create a Workflow'
                startIcon={
                  <AddButtonIcon
                    style={{ fontSize: `${getFontSize('md')}!important` }}
                  />
                }
              />
            )}
          </Box>
        </Box>

        {/* TASK FILTER */}
        <Box
          position={'sticky'}
          top={0}
          bgcolor={OrbyColorPalette['white-0']}
          zIndex={1}
          paddingTop={'14px'}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'flex-start'}
            width={'100%'}
            gap={'8px'}
            paddingBottom={'24px'}
          >
            {/* SEARCH WORKFLOW BY NAME */}
            <Box>
              <OrbyTextField
                width={'320px'}
                tabIndex={0}
                value={displayNamePrefix}
                name={'search-workflow-name'}
                disabled={false}
                fontSize='14px'
                placeholder={'Search by workflow or connector name'}
                startAdornment={<GridSearchIcon fontSize='medium' />}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setDisplayNamePrefix(event.target.value)
                }
              />
            </Box>
            {/* FILTER WORKFLOWS BY APPS - ONLY COMES FOR API AUTOMATION */}
            {[WORKFLOW_PAGE.API_AUTOMATION_TAB_INDEX].includes(selectedTab) && (
              <Box>
                <AppsFilter
                  width='160px'
                  menuWidth='294px'
                  selectedApps={selectedApps}
                  setSelectedApps={setSelectedApps}
                  excludeAppTypes={[APP_TYPE_OTHER]}
                />
              </Box>
            )}

            {/* FILTER CONNECTORS BY WORKFLOWS - ONLY COMES FOR CONNECTOR */}
            {[WORKFLOW_PAGE.CONNECTOR_TAB_INDEX].includes(selectedTab) && (
              <Box>
                <WorkflowFilter
                  width='160px'
                  menuWidth='320px'
                  selectedWorkflows={selectedWorkflows}
                  setSelectedWorkflows={setSelectedWorkflows}
                />
              </Box>
            )}

            {/* FILTER WORKFLOWS BY USER FOR CONNECTOR SINCE IT TAKES EMAIL*/}
            {[WORKFLOW_PAGE.CONNECTOR_TAB_INDEX].includes(selectedTab) && (
              <Box>
                <UserFilter
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  user={user!}
                  isAdminView={isAdminView}
                  isShowAssignedToMe={false}
                  isShowUnassignedOnly={false}
                  allValue={ALL_CREATOR_VALUE}
                  width='160px'
                  menuWidth='294px'
                />
              </Box>
            )}
            {/* FILTER WORKFLOWS BY USER FOR UI AUTOMATION SINCE IT TAKES ID */}
            {[WORKFLOW_PAGE.UI_AUTOMATION_TAB_INDEX].includes(selectedTab) && (
              <Box>
                <UserFilter
                  selectedUsers={selectedUsersUiAuomtation}
                  setSelectedUsers={setSelectedUsersUiAuomtation}
                  user={user!}
                  isAdminView={isAdminView}
                  isShowAssignedToMe={false}
                  isShowUnassignedOnly={false}
                  userFilterMappedValue='value'
                  allValue={ALL_CREATOR_VALUE}
                  width='160px'
                  menuWidth='294px'
                />
              </Box>
            )}
            {/* FILTER WORKFLOWS BY MODES */}
            {[WORKFLOW_PAGE.API_AUTOMATION_TAB_INDEX].includes(selectedTab) && (
              <Box>
                <ModesFilter
                  width='160px'
                  menuWidth='294px'
                  selectedModes={selectedModes}
                  setSelectedModes={setSelectedModes}
                />
              </Box>
            )}
            {/* FILTER BY TIMESTAMP */}
            {
              <TimeFilter
                value={selectedTime}
                setValue={setSelectedTime}
                width='160px'
                menuWidth='200px'
                customRange={customRange}
                setCustomRange={setCustomRange}
                isShowAllTime={true}
              />
            }
          </Box>
          <OrbyTabs
            selectedTab={selectedTab}
            setSelectedTab={(tabIndex: number) => handleTabChange(tabIndex)}
            tabs={tabs}
          />
        </Box>

        {/* TAB PANELS */}
        <Box>
          {tabs.map((tab, index) => (
            <div
              key={tab.id}
              role='tabpanel'
              hidden={selectedTab !== index}
              id={`tabpanel-${tab.id}`}
              aria-labelledby={`tab-${tab.id}`}
            >
              {selectedTab === index && (
                <Box sx={{ paddingTop: '12px' }}>{tab.children}</Box>
              )}
            </div>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default React.memo(MyWorkflows);
