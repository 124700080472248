import { TaskV2ActionType } from './actions.constants';
import {
  ListTasksRequest,
  UpdateTaskRequest,
  Task,
  CreateTaskRequest,
  GetTaskRequest,
  UpdateBatchTasksRequest,
  UpdateBatchTasksResponse,
  RetryTasksRequest,
  RetryTasksResponse,
} from 'protos/pb/v1alpha2/tasks_service';
import { EntityStatus } from '../reducers/taskV2.reducer';
import { DocumentEntity } from 'protos/google/cloud/documentai/v1/document';
import { Document } from 'protos/google/cloud/documentai/v1/document';
import {
  DeleteTaskActionCompletedPayload,
  DeleteTaskActionPayload,
} from '../../utils/constants';
import { ListWorkflowTasksRequest } from 'protos/pb/v1alpha1/orbot_service';
import {
  GetBlockedWorkflowExecutionStatisticsRequest,
  ListWorkflowExecutionsRequest,
} from 'protos/pb/v1alpha2/workflow_executions_service';

export const listTasksAction = (req: ListTasksRequest, refresh = false) => {
  return {
    type: TaskV2ActionType.LIST_TASKS_V2,
    payload: req,
    refresh,
  };
};

export const listTasksCompletedAction = (
  tasks: Task[],
  nextPageToken: string,
  totalSize: number,
  refresh: boolean,
) => ({
  type: TaskV2ActionType.LIST_TASKS_V2_COMPLETED,
  payload: tasks,
  nextPageToken,
  totalSize,
  refresh,
});

export const listTasksErrorAction = (error?: Error) => ({
  type: TaskV2ActionType.LIST_TASKS_V2_ERROR,
  payload: error,
});

export const listPendingTasksAction = (
  req: ListTasksRequest,
  refresh = false,
) => {
  return {
    type: TaskV2ActionType.LIST_PENDING_TASKS,
    payload: req,
    refresh,
  };
};

export const listPendingTasksCompletedAction = (
  tasks: Task[],
  nextPageToken: string,
  totalSize: number,
  refresh: boolean,
) => ({
  type: TaskV2ActionType.LIST_PENDING_TASKS_COMPLETED,
  payload: tasks,
  nextPageToken,
  totalSize,
  refresh,
});

export const listPendingTasksErrorAction = (error?: Error) => ({
  type: TaskV2ActionType.LIST_PENDING_TASKS_ERROR,
  payload: error,
});

export const listPendingTasksAdditionalRoundAction = (
  req: ListTasksRequest,
  refresh = false,
) => {
  return {
    type: TaskV2ActionType.LIST_PENDING_TASKS_ADDITIONAL_ROUND,
    payload: req,
    refresh,
  };
};

export const listPendingTasksAdditionalRoundCompletedAction = (
  tasks: Task[],
  nextPageToken: string,
  totalSize: number,
  refresh: boolean,
) => ({
  type: TaskV2ActionType.LIST_PENDING_TASKS_ADDITIONAL_ROUND_COMPLETED,
  payload: tasks,
  nextPageToken,
  totalSize,
  refresh,
});

export const listPendingTasksAdditionalRoundErrorAction = (error?: Error) => ({
  type: TaskV2ActionType.LIST_PENDING_TASKS_ADDITIONAL_ROUND_ERROR,
  payload: error,
});

export const createTaskAction = (req: CreateTaskRequest) => ({
  type: TaskV2ActionType.CREATE_TASK_V2,
  payload: req,
});

export const createTaskCompletedAction = (task: Task) => ({
  type: TaskV2ActionType.CREATE_TASK_V2_COMPLETED,
  payload: task,
});

export const createTaskErrorAction = (error?: Error) => ({
  type: TaskV2ActionType.CREATE_TASK_V2_ERROR,
  payload: error,
});

export const createTaskClearAction = () => ({
  type: TaskV2ActionType.CREATE_TASK_V2_CLEAR,
});

export const updateTaskAction = (req: UpdateTaskRequest) => ({
  type: TaskV2ActionType.UPDATE_TASK_V2,
  payload: req,
});

export const updateTaskCompletedAction = (task: Task) => ({
  type: TaskV2ActionType.UPDATE_TASK_V2_COMPLETED,
  payload: task,
});

export const updateTaskErrorAction = (error?: Error) => ({
  type: TaskV2ActionType.UPDATE_TASK_V2_ERROR,
  payload: error,
});

export const setTaskDeclinedSuccessAction = (success?: boolean) => ({
  type: TaskV2ActionType.SET_TASK_DECLINED_SUCCESS,
  payload: success,
});

export const setCompletedTaskSuccessAction = (success?: boolean) => ({
  type: TaskV2ActionType.SET_COMPLETED_TASK_SUCCESS,
  payload: success,
});

export const deleteTaskAction = (payload: DeleteTaskActionPayload) => ({
  type: TaskV2ActionType.DELETE_TASK,
  payload,
});

export const clearDeleteTaskAction = () => ({
  type: TaskV2ActionType.CLEAR_DELETED_TASK,
});

export const deleteTaskCompletedAction = (
  payload: DeleteTaskActionCompletedPayload,
) => ({
  type: TaskV2ActionType.DELETE_TASK_COMPLETED,
  payload,
});

export const deleteTaskErrorAction = (error?: Error) => ({
  type: TaskV2ActionType.DELETE_TASK_ERROR,
  payload: error,
});

export const getTaskAction = (source: {
  req?: GetTaskRequest;
  task?: Task;
}) => ({
  type: TaskV2ActionType.GET_TASK,
  payload: { req: source.req, task: source.task },
});

export const getTaskCompletedAction = (task: Task) => ({
  type: TaskV2ActionType.GET_TASK_COMPLETED,
  payload: task,
});

export const getTaskErrorAction = (error?: Error) => ({
  type: TaskV2ActionType.GET_TASK_ERROR,
  payload: error,
});

export const updateTaskEntityAction = (status: EntityStatus) => ({
  type: TaskV2ActionType.UPDATE_ENTITY_STATUS,
  payload: status,
});

export const setSelectedTaskAction = (task?: Task) => ({
  type: TaskV2ActionType.SET_SELECTED_TASK,
  payload: task,
});

export const addTaskEntityAction = (entity: DocumentEntity) => ({
  type: TaskV2ActionType.ADD_ENTITY_TO_TASK,
  payload: entity,
});

export const clearEntityManualText = (entityId: string, segmentId: number) => ({
  type: TaskV2ActionType.CLEAR_MANUAL_TEXT,
  payload: { entityId: entityId, segmentId: segmentId },
});

export const updateCellTimestamp = (timestamp: number) => ({
  type: TaskV2ActionType.UPDATE_CELL_TIMESTAMP,
  payload: timestamp,
});

export const listDeclinedTasksAction = (
  req: ListTasksRequest,
  refresh = false,
) => {
  return {
    type: TaskV2ActionType.LIST_DECLINED_TASKS_V2,
    payload: req,
    refresh,
  };
};

export const listDeclinedTasksCompletedAction = (
  tasks: Task[],
  nextPageToken: string,
  totalSize: number,
  refresh: boolean,
) => ({
  type: TaskV2ActionType.LIST_DECLINED_TASKS_V2_COMPLETED,
  payload: tasks,
  nextPageToken,
  totalSize,
  refresh,
});

export const listDeclinedTasksErrorAction = (error?: Error) => ({
  type: TaskV2ActionType.LIST_DECLINED_TASKS_V2_ERROR,
  payload: error,
});

export const saveUploadedDocumentAction = (document: Document) => ({
  type: TaskV2ActionType.SAVE_UPLOADED_DOCUMENT,
  payload: document,
});

export const removeSavedDocumentAction = () => ({
  type: TaskV2ActionType.REMOVE_SAVED_DOCUMENT,
});

export const listSystemDeclinedTasksAction = (
  req: ListTasksRequest,
  refresh = false,
) => {
  return {
    type: TaskV2ActionType.LIST_SYSTEM_DECLINED_TASKS_V2,
    payload: req,
    refresh,
  };
};
export const listSystemDeclinedTasksCompletedAction = (
  tasks: Task[],
  nextPageToken: string,
  totalSize: number,
  refresh: boolean,
) => ({
  type: TaskV2ActionType.LIST_SYSTEM_DECLINED_TASKS_V2_COMPLETED,
  payload: tasks,
  nextPageToken,
  totalSize,
  refresh,
});

export const listSystemDeclinedTasksErrorAction = (error?: Error) => ({
  type: TaskV2ActionType.LIST_SYSTEM_DECLINED_TASKS_V2_ERROR,
  payload: error,
});

export const taskFormatErrorAction = (error?: boolean) => ({
  type: TaskV2ActionType.TASK_FORMAT_ERROR,
  payload: error,
});

export const updateBatchTasksAction = (req: UpdateBatchTasksRequest) => ({
  type: TaskV2ActionType.UPDATE_BATCH_TASKS,
  payload: req,
});

export const updateBatchTasksCompletedAction = (
  resp: UpdateBatchTasksResponse,
) => ({
  type: TaskV2ActionType.UPDATE_BATCH_TASKS_COMPLETED,
  payload: resp,
});

export const updateBatchTasksErrorAction = (error: Error) => ({
  type: TaskV2ActionType.UPDATE_BATCH_TASKS_ERROR,
  payload: error,
});

export const resetReassignmentStateAction = (removeFailedTasks = false) => ({
  type: TaskV2ActionType.RESET_REASSIGNMENT_STATE,
  removeFailedTasks,
});

export const updateDebugLayout = (enableDebugLayout: boolean) => ({
  type: TaskV2ActionType.UPDATE_DEBUG_LAYOUT,
  payload: enableDebugLayout,
});

export const updateActivityMonitorVisibility = (
  showActivityMonitor: boolean,
) => ({
  type: TaskV2ActionType.UPDATE_ACTIVITY_MONITOR_VISIBILITY,
  payload: showActivityMonitor,
});

export const listApiExecutionHistoryAction = (
  req: ListWorkflowExecutionsRequest,
  refresh = false,
) => {
  return {
    type: TaskV2ActionType.LIST_API_EXECUTION_HISTORY,
    payload: req,
    refresh,
  };
};

export const listApiExecutionHistoryCompletedAction = (
  tasks: Task[],
  totalSize: number,
  refresh: boolean,
) => ({
  type: TaskV2ActionType.LIST_API_EXECUTION_HISTORY_COMPLETED,
  payload: tasks,
  totalSize,
  refresh,
});

export const listApiExecutionHistoryErrorAction = (error?: Error) => ({
  type: TaskV2ActionType.LIST_API_EXECUTION_HISTORY_ERROR,
  payload: error,
});

export const listUiExecutionHistoryAction = (
  req: ListWorkflowTasksRequest,
  refresh = false,
) => {
  return {
    type: TaskV2ActionType.LIST_UI_EXECUTION_HISTORY,
    payload: req,
    refresh,
  };
};

export const listUiExecutionHistoryCompletedAction = (
  tasks: Task[],
  nextPageToken: string,
  totalSize: number,
  refresh: boolean,
) => ({
  type: TaskV2ActionType.LIST_UI_EXECUTION_HISTORY_COMPLETED,
  payload: tasks,
  nextPageToken,
  totalSize,
  refresh,
});

export const listUiExecutionHistoryErrorAction = (error?: Error) => ({
  type: TaskV2ActionType.LIST_UI_EXECUTION_HISTORY_ERROR,
  payload: error,
});

export const retryTasksAction = (req: RetryTasksRequest) => ({
  type: TaskV2ActionType.RETRY_TASKS,
  payload: req,
});

export const retryTasksCompletedAction = (resp: RetryTasksResponse) => ({
  type: TaskV2ActionType.RETRY_TASKS_COMPLETED,
  payload: resp,
});

export const retryTasksErrorAction = (error: Error) => ({
  type: TaskV2ActionType.RETRY_TASKS_ERROR,
  payload: error,
});

export const clearRetryTaskAction = () => ({
  type: TaskV2ActionType.CLEAR_RETRY_TASKS,
});

export const getBlockedWorkflowExecutionStatisticsAction = (
  req: GetBlockedWorkflowExecutionStatisticsRequest,
) => ({
  type: TaskV2ActionType.GET_BLOCKED_WORKFLOW_EXECUTION_STATISTICS,
  payload: req,
});

export const getBlockedWorkflowExecutionStatisticsCompletedAction = (
  resp: any,
) => ({
  type: TaskV2ActionType.GET_BLOCKED_WORKFLOW_EXECUTION_STATISTICS_COMPLETED,
  payload: resp,
});

export const getBlockedWorkflowExecutionStatisticsErrorAction = (
  error: Error,
) => ({
  type: TaskV2ActionType.GET_BLOCKED_WORKFLOW_EXECUTION_STATISTICS_ERROR,
  payload: error,
});
