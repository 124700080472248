import { getFeatureFlagsForOrgAndUserSelector } from '../redux/selectors/feature_flags.selectors';
import { isFeatureFlagEnabled } from '../pages/FeatureFlags/FeatureFlagUtils';
import { FEATURE_FLAGS } from '../utils/constants';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

/**
 * This custom hook can quickly find if the user has enabled cookie feature flag
 * @returns boolean
 */
export const useCheckCookieFeatureFlagEnabled = () => {
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);

  const isLoginCookieEnabled = useMemo(() => {
    const flag = isFeatureFlagEnabled(FEATURE_FLAGS.COOKIE, featureFlags);
    return flag;
  }, [featureFlags]);

  return isLoginCookieEnabled;
};
