import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrbyHeaderLabel, OrbyTable } from 'orby-ui/src';
import {
  apiExecutionHistoryListSelector,
  apiExecutionHistoryLoadingSelector,
  apiExecutionHistoryTotalSizeSelector,
  deletingTaskSelector,
  retryTasksErrorSelector,
  retryTasksLoadingSelector,
  retryTasksSuccessSelector,
  tasksDeletedSuccessfullySelector,
  tasksFailedToDeleteSelector,
} from '../../../../redux/selectors/taskV2.selectors';
import {
  buildApiRequest,
  handleExecutionsRowSelectionChange,
} from '../../ExecutionHelpers';
import { selectedOrgInfoSelector } from '../../../../redux/selectors/user.selectors';
import { useNavigate } from 'react-router-dom';
import {
  clearDeleteTaskAction,
  clearRetryTaskAction,
  listApiExecutionHistoryAction,
  retryTasksAction,
} from '../../../../redux/actions/taskV2.action';
import {
  WorkflowExecution,
  WorkflowExecutionStatus,
} from 'protos/pb/v1alpha2/workflow_executions_service';
import {
  EXECUTION_MENU_ACTION_TYPE,
  getExecutionActionItem,
  getExecutionActionMenuCell,
  getExecutionCell,
  getExecutionNameColumnWidth,
  getExecutionStatusCell,
  getExecutionTimeCell,
  getExecutionTimeWidth,
  getExecutionWorkflowDisplayNameCell,
  handleApiExecutionPageChange,
} from './api-table-body-helpers';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from 'orby-ui/src/components/table/table-utils';
import { handleDeleteTask } from '../../../Tasks/TaskHelpers';
import { toastService } from '../../../../services/ToastService';
import {
  getExecutionActionGroupErrorContent,
  getTaskActionGroupErrorContent,
} from '../../../Tasks/tabs/TaskTableBodyHelpers';
import { groupTasksByError } from '../../../../utils/helpers';
import TaskSelectionHeader from '../../../../pages/Tasks/tabs/TaskSelectionHeader';
import {
  RetryTasksRequest,
  Task,
  TaskSTATUS,
} from 'protos/pb/v1alpha2/tasks_service';
import ExecutionDeletionModal from '../../../../pages/PendingTasks/ExecutionDeletionModal';
import { clearReviewState } from '../../../../redux/actions/review_task.action';
import {
  TASKS_TAB_INDEX,
  WORKFLOW_RESOURCE_NAMES,
} from '../../../../utils/constants';

export interface Props {
  displayNamePrefix: string;
  apiExecutionStatusFilters: string[];
  selectedWorkflows: Array<string>;
  isAdminView: boolean;
  tableWidth: number;
}

const ApiAutomationsTab: React.FC<Props> = ({
  displayNamePrefix,
  apiExecutionStatusFilters,
  selectedWorkflows,
  tableWidth,
  isAdminView,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const executions: WorkflowExecution[] = useSelector(
    apiExecutionHistoryListSelector,
  );

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const totalSize = useSelector(apiExecutionHistoryTotalSizeSelector) ?? 0;
  const tasksLoading = useSelector(apiExecutionHistoryLoadingSelector);

  const deletingTask = useSelector(deletingTaskSelector);
  const tasksFailedToDelete = useSelector(tasksFailedToDeleteSelector);
  const tasksDeletedSuccessfully = useSelector(
    tasksDeletedSuccessfullySelector,
  );

  const retryTaskLoading = useSelector(retryTasksLoadingSelector);
  const retryTasksError = useSelector(retryTasksErrorSelector);
  const retryTasksSuccessfully = useSelector(retryTasksSuccessSelector);

  const [page, setPage] = useState(DEFAULT_FIRST_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [selectedExecution, setSelectedExecution] =
    useState<WorkflowExecution | null>(null);
  const [selectedExecutions, setSelectedExecutions] = useState<
    WorkflowExecution[]
  >([]);
  const [selectedExecutionsToDelete, setSelectedExecutionsToDelete] = useState<
    WorkflowExecution[]
  >([]);

  const [actionMenuAnchorEl, setActionMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const pagedExecutions = executions.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage,
  );

  /**
   * ACTION MENU
   */
  const actionMenuOpen = Boolean(actionMenuAnchorEl);
  const handleActionMenuClick = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    execution: WorkflowExecution,
  ) => {
    setSelectedExecution(execution);
    setActionMenuAnchorEl(event.currentTarget);
  };

  /**
   * HANDLE ACTION MENU CLOSE
   */
  const handleActionMenuClose = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type?: string,
  ) => {
    event.stopPropagation();
    if (type && selectedExecution) {
      if (type === EXECUTION_MENU_ACTION_TYPE.DELETE) {
        setSelectedExecutionsToDelete([selectedExecution]);
      } else if (type === EXECUTION_MENU_ACTION_TYPE.RETRY) {
        retryTask([selectedExecution]);
      }
    }
    setActionMenuAnchorEl(null);
  };

  /**
   * HANDLE REVIEW TASKS CLICK
   */
  const handleReviewTasksClick = (execution: WorkflowExecution) => {
    navigate(
      `/tasks?${WORKFLOW_RESOURCE_NAMES}=${execution.workflowResourceName}&tab=${TASKS_TAB_INDEX.PENDING_TAB}`,
    );
  };

  /**
   * FETCH UI Executions
   * @param pageNumber
   * @param pageSize
   * @param refresh
   */
  const listExecutions = (
    pageNumber: number,
    pageSize: number,
    refresh: boolean,
  ) => {
    const req = buildApiRequest({
      navigate,
      selectedWorkflows,
      displayNamePrefix,
      apiExecutionStatusFilters,
      orgResourceName: selectedOrgInfo!.orgResourceName as string,
    });
    req.pageNumber = pageNumber;
    req.pageSize = pageSize;
    dispatch(listApiExecutionHistoryAction(req, refresh));
  };

  /**
   * RETRY TASKS
   */
  const retryTask = (executionList: WorkflowExecution[]) => {
    const execution = executionList.map(
      (e: WorkflowExecution) => e.name,
    ) as string[];
    if (execution.length) {
      dispatch(
        retryTasksAction(
          RetryTasksRequest.create({
            names: execution,
            orgResourceName: selectedOrgInfo?.orgResourceName,
          }),
        ),
      );
    }
  };

  /**
   * REFRESH PAGE
   */
  const refreshPage = () => {
    setPage(DEFAULT_FIRST_PAGE);
    setSelectedExecutions([]);
    listExecutions(DEFAULT_FIRST_PAGE, rowsPerPage, true);
  };

  // CHECK IF SPECIFIC EXECUTION IS SELECTED
  const isExecutionSelected = (task: WorkflowExecution) => {
    return selectedExecutions.some((execution) => execution.name === task.name);
  };

  // HANDLE SELECTION/DESELECTION OF THE EXECUTION
  const handleExecutionSelection = (
    task: WorkflowExecution,
    isSelected: boolean,
  ) => {
    if (isSelected) {
      setSelectedExecutions((prevSelectedExecutions) => [
        ...prevSelectedExecutions,
        task,
      ]);
    } else {
      setSelectedExecutions((prevSelectedExecutions) =>
        prevSelectedExecutions.filter((t) => t.name !== task.name),
      );
    }
  };

  // CHECK IF ALL THE TASKS ARE SELECTED ON THE CURRENT PAGE
  const areAllExecutionsSelected = () => {
    if (selectedExecutions.length) {
      const executionsOnCurrentPage = executions.slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage,
      );
      return executionsOnCurrentPage.every((execution) =>
        selectedExecutions.includes(execution),
      );
    }
    return false;
  };

  // HANDLE SELECTION/DESELECTION OF ALL TASKS ON THE CURRENT PAGE
  const handleAllExecutionsSelection = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedExecutions(pagedExecutions);
    } else {
      setSelectedExecutions([]);
    }
  };

  /**
   * USE EFFECTS
   */
  useEffect(() => {
    refreshPage();
  }, []);

  useEffect(() => {
    refreshPage();
  }, [displayNamePrefix, selectedWorkflows, apiExecutionStatusFilters]);

  useEffect(() => {
    if (tasksDeletedSuccessfully.length > 0) {
      toastService.showSuccess(
        tasksDeletedSuccessfully.length > 1
          ? `${tasksDeletedSuccessfully.length} Tasks were deleted successfully`
          : 'Task was deleted successfully',
        {
          position: 'top-right',
        },
      );

      dispatch(clearDeleteTaskAction());
      refreshPage();
    }
  }, [tasksDeletedSuccessfully]);

  useEffect(() => {
    if (tasksFailedToDelete.length > 0) {
      toastService.showError(
        getTaskActionGroupErrorContent(
          groupTasksByError(tasksFailedToDelete),
          false,
        ),
        {
          position: 'top-right',
        },
      );
      dispatch(clearDeleteTaskAction());
      refreshPage();
    }
  }, [tasksFailedToDelete]);

  useEffect(() => {
    if (retryTasksSuccessfully) {
      toastService.showSuccess(
        'The tasks will be re-executed, and you will be notified by email upon successful execution.',
        {
          position: 'top-right',
        },
      );
    }
    dispatch(clearRetryTaskAction());
    refreshPage();
  }, [retryTasksSuccessfully]);

  useEffect(() => {
    if (typeof retryTasksError === 'string') {
      toastService.showError(retryTasksError, {
        position: 'top-right',
      });
    } else if (retryTasksError && retryTasksError.length > 0) {
      toastService.showError(
        getExecutionActionGroupErrorContent(groupTasksByError(retryTasksError)),
        {
          position: 'top-right',
        },
      );
    }
    dispatch(clearRetryTaskAction());
    refreshPage();
  }, [retryTasksError]);

  const statusesSupportingDetails = [
    TaskSTATUS.READY,
    TaskSTATUS.COMPLETED,
    TaskSTATUS.REJECTED_INCORRECT,
    TaskSTATUS.REJECTED_ALREADY_COMPLETED,
  ];
  return (
    <>
      <OrbyTable
        tableWidth={tableWidth}
        tableContainerSx={{
          width: 'auto',
          overflowX: 'auto',
        }}
        onTableRowClick={(data) => {
          const task = data as Task;
          if (task.status && statusesSupportingDetails.includes(task.status)) {
            dispatch(clearReviewState());
            navigate(`/${task.name}/automation-review`);
          }
        }}
        isDataLoading={tasksLoading || deletingTask || retryTaskLoading}
        noDataMessage={
          displayNamePrefix
            ? `No results for "${displayNamePrefix}"`
            : 'There are no executions available'
        }
        colSx={[
          {
            width: `${getExecutionNameColumnWidth(tableWidth, 280)}px`,
            maxWidth: `${getExecutionNameColumnWidth(tableWidth, 280)}px`,
            minWidth: `${getExecutionNameColumnWidth(tableWidth, 280)}px`,
          },
          {
            width: `${getExecutionNameColumnWidth(tableWidth, 280)}px`,
            maxWidth: `${getExecutionNameColumnWidth(tableWidth, 280)}px`,
            minWidth: `${getExecutionNameColumnWidth(tableWidth, 280)}px`,
          },
          {
            width: '178px',
            maxWidth: '178px',
            minWidth: '178px',
          },
          {
            width: `${getExecutionTimeWidth(tableWidth, 80)}px`,
            maxWidth: `${getExecutionTimeWidth(tableWidth, 80)}px`,
            minWidth: `${getExecutionTimeWidth(tableWidth, 80)}px`,
          },
          {
            width: '69px',
            maxWidth: '69px',
            minWidth: '69px',
          },
        ]}
        tableId='api-automation-table'
        showCheckBox={true}
        areAllRowsSelected={areAllExecutionsSelected}
        handleAllRowsSelection={handleAllExecutionsSelection}
        isCheckBoxDisabled={false}
        isRowSelected={(row) => {
          const execution = row as WorkflowExecution;
          return isExecutionSelected(execution);
        }}
        selectedRows={selectedExecutions}
        handleRowSelection={(row, isSelected) => {
          const task = row as WorkflowExecution;
          handleExecutionSelection(task, isSelected);
        }}
        /**
         * CHECKBOX SELECTION HEADER
         */
        checkBoxSelectionHeader={
          <TaskSelectionHeader
            selectedItems={selectedExecutions}
            onCancel={() => {
              setSelectedExecutions([]);
              setSelectedExecutionsToDelete([]);
            }}
            secondaryLabel={
              selectedExecutions.every(
                (execution) =>
                  execution.status === WorkflowExecutionStatus.FAILED,
              )
                ? 'Retry'
                : ''
            }
            onSecondaryClick={() => {
              retryTask(selectedExecutions);
            }}
            primaryLabel={'Delete'}
            onPrimaryClick={() => {
              if (isAdminView) {
                setSelectedExecutionsToDelete(selectedExecutions);
              }
            }}
          />
        }
        /**
         * PAGINATION
         */
        pagination={{
          rowsPerPage,
          currentPage: page,
          totalRows: totalSize,
          setCurrentPage: (pageNumber: number) =>
            handleApiExecutionPageChange({
              executions,
              pageNumber,
              page,
              rowsPerPage,
              setPage,
              listExecutions,
              setSelectedExecutions,
            }),
          setRowsPerPage: (rows: number) =>
            handleExecutionsRowSelectionChange({
              rowsNumber: rows,
              setPage,
              setRowsPerPage,
              listExecutions,
              setSelectedExecutions,
            }),
        }}
        cursor='pointer'
        /**
         * TABLE HEADER ROWS
         */
        headerRows={[
          <OrbyHeaderLabel
            key={'execution-header-execution'}
            label='Execution'
          />,
          <OrbyHeaderLabel
            key={'execution-header-workflow'}
            label='Workflow'
          />,
          <OrbyHeaderLabel key={'execution-header-status'} label='Status' />,
          <OrbyHeaderLabel
            key={'execution-header-last-updated'}
            label='Last Updated'
          />,
          <OrbyHeaderLabel key={'task-header-actions'} label='' />,
        ]}
        /**
         * TABLE ROWS
         */
        dataRows={pagedExecutions.map((execution) => {
          return {
            title: execution.displayName!,
            id: execution.name!,
            row: execution,
            cells: [
              getExecutionCell(execution, displayNamePrefix),
              getExecutionWorkflowDisplayNameCell(execution),
              getExecutionStatusCell(execution, handleReviewTasksClick),
              getExecutionTimeCell(execution, execution.lastUpdatedTime),
              getExecutionActionMenuCell(
                WorkflowExecution.create(execution),
                handleActionMenuClick,
              ),
            ],
          };
        })}
      />

      {/* EXECUTION ACTION MENU */}
      {getExecutionActionItem(
        actionMenuAnchorEl,
        actionMenuOpen,
        handleActionMenuClose,
        isAdminView,
        selectedExecution,
      )}

      {/* DELETE EXECUTION MODEL */}
      <ExecutionDeletionModal
        selectedExecutions={selectedExecutionsToDelete}
        open={selectedExecutionsToDelete.length > 0}
        handleClose={() => setSelectedExecutionsToDelete([])}
        onSubmit={(deletionReason, executions) => {
          // Workflow Execution is essentially Task only so we can use handleDeleteTask function only
          handleDeleteTask(deletionReason, executions, dispatch);
          setSelectedExecutions([]);
          setSelectedExecutionsToDelete([]);
        }}
      />
    </>
  );
};

export default React.memo(ApiAutomationsTab);
