import React from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';

import UserCard from '../../components/UserCard';
import { NavigateFunction } from 'react-router-dom';
import {
  ELLIPSIS_STYLE,
  NAME_EMAIL_PREFIX,
  WORKFLOW_RESOURCE_NAMES,
} from '../../utils/constants';
import {
  OrbyBadge,
  OrbyBodyLabel,
  OrbyColorPalette,
  OrbyTypography,
} from 'orby-ui/src';
import { getRole } from '../../utils/helpers';
import { DEFAULT_WORKFLOW_VALUE } from '../Tasks/TaskHelpers';
import { ListUsersRequest } from 'protos/pb/v1alpha1/users_service';
import { OrgInfo, User } from 'protos/pb/v1alpha1/user';
import { DEFAULT_FIRST_PAGE } from 'orby-ui/src/components/table/table-utils';

export const buildTeamsRequest = ({
  navigate,
  displayNamePrefix,
  orgResourceName,
  selectedWorkflows,
  selectedRole,
}: {
  navigate: NavigateFunction;
  displayNamePrefix: string;
  orgResourceName: string;
  selectedWorkflows: string[];
  selectedRole: string;
}) => {
  const req: ListUsersRequest = ListUsersRequest.fromJSON({
    filter: getTeamsFilter(
      navigate,
      displayNamePrefix,
      selectedWorkflows,
      selectedRole,
    ),
    orgResourceName: orgResourceName,
  });
  return req;
};

export const getTeamsFilter = (
  navigate: NavigateFunction,
  searchQuery: string,
  selectedWorkflows: string[],
  selectedRole: string,
) => {
  let filter = '';

  const searchParams = new URLSearchParams();

  if (searchQuery) {
    filter += `${NAME_EMAIL_PREFIX}=${searchQuery}`;
    searchParams.append(NAME_EMAIL_PREFIX, searchQuery);
  }

  if (
    selectedWorkflows.length &&
    !selectedWorkflows.includes(DEFAULT_WORKFLOW_VALUE.value)
  ) {
    const parsedWorkflows = selectedWorkflows.map((workflow) =>
      workflow.replace('workflows/', ''),
    );
    filter += `,workflow_resource_names=workflows/${parsedWorkflows.join('/')}`;
    searchParams.append(WORKFLOW_RESOURCE_NAMES, selectedWorkflows.join(','));
  }

  if (selectedRole) {
    filter += `,role=${selectedRole}`;
    searchParams.append('role', selectedRole);
  }

  navigate({ search: `?${searchParams.toString()}` });

  // Return the updated filter string
  return filter.length && filter[0] === ',' ? filter.substring(1) : filter;
};

/**
 * Handle Teams Page Change
 */
export const handleTeamsPageChange = ({
  users,
  pageNumber,
  page,
  rowsPerPage,
  setPage,
  listUsers,
}: {
  users: User[];
  pageNumber: number;
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  listUsers: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
}) => {
  setPage(pageNumber);
  if (pageNumber >= page && users.length <= pageNumber * rowsPerPage) {
    listUsers(pageNumber, rowsPerPage, false);
  }
};

/**
 *  HANDLE ROWS SELECTION CHANGE
 */
export const handleTeamsPageRowSelectionChange = ({
  rowsNumber,
  setPage,
  setRowsPerPage,
  listUsers,
}: {
  rowsNumber: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  listUsers: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
}) => {
  setPage(DEFAULT_FIRST_PAGE);
  // Refresh is needed when rows per page is changes to fetch fresh data
  setRowsPerPage(rowsNumber);
  listUsers(DEFAULT_FIRST_PAGE, rowsNumber, true);
};

export const getUserRoleForSelectedOrg = (
  user: User,
  selectedOrgInfo: OrgInfo,
) => {
  return user?.orgInfos?.find(
    (i: OrgInfo) => i.orgResourceName === selectedOrgInfo?.orgResourceName,
  )?.role;
};

export const getUserCell = (user: User): JSX.Element => {
  return (
    <UserCard
      key={user.id}
      email={user.email ?? ''}
      imageUrl={user.profileImageUrl}
      fullName={user.fullName}
    />
  );
};

export const getUserRoleCell = (
  user: User,
  selectedOrgInfo: OrgInfo,
): JSX.Element => {
  return (
    <OrbyBodyLabel
      key={user.id}
      label={getRole(getUserRoleForSelectedOrg(user, selectedOrgInfo))}
      fontSize='sm'
      fontWeight='regular'
      color={OrbyColorPalette['grey-900']}
    />
  );
};

const measureTextWidth = (text: string, font: string): number => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = font;
    return context.measureText(text).width;
  }
  return 0;
};

// The handleTooltipOpen function to handle the tooltip open event
const handleTooltipOpen = (event: React.SyntheticEvent<Element, Event>) => {
  // Use setTimeout to defer the execution until the DOM is updated
  setTimeout(() => {
    // Select all elements with the role of "tooltip"
    document.querySelectorAll('[role="tooltip"]')?.forEach((tooltip) => {
      // Get the first child of the tooltip element and cast it to HTMLElement
      const tooltipContent = tooltip.children[0] as HTMLElement;
      if (tooltipContent) {
        // Set the overflow style to 'scroll'
        tooltipContent.style.overflow = 'scroll';

        // Get the target element that triggered the event and cast it to HTMLElement
        const target = event.target as HTMLElement;
        // Get the bounding client rectangle of the target element
        const targetRect = target.getBoundingClientRect();

        // Calculate the maximum height for the tooltip content
        // Subtract 50 pixels to add some padding
        tooltipContent.style.maxHeight = `${
          Math.max(window.innerHeight - targetRect.bottom, targetRect.top) - 50
        }px`;
      }
    });
  }, 0);
};

export const getUserWorkflowCell = (
  user: User,
  tableWidth: number,
): JSX.Element => {
  let workflowsRenderLength = 0;
  // table width - padding left - padding right
  const workflowBoxWidth = getWorkflowCellWidth(tableWidth) - 15.75 - 15.75;
  // table width - max width for showing +n workflows
  const workflowCellMaxWidth = workflowBoxWidth - 100;
  let renderedWorkflows = 0;
  const workflows = user.assignedWorkflows?.slice() ?? [];
  user.managedWorkflows?.forEach((workflow) => {
    if (!workflows.includes(workflow)) {
      workflows.push(workflow);
    }
  });
  return workflows.length ? (
    <Box
      display={'flex'}
      gap={'8px'}
      sx={{
        maxWidth: `${workflowBoxWidth}px`,
        overflow: 'hidden',
      }}
    >
      {workflows.map((workflow: string, index: number) => {
        /**
         * Calculate the length of the workflow text which would be used to display the workflow badge
         * 8px gap between badges
         * 16px padding on the left and right of the badge
         * 4px is the error margin on the measureTextWidth Calculation
         */
        workflowsRenderLength =
          workflowsRenderLength +
          (measureTextWidth(workflow, '12px Inter, sans-serif') + 8 + 16 + 4);
        if (workflowsRenderLength <= workflowCellMaxWidth || index === 0) {
          renderedWorkflows++;
          return (
            <OrbyBadge
              key={workflow}
              backgroundColor={OrbyColorPalette['blueGrey-50']}
              textColor={OrbyColorPalette['blueGrey-700']}
              badgeName={workflow}
              size='small'
              boxSx={{
                maxWidth: workflowCellMaxWidth,
              }}
              textSx={ELLIPSIS_STYLE}
            />
          );
        }
      })}
      {renderedWorkflows < workflows.length && (
        <Tooltip
          onOpen={handleTooltipOpen}
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: OrbyColorPalette['white-0'],
                borderRadius: '8px',
                maxWidth: '200px',
                boxShadow:
                  '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
                maxHeight: `400px`,
                overflowY: 'scroll',
              },
            },
          }}
          title={
            <Box>
              {workflows.slice(renderedWorkflows).map((workflow: string) => {
                return (
                  <Box
                    key={workflow}
                    sx={{
                      ...ELLIPSIS_STYLE,
                      padding: '6px 8px',
                    }}
                    title={workflow}
                  >
                    <OrbyTypography
                      size='xs'
                      weight='semibold'
                      color={OrbyColorPalette['grey-700']}
                      sx={{
                        ...ELLIPSIS_STYLE,
                      }}
                    >
                      {workflow}
                    </OrbyTypography>
                  </Box>
                );
              })}
            </Box>
          }
        >
          <Box>
            <OrbyBadge
              key={user.id}
              backgroundColor={OrbyColorPalette['white-0']}
              textColor={OrbyColorPalette['black-0']}
              badgeName={`+ ${workflows.length - renderedWorkflows}`}
              size='small'
              boxSx={{
                cursor: 'pointer',
                border: `1px solid ${OrbyColorPalette['grey-200']}`,
                pointerEvents: 'none',
              }}
            />
          </Box>
        </Tooltip>
      )}
    </Box>
  ) : (
    <></>
  );
};

export const getUserStatusCell = (user: User): JSX.Element => {
  if (user.activated) {
    return (
      <OrbyBadge
        backgroundColor={OrbyColorPalette['green-50']}
        textColor={OrbyColorPalette['green-700']}
        badgeName='Activated'
        size='small'
      />
    );
  } else {
    return (
      <OrbyBadge
        backgroundColor={OrbyColorPalette['orange-50']}
        textColor={OrbyColorPalette['orange-700']}
        badgeName='Pending Activation'
        size='small'
      />
    );
  }
};

export const getUserActionMenuCell = (
  user: User,
  handleActionMenuClick: (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    user: User,
  ) => void,
): JSX.Element => {
  return (
    <IconButton
      title='Menu'
      aria-label='Menu'
      tabIndex={0}
      key={user.id}
      onClick={(
        event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
      ) => {
        event.stopPropagation();
        handleActionMenuClick(event, user);
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      <MoreVertOutlinedIcon sx={{ color: OrbyColorPalette['grey-400'] }} />
    </IconButton>
  );
};

export const USER_MENU_ACTION_TYPE = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
};

export const getUserActionMenu = (
  actionMenuAnchorEl: HTMLElement | null,
  handleActionMenuClose: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type: string,
  ) => void,
) => {
  return (
    <Menu
      id='basic-menu'
      anchorEl={actionMenuAnchorEl}
      open={!!actionMenuAnchorEl}
      onClose={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        handleActionMenuClose(e, '');
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
          overflow: 'visible',
          borderRadius: '8px',
        },
      }}
      transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      <MenuItem
        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={(e) => {
          handleActionMenuClose(e, USER_MENU_ACTION_TYPE.EDIT);
        }}
      >
        <OrbyTypography
          size='md'
          weight='medium'
          color={OrbyColorPalette['grey-900']}
        >
          Edit
        </OrbyTypography>
      </MenuItem>

      <MenuItem
        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={(e) => {
          handleActionMenuClose(e, USER_MENU_ACTION_TYPE.DELETE);
        }}
      >
        <OrbyTypography
          size='md'
          weight='medium'
          color={OrbyColorPalette['grey-900']}
        >
          Remove
        </OrbyTypography>
      </MenuItem>
    </Menu>
  );
};

export const getWorkflowCellWidth = (tableWidth: number, minWidth?: number) => {
  const width = tableWidth - 270 - 90 - 180 - 69 - 10;
  if (minWidth) {
    return Math.max(width, minWidth);
  }
  return width;
};
