import React, { useEffect, useState } from 'react';
import HITLTaskOverview from './components/HITLTaskOverview';
import { OrbyColorPalette } from 'orby-ui/src';
import { Box } from '@mui/material';
import HITLExtractedDataReview from './components/HITLExtractedDataReview';
import HITLSystemReference from './components/HITLSystemReference';
import { ReactComponent as ExpandIcon } from '../../static/icons/expand.svg';
import {
  GetTaskRequest,
  TaskSTATUS,
  UpdateReviewTaskRequest,
} from 'protos/pb/v1alpha2/tasks_service';
import {
  clearReviewTask,
  getTaskForHitlAction,
  updateReviewTaskAction,
} from '../../redux/actions/review_task.action';
import { useDispatch, useSelector } from 'react-redux';
import {
  HitlDataSelector,
  hitlDataUpdatedErrorSelector,
  hitlDataUpdatedSuccessSelector,
  hitlDataUpdatingSelector,
  HitlLoadingSelector,
} from '../../redux/selectors/review_task.selectors';
import { useNavigate, useParams } from 'react-router-dom';
import { getReconcileItemsFromTask } from '../Utils/taskV2Utils';
import ReviewPageLoader from '../AutomationReviewPage/components/ReviewPageStateComponents/ReviewPageLoader';
import { TASKS_TAB_INDEX } from '../../utils/constants';

interface Props {
  sideDrawerCollapse: boolean;
}

const Overlay: React.FC<{
  isRightSideExpanded: boolean;
  isLeftSideExpanded: boolean;
  background: keyof typeof OrbyColorPalette;
}> = ({ isLeftSideExpanded, isRightSideExpanded, background }) => (
  <Box
    sx={{
      opacity: isLeftSideExpanded || isRightSideExpanded ? '50%' : '100%',
      background:
        isLeftSideExpanded || isRightSideExpanded
          ? OrbyColorPalette[background]
          : 'unset',
      width: isLeftSideExpanded ? '25.3%' : '16.8%',
      height: 'calc(100% + 8px)',
      zIndex: 999,
      position: 'absolute',
      left: isRightSideExpanded ? 0 : 'unset',
      right: isLeftSideExpanded ? 0 : 'unset',
      top: '-8px',
    }}
  />
);

const ExpandIconButton: React.FC<{
  onClick: () => void;
  isExpanded: boolean;
  position: 'top' | 'bottom';
}> = ({ onClick, isExpanded, position }) => {
  // Function to determine the rotation based on position and isExpanded
  const getTransform = (isExpanded: boolean, position: 'top' | 'bottom') => {
    if (position === 'bottom' && isExpanded) return 'rotate(0deg)';
    if (position === 'bottom' && !isExpanded) return 'rotate(180deg)';
    if (position === 'top' && isExpanded) return 'rotate(180deg)';
    if (position === 'top' && !isExpanded) return 'rotate(0deg)';
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        [position === 'top' ? 'top' : 'bottom']: '4%',
        left: '-18px',
        transform: getTransform(isExpanded, position),
        transition: 'transform 0.2s ease',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <ExpandIcon height={'40px'} width={'40px'} />
    </Box>
  );
};

const Divider: React.FC = () => (
  <Box
    sx={{
      position: 'relative',
      borderLeft: '3px solid',
      borderColor: OrbyColorPalette['grey-200'],
      height: 'calc(100% + 8px)',
      top: '-8px',
    }}
  />
);

const HITLDetails: React.FC<Props> = () => {
  const [isLeftSideExpanded, setIsLeftSideExpanded] = useState(false);
  const [isRightSideExpanded, setIsRightSideExpanded] = useState(false);
  const loading: boolean = useSelector(HitlLoadingSelector);
  const hitlData = useSelector(HitlDataSelector);
  const { task_id, workflow_id } = useParams();
  const taskName = `orbot_workflow_tasks/${workflow_id}/tasks/${task_id}`;
  // const error = useSelector(HitlErrorSelector);  //will use once we have the api response
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleExpandClick = () => {
    setIsLeftSideExpanded((prev) => !prev);
    setIsRightSideExpanded(false);
  };

  const handleRightExpandClick = () => {
    setIsRightSideExpanded((prev) => !prev);
    setIsLeftSideExpanded(false);
  };

  const handleProceed = () => {
    // The backend expects the frontend to populate the final results in
    // CorrectedSmartActionResult. If the user modified the ML results,
    // then the original results will be stored in SmartActionResult.
    // First step contains the execution steps result.
    const task = { ...hitlData.task };
    const smartActionResult =
      task.executionSteps?.[0]?.result?.smartActionResult;
    if (task.executionSteps && smartActionResult) {
      if (!smartActionResult.correctedSmartActionResult) {
        // Results were not modified by user , hence we need to copy the result into correctedSmartActionResult
        const newSmartActionResult = {
          ...smartActionResult,
          correctedSmartActionResult: {
            ...smartActionResult.smartActionResult,
          },
          smartActionResult: undefined,
        };

        const newExecutionSteps = [...task.executionSteps];
        newExecutionSteps[0] = {
          ...newExecutionSteps[0],
          result: {
            ...newExecutionSteps[0].result,
            smartActionResult: newSmartActionResult,
          },
        };

        task.executionSteps = newExecutionSteps;
      }

      task.status = TaskSTATUS.COMPLETED;
      const req = UpdateReviewTaskRequest.fromJSON({ task });
      dispatch(updateReviewTaskAction(req));
    }
  };

  useEffect(() => {
    const req: GetTaskRequest = GetTaskRequest.fromJSON({
      name: taskName,
    }); //append taskName here
    dispatch(getTaskForHitlAction(req));
  }, []);

  const hitlDataUpdating = useSelector(hitlDataUpdatingSelector);
  const hitlDataUpdatedError = useSelector(hitlDataUpdatedErrorSelector);
  const hitlDataUpdatedSuccess = useSelector(hitlDataUpdatedSuccessSelector);

  useEffect(() => {
    if (hitlDataUpdatedSuccess) {
      const url = `/tasks?tab=${TASKS_TAB_INDEX.COMPLETED_TAB}`;
      navigate(url, { state: { preventBlocking: true } });
    }
  }, [hitlDataUpdatedSuccess]);

  useEffect(() => {
    return () => {
      // Clear state on unmount.
      dispatch(clearReviewTask());
    };
  }, []);
  useEffect(() => {
    if (hitlDataUpdatedError) {
      const url = `/tasks?tab=${TASKS_TAB_INDEX.DECLINED_TAB}`;
      navigate(url, { state: { preventBlocking: true } });
    }
  }, [hitlDataUpdatedError]);

  const reconcileItems = getReconcileItemsFromTask(hitlData?.task);
  if (loading || hitlDataUpdating) {
    return <ReviewPageLoader contextHolder={undefined} />;
  }
  return (
    !loading && (
      <>
        {/* Header Or Overview Zone */}
        <HITLTaskOverview
          title={hitlData?.task?.displayName || 'Invoice #1234'}
          workflowName={hitlData?.task?.workflowDisplayName}
          data={hitlData?.task}
          onProceed={handleProceed}
        />

        <Box
          display='flex'
          flexDirection='row'
          height='calc(100% - 158.6px)'
          position='relative'
        >
          {/* Left Panel or ExtractedDataReviewZone */}
          <Box
            sx={{
              width: isLeftSideExpanded ? '90%' : '60%',
              transition: 'width 0.2s ease',
              background: 'white',
              height: '100%',
              zIndex: isLeftSideExpanded ? 2 : 0,
              position: 'absolute',
              overflow: 'auto',
              pb: '20px',
            }}
          >
            {isRightSideExpanded && (
              <Overlay
                isRightSideExpanded={isRightSideExpanded}
                isLeftSideExpanded={isLeftSideExpanded}
                background='grey-900'
              />
            )}
            <HITLExtractedDataReview
              isExpanded={isLeftSideExpanded}
              taskData={hitlData?.task}
            />
          </Box>

          {/* Partition and Expand/Collapse Icons */}
          <Box
            sx={{
              position: 'absolute',
              left: isLeftSideExpanded
                ? '90%'
                : isRightSideExpanded
                  ? '10%'
                  : '61%',
              height: '100%',
              zIndex: 10,
              transition: 'left 0.3s ease, transform 0.3s ease',
              transform:
                isLeftSideExpanded || isRightSideExpanded
                  ? 'none'
                  : 'translateX(0)',
            }}
          >
            <Divider />
            {!isRightSideExpanded && (
              <ExpandIconButton
                onClick={handleExpandClick}
                isExpanded={isLeftSideExpanded}
                position='top'
              />
            )}
            {!isLeftSideExpanded && (
              <ExpandIconButton
                onClick={handleRightExpandClick}
                isExpanded={isRightSideExpanded}
                position='bottom'
              />
            )}
          </Box>

          {/* Right Panel or SystemReferenceZone */}
          <Box
            sx={{
              width: isRightSideExpanded ? '90%' : '39%',
              transition: 'width 0.3s ease',
              background: 'white',
              zIndex: isRightSideExpanded ? 2 : 0,
              height: '100%',
              position: 'absolute',
              right: 0,
            }}
          >
            {isLeftSideExpanded && (
              <Overlay
                isRightSideExpanded={isRightSideExpanded}
                isLeftSideExpanded={isLeftSideExpanded}
                background='grey-900'
              />
            )}
            <HITLSystemReference reconcileItems={reconcileItems} />
          </Box>
        </Box>
      </>
    )
  );
};

export default React.memo(HITLDetails);
