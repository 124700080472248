import { Box, SxProps, TextField, Theme } from '@mui/material';
import { OrbyColorPalette } from 'orby-ui/src';
import React, { useState, useRef, useEffect } from 'react';
import { validateTime } from '../pages/MyWorkflows/details/utils/helper';

// Common styles for TextField components
const commonTextFieldStyles: SxProps<Theme> = {
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

interface TimeInputProps {
  onEnter: (times: { hours: string; minutes: string }) => void;
  error: string | null;
  setError: (error: string | null) => void;
  additionalValidator?: () => string;
  getCurrentInputValue?: (times: { hours: string; minutes: string }) => void;
  readonly?: boolean;
}

const TimeInput: React.FC<TimeInputProps> = ({
  onEnter,
  additionalValidator = () => null,
  getCurrentInputValue = () => null,
  error,
  setError,
  readonly = false,
}) => {
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');

  const hoursRef = useRef<HTMLInputElement>(null);
  const minutesRef = useRef<HTMLInputElement>(null);

  const commonInputProps = {
    maxLength: 3,
    sx: {
      padding: '0px',
      width: '20px',
      '& .MuiOutlinedInput-notchedOutline': {
        padding: '0',
      },
    },
    onBlur: () => {
      if (readonly) return;
      if (!additionalValidator()) {
        setError(additionalValidator());
      } else if (!validateTime({ hours, minutes })) {
        setError('Invalid time');
      }
    },
  };

  // Handle changes for hours input
  const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (readonly) return;
    let input = e.target.value.replace(/\D/g, ''); // Remove any non-digits
    if (parseInt(input, 10) > 23) input = '23'; // Limit hours to 23
    if (+input * 10 > 23 || input.length >= 2) {
      minutesRef?.current?.focus(); // Use ref to focus minutes input
      setHours((+input).toString().padStart(2, '0'));
    } else {
      setHours(input);
    }
    setError('');
  };

  // Handle changes for minutes input
  const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (readonly) return;
    let input = e.target.value.replace(/\D/g, ''); // Remove any non-digits
    if (parseInt(input, 10) > 59) input = '59'; // Limit minutes to 59
    if (input.length > 2) {
      input = (+input).toString().padStart(2, '0');
    }
    setMinutes(input);
    setError('');
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (readonly) return;
    if (validateTime({ hours, minutes })) {
      onEnter({ hours, minutes });
      setHours('');
      setMinutes('');
      (e.target as HTMLInputElement).blur();
    } else if (additionalValidator()) {
      setError(additionalValidator());
    } else {
      setError('Invalid time');
    }
  };

  useEffect(() => {
    if (readonly) return;
    getCurrentInputValue({ hours, minutes });
  }, [hours, minutes]);

  return (
    <>
      <Box
        className={error ? 'Mui-error' : ''}
        sx={{
          padding: '10px 14px',
          width: '162px',
          height: '44px',
          display: 'flex',
          border: `1px solid ${OrbyColorPalette['grey-200']}`,
          borderRadius: '8px',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          gap: '4px',
          alignItems: 'center',
          '&:focus-within': {
            outline: 'none',
            boxShadow:
              '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF',
            borderWidth: '1px !important',
            borderColor: OrbyColorPalette['blue-700'],
          },
          '&:hover': {
            borderColor: OrbyColorPalette['blue-700'],
          },
          '&.Mui-error': {
            borderColor: OrbyColorPalette['error-700'],
          },
        }}
      >
        <TextField
          inputRef={hoursRef}
          value={hours}
          onChange={handleHoursChange}
          placeholder='00'
          sx={commonTextFieldStyles}
          inputProps={commonInputProps}
          aria-readonly={readonly}
          disabled={readonly}
          autoComplete='off'
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (readonly) return;
            if (
              (e.target as HTMLInputElement).selectionStart === hours.length &&
              e.key === 'ArrowRight'
            ) {
              if (hours.length > 0) {
                setHours(hours.padStart(2, '0'));
              }
              minutesRef?.current?.focus();
              minutesRef?.current?.setSelectionRange(0, 0);
              e.preventDefault();
            } else if (e.key === 'Enter') {
              handleEnter(e);
            }
          }}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (readonly) return;
            if (
              (e.key === 'Backspace' || e.key === 'Delete') &&
              (e.target as HTMLInputElement).selectionStart === 0
            ) {
              hoursRef?.current?.focus();
              hoursRef?.current?.setSelectionRange(hours.length, hours.length);
            }
          }}
        />
        <Box component='span' textAlign='center'>
          :
        </Box>
        <TextField
          inputRef={minutesRef}
          value={minutes}
          onChange={handleMinutesChange}
          sx={commonTextFieldStyles}
          placeholder='00'
          aria-readonly={readonly}
          disabled={readonly}
          autoComplete='off'
          inputProps={{
            ...commonInputProps,
            sx: { ...commonInputProps.sx, width: '100px' },
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (readonly) return;
            if (
              (e.target as HTMLInputElement).selectionStart === 0 &&
              e.key === 'ArrowLeft'
            ) {
              setMinutes(minutes.padStart(2, '0'));
              hoursRef?.current?.focus();
              hoursRef?.current?.setSelectionRange(hours.length, hours.length);
              e.preventDefault();
            } else if (e.key === 'Enter') {
              handleEnter(e);
            }
          }}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (readonly) return;
            if (
              (e.key === 'Backspace' || e.key === 'Delete') &&
              (e.target as HTMLInputElement).selectionStart === 0
            ) {
              hoursRef?.current?.focus();
              hoursRef?.current?.setSelectionRange(hours.length, hours.length);
            }
          }}
        />
      </Box>
      <Box
        sx={{
          color: OrbyColorPalette['error-700'],
          fontSize: '14px',
          mt: '3px',
        }}
      >
        {error}
      </Box>
    </>
  );
};

export default TimeInput;
