import { Box } from '@mui/material';
import {
  OrbyColorPalette,
  OrbyMenuItem,
  OrbySelect,
  OrbyTypography,
} from 'orby-ui/src';
import { ScheduleConfigRecurrencePatternWeekOfMonth } from 'protos/pb/v1alpha1/schedule';
import React from 'react';

const weekOptions = [
  { label: 'The 1st', value: ScheduleConfigRecurrencePatternWeekOfMonth.FIRST },
  {
    label: 'The 2nd',
    value: ScheduleConfigRecurrencePatternWeekOfMonth.SECOND,
  },
  { label: 'The 3rd', value: ScheduleConfigRecurrencePatternWeekOfMonth.THIRD },
  {
    label: 'The 4th',
    value: ScheduleConfigRecurrencePatternWeekOfMonth.FOURTH,
  },
  { label: 'The Last', value: ScheduleConfigRecurrencePatternWeekOfMonth.LAST },
];

const ScheduleWeekOfMonthSelector: React.FC<{
  repeatOn:
    | (ScheduleConfigRecurrencePatternWeekOfMonth | undefined)[]
    | undefined;
  setRepeatOn: (days: ScheduleConfigRecurrencePatternWeekOfMonth[]) => void;
  sx?: React.CSSProperties;
  error: string | null;
  setError: (error: string | null) => void;
}> = ({ repeatOn, setRepeatOn, sx = {}, error, setError }) => {
  return (
    <>
      <OrbySelect
        width='100%'
        height='44px'
        value={repeatOn}
        error={!!error}
        errorBorderColor={OrbyColorPalette['error-700']}
        multiple
        inputProps={{
          placeholder: 'Select weeks',
          sx: {
            '& .MuiSelect-select': {
              padding: '10px 14px',
            },
            height: '24px',
            '& .MuiTypography-root': {
              fontSize: '16px',
            },
            '& .MuiInputBase-root': {
              height: '44px',
            },
            ...sx,
          },
        }}
        renderValue={(value) => {
          const selectedLabels = weekOptions
            .filter((option) =>
              (value as ScheduleConfigRecurrencePatternWeekOfMonth[]).includes(
                option.value,
              ),
            )
            .map((option) => option.label);
          return selectedLabels.length > 0 ? (
            <OrbyTypography
              size='sm'
              color={OrbyColorPalette['grey-900']}
              weight={'regular'}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {selectedLabels.join(', ')}
            </OrbyTypography>
          ) : (
            <OrbyTypography
              size='sm'
              color={OrbyColorPalette['grey-500']}
              weight={'regular'}
            >
              Select weeks
            </OrbyTypography>
          );
        }}
        onChange={(event) => {
          setRepeatOn(
            event.target.value as ScheduleConfigRecurrencePatternWeekOfMonth[],
          );
          setError(null);
        }}
      >
        {weekOptions.map((rep) => (
          <OrbyMenuItem
            width='auto'
            key={rep.value}
            title={rep.label}
            value={rep.value}
            isSelected={repeatOn && repeatOn.includes(rep.value)}
          />
        ))}
      </OrbySelect>

      <Box
        sx={{
          color: OrbyColorPalette['error-700'],
          fontSize: '14px',
          mt: '3px',
        }}
      >
        {error}
      </Box>
    </>
  );
};

export default ScheduleWeekOfMonthSelector;
