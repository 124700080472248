import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { OrbyColorPalette } from 'orby-ui/src';
import React from 'react';
import { TextField } from '@mui/material';
import CalendarIcon from '../../../../static/icons/calendar.svg';
import { convertDateMessageToDate, validateStartDate } from '../utils/helper';
import { DateMessage } from 'protos/google/type/date';
import dayjs from 'dayjs';

const SchedulerDatePicker: React.FC<{
  date: DateMessage | null;
  setDate: (date: DateMessage | null) => void;
  dateError: string | null;
  setDateError: (error: string | null) => void;
  minDate?: DateMessage;
  maxDate?: DateMessage;
}> = ({ date, setDate, dateError, setDateError, minDate, maxDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={convertDateMessageToDate(date)}
        minDate={minDate ? convertDateMessageToDate(minDate) : dayjs()}
        maxDate={maxDate ? convertDateMessageToDate(maxDate) : undefined}
        components={{
          OpenPickerIcon: () => <img src={CalendarIcon} alt='calendar' />,
        }}
        onAccept={(newDate) => {
          if (newDate) {
            setDate({
              year: newDate.year(),
              month: newDate.month() + 1,
              day: newDate.date(),
            });
          }
        }}
        InputProps={{
          autoComplete: 'off',
          slotProps: {
            input: {
              placeholder: 'Select date',
            },
          },
        }}
        onChange={(newValue) => {
          if (newValue) {
            setDate({
              year: newValue.year(),
              month: newValue.month() + 1,
              day: newValue.date(),
            });
            setDateError(null);
          }
        }}
        renderInput={(params) => (
          <TextField
            placeholder='Select date'
            error={!!dateError}
            helperText={dateError}
            onBlur={() => setDateError(validateStartDate(date))}
            sx={{
              '& .MuiInputBase-root': {
                padding: '10px 14px',
                gap: '8px',
                height: '44px',
                width: '270px',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: dateError
                    ? OrbyColorPalette['error-700']
                    : OrbyColorPalette['grey-200'],
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                  transition: 'all 0.2s',
                },
                '&:hover fieldset': {
                  borderColor: dateError
                    ? OrbyColorPalette['error-700']
                    : OrbyColorPalette['blue-700'],
                },
                '&.Mui-focused fieldset': {
                  borderColor: dateError
                    ? OrbyColorPalette['error-700']
                    : OrbyColorPalette['blue-700'],
                  borderWidth: '2px',
                  boxShadow: dateError
                    ? 'none'
                    : '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF',
                },
              },
              '& .MuiInputBase-input': {
                padding: '0px',
              },
              '& .MuiFormHelperText-root': {
                marginLeft: '0px',
                color: OrbyColorPalette['error-700'],
                fontSize: '14px',
              },
            }}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default SchedulerDatePicker;
