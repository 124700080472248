import React from 'react';
import { styled, Switch, SwitchProps } from '@mui/material';
import { OrbyColorPalette } from 'orby-ui/src';

const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme, disabled }) => ({
  width: 36,
  height: 20,
  padding: 0,
  cursor: disabled ? 'not-allowed' : 'pointer',
  '& .MuiSwitch-switchBase': {
    padding: 2,
    margin: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: OrbyColorPalette['white-0'],
      '& + .MuiSwitch-track': {
        backgroundColor: OrbyColorPalette['green-500'],
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      border: `6px solid ${OrbyColorPalette['white-0']}`,
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: OrbyColorPalette['grey-100'],
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default CustomSwitch;
