import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrbyHeaderLabel, OrbyTable } from 'orby-ui/src';

import { selectedOrgInfoSelector } from '../../../../redux/selectors/user.selectors';
import { useNavigate } from 'react-router-dom';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from 'orby-ui/src/components/table/table-utils';
import { Connector } from 'protos/pb/v1alpha2/connector';
import {
  CONNECTOR_ACTION_MENU_ITEM,
  buildRequest,
  getConnectorActionItem,
  getConnectorActionMenuCell,
  getConnectorAppsCell,
  getConnectorCreatorCell,
  getConnectorDestinationWorkflowCell,
  getConnectorNameCell,
  getConnectorNameColumnWidth,
  getConnectorSourceWorkflowCell,
  getConnectorTimeCell,
  getConnectorTimeWidth,
  handleConnectorPageChange,
  handleConnectorsRowSelectionChange,
} from './connectors-helper';
import { listWorkflowErrorAction } from '../../../../redux/actions/workflow.action';
import { toastService } from '../../../../services/ToastService';
import { getIdFromResourceName } from '../../../../utils/WorkflowUtils';
import {
  deleteConnectorAction,
  listConnectorAction,
} from '../../../../redux/actions/connector.action';
import {
  connectorListLoadingSelector,
  connectorListSelector,
  connectorTotalSizeSelector,
  processConnectorErrorSelector,
  updatedConnectorSelector,
} from '../../../../redux/selectors/connector.selectors';
import { DateRange } from '../../../../utils/constants';
import ConnectorDeletionModal from '../../../../pages/WorkflowConnector/ConnectorDeletionModal';
import { DeleteConnectorRequest } from 'protos/pb/v1alpha2/connector_service';

export interface Props {
  displayNamePrefix: string;
  selectedUsers: Array<string>;
  selectedWorkflows: Array<string>;
  tableWidth: number;
  selectedTime: string;
  customRange: DateRange;
}

const ConnectorTab: React.FC<Props> = ({
  displayNamePrefix,
  selectedWorkflows,
  tableWidth,
  selectedTime,
  customRange,
  selectedUsers,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const connectors: Connector[] = useSelector(connectorListSelector);
  const loading = useSelector(connectorListLoadingSelector);
  const updatedConnector = useSelector(updatedConnectorSelector);
  const connectorError = useSelector(processConnectorErrorSelector);
  const totalSize = useSelector(connectorTotalSizeSelector) ?? 0;

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);

  const [selectedConnector, setSelectedConnector] = useState<Connector | null>(
    null,
  );

  const [page, setPage] = useState(DEFAULT_FIRST_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const pagedConnectors = connectors.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage,
  );

  const [deletingConnector, setDeletingConnector] = useState(false);

  const [actionMenuAnchorEl, setActionMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

  /**
   * ACTION MENU
   */
  const actionMenuOpen = Boolean(actionMenuAnchorEl);
  const handleActionMenuClick = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    connector: Connector,
  ) => {
    setSelectedConnector(connector);
    setActionMenuAnchorEl(event.currentTarget);
  };

  /**
   * HANDLE ACTION MENU CLOSE
   */
  const handleActionMenuClose = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type?: string,
  ) => {
    event.stopPropagation();
    if (type && selectedConnector) {
      if (type === CONNECTOR_ACTION_MENU_ITEM.DELETE) {
        setIsDeletionModalOpen(true);
      } else if (type === CONNECTOR_ACTION_MENU_ITEM.EDIT) {
        const id = getIdFromResourceName(selectedConnector.name as string);
        if (id) {
          navigate(`/workflow/connector/${id}/update`);
        } else {
          toastService.showError('Something went wrong', {
            position: 'top-right',
          });
        }
      }
    }
    setActionMenuAnchorEl(null);
  };

  const handleDelete = (deleteReason: string) => {
    const req: DeleteConnectorRequest = {
      name: selectedConnector?.name,
      deletedReason: deleteReason,
    };
    dispatch(deleteConnectorAction(req));
    setDeletingConnector(true);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setIsDeletionModalOpen(false);
    setSelectedConnector(null);
  };

  /**
   * FETCH CONNECTORS
   * @param pageNumber
   * @param pageSize
   * @param refresh
   */
  const listConnectors = (
    pageNumber: number,
    pageSize: number,
    refresh: boolean,
  ) => {
    const req = buildRequest({
      navigate,
      displayNamePrefix,
      orgResourceName: selectedOrgInfo!.orgResourceName as string,
      selectedTime,
      customRange,
      selectedWorkflows,
      selectedUsers,
    });
    req.pageNumber = pageNumber;
    req.pageSize = pageSize;
    dispatch(listConnectorAction(req, refresh));
  };

  /**
   * REFRESH PAGE
   */
  const refreshPage = () => {
    setPage(DEFAULT_FIRST_PAGE);
    listConnectors(DEFAULT_FIRST_PAGE, rowsPerPage, true);
  };

  /**
   * USE EFFECTS
   */
  useEffect(() => {
    refreshPage();
  }, []);

  useEffect(() => {
    refreshPage();
  }, [
    displayNamePrefix,
    selectedTime,
    selectedWorkflows,
    selectedUsers,
    selectedTime,
    customRange,
  ]);

  useEffect(() => {
    if (connectorError) {
      toastService.showError(connectorError.message, {
        position: 'top-right',
      });
      dispatch(listWorkflowErrorAction());
    }
  }, [connectorError]);

  useEffect(() => {
    if (updatedConnector && deletingConnector) {
      toastService.showSuccess(`Connector is deleted successfully`, {
        position: 'top-right',
      });
      // TODO: change this logic when batch deletion is supported
      if (totalSize > connectors.length) {
        refreshPage();
      }
    }
  }, [updatedConnector]);

  return (
    <>
      <OrbyTable
        onTableRowClick={(data) => {
          const selectedConnector = data as Connector;
          const id = getIdFromResourceName(selectedConnector.name as string);
          navigate(`/workflow/connector/${id}/update`);
        }}
        tableContainerSx={{
          width: 'auto',
          overflowX: 'auto',
        }}
        tableWidth={tableWidth}
        isDataLoading={loading}
        noDataMessage={
          displayNamePrefix
            ? `No results for "${displayNamePrefix}"`
            : 'There are no connectors available.'
        }
        colSx={[
          {
            width: `${getConnectorNameColumnWidth(tableWidth, 130)}px`,
            maxWidth: `${getConnectorNameColumnWidth(tableWidth, 130)}px`,
            minWidth: `${getConnectorNameColumnWidth(tableWidth, 130)}px`,
          },
          {
            width: '74px',
            minWidth: '74px',
            maxWidth: '74px',
          },
          {
            width: `${getConnectorNameColumnWidth(tableWidth, 130)}px`,
            maxWidth: `${getConnectorNameColumnWidth(tableWidth, 130)}px`,
            minWidth: `${getConnectorNameColumnWidth(tableWidth, 130)}px`,
          },
          {
            width: `${getConnectorNameColumnWidth(tableWidth, 130)}px`,
            maxWidth: `${getConnectorNameColumnWidth(tableWidth, 130)}px`,
            minWidth: `${getConnectorNameColumnWidth(tableWidth, 130)}px`,
          },
          {
            width: '217px',
            minWidth: '217px',
            maxWidth: '217px',
          },
          {
            width: `${getConnectorTimeWidth(tableWidth, 80)}px`,
            maxWidth: `${getConnectorTimeWidth(tableWidth, 80)}px`,
            minWidth: `${getConnectorTimeWidth(tableWidth, 80)}px`,
          },
          {
            width: '69px',
            maxWidth: '69px',
            minWidth: '69px',
          },
        ]}
        tableId='pending-task-table'
        showCheckBox={false}
        isCheckBoxDisabled={true}
        cursor='pointer'
        /**
         * PAGINATION
         */
        pagination={{
          rowsPerPage,
          currentPage: page,
          totalRows: totalSize,
          setCurrentPage: (pageNumber: number) =>
            handleConnectorPageChange({
              connectors,
              pageNumber,
              page,
              rowsPerPage,
              setPage,
              listConnectors,
            }),
          setRowsPerPage: (rows: number) =>
            handleConnectorsRowSelectionChange({
              rowsNumber: rows,
              setPage,
              setRowsPerPage,
              listConnectors,
            }),
        }}
        /**
         * TABLE HEADER ROWS
         */
        headerRows={[
          <OrbyHeaderLabel
            key={'workflow-header-connector-name'}
            label='Connector Name'
          />,
          <OrbyHeaderLabel key={'workflow-header-apps-name'} label='Apps' />,
          <OrbyHeaderLabel
            key={'workflow-header-source-workflow'}
            label='Source Workflow'
          />,
          <OrbyHeaderLabel
            key={'workflow-header-destination-workflow'}
            label='Destination Workflow'
          />,
          <OrbyHeaderLabel key={'workflow-header-creator'} label='Creator' />,
          <OrbyHeaderLabel
            key={'workflow-header-time-modified'}
            label='Time Modified'
          />,
          <OrbyHeaderLabel key={'workflow-header-actions'} label='' />,
        ]}
        /**
         * TABLE ROWS
         */
        dataRows={pagedConnectors.map((connector) => {
          return {
            title: connector.displayName!,
            id: connector.name!,
            row: connector,
            cells: [
              getConnectorNameCell(connector, displayNamePrefix),
              getConnectorAppsCell(),
              getConnectorSourceWorkflowCell(connector),
              getConnectorDestinationWorkflowCell(connector),
              getConnectorCreatorCell(connector),
              getConnectorTimeCell(connector),
              getConnectorActionMenuCell(
                Connector.create(connector),
                handleActionMenuClick,
              ),
            ],
          };
        })}
      />

      {/* CONNECTOR ACTION MENU */}
      {getConnectorActionItem(
        actionMenuAnchorEl,
        actionMenuOpen,
        handleActionMenuClose,
      )}

      {/* CONNECTOR DELETION MODAL */}
      <ConnectorDeletionModal
        open={isDeletionModalOpen}
        handleClose={handleCloseModal}
        onSubmit={handleDelete}
      />
    </>
  );
};

export default React.memo(ConnectorTab);
