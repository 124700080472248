import { Alert, Box, styled, Typography } from '@mui/material';

import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFiltersAction } from '../../../redux/actions/filter_options.action';
import { selectedOrgInfoSelector } from '../../../redux/selectors/user.selectors';
import { OrbyButton, OrbyHeaderLabel, OrbyTable } from 'orby-ui/src';
import {
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
} from '../../../utils/constants';
import {
  getScheduleDateRangeCell,
  getScheduleDeleteCell,
  getScheduleEditCell,
  getScheduleEnableCell,
  getScheduleRecurrenceCell,
  getScheduleTimeCell,
  handleSchedulePageChange,
  handleSchedulesRowSelectionChange,
} from './ui-table-helper';
import { DEFAULT_ROWS_PER_PAGE } from 'orby-ui/src/components/table/table-utils';
import {
  deleteWorkflowScheduleError,
  getSchedules,
  updateWorkflowSchedule,
} from '../../../redux/actions/workflow_details.constants';
import { useParams } from 'react-router-dom';
import WorkflowSchedulerModal from './WorkflowSchedulerModal';
import {
  deleteWorkflowScheduleErrorSelector,
  getSchedulesLoadingSelector,
  getWorkflowSchedulesSelector,
  workflowAllDetailsSelector,
} from '../../../redux/selectors/workflow_details.selectors';
import ScheduleDeleteModal from './ScheduleDeleteModal';
import { deleteWorkflowSchedule } from '../../../redux/actions/workflow_details.constants';
import { toastService } from '../../../services/ToastService';
import { Schedule, ScheduleScheduleStatus } from 'protos/pb/v1alpha1/schedule';
import { useWorkflowScheduleError } from './components/useWorkflowScheduleError';
import ScheduleEditStatus from './ScheduleEditStatus';
import ScheduleEditConfirmModal from './ScheduleEditConfirmModal';

interface IWorkflowProps {
  workflow: Workflow | null;
  sideDrawerCollapse: boolean;
  isSecretsSet: boolean;
}

const Title = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '28px',
  color: '#212121',
}));

const ScheduleWorkflow: React.FC<IWorkflowProps> = ({
  sideDrawerCollapse,
  isSecretsSet,
}) => {
  const dispatch = useDispatch();
  // Getting the org id
  const orgInfo = useSelector(selectedOrgInfoSelector)!;
  const orgId = orgInfo?.orgResourceName!.replace(
    'organizations/',
    '',
  ) as string;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [deleteSchedule, setDeleteSchedule] = useState<Schedule | null>(null);
  const [deleteScheduleLoading, setDeleteScheduleLoading] = useState(false);

  // State to know if the schedule edit popup needs to be opened, and contains the editable schedule's id
  const [editingScheduleId, setEditingScheduleId] = useState<string | null>(
    null,
  );

  // State to know if the schedule edit status popup needs to be opened
  const [openScheduleEditStatusModal, setOpenScheduleEditStatusModal] =
    useState<{ schedule?: Schedule } | null>(null);

  // State to know if the confirmation popup before editing schedule needs to be opened
  const [openScheduleEditConfirmModal, setOpenScheduleEditConfirmModal] =
    useState<{ schedule?: Schedule } | null>(null);

  const { workflow_id: workflowId } = useParams();
  const [openWorkflowSchedulerModal, setOpenWorkflowSchedulerModal] =
    useState(false);

  // All the information of workflow details
  const workflowStore = useSelector(workflowAllDetailsSelector);

  const allSchedules = useSelector(getWorkflowSchedulesSelector);
  const isLoading = useSelector(getSchedulesLoadingSelector);
  const deleteWorkflowError = useSelector(deleteWorkflowScheduleErrorSelector);

  //   The below hook is for showing toast when API returns an error
  useWorkflowScheduleError();

  const fetchSchedules = async (
    pageNumber: number,
    rowsPerPage: number,
    reset = false,
  ) => {
    dispatch(
      getSchedules(
        {
          orgId,
          filter: {
            workflowId,
          },
          pageNumber,
          pageSize: rowsPerPage,
        },
        reset,
      ),
    );
  };

  const callbackForRefreshSchedules = async () => {
    setPage(0);
    await fetchSchedules(0, rowsPerPage, true);
  };

  useEffect(() => {
    setDeleteSchedule(null);
    setEditingScheduleId(null);
    setOpenScheduleEditStatusModal(null);
    setOpenScheduleEditConfirmModal(null);
  }, []);

  useEffect(() => {
    dispatch(getUserFiltersAction(orgInfo.orgResourceName as string));

    if (orgId) {
      fetchSchedules(page, rowsPerPage, true);
    }
  }, [orgId]);

  /**
   * GET WIDTH OF THE TABLE
   */
  const getTableWidth = () => {
    const sideNavWidth = sideDrawerCollapse
      ? DRAWER_WIDTH_COLLAPSED
      : DRAWER_WIDTH_EXPANDED;
    const PADDING = 48 * 2;

    return window.innerWidth - sideNavWidth - PADDING;
  };

  const handleScheduleStatusChange = (schedule?: Schedule) => {
    setOpenScheduleEditStatusModal({
      schedule,
    });
  };

  const handleDeleteSchedule = (id: string) => {
    setDeleteScheduleLoading(true);
    dispatch(
      deleteWorkflowSchedule(
        {
          id,
          orgId,
        },
        workflowId!,
        callbackForRefreshSchedules,
      ),
    );
    setDeleteScheduleLoading(false);
    setDeleteSchedule(null);
  };

  const updateScheduleStatus = async (schedule: Schedule | undefined) => {
    if (!schedule) {
      return;
    }

    dispatch(
      updateWorkflowSchedule(
        {
          schedule: {
            ...schedule,
            id: schedule?.id,
            orgId,
            workflowId,
            status:
              schedule?.status === ScheduleScheduleStatus.ACTIVE
                ? ScheduleScheduleStatus.PAUSED
                : ScheduleScheduleStatus.ACTIVE,
          },
          fieldMask: ['status'],
        },
        () => {
          callbackForRefreshSchedules();
          setOpenScheduleEditConfirmModal(null);
        },
      ),
    );
  };

  const pagedExecutions = allSchedules?.schedules?.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage,
  );

  useEffect(() => {
    if (deleteWorkflowError) {
      toastService.showError(deleteWorkflowError.message, {
        position: 'top-right',
      });

      dispatch(deleteWorkflowScheduleError(null));
    }
  }, [deleteWorkflowError]);

  return (
    <>
      {!isSecretsSet && (
        <Box sx={{ marginBottom: '16px' }}>
          <Alert severity='warning'>
            Action required: Set up your credentials to enable workflow
            scheduling. <a href='definition'>Go to Credentials</a>
          </Alert>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 4,
        }}
      >
        <Title>Workflow schedule</Title>
        <OrbyButton
          onClick={() => {
            setOpenWorkflowSchedulerModal(true);
            setEditingScheduleId(null);
            setOpenScheduleEditStatusModal(null);
          }}
          variant='primary-outline'
          size='small'
          label='Add'
          ariaLabel='Add'
          sx={{
            padding: '8px 39px',
          }}
          disabled={!isSecretsSet} // Disable the button if secrets are not set
        />
      </Box>
      <Box display='flex' flexDirection='column' mt={2} gap={4}>
        <OrbyTable
          noDataMessage={'There are no workflow schedules.'}
          tableId='workflow-api-automation-table'
          showCheckBox={false}
          isCheckBoxDisabled={true}
          cursor='pointer'
          isDataLoading={isLoading}
          tableWidth={getTableWidth()}
          /**
           * PAGINATION
           */
          pagination={{
            rowsPerPage,
            currentPage: page,
            totalRows: allSchedules?.totalSize || 0,
            setCurrentPage: (pageNumber: number) =>
              handleSchedulePageChange({
                schedules: allSchedules?.schedules || [],
                pageNumber,
                page,
                rowsPerPage,
                setPage,
                fetchSchedules,
              }),
            setRowsPerPage: (rows: number) =>
              handleSchedulesRowSelectionChange({
                rowsNumber: rows,
                setPage,
                setRowsPerPage,
                fetchSchedules,
              }),
          }}
          /**
           * TABLE HEADER ROWS
           */
          headerRows={[
            <OrbyHeaderLabel
              key={'workflow-header-start-end-date'}
              label='Start - End date'
            />,
            <OrbyHeaderLabel key={'workflow-header-repeat'} label='Repeat' />,
            <OrbyHeaderLabel key={'workflow-header-time'} label='Time' />,
            <OrbyHeaderLabel key={'workflow-header-enable'} label='Enable' />,
            <OrbyHeaderLabel key={'workflow-header-delete'} label='' />,
            <OrbyHeaderLabel key={'workflow-header-edit'} label='' />,
          ]}
          rowSx={{
            height: '73px',
          }}
          colSx={[
            {
              width: '267px',
              maxWidth: '303px',
              minWidth: '267px',
              padding: '16px 24px',
            },
            {
              width: '349px',
              maxWidth: '493px',
              minWidth: '349px',
              padding: '16px 24px',
            },
            {
              width: '248px',
              maxWidth: '248px',
              minWidth: '200px',
              padding: '16px 24px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
            {
              width: '68px',
              maxWidth: '68px',
              minWidth: '68px',
              padding: '16px 24px',
            },
            {
              width: '68px',
              maxWidth: '68px',
              minWidth: '68px',
              padding: '16px 24px',
            },
            {
              width: '68px',
              maxWidth: '68px',
              minWidth: '68px',
              padding: '16px 24px',
            },
          ]}
          /**
           * TABLE ROWS
           */
          dataRows={(pagedExecutions || []).map((schedule) => {
            return {
              title: schedule.id ?? '',
              id: schedule.id!,
              row: schedule,
              cells: [
                getScheduleDateRangeCell(schedule),
                getScheduleRecurrenceCell(schedule),
                getScheduleTimeCell(schedule),
                getScheduleEnableCell(schedule, () =>
                  handleScheduleStatusChange(schedule),
                ),
                getScheduleDeleteCell(() => {
                  setDeleteSchedule(schedule || null);
                  setOpenScheduleEditStatusModal(null);
                  setOpenScheduleEditConfirmModal(null);
                }),
                getScheduleEditCell(() => {
                  setOpenScheduleEditConfirmModal({ schedule });
                  setEditingScheduleId(schedule?.id || null);
                }),
              ],
            };
          })}
        />
      </Box>

      <ScheduleEditConfirmModal
        open={!!openScheduleEditConfirmModal?.schedule?.id}
        handleClose={() => setOpenScheduleEditConfirmModal(null)}
        onSubmit={() => {
          updateScheduleStatus(openScheduleEditConfirmModal?.schedule);
          setOpenWorkflowSchedulerModal(true);
          setOpenScheduleEditConfirmModal(null);
        }}
        description={
          openScheduleEditConfirmModal?.schedule?.status ===
          ScheduleScheduleStatus.ACTIVE
            ? `Before editing, need to first disable the schedule. Clicking "Continue editing" will disable the schedule for you`
            : `Schedule is disabled, changing configuration will activate it again.`
        }
      />

      {openWorkflowSchedulerModal && (
        <WorkflowSchedulerModal
          open={openWorkflowSchedulerModal}
          onClose={() => {
            setOpenWorkflowSchedulerModal(false);
            setEditingScheduleId(null);
          }}
          editingScheduleId={editingScheduleId}
          refreshDataHandler={callbackForRefreshSchedules}
        />
      )}

      {deleteSchedule ? (
        <ScheduleDeleteModal
          open={!!deleteSchedule}
          handleClose={() => setDeleteSchedule(null)}
          onSubmit={() => handleDeleteSchedule(deleteSchedule?.id || '')}
          loading={deleteScheduleLoading}
          title={workflowStore?.workflow?.displayName || ''}
        />
      ) : null}

      {openScheduleEditStatusModal?.schedule?.id ? (
        <ScheduleEditStatus
          open={!!openScheduleEditStatusModal}
          handleClose={() => setOpenScheduleEditStatusModal(null)}
          onSubmit={() => {
            updateScheduleStatus(openScheduleEditStatusModal?.schedule);
            setOpenScheduleEditStatusModal(null);
          }}
          currentStatus={openScheduleEditStatusModal?.schedule?.status}
        />
      ) : null}
    </>
  );
};

export default ScheduleWorkflow;
