import { removeElementAnnotation } from 'workflow-utils/src/helper';
import { Action } from 'protos/pb/v1alpha1/orbot_action';
import ExtractEntity from './ExtractEntity';
import { ItemDescription, WidgetContainer } from './Styled';
import React from 'react';
import { ClickableTextWithPopover } from './ClickableTextWithPopover';

interface ExtractEntityDescriptionProps {
  action: Action;
  isEditable: boolean;
}

const ExtractEntityDescription: React.FC<ExtractEntityDescriptionProps> = ({
  action,
  isEditable,
}) => {
  const fields = action.extractFields?.fields || [];
  const description = removeElementAnnotation(action.description || '');

  const renderExtractFieldsPopover = (
    open: boolean,
    handleClose: () => void,
  ) => (
    <ExtractEntity
      onClose={handleClose}
      actionId={action.id!}
      action={action}
      isEditable={isEditable}
      fields={fields}
    />
  );
  return (
    <WidgetContainer sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <ItemDescription>
        {`${description} for the following fields:`}
      </ItemDescription>
      <ClickableTextWithPopover
        isEditable={isEditable}
        text={getExtractEntityDescriptions(fields)}
        renderPopover={renderExtractFieldsPopover}
      />
    </WidgetContainer>
  );
};

function getExtractEntityDescriptions(fields: string[]): string {
  if (fields.length > 0) {
    return fields.join(' , ');
  }
  return 'No fields provided';
}
export default ExtractEntityDescription;
