import React, { useCallback, useRef, useState } from 'react';
import { Box, styled, TextField, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  NotDraggingStyle,
  DraggingStyle,
} from 'react-beautiful-dnd';
import { OrbyColorPalette } from 'orby-ui/src';

export interface GroupingItem {
  id: string;
  content: JSX.Element;

  // if this is provided for the right item, then we would show the manual input
  // to allow user change the matching by editing the fields between two tables.
  matchingId?: number;
}

export interface Grouping {
  leftItems: GroupingItem[];
  rightItems: GroupingItem[];
}

const Title = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'left',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '1.5',
  marginBottom: '12px',
}));

const GroupingTitle = styled(Title)(() => ({
  marginBottom: '8px',
  fontSize: '14px',
  gap: '8px',
  paddingLeft: '8px',
}));

const Header = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  backgroundColor: OrbyColorPalette['grey-200'],
  padding: '8px 0',
  borderRadius: '8px 8px 0 0',
  height: '34px',
}));

const Row = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}));

interface DraggableItemProps {
  isDragging: boolean;
  index: number;
  draggableStyle?: DraggingStyle | NotDraggingStyle | undefined;
  customStyle?: React.CSSProperties;
  isLastIndex?: boolean;
}

const GroupingItem = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isLastIndex' &&
    prop !== 'isDragging' &&
    prop !== 'draggableStyle' &&
    prop !== 'customStyle',
})<DraggableItemProps>(({ isDragging, index, draggableStyle, customStyle }) => {
  const isLastIndex = false; // This is now a local variable, not a prop
  return {
    display: 'flex',
    flex: 1,
    userSelect: 'none',
    padding: '12px 0px',
    alignItems: 'center',
    // change background colour if dragging
    background: isDragging
      ? OrbyColorPalette['indigo-100']
      : Number.isInteger(index) && index % 2 === 0
        ? OrbyColorPalette['white-0']
        : OrbyColorPalette['grey-100'],

    borderBottom: isDragging
      ? `2px solid ${OrbyColorPalette['indigo-500']}`
      : isLastIndex
        ? `1px solid ${OrbyColorPalette['grey-300']}`
        : 'none',

    boxShadow: isDragging ? `0px 0px 4px 0px rgba(0, 0, 0, 0.2)` : 'none',
    // styles we need to apply on draggables
    ...draggableStyle,
    // any custom style override
    ...customStyle,
  };
});

interface GroupContainerProps {
  isDraggingOver: boolean;
}

const GroupContainer = styled(Row, {
  shouldForwardProp: (prop) => prop !== 'isDraggingOver',
})<GroupContainerProps>(({ isDraggingOver }) => ({
  alignItems: 'stretch',
  borderBottom: isDraggingOver
    ? `2px solid ${OrbyColorPalette['indigo-500']}`
    : 'none',
}));

interface GroupItemContainerProps {
  isDraggingOver: boolean;
  groupingIndex: number;
}

const GroupItemContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isDraggingOver' && prop !== 'groupingIndex',
})<GroupItemContainerProps>(({ isDraggingOver, groupingIndex }) => {
  const getBackground = () => {
    if (isDraggingOver) {
      return OrbyColorPalette['indigo-300'];
    } else if (Number.isInteger(groupingIndex) && groupingIndex % 2 === 0) {
      return OrbyColorPalette['white-0'];
    } else {
      return OrbyColorPalette['grey-100'];
    }
  };

  return {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    background: getBackground(),
  };
});

const UnmatchedGroupItemContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDraggingOver',
})<Omit<GroupItemContainerProps, 'groupingIndex'>>(({ isDraggingOver }) => {
  const getBackground = () => {
    if (isDraggingOver) {
      return OrbyColorPalette['error-300'];
    }
    return OrbyColorPalette['error-50'];
  };

  return {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    background: getBackground(),
  };
});

const UnmatchedContainer = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  border: `2px solid ${OrbyColorPalette['error-400']}`,
  borderRadius: '8px',
  padding: '8px 0px',
}));

const ManualMatchTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'unmatched',
})<{ unmatched: boolean }>(({ unmatched }) => ({
  padding: '10px 0',

  '& .MuiInputBase-root': {
    fontSize: '12px',
    borderRadius: '16px',
    height: '22px',
    backgroundColor: unmatched
      ? OrbyColorPalette['orange-200']
      : OrbyColorPalette['green-100'],
  },
  '& .MuiInputBase-root.Mui-focused': {
    backgroundColor: 'white',
  },
  '& .MuiInputBase-input': {
    padding: '5px 10px',
    textAlign: 'center',
  },
}));

function NoMatch() {
  return (
    <Box
      sx={{
        width: '100%',
        padding: '4px',
        marginLeft: '12px',
        marginRight: '12px',
        borderRadius: '12px',
        backgroundColor: OrbyColorPalette['orange-50'],
      }}
    >
      <Typography
        display='flex'
        flex={1}
        justifyContent='center'
        fontSize={12}
        color={OrbyColorPalette['orange-700']}
      >
        No match
      </Typography>
    </Box>
  );
}

export interface GroupingResult {
  matchGroupings: Grouping[];
}

export interface RematchProps {
  leftTitle: string | JSX.Element;
  rightTitle: string | JSX.Element;
  leftHeader: JSX.Element;
  rightHeader: JSX.Element;
  matchGroupings: Grouping[];
  leftUnmatchedTitle: string | JSX.Element;
  // without specifying this, we would get unaligned rows
  rightItemMinWidth: number;
  onChange: (groupingResult: GroupingResult) => void;
  disabled: boolean;
}

function ManualMatchInput(props: {
  value: number | undefined;
  // returns true if the update is successful which means the value is valid
  onConfirm: (value: number | undefined) => boolean;
  disabled: boolean;
}) {
  const inputRef = useRef<HTMLInputElement>();
  const initialValue = Number.isInteger(props.value)
    ? props.value!.toString()
    : '';
  const [value, setValue] = useState<string>(initialValue);
  const isDirtyRef = useRef(false);

  const updateMatch = useCallback(() => {
    if (!isDirtyRef.current) {
      return;
    }
    isDirtyRef.current = false;
    const v = parseInt(value);
    if (!props.onConfirm(isNaN(v) ? undefined : v)) {
      // if the update fails such as invalid input, we would revert to the
      // initial value.
      setValue(initialValue);
    }
    inputRef.current?.blur();
  }, [value]);

  return (
    <ManualMatchTextField
      inputRef={inputRef}
      size='small'
      value={value}
      unmatched={props.value === undefined}
      onChange={(event) => {
        isDirtyRef.current = true;
        setValue(event.target.value);
      }}
      onBlur={updateMatch}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          updateMatch();
        } else if (event.key === 'Escape') {
          // reset to the previous value
          setValue(initialValue);
          inputRef.current?.blur();
        }
      }}
      disabled={props.disabled}
    />
  );
}

const MATCH_COLUMN_SX = {
  width: '64px',
  maxWidth: '64px',
  textAlign: 'center',
  justifyContent: 'center',
  paddingLeft: '8px',
  paddingRight: '8px',
};

export function Rematch({
  leftTitle,
  rightTitle,
  leftHeader,
  rightHeader,
  matchGroupings,
  leftUnmatchedTitle,
  rightItemMinWidth,
  disabled,
  onChange,
}: RematchProps) {
  const leftUnmatchedDroppableId = `${matchGroupings.length}`;
  function onDragEnd(result: DropResult) {
    const { source, destination } = result;

    // dropped outside the list would move the invoice line to unmatched status
    if (!destination) {
      // If drag from unmatch to nowhere, do nothing, because it will be moved back to unmatch
      if (source.droppableId === leftUnmatchedDroppableId) {
        return;
      }
      const sInd = +source.droppableId;
      const newGroupings = [...matchGroupings];
      const [removed] = newGroupings[sInd].leftItems.splice(source.index, 1);
      newGroupings.push({
        leftItems: [removed],
        rightItems: [],
      });
      onChange({
        matchGroupings: newGroupings,
      });
      return;
    }

    if (source.droppableId === destination.droppableId) {
      // If drag from unmatch to unmatch, do nothing
      if (source.droppableId === leftUnmatchedDroppableId) {
        return;
      }
      const sInd = +source.droppableId;
      const items = reorder(
        matchGroupings[sInd].leftItems,
        source.index,
        destination.index,
      );
      const newMatchGroupings = [...matchGroupings];
      newMatchGroupings[sInd].leftItems = items;
      onChange({ matchGroupings: newMatchGroupings });
    } else {
      // If drag from match to unmatch
      if (destination.droppableId === leftUnmatchedDroppableId) {
        const sInd = +source.droppableId;
        const newMatchGroupings = [...matchGroupings];
        const [removed] = newMatchGroupings[sInd].leftItems.splice(
          source.index,
          1,
        );
        newMatchGroupings.push({
          leftItems: [removed],
          rightItems: [],
        });
        onChange({
          matchGroupings: newMatchGroupings.filter(
            (g) => g.leftItems.length || g.rightItems.length,
          ),
        });
        return;
      }
      // If drag from match to match
      const sInd = +source.droppableId;
      const dInd = +destination.droppableId;
      const [updateSourceItems, updatedDestinationItems] = move(
        matchGroupings[sInd].leftItems,
        matchGroupings[dInd].leftItems,
        source,
        destination,
      );
      const newMatchGroupings = [...matchGroupings];
      newMatchGroupings[sInd].leftItems = updateSourceItems;
      newMatchGroupings[dInd].leftItems = updatedDestinationItems;

      onChange({
        matchGroupings: newMatchGroupings.filter(
          (grouping) => grouping.leftItems.length || grouping.rightItems.length,
        ),
      });
    }
  }

  /**
   * return true if the update is successful
   */
  function onManualInputChange(
    groupingIdx: number,
    leftItemIdx: number,
    currMatchingId: number | undefined,
    newMatchingId: number | undefined,
  ): boolean {
    if (newMatchingId === currMatchingId) {
      // 0. no change to the matching
      return true;
    }

    const newMatchGroupings = [...matchGroupings];

    const matchGrouping = newMatchGroupings[groupingIdx];
    if (newMatchingId === undefined) {
      // 1. move to unmatched. depending on different case blow, we generate a
      // new grouping to minimize changes.
      if (matchGrouping.leftItems.length === 1) {
        // 1.1. if the grouping only have one left item, we move the right item
        // to a new group in the bottom so that the left item stays in the
        // current position
        const rightItems = [...matchGrouping.rightItems];
        matchGrouping.rightItems = [];
        newMatchGroupings.push({
          leftItems: [],
          rightItems: rightItems,
        });
      } else {
        // 1.2. if the left items contain multiple items, we insert a new grouping
        // either before or after the current one that minimize the change.
        const [removed] = newMatchGroupings[groupingIdx].leftItems.splice(
          leftItemIdx,
          1,
        );

        let insertPosition: number;
        if (leftItemIdx < matchGrouping.leftItems.length / 2) {
          // the item is in the first half of the grouping
          insertPosition = groupingIdx;
        } else {
          // the item is in the second half of the grouping
          insertPosition = groupingIdx + 1;
        }
        newMatchGroupings.splice(insertPosition, 0, {
          leftItems: [removed],
          rightItems: [],
        });
      }
    } else {
      // 2. move to a different group
      const targetGroupIdx = newMatchGroupings.findIndex((g) =>
        g.rightItems.find((item) => item.matchingId === newMatchingId),
      );
      if (targetGroupIdx === -1) {
        console.error('Cannot find the target group with the matching index');
        return false;
      }
      const targetGroup = matchGroupings[targetGroupIdx];
      if (targetGroup.leftItems.length === 0) {
        // 2.1. if the matched group doesn't have any left item, then move that
        // right item to the current group so the left item doesn't need to move.
        if (matchGrouping.leftItems.length === 1) {
          // reuse the current grouping by:
          // a. move the current right items to a new group in the end
          // b. move the right items from the target group to the current grouping
          // c. remove the target group
          newMatchGroupings.push({
            leftItems: [],
            rightItems: [...matchGrouping.rightItems],
          });
          matchGrouping.rightItems = [...targetGroup.rightItems];
          targetGroup.rightItems = [];
        } else {
          // create a new grouping and insert to nearest location.
          const [removed] = matchGrouping.leftItems.splice(leftItemIdx, 1);
          let insertPosition: number;
          if (leftItemIdx < matchGrouping.leftItems.length / 2) {
            // the item is in the first half of the grouping
            insertPosition = groupingIdx;
          } else {
            // the item is in the second half of the grouping
            insertPosition = groupingIdx + 1;
          }
          newMatchGroupings.splice(insertPosition, 0, {
            leftItems: [removed],
            rightItems: [...targetGroup.rightItems],
          });
          targetGroup.rightItems = [];
        }
      } else {
        // 2.2. otherwise, move the current left item to the matching group.
        const [removed] = matchGrouping.leftItems.splice(leftItemIdx, 1);
        targetGroup.leftItems.push(removed);
      }
    }
    onChange({
      matchGroupings: newMatchGroupings,
    });
    return true;
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 49px 0 48px',
      }}
    >
      <Row>
        <Title>{leftTitle}</Title>
        <Box sx={MATCH_COLUMN_SX}></Box>
        <Title>{rightTitle}</Title>
      </Row>
      <Row>
        <Header>{leftHeader}</Header>
        <Header
          sx={{
            ...MATCH_COLUMN_SX,
            backgroundColor: 'inherit',
          }}
        >
          <Typography fontSize={12}>Match</Typography>
        </Header>
        <Header>{rightHeader}</Header>
      </Row>
      <DragDropContext onDragEnd={onDragEnd}>
        {matchGroupings.map((grouping, groupingIndex) => (
          <Droppable key={groupingIndex} droppableId={`${groupingIndex}`}>
            {(provided, snapshot) => (
              <GroupContainer isDraggingOver={snapshot.isDraggingOver}>
                <GroupItemContainer
                  ref={provided.innerRef}
                  isDraggingOver={snapshot.isDraggingOver}
                  groupingIndex={groupingIndex}
                  {...provided.droppableProps}
                >
                  {grouping.leftItems.map((item, index) =>
                    disabled
                      ? renderItem(
                          item,
                          groupingIndex,
                          matchGroupings.length - 1 === groupingIndex,
                        )
                      : renderDraggableItem(
                          item,
                          index,
                          groupingIndex,
                          matchGroupings.length - 1 === groupingIndex,
                        ),
                  )}
                  {provided.placeholder}
                </GroupItemContainer>
                <GroupItemContainer
                  sx={MATCH_COLUMN_SX}
                  isDraggingOver={false}
                  groupingIndex={groupingIndex}
                >
                  {grouping.leftItems.map((item, index) => (
                    <ManualMatchInput
                      // including the matchingId to re-render the component when
                      // matching changes.
                      key={`${item.id}-${index}-${grouping.rightItems?.[0]?.matchingId}`}
                      value={grouping.rightItems?.[0]?.matchingId}
                      disabled={disabled}
                      onConfirm={(updatedValue) =>
                        onManualInputChange(
                          groupingIndex,
                          index,
                          grouping.rightItems?.[0]?.matchingId,
                          updatedValue,
                        )
                      }
                    />
                  ))}
                </GroupItemContainer>
                <GroupItemContainer
                  isDraggingOver={false}
                  groupingIndex={groupingIndex}
                >
                  {grouping.rightItems.map((item) =>
                    // The Draggable index has to be consecutive in Droppable, if in the future we make right items draggable, we need to change this
                    renderItem(
                      item,
                      groupingIndex,
                      matchGroupings.length - 1 === groupingIndex,
                    ),
                  )}
                  {grouping.rightItems.length === 0 && (
                    <Box
                      sx={{
                        minWidth: rightItemMinWidth,
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <NoMatch />
                    </Box>
                  )}
                </GroupItemContainer>
              </GroupContainer>
            )}
          </Droppable>
        ))}
        {/* render right unmatched as a grouping */}
        <Row mt='16px'>
          <UnmatchedContainer>
            <GroupingTitle>
              <WarningIcon color='error' />
              {leftUnmatchedTitle}
            </GroupingTitle>
            <Droppable droppableId={leftUnmatchedDroppableId}>
              {(provided, snapshot) => {
                return (
                  <GroupContainer
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                    {...provided.droppableProps}
                  >
                    <UnmatchedGroupItemContainer
                      isDraggingOver={snapshot.isDraggingOver}
                    >
                      {provided.placeholder}
                    </UnmatchedGroupItemContainer>
                  </GroupContainer>
                );
              }}
            </Droppable>
          </UnmatchedContainer>
          <Box style={{ flex: 1 }}>{/* right unmatched */}</Box>
        </Row>
      </DragDropContext>
    </Box>
  );
}

const reorder = (
  items: GroupingItem[],
  startIndex: number,
  endIndex: number,
): GroupingItem[] => {
  const result = Array.from(items);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (
  sourceItems: GroupingItem[],
  destinationItems: GroupingItem[],
  droppableSource: { index: number },
  droppableDestination: { index: number },
): [GroupingItem[], GroupingItem[]] => {
  const sourceItemsClone = Array.from(sourceItems);
  const destItemsClone = Array.from(destinationItems);
  const [removed] = sourceItemsClone.splice(droppableSource.index, 1);

  destItemsClone.splice(droppableDestination.index, 0, removed);

  return [sourceItemsClone, destItemsClone];
};

const renderDraggableItem = (
  item: GroupingItem,
  index: number,
  groupingIndex: number,
  isLastIndex: boolean,
) => {
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <GroupingItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
          index={groupingIndex}
          draggableStyle={provided.draggableProps.style}
          isLastIndex={isLastIndex}
        >
          {item.content}
        </GroupingItem>
      )}
    </Draggable>
  );
};

const renderItem = (
  item: GroupingItem,
  groupingIndex: number,
  isLastIndex: boolean,
) => {
  return (
    <GroupingItem
      key={item.id}
      isDragging={false}
      index={groupingIndex}
      customStyle={{
        borderBottom: isLastIndex
          ? `1px solid ${OrbyColorPalette['grey-300']}`
          : 'none',
      }}
    >
      {item.content}
    </GroupingItem>
  );
};
