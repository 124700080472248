import { TableRow } from '@mui/material';
import React, { FC, memo } from 'react';
import CustomTableCell from '../../../../../../../../../components/CustomTableCell';
import { OrbyColorPalette } from 'orby-ui/src';
import { EntityInfo } from '../../../../../../../../../redux/reducers/review_task.reducer';
import ResizeLine from './ResizeLine';
import { ELLIPSIS_STYLE } from '../../../../../../../../../utils/constants';
import FloatingModalNormalizedSection from '../../../../../../ReviewPageFloatingModal/components/FloatingModalNormalizedSection/FloatingModalNormalizedSection';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import { useSelector } from 'react-redux';
import { taskForReviewSelector } from '../../../../../../../../../redux/selectors/review_task.selectors';

interface Props {
  rowCells: EntityInfo[];
  columnTypeToResize: string;
  showResizeLine: (rowType: string) => void;
  hideResizeLine: () => void;
  startResizingColumn: (e: React.MouseEvent, type: string) => void;
}
const NormalizationRow: FC<Props> = ({
  rowCells,
  columnTypeToResize,
  showResizeLine,
  hideResizeLine,
  startResizingColumn,
}) => {
  const task = useSelector(taskForReviewSelector);

  return (
    <TableRow>
      <CustomTableCell
        sx={{
          padding: '0px',
          border: 'none',
        }}
      />
      <CustomTableCell
        width={'34px'}
        padding='none'
        sx={{
          borderRight: '1px solid transparent',
          borderBottom: `1px solid ${OrbyColorPalette['grey-300']}`,
          borderLeft: `1px solid ${OrbyColorPalette['grey-300']}`,
        }}
      />

      {rowCells.map((cell, index) => {
        return (
          <>
            {cell.isExtra ? (
              <CustomTableCell
                ellipsis={false}
                sx={{
                  padding: '0px',
                  position: 'relative',
                  borderLeft: '1px solid transparent',
                  borderBottom: `1px solid ${OrbyColorPalette['grey-300']}`,
                  borderRight:
                    rowCells.length - 1 === index
                      ? `1px solid ${OrbyColorPalette['grey-300']}`
                      : '1px solid transparent',
                }}
              >
                <ResizeLine
                  open={columnTypeToResize === cell.type}
                  onMouseEnter={() => showResizeLine(cell.type)}
                  onMouseLeave={hideResizeLine}
                  onMouseDown={(e) => {
                    startResizingColumn(e, `cell-${cell.type}`);
                  }}
                />
              </CustomTableCell>
            ) : (
              <CustomTableCell
                ellipsis={false}
                sx={{
                  ...ELLIPSIS_STYLE,
                  position: 'relative',
                  maxWidth: '100%',
                  cursor: 'pointer',
                  ':focus': {
                    outline: 'none',
                  },
                  px: '3px',
                  pt: '8px',
                  pb: '6px',
                  verticalAlign: 'top',
                  borderLeft: '1px solid transparent',
                  borderRight:
                    rowCells.length - 1 === index
                      ? `1px solid ${OrbyColorPalette['grey-300']}`
                      : '1px solid transparent',
                  borderBottom: `1px solid ${OrbyColorPalette['grey-300']}`,
                }}
              >
                <FloatingModalNormalizedSection
                  task={task as Task}
                  selectedEntity={cell}
                  isTableView
                />
                <ResizeLine
                  open={columnTypeToResize === cell.type}
                  onMouseEnter={() => showResizeLine(cell.type)}
                  onMouseLeave={hideResizeLine}
                  onMouseDown={(e) => {
                    startResizingColumn(e, `cell-${cell.type}`);
                  }}
                />
              </CustomTableCell>
            )}
          </>
        );
      })}
    </TableRow>
  );
};

export default memo(NormalizationRow);
