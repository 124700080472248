import React from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { OrbyBodyLabel, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { formatDateTime } from '../../../../utils/helpers';
import {
  WorkflowTask,
  WorkflowTaskStatus,
} from 'protos/pb/v1alpha1/orbot_workflow';
import UserCard from '../../../../components/UserCard';
import ScheduleCard from './details/component/ScheduleCard';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

export const EXECUTION_MENU_ACTION_TYPE = {
  CANCEL: 'CANCEL',
  CANCEL_CHILD: 'CANCEL_CHILD',
};

export const getExecutionCell = (
  execution: WorkflowTask,
  displayNamePrefix: string,
): JSX.Element => {
  const executionName = execution.id || '';

  const startIndex = executionName
    .toLowerCase()
    .indexOf(displayNamePrefix?.toLowerCase());
  const endIndex = startIndex + displayNamePrefix?.length;

  return (
    <OrbyBodyLabel
      title={executionName}
      key={execution.id}
      label={
        <span>
          <span
            style={{ color: OrbyColorPalette['blue-700'], fontWeight: '700' }}
          >
            {executionName.substring(startIndex, endIndex)}
          </span>
          {executionName.substring(endIndex)}
        </span>
      }
      fontSize='sm'
      fontWeight='medium'
      color={OrbyColorPalette['grey-900']}
    />
  );
};

export const getExecutionActionMenuCell = (
  execution: WorkflowTask,
  handleActionMenuClick: (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    execution: WorkflowTask,
  ) => void,
): JSX.Element => {
  return (
    <>
      {execution.scheduleId &&
      execution.status !== WorkflowTaskStatus.FAIL &&
      execution.status !== WorkflowTaskStatus.TERMINATED ? (
        <IconButton
          title='Menu'
          aria-label='Menu'
          key={execution.id}
          onClick={(
            event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
          ) => {
            event.stopPropagation();
            handleActionMenuClick(event, execution);
          }}
        >
          <MoreVertOutlinedIcon sx={{ color: OrbyColorPalette['grey-400'] }} />
        </IconButton>
      ) : null}
    </>
  );
};

export const getExecutionWorkflowDisplayNameCell = (
  execution: WorkflowTask,
): JSX.Element => {
  return (
    <OrbyBodyLabel
      title={execution.workflowDisplayName}
      key={execution.workflowId}
      label={execution.workflowDisplayName || 'NA'}
      fontSize='sm'
      fontWeight='regular'
      color={OrbyColorPalette['grey-600']}
    />
  );
};

type WorkflowTaskStatusDetails = {
  color: string;
  label: string;
  value: string;
  backgroundColor: string;
  dotColor: string;
};

export const WorkflowTaskStatusEnum: Record<number, WorkflowTaskStatusDetails> =
  {
    [WorkflowTaskStatus.PENDING]: {
      color: OrbyColorPalette['blueGrey-700'],
      label: 'Waiting for execution',
      value: 'PENDING',
      backgroundColor: OrbyColorPalette['blueGrey-50'],
      dotColor: OrbyColorPalette['blueGrey-500'],
    },
    [WorkflowTaskStatus.EXECUTING]: {
      color: OrbyColorPalette['blue-800'],
      label: 'Execution in process',
      value: 'EXECUTING',
      backgroundColor: OrbyColorPalette['blue-50'],
      dotColor: OrbyColorPalette['blue-600'],
    },
    [WorkflowTaskStatus.WAITING_FOR_REVIEW]: {
      color: OrbyColorPalette['blue-600'],
      label: 'Pending Review',
      value: 'WAITING_FOR_REVIEW',
      backgroundColor: OrbyColorPalette['blue-50'],
      dotColor: OrbyColorPalette['blue-400'],
    },
    [WorkflowTaskStatus.SUCCESS]: {
      color: OrbyColorPalette['green-700'],
      label: 'Completed',
      value: 'SUCCESS',
      backgroundColor: OrbyColorPalette['green-50'],
      dotColor: OrbyColorPalette['green-500'],
    },
    [WorkflowTaskStatus.TERMINATED]: {
      color: OrbyColorPalette['pink-700'],
      label: 'Cancelled',
      value: 'TERMINATED',
      backgroundColor: OrbyColorPalette['pink-50'],
      dotColor: OrbyColorPalette['pink-500'],
    },
    [WorkflowTaskStatus.FAIL]: {
      color: OrbyColorPalette['orange-700'],
      label: 'Error',
      value: 'FAIL',
      backgroundColor: OrbyColorPalette['orange-50'],
      dotColor: OrbyColorPalette['orange-500'],
    },
  };

export const getExecutionStatusCell = (
  execution: WorkflowTask,
): JSX.Element | JSX.Element[] => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
      <Box
        key={execution.id}
        title={WorkflowTaskStatusEnum[execution.status!].label}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={'6px'}
        sx={{
          paddingX: '8px',
          paddingY: '2px',
          backgroundColor:
            WorkflowTaskStatusEnum[execution.status!].backgroundColor,
          borderRadius: '16px',
          width: 'fit-content',
        }}
      >
        <CircleIcon
          sx={{
            width: '6px',
            height: '6px',
            color: WorkflowTaskStatusEnum[execution.status!].dotColor,
          }}
        />
        <OrbyTypography
          size='xs'
          weight='medium'
          color={WorkflowTaskStatusEnum[execution.status!].color}
        >
          {WorkflowTaskStatusEnum[execution.status!].label}
        </OrbyTypography>
      </Box>
    </Box>
  );
};

export const getExecutionTimeCell = (
  execution: WorkflowTask,
  executionTime?: Date,
): JSX.Element => {
  if (executionTime) {
    const { date, time } = formatDateTime(executionTime);
    return (
      <Box key={execution.id}>
        <OrbyBodyLabel
          label={date}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-900']}
        />
        <OrbyBodyLabel
          label={time}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-600']}
        />
      </Box>
    );
  } else {
    return (
      <OrbyBodyLabel
        key={execution.id}
        label={'-'}
        fontSize='sm'
        fontWeight='regular'
        color={OrbyColorPalette['grey-900']}
      />
    );
  }
};

export const getExecutionRanByCell = (execution: WorkflowTask): JSX.Element => {
  return execution.creator ? (
    <UserCard
      key={execution.creator?.fullName}
      email={execution.creator?.username ?? ''}
      imageUrl={execution.creator?.imageUrl}
      fullName={execution.creator?.fullName}
    />
  ) : (
    <ScheduleCard />
  );
};

export const getExecutionNameColumnWidth = (
  tableWidth: number,
  minWidth?: number,
) => {
  const width =
    (tableWidth -
      getExecutionTimeWidth(tableWidth) -
      getExecutionTimeWidth(tableWidth) -
      188 -
      217 -
      69 -
      10) /
    2;
  if (minWidth) {
    return Math.max(width, minWidth);
  }
  return width;
};

export const getExecutionTimeWidth = (
  tableWidth: number,
  minWidth?: number,
) => {
  const width = tableWidth >= 1272 ? 182 : 139;
  if (minWidth) {
    return Math.max(width, minWidth);
  }
  return width;
};

/**
 * Handle UI Execution Page Change
 */
export const handleUiExecutionPageChange = ({
  executions,
  pageNumber,
  page,
  rowsPerPage,
  setPage,
  listExecutions,
}: {
  executions: WorkflowTask[];
  pageNumber: number;
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  listExecutions: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
}) => {
  setPage(pageNumber);
  if (pageNumber >= page && executions.length <= pageNumber * rowsPerPage) {
    listExecutions(pageNumber, rowsPerPage, false);
  }
};

export const getExecutionActionItem = (
  actionMenuAnchorEl: HTMLElement | null,
  open: boolean,
  handleActionMenuClose: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type: string,
  ) => void,
  isAdmin: boolean,
  selectedExecution: WorkflowTask | null,
) => {
  return (
    <Menu
      id='basic-menu'
      anchorEl={actionMenuAnchorEl}
      open={open}
      onClose={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        handleActionMenuClose(e, '');
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
          overflow: 'visible',
          borderRadius: '8px',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {isAdmin && selectedExecution?.scheduleId && (
        <>
          {selectedExecution.status != WorkflowTaskStatus.SUCCESS && (
            <MenuItem
              sx={{ paddingTop: '10px', paddingBottom: '10px' }}
              onClick={(e) => {
                handleActionMenuClose(e, EXECUTION_MENU_ACTION_TYPE.CANCEL);
              }}
            >
              <OrbyTypography
                size='md'
                weight='medium'
                color={OrbyColorPalette['grey-900']}
              >
                Cancel
              </OrbyTypography>
            </MenuItem>
          )}
          {!selectedExecution?.parentTaskId && (
            <MenuItem
              sx={{ paddingTop: '10px', paddingBottom: '10px' }}
              onClick={(e) => {
                handleActionMenuClose(
                  e,
                  EXECUTION_MENU_ACTION_TYPE.CANCEL_CHILD,
                );
              }}
            >
              <OrbyTypography
                size='md'
                weight='medium'
                color={OrbyColorPalette['grey-900']}
              >
                Cancel Child Executions
              </OrbyTypography>
            </MenuItem>
          )}
        </>
      )}
    </Menu>
  );
};
