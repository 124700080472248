import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { OrbyTypography } from 'orby-ui/src';
import SchedulerDatePicker from './SchedulerDatePicker';
import SchedulerTimeInput from './SchedulerTimeInput';
import SchedulerTimezoneSelector from './SchedulerTimezoneSelector';
import SchedulerWeekdaysSelector from './SchedulerWeekdaysSelector';
import WorkflowSchedulerModalFooter from './WorkflowSchedulerModalFooter';
import {
  validateEndDate,
  validateStartDate,
  validateTime,
  validateWeekdays,
  VALIDATION_ERROR_MESSAGE,
} from '../utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import {
  createWorkflowSchedule,
  updateWorkflowSchedule,
} from '../../../../redux/actions/workflow_details.constants';
import {
  ScheduleConfigRecurrencePatternDayOfWeek,
  ScheduleScheduleStatus,
} from 'protos/pb/v1alpha1/schedule';
import { getWorkflowScheduleSelector } from '../../../../redux/selectors/workflow_details.selectors';
import { DateMessage } from 'protos/google/type/date';

interface Props {
  handleClose: () => void;
  workflowId: string;
  orgId: string;
  refreshDataHandler?: () => void;
}

const ScheduleWeekly: React.FC<Props> = ({
  handleClose,
  workflowId,
  orgId,
  refreshDataHandler = () => null,
}) => {
  const [startDate, setStartDate] = useState<DateMessage | null>(null);
  const [startDateError, setStartDateError] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<DateMessage | null>(null);
  const [endDateError, setEndDateError] = useState<string | null>(null);
  const [scheduledTimes, setScheduledTimes] = useState<
    { hours: string; minutes: string }[]
  >([]);
  const [timeError, setTimeError] = useState<string | null>(null);
  const [selectedDays, setSelectedDays] = useState<
    ScheduleConfigRecurrencePatternDayOfWeek[] | undefined
  >([]);
  const [selectedDaysError, setSelectedDaysError] = useState<string | null>(
    null,
  );
  const [timezone, setTimezone] = useState<string>('');
  const [currentInputValue, setCurrentInputValue] = useState<{
    hours: string;
    minutes: string;
  } | null>(null);

  const dispatch = useDispatch();
  const editingSchedule = useSelector(getWorkflowScheduleSelector);

  const validateForm = () => {
    let isValid = true;
    const startDateValidationError = validateStartDate(startDate);
    if (startDateValidationError) {
      setStartDateError(startDateValidationError);
      isValid = false;
    }
    const endDateValidationError = validateEndDate(endDate);
    if (endDateValidationError) {
      setEndDateError(endDateValidationError);
      isValid = false;
    }

    if (startDate && endDate && startDate > endDate) {
      setStartDateError(
        VALIDATION_ERROR_MESSAGE.START_DATE_GREATER_THAN_END_DATE,
      );
      isValid = false;
    }

    const timeValidationError = validateTime({
      hours: currentInputValue?.hours,
      minutes: currentInputValue?.minutes,
    });
    if (scheduledTimes.length === 0 && !timeValidationError) {
      setTimeError(VALIDATION_ERROR_MESSAGE.TIME);
      isValid = false;
    }

    const selectedDaysValidationError = validateWeekdays(
      selectedDays?.map((day) => day.toString()) || [],
    );
    if (selectedDaysValidationError) {
      setSelectedDaysError(selectedDaysValidationError);
      isValid = false;
    }

    if (!timezone) {
      isValid = false;
    }
    return isValid;
  };

  // Here we are formatting the request to be sent to the backend as per create or update API
  const requestFormatter = () => {
    const request = {
      schedule: {
        id: editingSchedule?.id,
        orgId,
        workflowId,
        startDate: startDate || undefined,
        endDate: endDate || undefined,
        timezoneName: timezone,
        status: editingSchedule?.status ?? ScheduleScheduleStatus.ACTIVE,
        config: {
          schedulePattern: {
            runTimes: scheduledTimes?.length
              ? scheduledTimes.map((time) => ({
                  hours: parseInt(time.hours),
                  minutes: parseInt(time.minutes),
                }))
              : [
                  {
                    hours: parseInt(currentInputValue!.hours),
                    minutes: parseInt(currentInputValue!.minutes),
                  },
                ],
            recurrencePattern: {
              weeklyRecurrence: {
                daysOfWeek: selectedDays,
              },
            },
          },
          interval: 1,
        },
      },
    };

    return request;
  };

  const handleSave = () => {
    if (validateForm()) {
      dispatch(createWorkflowSchedule(requestFormatter(), refreshDataHandler));
      handleClose();
    }
  };

  useEffect(() => {
    if (editingSchedule) {
      setStartDate(editingSchedule.startDate || null);
      setStartDateError(null);

      setEndDate(editingSchedule.endDate || null);
      setEndDateError(null);

      setScheduledTimes(
        editingSchedule.config?.schedulePattern?.runTimes?.map((time) => ({
          hours: time?.hours?.toString() || '',
          minutes: time?.minutes?.toString() || '',
        })) || [],
      );
      setTimeError(null);

      setSelectedDays(
        editingSchedule.config?.schedulePattern?.recurrencePattern
          ?.weeklyRecurrence?.daysOfWeek || [],
      );
      setSelectedDaysError(null);

      setTimezone(editingSchedule.timezoneName || '');
      setCurrentInputValue(null);
    }
  }, [editingSchedule]);

  const handleUpdate = () => {
    if (validateForm()) {
      dispatch(updateWorkflowSchedule(requestFormatter(), refreshDataHandler));
      handleClose();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
          width: '100%',
        }}
      >
        <Box>
          <OrbyTypography
            size='sm'
            color='grey-700'
            sx={{ marginBottom: '6px' }}
          >
            Start date
          </OrbyTypography>
          <SchedulerDatePicker
            date={startDate}
            setDate={setStartDate}
            dateError={startDateError}
            setDateError={setStartDateError}
            maxDate={endDate || undefined}
          />
        </Box>
        <Box>
          <OrbyTypography
            size='sm'
            color='grey-700'
            sx={{ marginBottom: '6px' }}
          >
            End date
          </OrbyTypography>
          <SchedulerDatePicker
            date={endDate}
            setDate={setEndDate}
            dateError={endDateError}
            setDateError={setEndDateError}
            minDate={startDate || undefined}
          />
        </Box>
      </Box>
      <Box>
        <OrbyTypography size='sm' color='grey-700' sx={{ marginBottom: '6px' }}>
          Repeat on (multiple choices)
        </OrbyTypography>
        <SchedulerWeekdaysSelector
          selectedDays={selectedDays || []}
          setSelectedDays={(days) =>
            setSelectedDays(
              days?.filter(
                (day): day is ScheduleConfigRecurrencePatternDayOfWeek =>
                  day !== undefined,
              ),
            )
          }
          error={selectedDaysError}
          setError={setSelectedDaysError}
        />
      </Box>
      <Box>
        <OrbyTypography size='sm' color='grey-700' sx={{ marginBottom: '6px' }}>
          Time
        </OrbyTypography>
        <SchedulerTimeInput
          scheduledTimes={scheduledTimes}
          setScheduledTimes={setScheduledTimes}
          timeError={timeError}
          setTimeError={setTimeError}
          getCurrentInputValue={setCurrentInputValue}
        />
      </Box>
      <Box>
        <OrbyTypography size='sm' color='grey-700' sx={{ marginBottom: '6px' }}>
          Time zone
        </OrbyTypography>
        <SchedulerTimezoneSelector
          timezone={timezone}
          setTimezone={setTimezone}
        />
      </Box>
      <WorkflowSchedulerModalFooter
        handleSave={() => {
          if (editingSchedule?.id) {
            handleUpdate();
          } else {
            handleSave();
          }
        }}
        setOpen={handleClose}
      />
    </>
  );
};

export default ScheduleWeekly;
