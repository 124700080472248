import { WorkflowTaskActionType } from '../actions/actions.constants';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { orbotService } from '../../services/OrbotService';
import {
  getWorkflowByIdSuccess,
  getWorkflowTaskFailure,
  getWorkflowTaskSuccess,
  listWorkflowsTemplateCompletedAction,
  listWorkflowsTemplateErrorAction,
  updateLinkedTasks,
  getOrbotWorkflowTemplateCompletedAction,
  getOrbotWorkflowTemplateErrorAction,
} from '../actions/workflow_task.constants';

import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';
import { getCreateTaskActionUUIDs } from 'workflow-utils/src/v2/workflow';
import {
  GetWorkflowTaskRequest,
  GetWorkflowTemplateRequest,
  ListWorkflowTemplatesRequest,
} from 'protos/pb/v1alpha1/orbot_service';
import { WorkflowWithTask } from '../reducers/workflow_task.reducer';

interface GetWorkflowTaskAction {
  type: typeof WorkflowTaskActionType.GET_WORKFLOW_WITH_TASK;
  payload: { req: GetWorkflowTaskRequest; refresh: boolean };
}

interface ListWorkflowTemplateAction {
  type: typeof WorkflowTaskActionType.LIST_WORKFLOWS_TEMPLATE_ORBOT;
  req: ListWorkflowTemplatesRequest;
  refresh: boolean;
}

interface GetWorkflowTemplateAction {
  type: typeof WorkflowTaskActionType.GET_WORKFLOW_TEMPLATE_ORBOT;
  req: GetWorkflowTemplateRequest;
}

function* getOrbotWorkflowWithTaskSaga(action: GetWorkflowTaskAction): any {
  try {
    // getWorkflowWithTask
    const { task, workflow, error }: WorkflowWithTask = yield call(
      orbotService.getWorkflowWithTask,
      action.payload.req,
    );

    if (task && workflow) {
      yield put(getWorkflowByIdSuccess(workflow)); // Dispatch success action with response
      yield put(getWorkflowTaskSuccess(task)); // Dispatch success action with response

      // Get children tasks
      const executedActions = task.executedActions || [];
      const createTaskActionUUIDs = getCreateTaskActionUUIDs(
        workflow as Workflow,
        task.processId,
      );

      const linkedTaskIds: string[] = executedActions
        .filter(
          (ea) =>
            ea.preparedActionUuid &&
            createTaskActionUUIDs.includes(ea.preparedActionUuid) &&
            ea.outputValue,
        )
        .map((ea) => JSON.parse(ea.outputValue!));

      const linkedTasks: WorkflowWithTask[] = [];
      for (const taskId of linkedTaskIds) {
        const linkedTask: WorkflowWithTask = yield call(
          orbotService.getWorkflowWithTask,
          { ...action.payload.req, taskId },
        );

        linkedTasks.push(linkedTask);
      }

      yield put(updateLinkedTasks(linkedTasks as WorkflowWithTask[]));
    } else {
      yield put(getWorkflowTaskFailure(error as Error)); // Dispatch failure action with error
    }
  } catch (error) {
    yield put(getWorkflowTaskFailure(error as Error)); // Dispatch failure action if any unexpected error occurs
  }
}

function* listOrbotWorkflowTemplatesSaga(
  action: ListWorkflowTemplateAction,
): any {
  try {
    const { response, error } = yield call(
      orbotService.listWorkflowsTemplates,
      action.req,
    );

    if (response) {
      yield put(
        listWorkflowsTemplateCompletedAction(
          response.workflows,
          response.totalSize,
        ),
      );
    } else {
      yield put(listWorkflowsTemplateErrorAction(error));
    }
  } catch (error) {
    yield put(listWorkflowsTemplateErrorAction(error as Error)); // Dispatch failure action if any unexpected error occurs
  }
}

function* getOrbotWorkflowTemplateSaga(action: GetWorkflowTemplateAction): any {
  try {
    const { response, error } = yield call(
      orbotService.getWorkflowTemplate,
      action.req,
    );

    if (response) {
      yield put(getOrbotWorkflowTemplateCompletedAction(response));
    } else {
      yield put(getOrbotWorkflowTemplateErrorAction(error));
    }
  } catch (error) {
    yield put(getOrbotWorkflowTemplateErrorAction(error as Error)); // Dispatch failure action if any unexpected error occurs
  }
}

function* workflowTaskSaga() {
  yield all([
    takeLatest(
      WorkflowTaskActionType.GET_WORKFLOW_WITH_TASK,
      getOrbotWorkflowWithTaskSaga,
    ),
    takeLatest(
      WorkflowTaskActionType.LIST_WORKFLOWS_TEMPLATE_ORBOT,
      listOrbotWorkflowTemplatesSaga,
    ),
    takeLatest(
      WorkflowTaskActionType.GET_WORKFLOW_TEMPLATE_ORBOT,
      getOrbotWorkflowTemplateSaga,
    ),
  ]);
}

export default workflowTaskSaga;
