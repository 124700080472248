import { Box, Chip, styled } from '@mui/material';
import React, { useCallback, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { OrbyTextField } from 'orby-ui/src';

interface ExtractEntityComponentProps {
  entities: string[];
  onEntitiesChange: (newEntities: string[]) => void;
  isEditable?: boolean;
}

export const ExtractEntityComponent: React.FC<ExtractEntityComponentProps> = ({
  entities,
  onEntitiesChange,
  isEditable = true,
}) => {
  const [value, setValue] = useState<string>('');

  const handleAddEntity = useCallback(
    (entity: string) => {
      if (!entities.includes(entity)) {
        onEntitiesChange([...entities, entity]);
      }
    },
    [entities, onEntitiesChange],
  );

  const handleRemoveEntity = useCallback(
    (entityToRemove: string) => {
      onEntitiesChange(entities.filter((e) => e !== entityToRemove));
    },
    [entities, onEntitiesChange],
  );

  return (
    <>
      <OrbyTextField
        variant='standard'
        inputSx={{
          width: '100%',
        }}
        disabled={!isEditable}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && value.trim()) {
            handleAddEntity(value.trim());
            setValue('');
          }
        }}
        placeholder='Add fields to extract...'
      />
      <EntityChips
        isEditable={isEditable}
        entities={entities}
        onDelete={handleRemoveEntity}
      />
    </>
  );
};

const EntityChipsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexDirection: 'row',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  width: '100%',
}));

const EntityChips = ({
  entities,
  onDelete,
  isEditable = true,
}: {
  entities: string[];
  onDelete: (entity: string) => void;
  isEditable: boolean;
}) => {
  return (
    <EntityChipsContainer>
      {entities.map((e, i) => (
        <Chip
          key={i}
          label={e}
          disabled={!isEditable}
          deleteIcon={<CancelIcon />}
          onDelete={() => onDelete(e)}
        ></Chip>
      ))}
    </EntityChipsContainer>
  );
};
