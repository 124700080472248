import React, { useState } from 'react';
import { Button, TextField, Box } from '@mui/material';
import { storageService } from '../../services/StorageService';
import { useSelector } from 'react-redux';
import { loggedInUserSelector } from '../../redux/selectors/user.selectors';
import { useCheckCookieFeatureFlagEnabled } from '../../hooks/useCheckCookieFeatureFlagEnabled';

const IntegrationTest: React.FC = () => {
  const [name, setName] = useState('');
  const user = useSelector(loggedInUserSelector)!;
  const isLoginCookieEnabled = useCheckCookieFeatureFlagEnabled();

  const copyPdfInsideInnerIntegrationTestFolder = async (
    name: string,
  ): Promise<string> => {
    return new Promise((resolve) => {
      gapi.load('client', async () => {
        const googleToken = await storageService.getStoredGoogleToken(
          user.email!,
          isLoginCookieEnabled,
        );
        //@ts-ignore
        gapi.client.setToken({ access_token: googleToken.accessToken });
        const fileId = process.env.REACT_APP_TEST_PDF;
        const copySamplePdfRequest = gapi.client.request({
          path: `/drive/v3/files/${fileId}/copy`,
          method: 'POST',
          body: {
            name: name,
            parents: [process.env.REACT_APP_TEST_INNER_INTEGRATION_FOLDER],
          },
        });
        copySamplePdfRequest.execute(function (resp) {
          resolve(resp.id);
        });
      });
    });
  };

  const copyExcelInsideOuterIntegrationTestFolder = async (
    name: string,
  ): Promise<string> => {
    return new Promise((resolve) => {
      gapi.load('client', async () => {
        const googleToken = await storageService.getStoredGoogleToken(
          user.email!,
          isLoginCookieEnabled,
        );
        //@ts-ignore
        gapi.client.setToken({ access_token: googleToken.accessToken });
        const excelId = process.env.REACT_APP_TEST_EXCEL;
        const copyExcelRequest = gapi.client.request({
          path: `/drive/v3/files/${excelId}/copy`,
          method: 'POST',
          body: {
            name: name,
            parents: [process.env.REACT_APP_TEST_PARENT_INTEGRATION_FOLDER],
          },
        });
        copyExcelRequest.execute(function (resp) {
          resolve(resp.id);
        });
      });
    });
  };

  const onInitTest = async () => {
    const id = await copyExcelInsideOuterIntegrationTestFolder(name);
    // eslint-disable-next-line no-console
    console.log(id);
  };

  const onStartPdfProcessing = async () => {
    await copyPdfInsideInnerIntegrationTestFolder(name);
  };

  const onClearUp = async () => {
    // eslint-disable-next-line no-console
    console.log();
  };

  return (
    <Box padding={'100px'}>
      <TextField
        id='standard-basic'
        label='Name'
        variant='standard'
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <Button variant='contained' sx={{ margin: '10px' }} onClick={onInitTest}>
        Init Test
      </Button>
      <Button
        variant='contained'
        sx={{ margin: '10px' }}
        onClick={onStartPdfProcessing}
      >
        Start Processing the Demo PDF
      </Button>
      <Button variant='contained' sx={{ margin: '10px' }} onClick={onClearUp}>
        Clear Up
      </Button>
    </Box>
  );
};

export default React.memo(IntegrationTest);
