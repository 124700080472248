import {
  OrbyColorPalette,
  OrbyMenuItem,
  OrbySelect,
  OrbyTypography,
} from 'orby-ui/src';
import React, { useEffect } from 'react';
import getTimeZones from '../utils/timezones';

const SchedulerTimezoneSelector: React.FC<{
  timezone: string;
  setTimezone: (timezone: string) => void;
  readonly?: boolean;
}> = ({ timezone, setTimezone, readonly = false }) => {
  const timezones = getTimeZones();
  // Setting the default timezone, if the user's timezone is not in the list, we set it to UTC
  useEffect(() => {
    const fetchUserTimezone = () => {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timezoneLabel =
        timezones.find((tz) => tz.value === userTimezone)?.value ||
        'Etc/GMT'; /* Always default to UTC */

      setTimezone(timezoneLabel);
    };

    fetchUserTimezone();
  }, []);

  return (
    <OrbySelect
      width='242px'
      height='44px'
      value={timezone}
      disabled={readonly}
      inputProps={{
        sx: {
          '& .MuiSelect-select': {
            padding: '10px 14px',
          },
          height: '24px',
          '& .MuiTypography-root': {
            fontSize: '16px',
          },
        },
      }}
      renderValue={(value) => {
        return (
          <OrbyTypography
            size='sm'
            color={OrbyColorPalette['grey-900']}
            weight={'regular'}
          >
            {value as string}
          </OrbyTypography>
        );
      }}
      onChange={(event) => {
        if (readonly) return;
        setTimezone(event.target.value as string);
      }}
    >
      {timezones.map((tz) => (
        <OrbyMenuItem
          width='auto'
          key={tz.value}
          title={tz.label}
          value={tz.value}
          isSelected={tz.value === timezone}
        />
      ))}
    </OrbySelect>
  );
};

export default SchedulerTimezoneSelector;
