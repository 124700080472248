import {
  Item,
  ReconcileItemsResult,
} from 'protos/pb/v1alpha1/actionprocessing';
import { Field } from 'protos/pb/v1alpha1/field';
import Decimal from 'decimal.js';
import { FieldsUpdate } from './po-reconcile';

export const FalloutReason = {
  // When there is no match, ML should return detailed explanation. This is a fallback just in case.
  NO_MATCH: 'There is no match',
  UNIT_COST_MISMATCH:
    'There is a match, but the PO line has a unit cost, and we cannot update the quantity accordingly to get the amount to match',
  MULTIPLE_MATCHES: 'There are multiple POs matched to one invoice',
  AMOUNT_MISMATCH:
    'There is a match, but the invoice amount is greater than the PO amount',
} as const;

export type FalloutStatus =
  | {
      isFallout: true;
      falloutReason: string;
    }
  | {
      isFallout: false;
    };
/**
 * Source -> JDE1
 * Target -> CAAPS
 *
 * Return an array of the same length as FieldGroupMatches in ReconcileItemsResult.
 * Each item represents whether the fieldGroup is a fallout. If a fallout, it also
 * includes a fallout reason that combines ML result and additional fallout logic.
 */
// Are these covered in the new ML explanation?
export function getFalloutStatus(
  result: ReconcileItemsResult,
  items: Item[],
): FalloutStatus[] {
  if (!result.fieldGroupMatches) {
    return [];
  }
  const falloutStatus: FalloutStatus[] = (result.fieldGroupMatches || []).map(
    (): FalloutStatus => ({ isFallout: false }),
  );
  const jde1ToCappsIndices = new Map<
    number,
    { targetIndex: number; fieldGroupIndex: number }[]
  >();

  const cappsIndexSet = new Set<number>();

  for (const [index, fg] of result.fieldGroupMatches.entries()) {
    if (fg.match) {
      const jde1Index = fg.match.sourceIndex!;
      if (!jde1ToCappsIndices.has(jde1Index)) {
        jde1ToCappsIndices.set(jde1Index, []);
      }
      jde1ToCappsIndices.get(jde1Index)?.push({
        targetIndex: fg.match.targetIndex!,
        fieldGroupIndex: index,
      });

      // If amount/unit-cost is not integer, ML will return an error
      if (fg.match?.updateError) {
        falloutStatus[index] = {
          isFallout: true,
          falloutReason: fg.explanation || FalloutReason.UNIT_COST_MISMATCH,
        };
      }

      // If there is any 1 (invoice) -> N (POs) match, we don't know how to update CAAPS and split amount, so fallout
      if (fg.match.targetIndex !== undefined) {
        if (cappsIndexSet.has(fg.match.targetIndex)) {
          falloutStatus[index] = {
            isFallout: true,
            falloutReason: FalloutReason.MULTIPLE_MATCHES,
          };
        } else {
          cappsIndexSet.add(fg.match.targetIndex);
        }
      }
    }

    // If any invoice not found in JDE1
    if (fg.unmatchedTarget) {
      falloutStatus[index] = {
        isFallout: true,
        falloutReason: fg.explanation || FalloutReason.NO_MATCH,
      };
    }
  }

  for (const [jde1Index, cappsIndices] of jde1ToCappsIndices.entries()) {
    if (cappsIndices.length !== 1) {
      continue;
    }

    // For 1:1 cases, we need to check unit cost and amount
    const result = getJDE1UpdateOrFallout(
      jde1Index,
      [cappsIndices[0].targetIndex],
      items,
    );
    if (result.isFallout) {
      falloutStatus[cappsIndices[0].fieldGroupIndex] = result;
    }
  }

  return falloutStatus;
}

export function getJDE1UpdateOrFallout(
  jde1Index: number,
  cappsIndices: number[],
  items: Item[],
):
  | { isFallout: true; falloutReason: string }
  | { isFallout: false; update: FieldsUpdate } {
  const jde1Item = items[0];
  const cappsItem = items[1];

  const totalAmount = cappsIndices.reduce((acc, cappsIndex) => {
    const cappsFields = cappsItem.fieldGroups![cappsIndex].fields;
    const amountField = cappsFields!.find((f) => f.name === 'line item/amount');
    return acc + parseMoney(amountField?.value?.text || '0');
  }, 0);
  // For 1:1 cases, we need to check unit cost and amount
  // If the PO Amount is greater than Invoice line item amount AND the unit cost is > 0, then see if invoice amount divided by unit cost results in a number with 2 or less decimal places
  // If it does, then update the quantity field in JDE1
  // Else, throw an error to be caught by reconcileItemsAction to fallout
  if (cappsIndices.length === 1) {
    const cappsFields = cappsItem.fieldGroups![cappsIndices[0]].fields;
    const jde1Fields = jde1Item.fieldGroups![jde1Index].fields;
    const caapsAmount = findFieldAndParseMoney(cappsFields, 'line item/amount');
    const jde1Amount = findFieldAndParseMoney(jde1Fields, 'line item/amount');
    const jde1UnitCost = findFieldAndParseMoney(
      jde1Fields,
      'line item/unit cost',
    );
    if (jde1Amount > caapsAmount && jde1UnitCost > 0) {
      if (hasTwoOrFewerDecimalPlaces(totalAmount, jde1UnitCost)) {
        return {
          isFallout: false,
          update: {
            fieldGroupIndex: jde1Index,
            fields: [
              Field.create({
                name: 'line item/quantity',
                value: {
                  text: (totalAmount / jde1UnitCost).toFixed(2).toString(),
                },
              }),
            ],
          },
        };
      } else {
        return {
          isFallout: true,
          falloutReason: FalloutReason.UNIT_COST_MISMATCH,
        };
      }
    } else if (caapsAmount > jde1Amount) {
      return {
        isFallout: true,
        falloutReason: FalloutReason.AMOUNT_MISMATCH,
      };
    }
  }
  return {
    isFallout: false,
    update: {
      fieldGroupIndex: jde1Index,
      fields: [
        Field.create({
          name: 'line item/amount',
          value: { text: totalAmount.toString() },
        }),
      ],
    },
  };
}

function findFieldAndParseMoney(
  fields: Field[] | undefined,
  name: string,
): number {
  if (!fields) {
    throw new Error('Fields are undefined');
  }
  const field = fields.find((f) => f.name === name);
  if (!field) {
    return 0;
  }
  return parseMoney(field.value?.text || '0');
}

function parseMoney(value: string): number {
  return parseFloat(value.replace(/,/g, ''));
}

function hasTwoOrFewerDecimalPlaces(amount: number, unitCost: number): boolean {
  // Perform the division
  const quantity = new Decimal(amount).dividedBy(unitCost);
  if (quantity.decimalPlaces() <= 2) {
    return true;
  }
  return false;
}
