import { composeTaskFiltersAndUpdateNetworkURLV2 } from '../Utils/taskV2Utils';
import {
  DeleteBatchTasksRequest,
  ListTasksRequest,
  Task,
} from 'protos/pb/v1alpha2/tasks_service';
import { User } from 'protos/pb/v1alpha1/user';
import { NavigateFunction } from 'react-router-dom';
import { deleteTaskAction } from '../../redux/actions/taskV2.action';
import {
  DeleteTaskType,
  PAGE_NO,
  PAGE_SIZE,
  ROWS_PER_PAGE,
} from '../../utils/constants';
import { Dispatch } from 'react';
import { WorkflowExecution } from 'protos/pb/v1alpha2/workflow_executions_service';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from 'orby-ui/src/components/table/table-utils';

export const buildRequest = ({
  status,
  user,
  isAdminView,
  navigate,
  selectedWorkflows,
  displayNamePrefix,
  selectedUsers,
  orgResourceName,
  pageNumber,
  pageSize,
}: {
  status: string;
  user: User;
  isAdminView: boolean;
  navigate: NavigateFunction;
  selectedWorkflows: string[];
  displayNamePrefix: string;
  selectedUsers: string[];
  orgResourceName: string;
  pageNumber: number;
  pageSize: number;
}) => {
  const req: ListTasksRequest = ListTasksRequest.fromJSON({
    filter: composeTaskFiltersAndUpdateNetworkURLV2(
      status,
      user?.email as string,
      isAdminView,
      navigate,
      selectedWorkflows,
      displayNamePrefix,
      selectedUsers,
      pageNumber,
      pageSize,
    ),
    pageNumber,
    pageSize,
    parent: orgResourceName,
  });
  return req;
};

export const DEFAULT_WORKFLOW_VALUE = {
  label: 'All workflows',
  value: 'all-workflows',
};

export const ALL_TASK_VALUE = {
  label: 'All users',
  value: 'All tasks',
};

export const ALL_USER_VALUE = {
  label: 'All users',
  value: 'All users',
};

export const ALL_CREATOR_VALUE = {
  label: 'All creators',
  value: 'All creators',
};

export const UNASSIGNED_TASK_VALUE = {
  label: 'Unassigned only',
  value: 'OrbyUnassigned',
};

/**
 * DELETE TASKS
 */
export const handleDeleteTask = (
  deleteReason: string,
  itemsToDelete: Task[] | WorkflowExecution[],
  dispatch: Dispatch<any>,
) => {
  const req = DeleteBatchTasksRequest.create({
    names: itemsToDelete.map((item) => item.name) as string[],
    deletedReason: deleteReason,
  });
  dispatch(
    deleteTaskAction({
      deleteType: DeleteTaskType.PENDING,
      req,
    }),
  );
};

/**
 * CHECK IF ALL THE TASKS ARE SELECTED ON THE CURRENT PAGE
 */
export const areAllTasksSelected = (
  selectedTasks: Task[],
  tasks: Task[],
  page: number,
  rowsPerPage: number,
) => {
  if (selectedTasks.length) {
    const tasksOnCurrentPage = tasks.slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage,
    );
    return tasksOnCurrentPage.every((task) => selectedTasks.includes(task));
  }
  return false;
};

/**
 * Handle Task Page Change
 */
export const handleTaskPageChange = ({
  pageNumber,
  rowsPerPage,
  setPage,
  listTasks,
  setSelectedTasks,
}: {
  pageNumber: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  listTasks: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
  setSelectedTasks: (tasks: Task[]) => void;
}) => {
  setPage(pageNumber);
  listTasks(pageNumber, rowsPerPage, true);
  setSelectedTasks([]);
};

/**
 *  HANDLE ROWS SELECTION CHANGE
 */
export const handleTasksRowSelectionChange = ({
  rowsNumber,
  setPage,
  setRowsPerPage,
  listTasks,
  setSelectedTasks,
}: {
  rowsNumber: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  listTasks: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
  setSelectedTasks: (tasks: Task[]) => void;
}) => {
  setPage(DEFAULT_FIRST_PAGE);
  // Refresh is needed when rows per page is changes to fetch fresh data
  setRowsPerPage(rowsNumber);
  listTasks(DEFAULT_FIRST_PAGE, rowsNumber, true);
  setSelectedTasks([]);
};

export const getTaskNameColumnWidth = (
  tableWidth: number,
  minWidth?: number,
) => {
  // TABLE WIDTH - TIME MODIFIED WIDTH - USERS WIDTH - ACTION Width
  // Subtract 10 pixels to add avoid overflowX auto on normal screen sizes (like desktop)
  const width =
    (tableWidth - getTimeModifiedWidth(tableWidth) - 217 - 69 - 10) / 2;
  if (minWidth) {
    return Math.max(width, minWidth);
  }
  return width;
};

export const getTaskNameColumnWidthCompletedTab = (
  tableWidth: number,
  minWidth?: number,
) => {
  // TABLE WIDTH - TAG WIDTH - TIME MODIFIED WIDTH - USERS WIDTH - ACTION Width
  const width =
    (tableWidth - 109 - getTimeModifiedWidth(tableWidth) - 270 - 69 - 10) / 2;
  if (minWidth) {
    return Math.max(width, minWidth);
  }
  return width;
};

export const getTaskNameColumnWidthDeclinedTab = (
  tableWidth: number,
  minWidth?: number,
) => {
  // TABLE WIDTH - DECLINED REASON WIDTH - TIME MODIFIED WIDTH - USERS WIDTH - ACTION Width
  const width =
    (tableWidth - 218 - getTimeModifiedWidth(tableWidth) - 217 - 69 - 10) / 2;
  if (minWidth) {
    return Math.max(width, minWidth);
  }
  return width;
};

export const getTimeModifiedWidth = (tableWidth: number, minWidth?: number) => {
  const width = tableWidth >= 1272 ? 182 : 139;
  if (minWidth) {
    return Math.max(width, minWidth);
  }
  return width;
};

export const getPageNumber = (urlSearchParams: URLSearchParams) => {
  const pageNumber = urlSearchParams.get(PAGE_NO);
  if (pageNumber === null || !(parseInt(pageNumber) >= 1)) {
    return DEFAULT_FIRST_PAGE;
  } else {
    return parseInt(pageNumber) || DEFAULT_FIRST_PAGE;
  }
};

export const getPageSize = (urlSearchParams: URLSearchParams) => {
  const pageSize = urlSearchParams.get(PAGE_SIZE);
  if (pageSize === null || !ROWS_PER_PAGE.includes(parseInt(pageSize))) {
    return DEFAULT_ROWS_PER_PAGE;
  } else {
    return parseInt(pageSize) || DEFAULT_ROWS_PER_PAGE;
  }
};

/**
 * Get the page number value from filter change and reset to default if any of the following is true
 * @param displayNamePrefix
 * @param selectedWorkflows
 * @param selectedUsers
 * @param page
 * @returns
 */
export const getPageNumberValueFromFilterChange = (
  displayNamePrefix: string,
  selectedWorkflows: string[],
  selectedUsers: string[],
  page: number,
) => {
  if (
    displayNamePrefix ||
    !selectedWorkflows.includes(DEFAULT_WORKFLOW_VALUE.value) ||
    selectedUsers.length
  ) {
    return DEFAULT_FIRST_PAGE;
  }
  return page;
};
