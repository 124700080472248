import React, { useEffect, useRef, useState } from 'react';
import { Box, Dialog } from '@mui/material';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import SchedulerTabs from './components/SchedulerTabs';
import ScheduleOneTime from './components/ScheduleOneTime';
import ScheduleDaily from './components/ScheduleDaily';
import ScheduleWeekly from './components/ScheduleWeekly';
import ScheduleMonthly from './components/ScheduleMonthly';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectedOrgInfoSelector } from '../../../redux/selectors/user.selectors';
import {
  getWorkflowSchedule,
  setWorkflowSchedule,
} from '../../../redux/actions/workflow_details.constants';
import { getWorkflowScheduleSelector } from '../../../redux/selectors/workflow_details.selectors';

export const TAB_OPTIONS = [
  { label: 'One-time', id: 'one-time' },
  { label: 'Daily', id: 'daily' },
  { label: 'Weekly', id: 'weekly' },
  { label: 'Monthly', id: 'monthly' },
];

const WorkflowSchedulerModal = ({
  open,
  onClose,
  editingScheduleId = null,
  refreshDataHandler = () => null,
}: {
  open: boolean;
  onClose: () => void;
  editingScheduleId: string | null;
  refreshDataHandler?: () => void;
}) => {
  const dispatch = useDispatch();
  const boxRef = useRef<HTMLDivElement>(null);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const { workflow_id: workflowId } = useParams<{
    workflow_id: string;
  }>();

  const orgInfo = useSelector(selectedOrgInfoSelector)!;
  const orgId = orgInfo?.orgResourceName!.replace(
    'organizations/',
    '',
  ) as string;
  const editingSchedule = useSelector(getWorkflowScheduleSelector);

  useEffect(() => {
    if (editingScheduleId) {
      // Fetch the schedule details from the API
      dispatch(getWorkflowSchedule({ id: editingScheduleId, orgId }));
    } else {
      dispatch(setWorkflowSchedule(null));
    }
  }, [editingScheduleId]);

  useEffect(() => {
    if (editingSchedule?.id) {
      const { noRecurrence, dailyRecurrence, weeklyRecurrence } =
        editingSchedule?.config?.schedulePattern?.recurrencePattern || {};
      setSelectedTab(
        noRecurrence ? 0 : dailyRecurrence ? 1 : weeklyRecurrence ? 2 : 3,
      );
    } else {
      setSelectedTab(0);
    }
  }, [editingSchedule]);

  if (!workflowId || !orgId) {
    return null;
  }

  return (
    <Dialog
      ref={boxRef}
      PaperProps={{
        style: {
          width: '600px',
          borderRadius: '10px',
          boxShadow: '0px 4px 40px 0px #00000026',
          padding: '24px',
        },
      }}
      open={open}
      onClose={onClose}
    >
      <OrbyTypography size='lg' weight='semibold'>
        Schedule workflow
      </OrbyTypography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '32px',
          gap: '24px',
        }}
      >
        <Box>
          <OrbyTypography
            size='sm'
            color={OrbyColorPalette['grey-700']}
            sx={{ marginBottom: '6px' }}
          >
            Repeat
          </OrbyTypography>

          <SchedulerTabs
            tabs={TAB_OPTIONS}
            selectedTab={selectedTab}
            setSelectedTab={(value) => setSelectedTab(value)}
          />
        </Box>

        {selectedTab === 0 ? (
          <ScheduleOneTime
            handleClose={onClose}
            workflowId={workflowId}
            orgId={orgId}
            refreshDataHandler={refreshDataHandler}
          />
        ) : selectedTab === 1 ? (
          <ScheduleDaily
            handleClose={onClose}
            workflowId={workflowId}
            orgId={orgId}
            refreshDataHandler={refreshDataHandler}
          />
        ) : selectedTab === 2 ? (
          <ScheduleWeekly
            handleClose={onClose}
            workflowId={workflowId}
            orgId={orgId}
            refreshDataHandler={refreshDataHandler}
          />
        ) : selectedTab === 3 ? (
          <ScheduleMonthly
            handleClose={onClose}
            workflowId={workflowId}
            orgId={orgId}
            refreshDataHandler={refreshDataHandler}
          />
        ) : null}
      </Box>
    </Dialog>
  );
};

export default WorkflowSchedulerModal;
