import React from 'react';
import { OrbyBodyLabel, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import {
  Schedule,
  ScheduleConfigRecurrencePattern,
  ScheduleScheduleStatus,
  ScheduleConfigRecurrencePatternDayOfWeek,
} from 'protos/pb/v1alpha1/schedule';
import deleteIcon from '../../../static/icons/delete-outlined.svg';
import editIcon from '../../../static/icons/edit-blue.svg';
import {
  formatListWithConjunction,
  formatTime,
  getOrdinalSuffix,
  getWeekdayNames,
} from './utils/helper';
import CustomSwitch from '../../../components/CustomSwitch';
// import dayjs from 'dayjs';
import {
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import getTimeZones from './utils/timezones';
import { DateMessage } from 'protos/google/type/date';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: OrbyColorPalette['white-0'],
    color: OrbyColorPalette['black-0'],
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 'normal',
  },
  '& .MuiTooltip-arrow': {
    color: OrbyColorPalette['white-0'],
  },
  '& .MuiTooltip-arrow::before': {
    boxShadow: '1px 1px 1px 0px #d4d2d2',
  },
}));

export const getScheduleCell = (schedule: { title: string }): JSX.Element => {
  return (
    <OrbyBodyLabel
      label={schedule.title}
      color={OrbyColorPalette['black-0']}
      fontWeight={'regular'}
      fontSize={'sm'}
    />
  );
};

const formatDateMessage = (dateMessage: DateMessage | undefined): string => {
  if (!dateMessage) {
    return '';
  }

  const { year, month, day } = dateMessage;

  if (!year || !month || !day) {
    return '';
  }

  // Ensure month and day are padded with leading zeros if necessary
  const formattedMonth = month.toString().padStart(2, '0');
  const formattedDay = day.toString().padStart(2, '0');

  return `${formattedMonth}/${formattedDay}/${year}`;
};

export const getScheduleDateRangeCell = (schedule: Schedule): JSX.Element => {
  return (
    <OrbyTypography
      color={OrbyColorPalette['black-0']}
      size='sm'
      sx={{ fontWeight: 'medium', letterSpacing: '1px' }}
    >
      {`${formatDateMessage(schedule.startDate)}${schedule.endDate ? ` - ${formatDateMessage(schedule.endDate)}` : ''}`}
    </OrbyTypography>
  );
};

const getRecurrencePattern = (
  recurrencePattern: ScheduleConfigRecurrencePattern | undefined,
): string => {
  if (recurrencePattern?.noRecurrence) {
    return 'One-time';
  }
  if (recurrencePattern?.dailyRecurrence) {
    return 'Daily';
  }
  if (recurrencePattern?.weeklyRecurrence) {
    return 'Weekly';
  }
  if (recurrencePattern?.monthlyRecurrence) {
    return 'Monthly';
  }
  return '';
};

export const getScheduleRecurrenceCell = (schedule: Schedule): JSX.Element => {
  const recurrencePattern = schedule.config?.schedulePattern?.recurrencePattern;

  let uniqueDayOfWeek: ScheduleConfigRecurrencePatternDayOfWeek[] = [],
    uniqueWeekOfMonth: any[] = [];

  if (recurrencePattern?.monthlyRecurrence) {
    recurrencePattern?.monthlyRecurrence?.dayOfWeekPattern?.map((pattern) => {
      uniqueDayOfWeek.push(pattern?.dayOfWeek || 0);
      uniqueWeekOfMonth.push(pattern?.weekOfMonth);
    });

    uniqueDayOfWeek = [...new Set(uniqueDayOfWeek)];
    uniqueWeekOfMonth = [...new Set(uniqueWeekOfMonth)];
  }

  return (
    <>
      <OrbyTypography color={OrbyColorPalette['black-0']} size='sm'>
        {`${getRecurrencePattern(recurrencePattern)}`}
      </OrbyTypography>
      {recurrencePattern?.weeklyRecurrence && (
        <OrbyTypography color={OrbyColorPalette['grey-500']} size='sm'>
          Repeat on{' '}
          {getWeekdayNames(
            schedule.config?.schedulePattern?.recurrencePattern
              ?.weeklyRecurrence?.daysOfWeek || [],
          ).join(', ')}
        </OrbyTypography>
      )}

      {recurrencePattern?.monthlyRecurrence && (
        <OrbyTypography color={OrbyColorPalette['grey-500']} size='sm'>
          Repeat on the{' '}
          {formatListWithConjunction(
            uniqueWeekOfMonth.map((week) => getOrdinalSuffix(+week)),
            ', ',
            ' & ',
          )}{' '}
          {formatListWithConjunction(
            getWeekdayNames(uniqueDayOfWeek.sort((a, b) => a - b)),
            ', ',
            ' & ',
          )}{' '}
          of every month
        </OrbyTypography>
      )}
    </>
  );
};

export const getScheduleTimeCell = (schedule: Schedule): JSX.Element => {
  const runTimes =
    schedule.config?.schedulePattern?.runTimes?.map((time) =>
      formatTime(
        time.hours?.toString() || '0',
        time.minutes?.toString() || '0',
      ),
    ) || [];
  const timezones = getTimeZones();
  const timezoneLabel = timezones.find(
    (tz) => tz.value === schedule.timezoneName,
  )?.label;
  if (timezoneLabel) {
    runTimes?.push(timezoneLabel);
  }

  const runTimesString = runTimes!.join(', ');

  const runTimesComponent = () => (
    <p
      style={{
        fontSize: '14px',
        fontWeight: 'normal',
        color: OrbyColorPalette['black-0'],
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        marginBottom: '0px',
      }}
    >
      {runTimesString.slice(0, 25)} {runTimesString.length > 25 ? '...' : ''}
    </p>
  );

  return runTimesString.length > 25 ? (
    <LightTooltip title={runTimesString} arrow placement='top'>
      {runTimesComponent()}
    </LightTooltip>
  ) : (
    runTimesComponent()
  );
};

export const getScheduleEnableCell = (
  schedule: Schedule,
  handleClick: () => void,
): JSX.Element => {
  return (
    <CustomSwitch
      size='small'
      color='primary'
      checked={schedule.status === ScheduleScheduleStatus.ACTIVE}
      onChange={handleClick}
      // TODO: use Start Date and End date
      // disabled={
      //   dayjs(schedule.startTime).isBefore(dayjs().startOf('day')) &&
      //   (schedule?.endTime
      //     ? dayjs(schedule.endTime).isBefore(dayjs().startOf('day'))
      //     : true)
      // }
    />
  );
};

export const getScheduleDeleteCell = (handleClick: () => void): JSX.Element => {
  return (
    <IconButton onClick={handleClick}>
      <img src={deleteIcon} alt='Delete' />
    </IconButton>
  );
};

export const getScheduleEditCell = (handleClick: () => void): JSX.Element => {
  return (
    <IconButton onClick={handleClick}>
      <img src={editIcon} alt='Edit' />
    </IconButton>
  );
};

/**
 * Handle Schedule Page Change
 */
export const handleSchedulePageChange = ({
  schedules,
  pageNumber,
  page,
  rowsPerPage,
  setPage,
  fetchSchedules,
}: {
  schedules: Schedule[];
  pageNumber: number;
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  fetchSchedules: (pageNumber: number, rowsPerPage: number) => void;
}) => {
  setPage(pageNumber);
  if (pageNumber >= page && schedules.length <= pageNumber * rowsPerPage) {
    fetchSchedules(pageNumber + 1, rowsPerPage);
  }
};

/**
 *  HANDLE ROWS SELECTION CHANGE
 */
export const handleSchedulesRowSelectionChange = ({
  rowsNumber,
  setPage,
  setRowsPerPage,
  fetchSchedules,
}: {
  rowsNumber: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  fetchSchedules: (
    pageNumber: number,
    rowsPerPage: number,
    reset?: boolean,
  ) => void;
}) => {
  setPage(0);
  // Refresh is needed when rows per page is changes to fetch fresh data
  setRowsPerPage(rowsNumber);
  fetchSchedules(1, rowsNumber, true);
};
