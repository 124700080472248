import dayjs from 'dayjs';
import { DateMessage } from 'protos/google/type/date';
import { ScheduleConfigRecurrencePatternDayOfWeek } from 'protos/pb/v1alpha1/schedule';

export const VALIDATION_ERROR_MESSAGE = {
  TIME: 'Please add at least one time',
  ONE_TIME_ALLOWED: 'Only one time is allowed',
  DATE_REQUIRED: 'Please input / select',
  DATE_INVALID: 'Invalid date',
  REPEAT_ON: 'Please select',
  WEEKDAYS: 'Please select',
  START_DATE_GREATER_THAN_END_DATE: 'Start date must be before end date',
};

export const WEEKDAYS = [
  { title: 'Sunday', value: ScheduleConfigRecurrencePatternDayOfWeek.SUNDAY },
  { title: 'Monday', value: ScheduleConfigRecurrencePatternDayOfWeek.MONDAY },
  { title: 'Tuesday', value: ScheduleConfigRecurrencePatternDayOfWeek.TUESDAY },
  {
    title: 'Wednesday',
    value: ScheduleConfigRecurrencePatternDayOfWeek.WEDNESDAY,
  },
  {
    title: 'Thursday',
    value: ScheduleConfigRecurrencePatternDayOfWeek.THURSDAY,
  },
  { title: 'Friday', value: ScheduleConfigRecurrencePatternDayOfWeek.FRIDAY },
  {
    title: 'Saturday',
    value: ScheduleConfigRecurrencePatternDayOfWeek.SATURDAY,
  },
];

// Modify validateTime function
export const validateTime = ({
  hours,
  minutes,
}: {
  hours?: string;
  minutes?: string;
}): boolean => {
  if (!hours || !minutes) {
    return false;
  }
  const h = parseInt(hours, 10);
  const m = parseInt(minutes, 10);
  return h >= 0 && h <= 23 && m >= 0 && m <= 59;
};

// Validate the start date input
export const validateStartDate = (date: DateMessage | null) => {
  if (!date || !date.year || !date.month || !date.day) {
    return VALIDATION_ERROR_MESSAGE.DATE_REQUIRED;
  }
  const jsDate = new Date(date.year, date.month - 1, date.day);

  if (
    !dayjs(jsDate).isValid() ||
    dayjs(jsDate).isBefore(dayjs().startOf('day')) ||
    dayjs(jsDate).isAfter(dayjs().add(100, 'year'))
  ) {
    return VALIDATION_ERROR_MESSAGE.DATE_INVALID;
  } else {
    return '';
  }
};

// Validate the end date input
export const validateEndDate = (date: DateMessage | null) => {
  if (!date) {
    return '';
  }

  if (date && (!date.year || !date.month || !date.day)) {
    return VALIDATION_ERROR_MESSAGE.DATE_INVALID;
  }

  const jsDate = new Date(date.year!, date.month! - 1, date.day);

  if (
    jsDate &&
    (!dayjs(jsDate).isValid() ||
      dayjs(jsDate).isBefore(dayjs()) ||
      dayjs(jsDate).isAfter(dayjs().add(100, 'year')))
  ) {
    return VALIDATION_ERROR_MESSAGE.DATE_INVALID;
  } else {
    return '';
  }
};

// Validate the weekdays input
export const validateWeekdays = (weekdays: string[]) => {
  if (weekdays.length === 0) {
    return VALIDATION_ERROR_MESSAGE.WEEKDAYS;
  }
  return '';
};

export const formatTime = (hours: string, minutes: string) => {
  const h = parseInt(hours, 10);
  const ampm = h >= 12 ? 'pm' : 'am';
  const formattedHours = h % 12 || 12;
  return `${formattedHours}:${minutes.padStart(2, '0')}${ampm}`;
};

export const getWeekdayNames = (
  weekdayEnums: ScheduleConfigRecurrencePatternDayOfWeek[],
): string[] => {
  return weekdayEnums
    .map(
      (dayEnum) => WEEKDAYS.find((day) => day.value === dayEnum)?.title || '',
    )
    .filter(Boolean);
};

// Adds the appropriate ordinal suffix (st, nd, rd, th) to a given number
export const getOrdinalSuffix = (num: number): string => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const value = num % 100;
  return num + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
};

// Formats a list of items with a primary and secondary conjunction
// We are using this to format the weekdays and weeks of the month
export const formatListWithConjunction = (
  list: any[],
  primaryConjunction: string,
  secondaryConjunction: string,
): string => {
  if (list.length === 0) {
    return '';
  } else if (list.length === 1) {
    return `${list[0]}`;
  }

  return `${list.slice(0, -1).join(primaryConjunction)} ${secondaryConjunction} ${list.slice(-1)}`;
};

export const convertDateMessageToDate = (date?: DateMessage | null) => {
  if (!date || !date.year || !date.month || !date.day) {
    return null;
  }

  return dayjs(new Date(date.year, date.month - 1, date.day));
};
