import { Typography, List, ListItem, Paper, Button, Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useIdleTimer, DEFAULT_EVENTS } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import {
  recordIdleSessionAction,
  reviewStartTimeAction,
} from '../../../../redux/actions/review_task.action';
import { showActivityMonitorSelector } from '../../../../redux/selectors/taskV2.selectors';
import {
  OFF_SCREEN_TASK_REVIEW_ACTIVITY_TIMEOUT,
  ON_SCREEN_TASK_REVIEW_ACTIVITY_TIMEOUT,
  TimeRange,
} from '../../../../utils/constants';
import { reviewStartTimeSelector } from '../../../../redux/selectors/review_task.selectors';

const ActivityMonitor: React.FC = () => {
  const dispatch = useDispatch();
  const showActivityMonitor = useSelector(showActivityMonitorSelector);
  const reviewStartTime = useSelector(reviewStartTimeSelector);
  const [idleTime, setIdleTime] = useState(0);

  // This state is used only for display purposes within this component.
  // The accurate value is maintained in the Redux store.
  // Additionally, this local state can be reset for easy testing and debugging
  // without affecting the actual idle sessions stored in the Redux store.
  const [idleSessions, setIdleSessions] = React.useState<TimeRange[]>([]);

  const [isActive, setIsActive] = useState(true);
  const offScreenTimeout = useRef<NodeJS.Timeout | null>(null);
  const idleStartTime = useRef<number | null>(null);

  const onIdle = () => {
    if (offScreenTimeout.current) {
      clearTimeout(offScreenTimeout.current);
    }
    // if already idle do nothing
    if (!isActive) {
      return;
    }

    const startIdleSession = (timeout: number) => {
      setIsActive(false);
      // Subtracting the timeout time from the current time to set the idle start time accurately.
      // This accounts for the delay introduced by the timeout, ensuring that the idle period
      // reflects the actual time the user became idle, not just when the timeout expired.
      idleStartTime.current = Date.now() - timeout;
    };
    // Delay starting the idle session when the document becomes hidden:
    // - Provides a grace period for brief interruptions
    // - Reduces false positives in idle time tracking
    // - Allows for short periods of inactivity without triggering idle state
    if (document.hidden) {
      // wait for 5 seconds before marking the person as idle
      offScreenTimeout.current = setTimeout(
        () => startIdleSession(OFF_SCREEN_TASK_REVIEW_ACTIVITY_TIMEOUT),
        OFF_SCREEN_TASK_REVIEW_ACTIVITY_TIMEOUT,
      );
    } else {
      startIdleSession(ON_SCREEN_TASK_REVIEW_ACTIVITY_TIMEOUT);
    }
  };

  const onAction = (event: Event | undefined) => {
    // Clear the offScreenTimeout if it exists
    // This is necessary to prevent an unnecessary transition to idle state
    // when the document becomes visible again ('visibilitychange' event) after being obscured
    // and has not been marked as idle yet. This ensures that brief
    // interruptions (like quick tab switches) don't trigger idle state.
    if (offScreenTimeout.current) {
      clearTimeout(offScreenTimeout.current);
    }
    if (isActive || (event && ['visibilitychange'].includes(event.type))) {
      return;
    }
    // Only mark as active when the user performs an action after being idle
    // This prevents false active states when the user returns to the page without interacting
    setIsActive(true);
    if (idleStartTime.current) {
      const idleEnd = Date.now();
      const idleDuration = idleEnd - idleStartTime.current;
      setIdleTime((prevIdleTime) => prevIdleTime + idleDuration);
      const idleSession = { start: idleStartTime.current, end: idleEnd };
      dispatch(recordIdleSessionAction(idleSession));

      // Update idle sessions state for display purposes
      setIdleSessions((prev) => [...prev, idleSession]);
      idleStartTime.current = null;
    }
  };

  useIdleTimer({
    onAction,
    onIdle,
    events: DEFAULT_EVENTS.filter((event) => event !== 'focus'),
    immediateEvents: ['visibilitychange'],
    timeout: ON_SCREEN_TASK_REVIEW_ACTIVITY_TIMEOUT,
    throttle: 1000,
  });

  useEffect(() => {
    // init the reviewStartTime
    dispatch(reviewStartTimeAction());
  }, []);

  const formatSessions = idleSessions.map((session) => ({
    start: new Date(session.start).toLocaleString(),
    end: new Date(session.end).toLocaleString(),
  }));

  return showActivityMonitor ? (
    // Record the activity, but only display the activity monitor UI if the feature is enabled.
    // This UI is intended solely for debugging and testing purposes.
    // If the feature is not enabled, the activity is still recorded without showing the UI.
    <Paper
      elevation={3}
      sx={{
        position: 'absolute',
        width: '300px', // Increased width for better readability
        zIndex: 100,
        backgroundColor: 'white',
        borderRadius: '10px',
        bottom: 20,
        right: 20,
        padding: 2,
        maxHeight: '400px', // Increased max height
      }}
    >
      <Typography variant='h6' gutterBottom>
        Activity Summary
      </Typography>
      <Typography variant='body1' gutterBottom>
        Task review started at:{' '}
        {reviewStartTime ? new Date(reviewStartTime).toLocaleString() : 'N/A'}
      </Typography>
      <Typography variant='body1' gutterBottom>
        Current Status: {isActive ? 'Active' : 'Idle'}
      </Typography>
      <Typography variant='body1' gutterBottom>
        Total Idle Time: {Math.floor(idleTime / 1000)} seconds
      </Typography>
      <Typography variant='subtitle1' gutterBottom sx={{ mt: 2 }}>
        Idle Sessions:
      </Typography>
      <Box sx={{ maxHeight: '150px', overflow: 'scroll' }}>
        <List dense sx={{ bgcolor: '#f5f5f5', borderRadius: '4px', p: 1 }}>
          {formatSessions.map((session, index) => (
            <ListItem
              key={index}
              sx={{
                py: 1,
                px: 2,
                mb: 1,
                bgcolor: 'white',
                borderRadius: '4px',
                boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
              }}
            >
              <Typography variant='body2' sx={{ fontWeight: 'bold', mr: 1 }}>
                {`${index + 1}.`}
              </Typography>
              <Typography variant='body2' sx={{ flexGrow: 1 }}>
                <span style={{ color: '#1976d2' }}>{session.start}</span>
                {' - '}
                <span
                  style={{
                    color: '#2e7d32',
                  }}
                >
                  {session.end}
                </span>
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>
      <Button
        variant='contained'
        color='secondary'
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => {
          // Resetting the displayed activity summary for debugging/testing purposes.
          // This does not affect the actual idle sessions stored in the Redux store.
          setIdleTime(0);
          setIdleSessions([]);
        }}
      >
        Reset Activity
      </Button>
    </Paper>
  ) : (
    <></>
  );
};

export default React.memo(ActivityMonitor);
