import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';

import { getFontSize } from 'orby-ui/src/components/typography/typography-utils';
import {
  OrbyButton,
  OrbyColorPalette,
  OrbyTabs,
  OrbyTextField,
  OrbyTypography,
} from 'orby-ui/src';

import PendingTasksTab from './tabs/pending/PendingTasksTab';
import { isAdmin } from '../../utils/helpers';
import {
  DISPLAY_NAME_PREFIX,
  DRAWER_WIDTH_COLLAPSED,
  DRAWER_WIDTH_EXPANDED,
  SELECTED_TAB,
  TASKS_TAB_INDEX,
  WORKFLOW_RESOURCE_NAMES,
  FEATURE_FLAGS,
  REFRESH_BLOCKED_EXECUTION_INTERVAL_MS,
  PAGE_NO,
  PAGE_SIZE,
} from '../../utils/constants';
import {
  getFilterValuesFromUrlV2,
  getWorkflowIdsFromParamStringV2,
} from '../Utils/taskV2Utils';
import WorkflowFilter from './component/WorkflowFilter';
import UserFilter from './component/UserFilter';
import { useDispatch, useSelector } from 'react-redux';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
  userPermissionsSelector,
} from '../../redux/selectors/user.selectors';
import CompletedTasksTab from './tabs/completed/CompletedTasksTab';
import DeclinedTasksTab from './tabs/declined/DeclinedTasksTab';
import AddTaskModal from '../../pages/PendingTasks/AddTaskModal';

import { ReactComponent as AddButtonIcon } from '../../static/icons/add-button-plus.svg';
import { useIsOrgChanged } from '../../hooks/useIsOrgChanged';
import { DEFAULT_WORKFLOW_VALUE } from './TaskHelpers';
import { useNavigate } from 'react-router-dom';
import { getFeatureFlagsForOrgAndUserSelector } from '../../redux/selectors/feature_flags.selectors';
import { isFeatureFlagEnabled } from '../FeatureFlags/FeatureFlagUtils';
import BlockedExecutionsAlert from './component/BlockedExecutionsAlert';
import {
  blockedWorkflowExecutionsCountSelector,
  blockedWorkflowResourceNamesSelector,
} from '../../redux/selectors/taskV2.selectors';
import { GetBlockedWorkflowExecutionStatisticsRequest } from 'protos/pb/v1alpha2/workflow_executions_service';
import { getBlockedWorkflowExecutionStatisticsAction } from '../../redux/actions/taskV2.action';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from 'orby-ui/src/components/table/table-utils';

interface Props {
  sideDrawerCollapse: boolean;
}

const Tasks: React.FC<Props> = ({ sideDrawerCollapse }) => {
  const urlSearchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const isOrgChanged = useIsOrgChanged(
    selectedOrgInfo?.orgResourceName ||
      (localStorage.getItem('org-resource-name') as string),
  );
  const user = useSelector(loggedInUserSelector);
  const isAdminView = isAdmin(selectedOrgInfo?.role);

  // rbac enabled
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const isRbacEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.RBAC, featureFlags);
  // user permissions
  const userPermissions = useSelector(userPermissionsSelector);

  const isOrgAdminOrWorkflowAdmin =
    isAdminView || (isRbacEnabled && userPermissions.listWorkflows);

  // after rbac enabled, reviewers are not supposed to add tasks
  const canAddTask = !isRbacEnabled || userPermissions.listWorkflows;

  const [selectedTab, setSelectedTab] = useState(
    parseInt(
      urlSearchParams.get(SELECTED_TAB) || `${TASKS_TAB_INDEX.PENDING_TAB}`,
    ),
  );
  const [isAddTaskModelOpen, setIsAddTaskModelOpen] = useState(false);

  // USED FOR SEARCHING TASK BY NAME
  const [displayNamePrefix, setDisplayNamePrefix] = useState(
    urlSearchParams.get(DISPLAY_NAME_PREFIX) ?? '',
  );
  // WORKFLOW FILTER
  const [selectedWorkflows, setSelectedWorkflows] = useState(
    getWorkflowIdsFromParamStringV2(
      urlSearchParams.get(WORKFLOW_RESOURCE_NAMES) ?? '',
    ),
  );
  // TASK FILTER BY USER
  const [selectedUsers, setSelectedUsers] = useState<Array<string>>(
    getFilterValuesFromUrlV2(urlSearchParams),
  );

  // ALERT BANNER FOR BLOCKED EXECUTIONS
  const alertCloseTimeKeyPrefix = 'blocked-executions-alert-close-time-';
  const [openBlockedExecutionsAlert, setOpenBlockedExecutionsAlert] =
    useState(false);
  const blockedWorkflowExecutionsCount = useSelector(
    blockedWorkflowExecutionsCountSelector,
  );
  const blockedWorkflowResourceNames = useSelector(
    blockedWorkflowResourceNamesSelector,
  );
  // used for auto refresh blocked executions
  const refreshBlockedExecutionTimerIdRef = React.useRef<NodeJS.Timeout | null>(
    null,
  );

  const handleCloseBlockedExecutionsAlert = () => {
    localStorage.setItem(
      alertCloseTimeKeyPrefix + selectedOrgInfo?.orgResourceName,
      new Date().toISOString(),
    );
    setOpenBlockedExecutionsAlert(false);
    if (refreshBlockedExecutionTimerIdRef.current) {
      clearInterval(refreshBlockedExecutionTimerIdRef.current);
      refreshBlockedExecutionTimerIdRef.current = null;
    }
  };
  const handleReviewTasksToUnblockExecutions = () => {
    setDisplayNamePrefix('');
    setSelectedUsers([]);
    setSelectedTab(TASKS_TAB_INDEX.PENDING_TAB);
    // get first 200 blocked workflows to avoid url
    // length exceeding 8192 characters
    setSelectedWorkflows(
      blockedWorkflowResourceNames.length <= 200
        ? blockedWorkflowResourceNames
        : blockedWorkflowResourceNames.slice(0, 200),
    );
  };
  const isAlertClosedByUserToday = () => {
    const closeTimeStr = localStorage.getItem(
      alertCloseTimeKeyPrefix + selectedOrgInfo?.orgResourceName,
    );
    if (!closeTimeStr) {
      return false;
    }
    const closeDate = new Date(closeTimeStr);
    const currentDate = new Date();

    return (
      closeDate.getFullYear() === currentDate.getFullYear() &&
      closeDate.getMonth() === currentDate.getMonth() &&
      closeDate.getDate() === currentDate.getDate()
    );
  };

  // used for stopping auto refresh when page is not visible
  const [isPageVisible, setIsPageVisible] = useState(true);

  // register visibility change event listener
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsPageVisible(!document.hidden);
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // reset open alert state when page is loaded
  useEffect(() => {
    if (isAlertClosedByUserToday()) {
      return;
    }
    setOpenBlockedExecutionsAlert(true);
  }, []);

  // set auto refresh timer
  const refreshBlockedExecutionsAlert = () => {
    const req = GetBlockedWorkflowExecutionStatisticsRequest.create({
      parent: selectedOrgInfo?.orgResourceName,
    });
    dispatch(getBlockedWorkflowExecutionStatisticsAction(req));
  };
  useEffect(() => {
    if (isPageVisible && openBlockedExecutionsAlert) {
      refreshBlockedExecutionsAlert();
      refreshBlockedExecutionTimerIdRef.current = setInterval(
        refreshBlockedExecutionsAlert,
        REFRESH_BLOCKED_EXECUTION_INTERVAL_MS,
      );
    } else if (refreshBlockedExecutionTimerIdRef.current) {
      clearInterval(refreshBlockedExecutionTimerIdRef.current);
      refreshBlockedExecutionTimerIdRef.current = null;
    }
    return () => {
      if (refreshBlockedExecutionTimerIdRef.current) {
        clearInterval(refreshBlockedExecutionTimerIdRef.current);
        refreshBlockedExecutionTimerIdRef.current = null;
      }
    };
  }, [isPageVisible, openBlockedExecutionsAlert]);

  /**
   * GET WIDTH OF THE TABLE
   */
  const getTableWidth = () => {
    const sideNavWidth = sideDrawerCollapse
      ? DRAWER_WIDTH_COLLAPSED
      : DRAWER_WIDTH_EXPANDED;
    const PADDING = 48 * 2;

    return window.innerWidth - sideNavWidth - PADDING;
  };

  /**
   * HANDLE TAB CHANGE
   */
  const handleTabChange = (tabIndex: number) => {
    const existingParams = new URLSearchParams(location.search);
    if (existingParams.has(SELECTED_TAB)) {
      existingParams.delete(SELECTED_TAB);
    }
    if (existingParams.has(PAGE_NO)) {
      existingParams.delete(PAGE_NO);
    }
    if (existingParams.has(PAGE_SIZE)) {
      existingParams.delete(PAGE_SIZE);
    }
    existingParams.append(SELECTED_TAB, tabIndex.toString());
    existingParams.append(PAGE_NO, DEFAULT_FIRST_PAGE.toString());
    existingParams.append(PAGE_SIZE, DEFAULT_ROWS_PER_PAGE.toString());
    navigate({ search: existingParams.toString() });
    setSelectedTab(tabIndex);
  };

  const tabs = [
    {
      id: 'pending-task-tab',
      label: 'Pending Review',
      children: (
        <PendingTasksTab
          displayNamePrefix={displayNamePrefix}
          selectedWorkflows={selectedWorkflows}
          selectedUsers={selectedUsers}
          isAdminView={isOrgAdminOrWorkflowAdmin}
          tableWidth={getTableWidth()}
        />
      ),
    },
    {
      id: 'completed-task-tab',
      label: 'Completed',
      children: (
        <CompletedTasksTab
          selectedWorkflows={selectedWorkflows}
          displayNamePrefix={displayNamePrefix}
          selectedUsers={selectedUsers}
          isAdminView={isOrgAdminOrWorkflowAdmin}
          tableWidth={getTableWidth()}
        />
      ),
    },
    {
      id: 'declined-task-tab',
      label: 'Declined',
      children: (
        <DeclinedTasksTab
          selectedWorkflows={selectedWorkflows}
          displayNamePrefix={displayNamePrefix}
          selectedUsers={selectedUsers}
          isAdminView={isOrgAdminOrWorkflowAdmin}
          tableWidth={getTableWidth()}
        />
      ),
    },
  ];

  useEffect(() => {
    if (isOrgChanged.isOrgChanged) {
      setSelectedWorkflows([DEFAULT_WORKFLOW_VALUE.value]);
      setDisplayNamePrefix('');
      setSelectedUsers([]);
    }
  }, [isOrgChanged]);

  return (
    <>
      <Box paddingX={'48px'} paddingY={'48px'}>
        {/* PAGE HEADER */}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          width={'100%'}
          paddingBottom={'10px'}
        >
          <Box>
            <OrbyTypography size={'display-sm'} weight={'medium'}>
              Tasks
            </OrbyTypography>
          </Box>
          {/* ADD TASK */}
          {canAddTask && (
            <Box>
              <OrbyButton
                onClick={() => setIsAddTaskModelOpen(true)}
                ariaLabel='Add Task'
                size='large'
                variant='primary-contained'
                label='Add Task'
                startIcon={
                  <AddButtonIcon
                    style={{ fontSize: `${getFontSize('md')}!important` }}
                  />
                }
              />
            </Box>
          )}
        </Box>

        {/* ALERT BANNER FOR BLOCKED EXECUTIONS */}
        <BlockedExecutionsAlert
          open={
            openBlockedExecutionsAlert && blockedWorkflowExecutionsCount > 0
          }
          numberOfBlockedExecutions={blockedWorkflowExecutionsCount}
          handleClose={handleCloseBlockedExecutionsAlert}
          handleClickReviewTasks={handleReviewTasksToUnblockExecutions}
        />

        {/* TASK FILTER */}
        <Box
          position={'sticky'}
          top={0}
          bgcolor={OrbyColorPalette['white-0']}
          zIndex={1}
          paddingTop={'14px'}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'flex-start'}
            width={'100%'}
            gap={'8px'}
            marginBottom={'24px'}
          >
            {/* SEARCH TASK BY NAME */}
            <Box>
              <OrbyTextField
                width={'240px'}
                fontSize='14px'
                tabIndex={0}
                value={displayNamePrefix}
                name={'search-task-name'}
                disabled={false}
                placeholder='Search by task name'
                startAdornment={<GridSearchIcon fontSize='medium' />}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setDisplayNamePrefix(event.target.value)
                }
              />
            </Box>
            {/* FILTER WORKFLOWS */}
            <Box>
              <WorkflowFilter
                selectedWorkflows={selectedWorkflows}
                setSelectedWorkflows={setSelectedWorkflows}
              />
            </Box>
            {/* FILTER TASKS BY USER */}
            <Box>
              <UserFilter
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                user={user!}
                isAdminView={isAdminView}
              />
            </Box>
          </Box>
          <OrbyTabs
            selectedTab={selectedTab}
            setSelectedTab={(tabIndex: number) => handleTabChange(tabIndex)}
            tabs={tabs}
          />
        </Box>

        {/* ADD TASK MODEL */}
        <AddTaskModal
          open={isAddTaskModelOpen}
          setOpen={setIsAddTaskModelOpen}
        />

        {/* TASK TAB PANELS */}
        <Box>
          {tabs.map((tab, index) => (
            <div
              key={tab.id}
              role='tabpanel'
              hidden={selectedTab !== index}
              id={`tabpanel-${tab.id}`}
              aria-labelledby={`tab-${tab.id}`}
            >
              {selectedTab === index && (
                <Box sx={{ paddingTop: '12px' }}>{tab.children}</Box>
              )}
            </div>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default React.memo(Tasks);
