import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrbyHeaderLabel, OrbyTable } from 'orby-ui/src';
import {
  uiExecutionHistoryListSelector,
  uiExecutionHistoryLoadingSelector,
  uiExecutionHistoryTotalSizeSelector,
} from '../../../../redux/selectors/taskV2.selectors';
import {
  buildUiRequest,
  handleExecutionsRowSelectionChange,
} from '../../ExecutionHelpers';
import { selectedOrgInfoSelector } from '../../../../redux/selectors/user.selectors';
import { useNavigate } from 'react-router-dom';
import { listUiExecutionHistoryAction } from '../../../../redux/actions/taskV2.action';
import {
  EXECUTION_MENU_ACTION_TYPE,
  getExecutionActionItem,
  getExecutionActionMenuCell,
  getExecutionCell,
  getExecutionNameColumnWidth,
  getExecutionRanByCell,
  getExecutionStatusCell,
  getExecutionTimeCell,
  getExecutionTimeWidth,
  getExecutionWorkflowDisplayNameCell,
  handleUiExecutionPageChange,
} from './ui-table-body-helpers';
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from 'orby-ui/src/components/table/table-utils';
import { WorkflowTask } from 'protos/pb/v1alpha1/orbot_workflow';
import { CancelExecutionRequest } from 'protos/pb/v1alpha1/orbot_service';
import {
  cancelChildExecutionsAction,
  cancelExecutionAction,
  clearCancelExecutionAction,
} from '../../../../redux/actions/orbot.action';
import {
  cancelExecutionErrorSelector,
  cancelExecutionLoadingSelector,
  cancelExecutionSuccessSelector,
} from '../../../../redux/selectors/orbot.selectors';
import { toastService } from '../../../../services/ToastService';

export interface Props {
  displayNamePrefix: string;
  selectedWorkflows: Array<string>;
  uiExecutionStatusFilters: Array<string>;
  selectedUsers: Array<string>;
  isAdminView: boolean;
  tableWidth: number;
}

const UiAutomationsTab: React.FC<Props> = ({
  displayNamePrefix,
  selectedWorkflows,
  uiExecutionStatusFilters,
  selectedUsers,
  tableWidth,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const executions: WorkflowTask[] = useSelector(
    uiExecutionHistoryListSelector,
  );

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const totalSize = useSelector(uiExecutionHistoryTotalSizeSelector) ?? 0;
  const tasksLoading = useSelector(uiExecutionHistoryLoadingSelector);

  const [page, setPage] = useState(DEFAULT_FIRST_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [selectedExecution, setSelectedExecution] =
    useState<WorkflowTask | null>(null);

  const [actionMenuAnchorEl, setActionMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const pagedExecutions = executions.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage,
  );

  const cancelExecutionLoading = useSelector(cancelExecutionLoadingSelector);
  const cancelExecutionError = useSelector(cancelExecutionErrorSelector);
  const cancelExecutionSuccessfully = useSelector(
    cancelExecutionSuccessSelector,
  );

  /**
   * ACTION MENU
   */
  const actionMenuOpen = Boolean(actionMenuAnchorEl);
  const handleActionMenuClick = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    execution: WorkflowTask,
  ) => {
    setSelectedExecution(execution);
    setActionMenuAnchorEl(event.currentTarget);
  };

  /**
   * HANDLE ACTION MENU CLOSE
   */
  const handleActionMenuClose = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type?: string,
  ) => {
    event.stopPropagation();
    if (type && selectedExecution) {
      if (type === EXECUTION_MENU_ACTION_TYPE.CANCEL) {
        cancelExecution(selectedExecution);
      } else if (type === EXECUTION_MENU_ACTION_TYPE.CANCEL_CHILD) {
        cancelChildExecutions(selectedExecution);
      }
    }
    setActionMenuAnchorEl(null);
  };

  useEffect(() => {
    if (cancelExecutionSuccessfully) {
      toastService.showSuccess('Execution will be cancelled', {
        position: 'top-right',
      });
    }
    dispatch(clearCancelExecutionAction());
    refreshPage();
  }, [cancelExecutionSuccessfully]);

  useEffect(() => {
    if (typeof cancelExecutionError === 'string') {
      toastService.showError(cancelExecutionError, {
        position: 'top-right',
      });
    }
    dispatch(clearCancelExecutionAction());
    refreshPage();
  }, [cancelExecutionError]);

  /**
   * Cancel Execution
   */
  const cancelExecution = (selectionExecution: WorkflowTask) => {
    dispatch(
      cancelExecutionAction(
        CancelExecutionRequest.create({
          executionId: selectionExecution.id,
          orgId: selectedOrgInfo!.orgResourceName!.replace(
            'organizations/',
            '',
          ),
        }),
      ),
    );
  };

  /**
   * Cancel Child Executions
   */
  const cancelChildExecutions = (parentExecution: WorkflowTask) => {
    dispatch(
      cancelChildExecutionsAction(
        CancelExecutionRequest.create({
          executionId: parentExecution.id,
          orgId: selectedOrgInfo!.orgResourceName!.replace(
            'organizations/',
            '',
          ),
          cancelNestedExecution: true,
        }),
      ),
    );
  };

  /**
   * FETCH UI Executions
   * @param pageNumber
   * @param pageSize
   * @param refresh
   */
  const listExecutions = (
    pageNumber: number,
    pageSize: number,
    refresh: boolean,
  ) => {
    const req = buildUiRequest({
      navigate,
      selectedWorkflows,
      selectedUsers,
      uiExecutionStatusFilters,
      displayNamePrefix,
      orgResourceName: selectedOrgInfo!.orgResourceName!.replace(
        'organizations/',
        '',
      ) as string,
    });
    req.pageNumber = pageNumber;
    req.pageSize = pageSize;
    dispatch(listUiExecutionHistoryAction(req, refresh));
  };

  /**
   * REFRESH PAGE
   */
  const refreshPage = () => {
    setPage(DEFAULT_FIRST_PAGE);
    listExecutions(DEFAULT_FIRST_PAGE, rowsPerPage, true);
  };

  /**
   * USE EFFECTS
   */
  useEffect(() => {
    refreshPage();
  }, []);

  useEffect(() => {
    refreshPage();
  }, [
    displayNamePrefix,
    selectedWorkflows,
    selectedUsers,
    uiExecutionStatusFilters,
  ]);

  return (
    <>
      <OrbyTable
        tableWidth={tableWidth}
        cursor={'pointer'}
        isDataLoading={tasksLoading || cancelExecutionLoading}
        noDataMessage={
          displayNamePrefix
            ? `No results for "${displayNamePrefix}"`
            : 'There are no executions available'
        }
        tableContainerSx={{
          width: 'auto',
          overflowX: 'auto',
        }}
        colSx={[
          {
            width: `${getExecutionNameColumnWidth(tableWidth, 150)}px`,
            maxWidth: `${getExecutionNameColumnWidth(tableWidth, 150)}px`,
            minWidth: `${getExecutionNameColumnWidth(tableWidth, 150)}px`,
          },
          {
            width: `${getExecutionNameColumnWidth(tableWidth, 150)}px`,
            maxWidth: `${getExecutionNameColumnWidth(tableWidth, 150)}px`,
            minWidth: `${getExecutionNameColumnWidth(tableWidth, 150)}px`,
          },
          {
            width: '188px',
            maxWidth: '188px',
            minWidth: '188px',
          },
          {
            width: `${getExecutionTimeWidth(tableWidth, 80)}px`,
            maxWidth: `${getExecutionTimeWidth(tableWidth, 80)}px`,
            minWidth: `${getExecutionTimeWidth(tableWidth, 80)}px`,
          },
          {
            width: `${getExecutionTimeWidth(tableWidth, 80)}px`,
            maxWidth: `${getExecutionTimeWidth(tableWidth, 80)}px`,
            minWidth: `${getExecutionTimeWidth(tableWidth, 80)}px`,
          },
          {
            width: '217px',
            maxWidth: '217px',
            minWidth: '217px',
          },
          {
            width: '69px',
            maxWidth: '69px',
            minWidth: '69px',
          },
        ]}
        tableId='ui-automation-table'
        showCheckBox={false}
        isCheckBoxDisabled={false}
        /**
         * PAGINATION
         */
        pagination={{
          rowsPerPage,
          currentPage: page,
          totalRows: totalSize,
          setCurrentPage: (pageNumber: number) =>
            handleUiExecutionPageChange({
              executions,
              pageNumber,
              page,
              rowsPerPage,
              setPage,
              listExecutions,
            }),
          setRowsPerPage: (rows: number) =>
            handleExecutionsRowSelectionChange({
              rowsNumber: rows,
              setPage,
              setRowsPerPage,
              listExecutions,
            }),
        }}
        /**
         * TABLE HEADER ROWS
         */
        headerRows={[
          <OrbyHeaderLabel
            key={'execution-header-execution'}
            label='Execution'
          />,
          <OrbyHeaderLabel
            key={'execution-header-workflow'}
            label='Workflow'
          />,
          <OrbyHeaderLabel key={'execution-header-status'} label='Status' />,
          <OrbyHeaderLabel
            key={'execution-header-start-time'}
            label='Start Time'
          />,
          <OrbyHeaderLabel
            key={'execution-header-last-updated'}
            label='Last Updated'
          />,
          <OrbyHeaderLabel key={'execution-header-ran-by'} label='Ran By' />,
          <OrbyHeaderLabel key={'task-header-actions'} label='' />,
        ]}
        /**
         * TABLE ROWS
         */
        dataRows={pagedExecutions.map((execution) => {
          return {
            title: execution.id!,
            id: execution.id!,
            row: execution,
            cells: [
              getExecutionCell(execution, displayNamePrefix),
              getExecutionWorkflowDisplayNameCell(execution),
              getExecutionStatusCell(execution),
              getExecutionTimeCell(execution, execution.discoverTime),
              getExecutionTimeCell(
                execution,
                execution.endTime ? execution.endTime : execution.startTime,
              ),
              getExecutionRanByCell(execution),
              getExecutionActionMenuCell(
                WorkflowTask.create(execution),
                handleActionMenuClick,
              ),
            ],
          };
        })}
        onTableRowClick={(data) => {
          const workflowTask = data as WorkflowTask;
          navigate(`/executions/${workflowTask.id}`);
        }}
      />
      {/* EXECUTION ACTION MENU */}
      {getExecutionActionItem(
        actionMenuAnchorEl,
        actionMenuOpen,
        handleActionMenuClose,
        true,
        selectedExecution,
      )}
    </>
  );
};

export default React.memo(UiAutomationsTab);
