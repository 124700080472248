import React from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';

import UserCard from '../../components/UserCard';
import { NavigateFunction } from 'react-router-dom';
import { DISPLAY_NAME_PREFIX, FilteredResource } from '../../utils/constants';
import { OrbyBodyLabel, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { formatDateTime } from '../../utils/helpers';
import {
  ArchivedResource,
  ListArchivedResourcesRequest,
} from 'protos/pb/v1alpha2/archived_resources_service';
import { DEFAULT_FIRST_PAGE } from 'orby-ui/src/components/table/table-utils';

export const ArchivedResourceType = {
  TASK: 'task',
  WORKFLOW: 'workflow',
  CONNECTOR: 'connector',
};

export const buildTrashCanRequest = ({
  navigate,
  displayNamePrefix,
  orgResourceName,
  type,
}: {
  navigate: NavigateFunction;
  displayNamePrefix: string;
  orgResourceName: string;
  type: string;
}) => {
  const req: ListArchivedResourcesRequest =
    ListArchivedResourcesRequest.fromJSON({
      filter: getFilters(navigate, displayNamePrefix, type),
      orgResourceName: orgResourceName,
    });
  return req;
};

export const getFilters = (
  navigate: NavigateFunction,
  searchQuery: string,
  type: string,
) => {
  let filter = '';

  const searchParams = new URLSearchParams();

  if (searchQuery) {
    filter += `${DISPLAY_NAME_PREFIX}=${searchQuery}`;
    searchParams.append(DISPLAY_NAME_PREFIX, searchQuery);
  }

  if (type) {
    filter += `,type=${type}`;
    searchParams.append('type', type);
  }

  navigate({ search: `?${searchParams.toString()}` });

  // Return the updated filter string
  return filter.length && filter[0] === ',' ? filter.substring(1) : filter;
};

/**
 * Handle Trash Can Page Change
 */
export const handleTrashPageChange = ({
  archivedItems,
  pageNumber,
  page,
  rowsPerPage,
  setPage,
  listTrash,
  setSelectedResources,
}: {
  archivedItems: ArchivedResource[];
  pageNumber: number;
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setSelectedResources: React.Dispatch<
    React.SetStateAction<ArchivedResource[]>
  >;
  listTrash: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
}) => {
  setPage(pageNumber);
  if (pageNumber >= page && archivedItems.length <= pageNumber * rowsPerPage) {
    listTrash(pageNumber, rowsPerPage, false);
  }
  setSelectedResources([]);
};

/**
 *  HANDLE ROWS SELECTION CHANGE
 */
export const handleTrashPageRowSelectionChange = ({
  rowsNumber,
  setPage,
  setRowsPerPage,
  listTrash,
  setSelectedResources,
}: {
  rowsNumber: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  setSelectedResources: React.Dispatch<
    React.SetStateAction<ArchivedResource[]>
  >;
  listTrash: (
    pageNumber: number,
    rowsPerPage: number,
    shouldRefresh: boolean,
  ) => void;
}) => {
  setPage(DEFAULT_FIRST_PAGE);
  // Refresh is needed when rows per page is changes to fetch fresh data
  setRowsPerPage(rowsNumber);
  listTrash(DEFAULT_FIRST_PAGE, rowsNumber, true);
  setSelectedResources([]);
};

export const getResourceType = (
  archivedResource: ArchivedResource,
): FilteredResource => {
  if (archivedResource.connector) {
    return archivedResource.connector;
  } else if (archivedResource.task) {
    return archivedResource.task;
  } else {
    return archivedResource.workflow as FilteredResource;
  }
};

const getKey = (resource: ArchivedResource) =>
  resource.task?.name ||
  resource.workflow?.name ||
  resource.connector?.name ||
  '';

export const getTaskNameCell = (
  resource: ArchivedResource,
  displayNamePrefix: string,
): JSX.Element => {
  const displayName =
    resource.task?.displayName ||
    resource.workflow?.displayName ||
    resource.connector?.displayName ||
    '';

  const startIndex = displayName
    .toLowerCase()
    .indexOf(displayNamePrefix?.toLowerCase());
  const endIndex = startIndex + displayNamePrefix?.length;

  return (
    <OrbyBodyLabel
      key={getKey(resource)}
      title={displayName}
      label={
        <span>
          <span
            style={{ color: OrbyColorPalette['blue-700'], fontWeight: '700' }}
          >
            {displayName.substring(startIndex, endIndex)}
          </span>
          {displayName.substring(endIndex)}
        </span>
      }
      fontSize='sm'
      fontWeight='medium'
      color={OrbyColorPalette['grey-900']}
    />
  );
};

// SHOWED IN CASE OF TASKS
export const getWorkflowDisplayNameCell = (
  resource: ArchivedResource,
): JSX.Element => {
  const displayName = resource.task?.workflowDisplayName;
  return (
    <OrbyBodyLabel
      title={displayName || 'NA'}
      key={resource.task?.name}
      label={displayName || 'NA'}
      fontSize='sm'
      fontWeight='regular'
      color={OrbyColorPalette['grey-600']}
    />
  );
};

export const getNotesCell = (resource: ArchivedResource): JSX.Element => {
  const notes =
    resource.task?.deletedObjectInfo?.deletedReason ||
    resource.workflow?.deletedObjectInfo?.deletedReason ||
    resource.connector?.deletedObjectInfo?.deletedReason ||
    'NA';
  return (
    <OrbyBodyLabel
      title={notes}
      key={getKey(resource)}
      label={notes}
      fontSize='sm'
      fontWeight='regular'
      color={OrbyColorPalette['grey-600']}
    />
  );
};

export const getTimeDeletedCell = (resource: ArchivedResource): JSX.Element => {
  const timeDeleted =
    resource.task?.deletedObjectInfo?.deletedTime ||
    resource.workflow?.deletedObjectInfo?.deletedTime ||
    resource.connector?.deletedObjectInfo?.deletedTime;
  if (timeDeleted) {
    const { date, time } = formatDateTime(timeDeleted);
    return (
      <Box key={getKey(resource)}>
        <OrbyBodyLabel
          label={date}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-900']}
        />
        <OrbyBodyLabel
          label={time}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-600']}
        />
      </Box>
    );
  } else {
    return (
      <OrbyBodyLabel
        key={getKey(resource)}
        label={'-'}
        fontSize='sm'
        fontWeight='regular'
        color={OrbyColorPalette['grey-900']}
      />
    );
  }
};

export const getDeletedByCell = (resource: ArchivedResource): JSX.Element => {
  const deletedBy =
    resource.task?.deletedObjectInfo?.deletedBy ||
    resource.workflow?.deletedObjectInfo?.deletedBy ||
    resource.connector?.deletedObjectInfo?.deletedBy;
  return (
    <UserCard
      key={deletedBy}
      email={deletedBy || ''}
      imageUrl={''}
      fullName={''}
    />
  );
};

export const getTrashCanActionMenuCell = (
  resource: ArchivedResource,
  handleActionMenuClick: (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    resource: ArchivedResource,
  ) => void,
): JSX.Element => {
  return (
    <IconButton
      title='Menu'
      aria-label='Menu'
      tabIndex={0}
      key={getKey(resource)}
      onClick={(
        event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
      ) => {
        event.stopPropagation();
        handleActionMenuClick(event, resource);
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      <MoreVertOutlinedIcon sx={{ color: OrbyColorPalette['grey-400'] }} />
    </IconButton>
  );
};

export const TRASH_CAN_ACTION_MENU_TYPE = {
  RESTORE: 'RESTORE',
};

export const getTrashCanActionMenu = (
  actionMenuAnchorEl: HTMLElement | null,
  handleActionMenuClose: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type: string,
  ) => void,
) => {
  return (
    <Menu
      id='basic-menu'
      anchorEl={actionMenuAnchorEl}
      open={!!actionMenuAnchorEl}
      onClose={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        handleActionMenuClose(e, '');
      }}
      MenuListProps={{
        'aria-labelledby': 'Action Menu',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
          overflow: 'visible',
          borderRadius: '8px',
        },
      }}
      transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      <MenuItem
        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={(e) => {
          handleActionMenuClose(e, TRASH_CAN_ACTION_MENU_TYPE.RESTORE);
        }}
      >
        <OrbyTypography
          size='md'
          weight='medium'
          color={OrbyColorPalette['grey-900']}
        >
          Restore
        </OrbyTypography>
      </MenuItem>
    </Menu>
  );
};

export const getResourceName = (
  selectedType: string,
  res: ArchivedResource,
) => {
  if (selectedType === ArchivedResourceType.TASK) {
    return res.task?.name;
  } else if (selectedType === ArchivedResourceType.WORKFLOW) {
    return res.workflow?.name;
  } else if (selectedType === ArchivedResourceType.CONNECTOR) {
    return res.connector?.name;
  }
  return '';
};

export const compareResourceName = (
  selectedType: string,
  res: ArchivedResource,
  resource: ArchivedResource,
) => {
  if (selectedType === ArchivedResourceType.TASK) {
    return res.task?.name === resource.task?.name;
  } else if (selectedType === ArchivedResourceType.WORKFLOW) {
    return res.workflow?.name === resource.workflow?.name;
  } else if (selectedType === ArchivedResourceType.CONNECTOR) {
    return res.connector?.name === resource.connector?.name;
  }
  return false;
};

export const allowSelectionForArchivedResources = (
  selectedType: string,
  resource: ArchivedResource,
) => {
  // if un-restorable Reason is not present or empty we allow selection
  if (selectedType === ArchivedResourceType.TASK) {
    return !resource.task?.deletedObjectInfo?.unrestorableReason;
  } else if (selectedType === ArchivedResourceType.WORKFLOW) {
    return !resource.workflow?.deletedObjectInfo?.unrestorableReason;
  } else if (selectedType === ArchivedResourceType.CONNECTOR) {
    return !resource.connector?.deletedObjectInfo?.unrestorableReason;
  } else {
    return false;
  }
};

/**
 * CHECK IF ALL THE TASKS ARE SELECTED ON THE CURRENT PAGE
 */
export const areAllResourcesSelected = (
  selectedResources: ArchivedResource[],
  resources: ArchivedResource[],
  page: number,
  rowsPerPage: number,
  selectedType: string,
) => {
  if (selectedResources.length) {
    const resourcesOnCurrentPage = resources.slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage,
    );
    return resourcesOnCurrentPage.every(
      (resource) =>
        selectedResources.includes(resource) ||
        !allowSelectionForArchivedResources(selectedType, resource),
    );
  }
  return false;
};

export const getTaskNameColumnWidth = (
  tableWidth: number,
  type: string,
  minWidth?: number,
) => {
  if (type === ArchivedResourceType.TASK) {
    // TABLE WIDTH - NOTES WIDTH - TIME WIDTH - USERS WIDTH - ACTION WIDTH
    const width =
      (tableWidth - 218 - getTimeModifiedWidth(tableWidth) - 217 - 69) / 2;
    if (minWidth) {
      return Math.max(width, minWidth);
    }
    return width;
  } else {
    const width =
      (tableWidth - getTimeModifiedWidth(tableWidth) - 217 - 69) / 2;
    if (minWidth) {
      return Math.max(width, minWidth);
    }
    return width;
  }
};

export const getNotesColumnWidth = (
  tableWidth: number,
  type: string,
  minWidth?: number,
) => {
  if (type === ArchivedResourceType.TASK) {
    return 218;
  } else {
    // TABLE WIDTH - NOTES WIDTH - TIME WIDTH - USERS WIDTH - ACTION WIDTH
    // Subtract 20 pixels to add avoid overflowX auto on normal screen sizes (like desktop)
    const width =
      (tableWidth - getTimeModifiedWidth(tableWidth) - 217 - 69 - 20) / 2;
    if (minWidth) {
      return Math.max(width, minWidth);
    }
    return width;
  }
};

export const getWorkflowNameColumnWidth = (
  tableWidth: number,
  minWidth?: number,
) => {
  // TABLE WIDTH - NOTES WIDTH - TIME WIDTH - USERS WIDTH - ACTION WIDTH
  const width =
    (tableWidth - 218 - getTimeModifiedWidth(tableWidth) - 217 - 69 - 10) / 2;
  if (minWidth) {
    return Math.max(width, minWidth);
  }
  return width;
};

export const getTimeModifiedWidth = (tableWidth: number, minWidth?: number) => {
  const width = tableWidth >= 1272 ? 182 : 139;
  if (minWidth) {
    return Math.max(width, minWidth);
  }
  return width;
};
