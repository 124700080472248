import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';
import { storageService } from './StorageService';
import {
  DeleteWorkflowRequest,
  DeleteWorkflowResponse,
  GetWorkflowRequest,
  GetWorkflowTaskRequest,
  ListWorkflowTasksRequest,
  ListWorkflowTasksResponse,
  ListWorkflowTemplatesRequest,
  ListWorkflowTemplatesResponse,
  ListWorkflowsRequest,
  ListWorkflowsResponse,
  OrbotClientImpl,
  CreateWorkflowRequest,
  GetWorkflowTemplateRequest,
  CancelExecutionRequest,
  CancelExecutionResponse,
} from 'protos/pb/v1alpha1/orbot_service';
import { Workflow, WorkflowTask } from 'protos/pb/v1alpha1/orbot_workflow';
import { WorkflowWithTask } from '../redux/reducers/workflow_task.reducer';
import { migrateWorkflow } from 'workflow-utils/src/workflow-migration';

export class OrbotService {
  private static instance: OrbotService;

  private static client = new OrbotClientImpl(rpcWithErrorHandling);

  public static getInstance(): OrbotService {
    if (!this.instance) {
      this.instance = new OrbotService();
    }
    return this.instance;
  }

  async getUiExecutionHistory(
    req: ListWorkflowTasksRequest,
  ): Promise<{ response?: ListWorkflowTasksResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrbotService.client.ListWorkflowTasks(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async listWorkflows(
    req: ListWorkflowsRequest,
  ): Promise<{ response?: ListWorkflowsResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrbotService.client.ListWorkflows(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async listAllWorkflows(
    orgResourceName: string,
    workflowList: Workflow[] = [],
    nextPageToken: string | undefined = undefined,
  ): Promise<{ response?: Workflow[]; error?: Error }> {
    try {
      const req: ListWorkflowsRequest = {};
      req.pageSize = 100;
      req.fieldMask = ['id', 'display_name'];

      req.orgId = orgResourceName!.replace('organizations/', '') as string;
      if (nextPageToken) {
        // eslint-disable-next-line no-restricted-properties
        req.pageToken = nextPageToken;
      }
      const { response, error } = await orbotService.listWorkflows(req);
      if (response && response.workflows) {
        workflowList = [...workflowList, ...response.workflows];
        // This is to check if the workflow list is not empty and the total size of the list is not reached the workflow list
        if (response.nextPageToken) {
          return await orbotService.listAllWorkflows(
            orgResourceName,
            workflowList,
            response.nextPageToken,
          );
        } else {
          return {
            response: workflowList,
          };
        }
      } else {
        return { error };
      }
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getWorkflowById(
    req: GetWorkflowRequest,
  ): Promise<{ response?: Workflow; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();

    try {
      const workflowRequest = {
        workflowId: req.workflowId,
        orgId: req.orgId,
      };
      const response = await OrbotService.client.GetWorkflow(
        workflowRequest,
        getMetaData({ authorization }),
      );

      return { response: migrateWorkflow(response) };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getWorkFlowTask(
    req: GetWorkflowTaskRequest,
  ): Promise<{ response?: WorkflowTask; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();

    try {
      const response = await OrbotService.client.GetWorkflowTask(
        req,
        getMetaData({ authorization }),
      );

      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  /**
   * The below function is used for fetching the Workflow task along with workflow
   * To NOTE: that this is fetching the workflow by ID
   * @param req
   * @returns
   */
  async getWorkflowWithTask(
    req: GetWorkflowTaskRequest,
  ): Promise<WorkflowWithTask> {
    const authorization = await storageService.getAuthorizationHeader();

    try {
      let workflow;
      const task = await OrbotService.client.GetWorkflowTask(
        req,
        getMetaData({ authorization }),
      );

      if (task) {
        const workflowRequest = {
          workflowId: task.workflowId,
          orgId: req.orgId,
        };

        workflow = await OrbotService.client.GetWorkflow(
          workflowRequest,
          getMetaData({ authorization }),
        );
      }

      return {
        task,
        workflow: workflow ? migrateWorkflow(workflow) : undefined,
      };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateWorkflow(req: any): Promise<Workflow> {
    try {
      const authorization = await storageService.getAuthorizationHeader();

      const workflow = await OrbotService.client.UpdateWorkflow(
        req,
        getMetaData({ authorization }),
      );

      return workflow;
    } catch (error) {
      throw new Error(`An error occurred while updating workflow`);
    }
  }

  async deleteWorkflow(
    req: DeleteWorkflowRequest,
  ): Promise<DeleteWorkflowResponse> {
    const authorization = await storageService.getAuthorizationHeader();
    return await OrbotService.client.DeleteWorkflow(
      req,
      getMetaData({ authorization }),
    );
  }

  async createWorkflow(req: CreateWorkflowRequest): Promise<Workflow> {
    const authorization = await storageService.getAuthorizationHeader();
    return await OrbotService.client.CreateWorkflow(
      req,
      getMetaData({ authorization }),
    );
  }

  // List Orbot Workflow Templates
  async listWorkflowsTemplates(
    req: ListWorkflowTemplatesRequest,
  ): Promise<{ response?: ListWorkflowTemplatesResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrbotService.client.ListWorkflowTemplates(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getWorkflowTemplate(
    req: GetWorkflowTemplateRequest,
  ): Promise<{ response?: Workflow; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrbotService.client.GetWorkflowTemplate(
        req,
        getMetaData({ authorization }),
      );
      return { response: migrateWorkflow(response) };
    } catch (error) {
      return { error: error as Error };
    }
  }

  // Cancel Execution
  async cancelExecution(
    req: CancelExecutionRequest,
  ): Promise<{ response?: CancelExecutionResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrbotService.client.CancelExecution(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }
}

export const orbotService = OrbotService.getInstance();
