import React from 'react';
import { Box, IconButton, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  OrbyBadge,
  OrbyButton,
  OrbyColorPalette,
  OrbyTypography,
} from 'orby-ui/src';
import { ReactComponent as BackArrowIcon } from '../../../static/icons/back-arrow.svg';
import { Task, TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import moment from 'moment';

const Container = styled(Box)(() => ({
  display: 'flex',
  padding: '24px 48px 12px',
  alignItems: 'flex-start',
  flexDirection: 'column',
}));

const TitleSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
}));

const BackButtonSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '20px',
  // marginTop: '4px',
}));

const DescriptionBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
}));

const ActionButtonsBox = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
  marginBottom: '26px',
  justifyContent: 'end',
}));

const ExecutionDetailsBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '80px',
}));

const ExecutionInfo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
}));

const Divider = styled(Box)(() => ({
  borderBottom: '0.5px solid',
  borderColor: OrbyColorPalette['grey-200'],
}));

const ShadowBox = styled(Box)({
  boxShadow: '0px 2px 6px 0px #0000000F',
  marginBottom: '8px',
});

interface Props {
  title: string;
  workflowName?: string;
  data?: Task;
  onProceed: () => void;
}

const HITLTaskOverview: React.FC<Props> = ({
  title,
  workflowName,
  data,
  onProceed,
}) => {
  const navigate = useNavigate();

  const navigateBack = () => {
    !history.state ? navigate('/') : navigate(-1);
  };

  const getDescription = (workflowName: string | undefined) => (
    <OrbyBadge
      key={workflowName}
      size='medium'
      badgeName={workflowName}
      backgroundColor={'#F2F4F7'}
      textColor={OrbyColorPalette['grey-700']}
      boxSx={{ borderRadius: '4px', paddingTop: '2px', paddingBottom: '2px' }}
    />
  );

  const getTimePause = (data: Task): string => {
    const readyTime = data?.readyTime;
    const reviewedTime = data?.reviews?.[0]?.reviewedTime;

    if (readyTime && reviewedTime) {
      const duration = moment.duration(
        moment(reviewedTime).diff(moment(readyTime)),
      );
      const hours = duration.hours();
      const minutes = duration.minutes();

      return `${hours}h ${minutes}m`;
    }

    return '--';
  };

  return (
    <ShadowBox>
      <Container>
        <TitleSection>
          <Box
            display='flex'
            flexDirection='row'
            width={'100%'}
            justifyContent={'space-between'}
          >
            <Box display='flex' flexDirection='column'>
              <BackButtonSection>
                <IconButton
                  onClick={navigateBack}
                  aria-label='Go Back'
                  sx={{ padding: '0px', mr: '11.51px' }}
                >
                  <BackArrowIcon height='20px' width='20px' />
                </IconButton>
                <OrbyTypography
                  color={OrbyColorPalette['grey-900']}
                  weight='medium'
                >
                  Back to all tasks
                </OrbyTypography>
              </BackButtonSection>

              <OrbyTypography size='display-xs' weight='semibold'>
                {title}
              </OrbyTypography>
            </Box>

            {data?.status == TaskSTATUS.READY && (
              <Box>
                <ActionButtonsBox>
                  <OrbyButton
                    variant='primary-outline'
                    sx={{ width: '160px' }}
                    size='large'
                    label='Proceed'
                    ariaLabel='Proceed'
                    onClick={onProceed}
                  />
                </ActionButtonsBox>
              </Box>
            )}
          </Box>
        </TitleSection>

        <Box display='flex' flexDirection='column' width={'100%'}>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <OrbyTypography
              componentType='div'
              color={OrbyColorPalette['grey-600']}
            >
              <DescriptionBox>{getDescription(workflowName)}</DescriptionBox>
            </OrbyTypography>

            <ExecutionDetailsBox>
              <ExecutionInfo>
                <Box display='flex' flexDirection='row' gap='12px'>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-600']}
                  >
                    Execution Start Time
                  </OrbyTypography>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-900']}
                    weight='medium'
                  >
                    {moment(data?.createTime).format('MMM DD,YYYY hh:mm ')}
                  </OrbyTypography>
                </Box>

                <Box display='flex' flexDirection='row' gap='17px'>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-600']}
                  >
                    Execution End Time
                  </OrbyTypography>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-900']}
                    weight='medium'
                  >
                    {data?.completeTime
                      ? moment(data?.completeTime).format('MMM DD,YYYY hh:mm ')
                      : '-- (Paused)'}
                  </OrbyTypography>
                </Box>
              </ExecutionInfo>

              <ExecutionInfo>
                <Box display='flex' flexDirection='row' gap='12px'>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-600']}
                  >
                    Time Paused
                  </OrbyTypography>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-900']}
                    weight='medium'
                  >
                    {getTimePause(data || {})}
                  </OrbyTypography>
                </Box>

                <Box display='flex' flexDirection='row' gap='43px'>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-600']}
                  >
                    Creator
                  </OrbyTypography>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-900']}
                    weight='medium'
                  >
                    {data?.username}
                  </OrbyTypography>
                  <OrbyTypography
                    size='xs'
                    color={OrbyColorPalette['grey-500']}
                    weight='medium'
                    sx={{
                      marginLeft: '-35px',
                    }}
                  >
                    {data?.username}
                  </OrbyTypography>
                </Box>
              </ExecutionInfo>
            </ExecutionDetailsBox>
          </Box>
        </Box>
      </Container>

      <Divider />
    </ShadowBox>
  );
};

export default React.memo(HITLTaskOverview);
