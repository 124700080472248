import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrbyColorPalette, OrbyHeaderLabel, OrbyTable } from 'orby-ui/src';

import {
  Task,
  TaskSTATUS,
  UpdateBatchTasksRequest,
} from 'protos/pb/v1alpha2/tasks_service';
import {
  deletingTaskSelector,
  pendingTasksListSelector,
  pendingTasksLoadingSelector,
  pendingTasksTotalSizeSelector,
  tasksDeletedSuccessfullySelector,
  tasksFailedToDeleteSelector,
  tasksFailedToReassignSelector,
  updateBatchTasksErrorSelector,
  updateBatchTasksLoadingSelector,
  updateBatchTasksSuccessSelector,
} from '../../../../redux/selectors/taskV2.selectors';
import {
  areAllTasksSelected,
  buildRequest,
  getPageNumber,
  getPageNumberValueFromFilterChange,
  getPageSize,
  getTaskNameColumnWidth,
  getTimeModifiedWidth,
  handleDeleteTask,
  handleTaskPageChange,
  handleTasksRowSelectionChange,
} from '../../TaskHelpers';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../../redux/selectors/user.selectors';
import { useNavigate } from 'react-router-dom';
import {
  ReassignmentOptions,
  selectedTasksPermissionErrorMsg,
} from '../../../../utils/constants';
import {
  clearDeleteTaskAction,
  listPendingTasksAction,
  resetReassignmentStateAction,
  updateBatchTasksAction,
} from '../../../../redux/actions/taskV2.action';
import {
  TASK_MENU_ACTION_TYPE,
  getDisplayNameCell,
  getTaskActionItem,
  getPendingTaskActions,
  getTaskModifiedTimeCell,
  getTaskPendingReviewerCell,
  getWorkflowDisplayNameCell,
  getTaskActionGroupErrorContent,
  isOrbotTask,
} from '../TaskTableBodyHelpers';
import TaskDeletionModal from '../../../../pages/PendingTasks/TaskDeletionModal';
import { toastService } from '../../../../services/ToastService';
import UserAssignmentMenu from '../../../../pages/PendingTasks/UserAssignmentMenu';
import TaskSelectionHeader from '../TaskSelectionHeader';
import { clearReviewState } from '../../../../redux/actions/review_task.action';
import {
  allowSelectionForPendingTask,
  groupTasksByError,
} from '../../../../utils/helpers';
import {
  isTaskViewable,
  isTaskDeletable,
  isTaskAssignable,
} from '../../../../utils/RbacUtils';

export interface Props {
  displayNamePrefix: string;
  selectedWorkflows: Array<string>;
  selectedUsers: Array<string>;
  isAdminView: boolean;
  tableWidth: number;
}

const PendingTasksTab: React.FC<Props> = ({
  displayNamePrefix,
  selectedWorkflows,
  selectedUsers,
  isAdminView,
  tableWidth,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(location.search);

  const tasks: Task[] = useSelector(pendingTasksListSelector);

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const user = useSelector(loggedInUserSelector);
  const totalSize = useSelector(pendingTasksTotalSizeSelector) ?? 0;

  const reassignmentLoading = useSelector(updateBatchTasksLoadingSelector);
  const tasksLoading = useSelector(pendingTasksLoadingSelector);
  const reassignmentError = useSelector(updateBatchTasksErrorSelector);
  const reassignmentSuccess = useSelector(updateBatchTasksSuccessSelector);
  const tasksFailedToReassign = useSelector(tasksFailedToReassignSelector);
  const deletingTask = useSelector(deletingTaskSelector);
  const tasksFailedToDelete = useSelector(tasksFailedToDeleteSelector);
  const tasksDeletedSuccessfully = useSelector(
    tasksDeletedSuccessfullySelector,
  );

  const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [selectedTasksToDelete, setSelectedTasksToDelete] = useState<Task[]>(
    [],
  );

  const isUserAssignmentVisible = !!selectedTasks.length || selectedTask;

  const [selectedUserForAssignment, setSelectedUserForAssignment] =
    useState<string>(ReassignmentOptions.DEFAULT);

  const [page, setPage] = useState(getPageNumber(urlSearchParams));
  const [rowsPerPage, setRowsPerPage] = useState(getPageSize(urlSearchParams));

  const [actionMenuAnchorEl, setActionMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const [userAssignmentAnchorEl, setUserAssignmentAnchorEl] =
    React.useState<null | HTMLElement>(null);

  /**
   * ACTION MENU
   */
  const actionMenuOpen = Boolean(actionMenuAnchorEl);
  const handleActionMenuClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
    task: Task,
  ) => {
    setSelectedTask(task);
    setActionMenuAnchorEl(event.currentTarget);
  };

  /**
   * HANDLE ACTION MENU CLOSE
   */
  const handleActionMenuClose = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type?: string,
  ) => {
    event.stopPropagation();
    if (type && selectedTask) {
      if (type === TASK_MENU_ACTION_TYPE.DELETE) {
        if (selectedTask.status !== TaskSTATUS.CREATED) {
          setSelectedTasksToDelete([selectedTask]);
        }
      } else if (type === TASK_MENU_ACTION_TYPE.UNASSIGN) {
        assignUnAssignTask([selectedTask], '');
      } else if (
        type === TASK_MENU_ACTION_TYPE.ASSIGN ||
        type === TASK_MENU_ACTION_TYPE.ASSIGN_TO_SELF
      ) {
        // bcoz UserAssignmentMenu is shared between
        // click on action menu and click on header,
        // we clear selectedTasks to correctly calculate
        // "canAssignToOthers" when showing UserAssignmentMenu
        if (selectedTasks.length > 0) {
          setSelectedTasks([]);
        }

        if (type == TASK_MENU_ACTION_TYPE.ASSIGN) {
          setUserAssignmentAnchorEl(actionMenuAnchorEl);
        } else {
          assignUnAssignTask([selectedTask], user?.email as string);
        }
      }
    } else {
      setSelectedTask(null);
    }
    setActionMenuAnchorEl(null);
  };

  /**
   * FETCH TASKS
   * @param pageNumber
   * @param pageSize
   */
  const listTasks = (pageNumber: number, pageSize: number) => {
    const req = buildRequest({
      status: 'status=READY-CREATED',
      user: user!,
      isAdminView,
      navigate,
      selectedWorkflows,
      displayNamePrefix,
      selectedUsers,
      orgResourceName: selectedOrgInfo!.orgResourceName as string,
      pageNumber,
      pageSize,
    });
    dispatch(listPendingTasksAction(req, true));
  };

  /**
   * ASSIGN / UNASSIGN TASKS
   */
  const assignUnAssignTask = (taskList: Task[], user: string) => {
    let tasks: Task[] = [];
    if (selectedTask) {
      // THIS WOULD BE CALLED IN CASE OF ASSIGN TO CALLED FROM ACTION MENU
      tasks = [
        {
          name: selectedTask.name!,
          displayName: selectedTask.displayName!,
          username: user,
          organizationResourceName: selectedTask.organizationResourceName!,
        },
      ];
    } else if (taskList.length) {
      tasks = taskList.map((t: Task) => {
        return {
          name: t.name,
          displayName: t.displayName,
          username: user,
          organizationResourceName: t.organizationResourceName,
        } as Task;
      });
    }
    if (tasks.length) {
      dispatch(
        updateBatchTasksAction(UpdateBatchTasksRequest.create({ tasks })),
      );
    }
  };

  /**
   * DELETE TASKS
   */
  const handleDelete = (deleteReason: string, itemsToDelete: Task[]) => {
    handleDeleteTask(deleteReason, itemsToDelete, dispatch);
    setSelectedTasks([]);
    setSelectedTasksToDelete([]);
  };

  // CHECK IF SPECIFIC TASKS IS SELECTED
  const isTaskSelected = (task: Task) => {
    return selectedTasks.some(
      (selectedTask) => selectedTask.name === task.name,
    );
  };

  // HANDLE SELECTION/DESELECTION OF THE TASK
  const handleTaskSelection = (task: Task, isSelected: boolean) => {
    if (isSelected) {
      setSelectedTasks((prevSelectedTasks) => [...prevSelectedTasks, task]);
    } else {
      setSelectedTasks((prevSelectedTasks) =>
        prevSelectedTasks.filter((t) => t.name !== task.name),
      );
    }
  };

  // HANDLE SELECTION/DESELECTION OF ALL TASKS ON THE CURRENT PAGE
  const handleAllTasksSelection = (isSelected: boolean) => {
    // Filter out tasks that are part of the 'orbot_workflow_tasks'
    const filteredTasks = tasks.filter((task) => !isOrbotTask(task));
    if (isSelected) {
      setSelectedTasks(filteredTasks);
    } else {
      setSelectedTasks([]);
    }
  };

  /**
   * RESET
   */
  const resetReassignmentState = () => {
    setSelectedTasks([]);
    setSelectedUserForAssignment(ReassignmentOptions.DEFAULT);
    dispatch(resetReassignmentStateAction());
  };

  /**
   * HANDLE ASSIGN BUTTON CLICK
   */
  const handleAssignToClick = (event: React.MouseEvent<HTMLElement>) => {
    setUserAssignmentAnchorEl(event.currentTarget);
  };
  const handleAssignToClose = () => {
    setSelectedTask(null);
    setUserAssignmentAnchorEl(null);
  };

  const handleAssignToMeClick = () => {
    assignUnAssignTask(selectedTasks, user?.email as string);
  };

  /**
   * REFRESH PAGE
   */
  const refreshPage = (selectedPage?: number) => {
    setSelectedTasks([]);
    listTasks(selectedPage ?? page, rowsPerPage);
  };

  /**
   * USE EFFECTS
   */
  useEffect(() => {
    refreshPage();
  }, []);

  useEffect(() => {
    const pageNumber = getPageNumberValueFromFilterChange(
      displayNamePrefix,
      selectedWorkflows,
      selectedUsers,
      page,
    );
    setPage(pageNumber);
    refreshPage(pageNumber);
  }, [displayNamePrefix, selectedWorkflows, selectedUsers, isAdminView]);

  useEffect(() => {
    if (tasksDeletedSuccessfully.length > 0) {
      toastService.showSuccess(
        tasksDeletedSuccessfully.length > 1
          ? `${tasksDeletedSuccessfully.length} Tasks were deleted successfully`
          : 'Task was deleted successfully',
        {
          position: 'top-right',
        },
      );

      dispatch(clearDeleteTaskAction());
      refreshPage();
    }
  }, [tasksDeletedSuccessfully]);

  useEffect(() => {
    if (tasksFailedToDelete.length > 0) {
      toastService.showError(
        getTaskActionGroupErrorContent(
          groupTasksByError(tasksFailedToDelete),
          false,
        ),
        {
          position: 'top-right',
        },
      );
      dispatch(clearDeleteTaskAction());
      refreshPage();
    }
  }, [tasksFailedToDelete]);

  useEffect(() => {
    if (reassignmentError) {
      if (typeof reassignmentError === 'string') {
        toastService.showError(reassignmentError, {
          position: 'top-right',
        });
      } else {
        toastService.showError(
          getTaskActionGroupErrorContent(reassignmentError, true),
          {
            position: 'top-right',
          },
        );
      }
      resetReassignmentState();
      setTimeout(() => dispatch(resetReassignmentStateAction(true)), 3000);
    }
  }, [reassignmentError]);

  useEffect(() => {
    if (reassignmentSuccess) {
      toastService.showSuccess(reassignmentSuccess, {
        position: 'top-right',
      });
      resetReassignmentState();
      refreshPage();
    }
  }, [reassignmentSuccess]);

  return (
    <>
      <OrbyTable
        tableWidth={tableWidth}
        onTableRowClick={(data) => {
          const task = data as Task;
          if (!isTaskViewable(task)) {
            toastService.showInfo(
              "You don't have permissions to view task details",
              {
                position: 'top-center',
                autoClose: 1000,
              },
            );
            return;
          }
          if (task.status !== TaskSTATUS.CREATED) {
            dispatch(clearReviewState());
            if (isOrbotTask(task)) {
              navigate(`/${task.name}/hitl-details`);
            } else {
              navigate(`/${task.name}/automation-review`);
            }
          }
        }}
        tableContainerSx={{
          width: 'auto',
          overflowX: 'auto',
        }}
        isDataLoading={tasksLoading || deletingTask}
        noDataMessage={
          displayNamePrefix
            ? `No results for "${displayNamePrefix}"`
            : 'Once the file that needs to be reviewed has been executed, it will appear in this list.'
        }
        colSx={[
          {
            width: `${getTaskNameColumnWidth(tableWidth, 230)}px`,
            maxWidth: `${getTaskNameColumnWidth(tableWidth, 230)}px`,
            minWidth: `${getTaskNameColumnWidth(tableWidth, 230)}px`,
          },
          {
            width: `${getTaskNameColumnWidth(tableWidth, 230)}px`,
            maxWidth: `${getTaskNameColumnWidth(tableWidth, 230)}px`,
            minWidth: `${getTaskNameColumnWidth(tableWidth, 230)}px`,
          },
          {
            width: `${getTimeModifiedWidth(tableWidth, 80)}px`,
            maxWidth: `${getTimeModifiedWidth(tableWidth, 80)}px`,
            minWidth: `${getTimeModifiedWidth(tableWidth, 80)}px`,
          },
          {
            width: '217px',
            minWidth: '217px',
            maxWidth: '217px',
          },
          {
            width: '69px',
            maxWidth: '69px',
            minWidth: '69px',
          },
        ]}
        rowSx={{
          '.visibility-off-icon': {
            display: 'none',
          },
          '&:hover .visibility-off-icon': {
            display: 'block',
          },
        }}
        tableId='pending-task-table'
        showCheckBox={true}
        isCheckBoxDisabled={
          reassignmentLoading ||
          !tasks.some((t) => allowSelectionForPendingTask(t))
        }
        areAllRowsSelected={() =>
          areAllTasksSelected(selectedTasks, tasks, page, rowsPerPage)
        }
        isRowSelected={(row) => {
          const task = row as Task;
          return isTaskSelected(task);
        }}
        selectedRows={selectedTasks}
        handleRowSelection={(row, isSelected) => {
          const task = row as Task;
          handleTaskSelection(task, isSelected);
        }}
        handleAllRowsSelection={handleAllTasksSelection}
        checkBoxSelectionHeader={
          <TaskSelectionHeader
            selectedItems={selectedTasks}
            onCancel={() => {
              setSelectedTasks([]);
              setSelectedTasksToDelete([]);
            }}
            tertiaryLabel={ReassignmentOptions.UNASSIGN}
            onTertiaryClick={() => {
              assignUnAssignTask(selectedTasks, '');
            }}
            secondaryLabel={isAdminView ? 'Delete' : ''}
            onSecondaryClick={() => {
              setSelectedTasksToDelete(selectedTasks);
            }}
            secondaryLabelDisabled={selectedTasks.some(
              (t) => !isTaskDeletable(t),
            )}
            secondaryLabelDisabledTooltip={selectedTasksPermissionErrorMsg}
            primaryLabel={
              (selectedTask && isTaskAssignable(selectedTask)) ||
              (selectedTasks.length > 0 &&
                selectedTasks.every((t) => isTaskAssignable(t)))
                ? 'Assign to'
                : 'Assign to me'
            }
            onPrimaryClick={
              (selectedTask && isTaskAssignable(selectedTask)) ||
              (selectedTasks.length > 0 &&
                selectedTasks.every((t) => isTaskAssignable(t)))
                ? handleAssignToClick
                : handleAssignToMeClick
            }
          />
        }
        /**
         * PAGINATION
         */
        pagination={{
          rowsPerPage,
          currentPage: page,
          totalRows: totalSize,
          setCurrentPage: (pageNumber: number) =>
            handleTaskPageChange({
              pageNumber,
              rowsPerPage,
              setPage,
              listTasks,
              setSelectedTasks,
            }),
          setRowsPerPage: (rows: number) =>
            handleTasksRowSelectionChange({
              rowsNumber: rows,
              setPage,
              setRowsPerPage,
              listTasks,
              setSelectedTasks,
            }),
        }}
        /**
         * TABLE HEADER ROWS
         */
        headerRows={[
          <OrbyHeaderLabel
            key={'task-header-display-name'}
            label='Task Name'
          />,
          <OrbyHeaderLabel key={'task-header-workflow'} label='Workflow' />,
          <OrbyHeaderLabel
            key={'task-header-time-modified'}
            label='Time Modified'
          />,
          <OrbyHeaderLabel
            key={'task-header-pending-reviewer'}
            label='Pending Reviewer'
          />,
          <OrbyHeaderLabel key={'task-header-actions'} label='' />,
        ]}
        /**
         * TABLE ROWS
         */
        dataRows={tasks.map((task) => {
          return {
            title: task.displayName!,
            id: task.name!,
            row: task,
            cells: [
              getDisplayNameCell(task, displayNamePrefix),
              getWorkflowDisplayNameCell(task),
              getTaskModifiedTimeCell(task),
              getTaskPendingReviewerCell(task),
              getPendingTaskActions(Task.create(task), handleActionMenuClick),
            ],
            customBackgroundColor: tasksFailedToReassign.includes(
              task.name as string,
            )
              ? OrbyColorPalette['error-50']
              : '',
            isClickable:
              task.status === TaskSTATUS.CREATED ? false : isTaskViewable(task),
            isCheckBoxDisabled:
              task.status === TaskSTATUS.CREATED ||
              isOrbotTask(task) /* Orbot Tasks */,
          };
        })}
      />

      {isUserAssignmentVisible && (
        <UserAssignmentMenu
          selectedUser={selectedUserForAssignment}
          setSelectedUser={(user) => {
            assignUnAssignTask(selectedTasks, user);
          }}
          canAssignToOthers={
            (selectedTask && isTaskAssignable(selectedTask)) ||
            (selectedTasks.length > 0 &&
              selectedTasks.every((t) => isTaskAssignable(t)))
          }
          handleClose={handleAssignToClose}
          anchorEl={userAssignmentAnchorEl}
        />
      )}

      {/* PENDING TASK ACTION MENU */}
      {getTaskActionItem(
        actionMenuAnchorEl,
        actionMenuOpen,
        handleActionMenuClose,
        (selectedTask && isTaskDeletable(selectedTask)) || false,
        (selectedTask && isTaskAssignable(selectedTask)) || false,
      )}

      {/* DELETE TASK MODEL */}
      <TaskDeletionModal
        selectedTasks={selectedTasksToDelete}
        open={selectedTasksToDelete.length > 0}
        handleClose={() => setSelectedTasksToDelete([])}
        onSubmit={(deletionReason, task) => {
          handleDelete(deletionReason, task as Task[]);
        }}
      />
    </>
  );
};

export default React.memo(PendingTasksTab);
