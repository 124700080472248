import { Box, Stack, styled } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Action } from 'protos/pb/v1alpha1/orbot_action';
import { workflowAllDetailsSelector } from '../../../redux/selectors/workflow_details.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getActions } from 'workflow-utils/src/v2/workflow';
import { OrbyButton, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { updateAction } from '../../../redux/actions/workflow_details.constants';
import { ExtractEntityComponent } from './ExtractActionWidget';

export interface ExtractEntityProps {
  fields: string[];
  actionId: string;
  action: Action;
  isEditable: boolean;
  onClose(): void;
}

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
  width: '450px',
}));

const ExtractEntity: React.FC<ExtractEntityProps> = ({
  fields,
  action,
  onClose,
  isEditable,
}) => {
  const dispatch = useDispatch();
  const [entities, setEntities] = useState(fields);
  const [errorMessage, setErrorMessage] = useState('');

  const workflowStore = useSelector(workflowAllDetailsSelector);

  const entityHasChanged = useMemo(() => {
    if (fields.length !== entities.length) return true;
    return fields.some((field, index) => {
      return field !== entities[index];
    });
  }, [fields, entities]);

  const onUpdate = () => {
    // TODO: Add validation for the entities.
    if (!workflowStore.workflow) {
      throw new Error('Workflow is not set');
    }

    // Before updating the entities, we need to check if the entities are valid.
    // Verify if there are future steps that are using entities which are not present in the new entities.
    const actions = getActions(workflowStore.workflow!) ?? [];
    for (const action of actions) {
      if (!action) {
        continue;
      }

      // This function checks, if there are no future steps which are using the entity that is being removed.
      const validateEntity = (
        referenceValue: string,
        referenceValueKey: string | undefined,
      ) => {
        return (
          referenceValue === action.id &&
          entities.some(
            (entity) =>
              entity.toLowerCase() === referenceValueKey?.toLowerCase(),
          )
        );
      };

      // Right now, we will only validate the new entities with a action group that has either jsFunction or setValueAction.
      // However we should validate for all the action groups in the future.
      if (action.jsFunction) {
        for (const param of action.jsFunction.params ?? []) {
          if (
            param.partialReferenceValue &&
            !validateEntity(
              param.partialReferenceValue.referenceValue!,
              param.partialReferenceValue.referenceValueKey!,
            )
          ) {
            setErrorMessage(
              `Entity  '${param.partialReferenceValue.referenceValueKey}' cannot be removed as it is used in future steps. 
                 Kindly add it back or remove the step that is using it.`,
            );
            return;
          }
        }
      }

      if (action.setValue?.fieldValue?.partialReferenceValue) {
        const { referenceValue, referenceValueKey } =
          action.setValue.fieldValue.partialReferenceValue;
        if (!validateEntity(referenceValue!, referenceValueKey!)) {
          setErrorMessage(
            `Entity '${referenceValueKey}' cannot be removed as it is used in future steps. 
               Kindly add it back or remove the step that is using it.`,
          );
          return;
        }
      }
    }

    const updated: Action = {
      ...action,
      description: action!.description,
      extractFields: {
        ...action.extractFields,
        fields: entities,
      },
    };

    const updatedAction = {
      action: updated,
      description: action!.description,
    };

    dispatch(updateAction(action, updatedAction));
    onClose();
    setErrorMessage('');
  };

  return (
    <Container spacing={2}>
      <OrbyTypography color={OrbyColorPalette['grey-900']} weight='bold'>
        Here are the entities to be extracted
      </OrbyTypography>
      <OrbyTypography color={OrbyColorPalette['grey-900']}>
        Press enter to add a new entity.
      </OrbyTypography>
      <ExtractEntityComponent
        entities={entities}
        onEntitiesChange={(entities: string[]) => {
          setErrorMessage('');
          setEntities(entities);
        }}
        isEditable={isEditable}
      />
      {errorMessage && (
        <OrbyTypography
          size='sm'
          color={OrbyColorPalette['error-700']}
          weight='medium'
        >
          Error: {errorMessage}
        </OrbyTypography>
      )}
      <Box>
        <OrbyButton
          variant='primary-outline'
          disabled={errorMessage !== '' || !entityHasChanged}
          onClick={onUpdate}
          label='Save'
        />
      </Box>
    </Container>
  );
};

export default ExtractEntity;
