import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextSegmentInfo } from '../../../../../../redux/reducers/review_task.reducer';
import {
  isMultipleCellsSelectedSelector,
  selectedEntityIdSelector,
  selectedEntityIdsForAnnotationSelector,
  selectedEntityInfoSelector,
  selectedParentEntityInfoSelector,
  selectedTableEntitiesInfoMapSelector,
  selectedTableEntitiesInfoSelector,
  selectedTaskEntityInfoSelector,
  selectedTextSegmentIdSelector,
} from '../../../../../../redux/selectors/review_task.selectors';
import BoundingBox from './components/BoundingBox';
import { boxPositionValuesUtilV2 } from '../../../../../../utils/BoxPositionValuesUtilV2';
import { Box } from '@mui/material';
import { addAlpha } from '../../../../../../utils/helpers';
import {
  handleAnnotationSuggestionAction,
  setSelectedEntityIdAction,
  setSelectedEntityIdsForAnnotationAction,
  setSelectedParentEntityTypeAction,
  setSelectedTextSegmentIdAction,
} from '../../../../../../redux/actions/review_task.action';
import { goToVisibleElement } from '../../../../../../utils/ReviewTaskUtils';
import TableLayoutBoundingBox from '../ReviewPageTableModal/components/TableLayoutBoundingBox';
import { getFeatureFlagsForOrgAndUserSelector } from '../../../../../../redux/selectors/feature_flags.selectors';
import { FEATURE_FLAGS } from '../../../../../../utils/constants';
import { isFeatureFlagEnabled } from '../../../../../FeatureFlags/FeatureFlagUtils';

interface Props {
  currentPage: number;
  scale: number;
}

const DocumentBoundingBoxLayout: React.FC<Props> = ({ currentPage, scale }) => {
  const dispatch = useDispatch();
  const selectedEntityIdsForAnnotation = useSelector(
    selectedEntityIdsForAnnotationSelector,
  );
  const selectedTableEntitiesInfo = useSelector(
    selectedTableEntitiesInfoSelector,
  );
  const selectedTableEntitiesInfoMap = useSelector(
    selectedTableEntitiesInfoMapSelector,
  );
  const featureFlags = useSelector(getFeatureFlagsForOrgAndUserSelector);
  const showNewNestedUI = isFeatureFlagEnabled(
    FEATURE_FLAGS.NESTED_HITL,
    featureFlags,
  );

  const selectedParentEntityInfo = useSelector(
    selectedParentEntityInfoSelector,
  );
  const entityInfoMap = useSelector(selectedTaskEntityInfoSelector);
  const selectedEntityId = useSelector(selectedEntityIdSelector);
  const selectedEntity = useSelector(selectedEntityInfoSelector);
  const selectedTextSegmentId = useSelector(selectedTextSegmentIdSelector);
  const isMultipleCellsSelected = useSelector(isMultipleCellsSelectedSelector);

  const getBackgroundColor = (entityId: string) => {
    const entity =
      selectedTableEntitiesInfoMap[entityId] || entityInfoMap[entityId];
    if (entity?.isReviewed) {
      return '#0F8470';
    }
  };
  // THIS EXTRACTS ALL THE TEXT SEGMENT FROM ENTITY INFO MAP TO DISPLAY BOUNDING
  // BOX FOR EACH SEGMENT
  const getAllTextSegments = useMemo(() => {
    const textSegments: TextSegmentInfo[] = [];
    if (selectedParentEntityInfo) {
      for (const entityInfo of selectedTableEntitiesInfo) {
        // Restrict showing bbox for that one id that is selected in selectedEntityIdsForAnnotation
        // when selectedEntityIdsForAnnotation.length is 1
        if (
          selectedEntityIdsForAnnotation.length === 1 &&
          selectedEntityIdsForAnnotation[0] === entityInfo.id
        ) {
          const segments = Object.values(entityInfo.textSegments);
          if (segments.length > 1) {
            textSegments.push(...segments.slice(1));
          }
          continue;
        }
        textSegments.push(...Object.values(entityInfo.textSegments));
      }
    } else {
      const entityInfoList = Object.values(entityInfoMap);
      for (const entityInfo of entityInfoList) {
        if (selectedEntityId !== entityInfo.id) {
          textSegments.push(...Object.values(entityInfo.textSegments));
        }
      }
    }
    // FILTERING TEXT-SEGMENTS OF CURRENT PAGE ONLY (SINCE THIS IS RENDERED FOR EVERY PAGE)
    return textSegments.filter((t) => t.modifiedPage === currentPage);
  }, [
    currentPage,
    selectedParentEntityInfo,
    selectedEntityIdsForAnnotation,
    selectedTableEntitiesInfo,
    selectedEntityId,
    entityInfoMap,
  ]);

  // THIS RETURNS THE TEXT SEGMENTS OF SELECTED ENTITY ONLY
  const getEntityAllTextSegments = useMemo(() => {
    return Object.values(selectedEntity?.textSegments || {});
  }, [selectedEntity]);

  // THIS RETURNS TOP, LEFT, WIDTH, HEIGHT OF SELECTED TEXT SEGMENT
  const getBoxPositionValuesOfSelectedTextSegment = () => {
    const textSegmentInfo =
      selectedEntity?.textSegments[selectedTextSegmentId!];
    return boxPositionValuesUtilV2.getBoxPositionValues(
      textSegmentInfo?.vertices,
      // SENDING SCALE 1 HERE TO GET THE DIMENSIONS ACCORDING TO ORIGINAL DOCUMENT IMAGE DIMENSIONS
      1,
      boxPositionValuesUtilV2.getAdjustedPadding(textSegmentInfo!, scale),
    );
  };

  // Checks whether bounding box needs to be displayed or not
  const showBoundingBox = (): boolean => {
    const segment =
      selectedEntity?.textSegments[selectedTextSegmentId as string];
    const page = segment?.modifiedPage || segment?.page;
    // If selected text segment of an entity lies on the current page
    const doesSelectedSegmentLiesOnCurrentPage = page === currentPage;
    // If for selected entity no text segment is selected and page is first page
    const noTextSegmentForCurrentPage =
      !selectedTextSegmentId && currentPage === 0;
    return doesSelectedSegmentLiesOnCurrentPage || noTextSegmentForCurrentPage;
  };

  const showTableBoundingBox = useMemo(() => {
    if (selectedEntityId || selectedEntityIdsForAnnotation.length === 0)
      return false;

    const selectedEntity =
      selectedTableEntitiesInfoMap[selectedEntityIdsForAnnotation[0]];

    const selectedEntitySegments = Object.values(
      selectedEntity?.textSegments || {},
    );
    // Determine if there are no text segments and the current page is 0
    const emptySegments = !selectedEntitySegments.length && currentPage === 0;
    // Determine if the first text segment lies on the current page
    const segmentLiesOnCurrentPage =
      selectedEntitySegments.length > 0 &&
      selectedEntitySegments[0].modifiedPage === currentPage;

    return emptySegments || segmentLiesOnCurrentPage;
  }, [
    selectedEntityId,
    selectedEntityIdsForAnnotation,
    currentPage,
    selectedTableEntitiesInfo,
  ]);

  const disableClickOnHighlight = useMemo(() => {
    if (selectedEntityId) return true;

    return (
      isMultipleCellsSelected || selectedEntityIdsForAnnotation.length === 1
    );
  }, [
    selectedEntityId,
    isMultipleCellsSelected,
    selectedEntityIdsForAnnotation,
  ]);

  // SCROLLING TO THE SELECTED TEXT SEGMENT'S BOUNDING BOX HERE
  useEffect(() => {
    // only scroll if vertices are present
    if (
      selectedTextSegmentId &&
      selectedEntity?.textSegments[selectedTextSegmentId]?.vertices &&
      selectedEntity?.textSegments[selectedTextSegmentId]?.vertices.length > 0
    ) {
      goToVisibleElement('bounding-box');
    }
  }, [selectedTextSegmentId, selectedEntityId]);

  // This useEffect is added to scroll the pdf to first table element's location
  // when any table entity is opened
  useEffect(() => {
    if (
      !selectedEntityIdsForAnnotation.length &&
      selectedTableEntitiesInfo.length &&
      currentPage === 0
    ) {
      let firstTextSegment: TextSegmentInfo | undefined;
      for (const entity of selectedTableEntitiesInfo) {
        const segments = Object.values(entity.textSegments);
        if (segments.length) {
          firstTextSegment = segments[0];
          break;
        } else {
          continue;
        }
      }
      if (firstTextSegment) {
        goToVisibleElement(
          `${firstTextSegment.entityId}-${firstTextSegment.id}`,
        );
      }
    }
  }, [selectedParentEntityInfo?.type]);

  return (
    <>
      {
        // WHEN THERE IS NO ENTITY SELECTED EACH BOUNDING BOX OF EVERY TEXT SEGMENT PRESENT
        // IN THE DOC GETS DISPLAYED HERE
        getAllTextSegments.map((textSegment: TextSegmentInfo) => {
          const boxPositionValues =
            boxPositionValuesUtilV2.getBoxPositionValues(
              textSegment?.pageCorrespondingVertices || textSegment?.vertices,
              scale,
              boxPositionValuesUtilV2.getAdjustedPadding(textSegment, scale),
              true,
            );

          // skip rendering BB if vertices / pageCorrespondingVertices are not present
          // this will increase rendering efficiency
          if (
            !(
              textSegment.vertices.length ||
              textSegment.pageCorrespondingVertices.length
            )
          ) {
            return (
              <React.Fragment
                key={`${textSegment.entityId}-${textSegment.id}`}
              />
            );
          }

          return (
            <Box
              role={'presentation'}
              id={`${textSegment.entityId}-${textSegment.id}`}
              key={`${textSegment.entityId}-${textSegment.id}`}
              zIndex={1}
              position={'absolute'}
              sx={{
                bgcolor: addAlpha(
                  getBackgroundColor(textSegment.entityId) || '#1669F7',
                  0.4,
                ),
                cursor: 'pointer',
              }}
              top={boxPositionValues.top}
              left={boxPositionValues.left}
              width={boxPositionValues.width}
              height={boxPositionValues.height}
              border={
                selectedEntityIdsForAnnotation.includes(textSegment.entityId)
                  ? `1.5px solid ${
                      getBackgroundColor(textSegment.entityId) || '#0500FF'
                    }`
                  : 'none'
              }
              onClick={() => {
                if (disableClickOnHighlight) return;
                // If SelectedParentEntityInfo is selected return to avoid unnecessary operations
                const entityInfo =
                  entityInfoMap[textSegment.entityId] ||
                  selectedTableEntitiesInfoMap[textSegment.entityId];

                if (!entityInfo) return;
                if (selectedParentEntityInfo) {
                  dispatch(
                    setSelectedEntityIdsForAnnotationAction([entityInfo.id]),
                  );
                  return;
                }
                if (entityInfo?.parentEntityId && showNewNestedUI) {
                  if (!selectedParentEntityInfo) {
                    dispatch(
                      setSelectedParentEntityTypeAction({
                        id: entityInfo.parentEntityId,
                        type: entityInfo.parentEntityType || '',
                      }),
                    );
                    dispatch(
                      setSelectedEntityIdsForAnnotationAction([entityInfo.id]),
                    );
                  }
                } else {
                  dispatch(setSelectedEntityIdAction(textSegment.entityId));
                  dispatch(setSelectedTextSegmentIdAction(textSegment.id));
                }
                // When any prediction is visible and user clicks on existing entity's bounding box
                // then we remove the suggestion annotation
                dispatch(handleAnnotationSuggestionAction(false));
              }}
            />
          );
        })
      }
      {/* WHEN ANY ENTITY IS SELECTED THIS COMPONENT GETS DISPLAYED AND NO BOUNDING BOX FOR EXTRA ENTITY */}
      {selectedEntityId && !selectedEntity?.isExtra && (
        <>
          {/* If selected text segment is present then check whether the text segment page is same as current page 
              to display bounding box. If the text segment is not present then only show it for the first page
          */}
          {showBoundingBox() && (
            <BoundingBox
              scale={scale}
              boxTop={getBoxPositionValuesOfSelectedTextSegment().top}
              boxLeft={getBoxPositionValuesOfSelectedTextSegment().left}
              boxWidth={getBoxPositionValuesOfSelectedTextSegment().width}
              boxHeight={getBoxPositionValuesOfSelectedTextSegment().height}
              currentPage={currentPage}
            />
          )}
          <Box className={'text-segment'}>
            {getEntityAllTextSegments.map(
              (textSegment: TextSegmentInfo, index) => {
                const boxPositionValues =
                  boxPositionValuesUtilV2.getBoxPositionValues(
                    textSegment.vertices,
                    scale,
                    boxPositionValuesUtilV2.getAdjustedPadding(
                      textSegment,
                      scale,
                    ),
                    true,
                  );
                if (
                  selectedTextSegmentId !== textSegment.id &&
                  textSegment.page === currentPage
                ) {
                  return (
                    <Box
                      key={index}
                      zIndex={1}
                      position={'absolute'}
                      sx={{
                        bgcolor: addAlpha('#1669F7', 0.4),
                        cursor: 'pointer',
                      }}
                      top={boxPositionValues.top}
                      left={boxPositionValues.left}
                      width={boxPositionValues.width}
                      height={boxPositionValues.height}
                      onClick={() => {
                        dispatch(
                          setSelectedTextSegmentIdAction(textSegment.id),
                        );
                      }}
                    />
                  );
                }
              },
            )}
          </Box>
        </>
      )}
      {/* When any parent entity is opened and some entities are selected for annotation, this 
          component is visible to enable user to drag to draw/ click to draw b-box
      */}
      {showTableBoundingBox && (
        <TableLayoutBoundingBox scale={scale} currentPage={currentPage} />
      )}
    </>
  );
};

export default React.memo(DocumentBoundingBoxLayout);
