import { Box } from '@mui/material';
import {
  OrbyColorPalette,
  OrbyMenuItem,
  OrbySelect,
  OrbyTypography,
} from 'orby-ui/src';
import { ScheduleConfigRecurrencePatternDayOfWeek } from 'protos/pb/v1alpha1/schedule';
import React from 'react';
import { WEEKDAYS } from '../utils/helper';

const SchedulerWeekdaysSelector: React.FC<{
  selectedDays:
    | (ScheduleConfigRecurrencePatternDayOfWeek | undefined)[]
    | undefined;
  setSelectedDays: (
    days: (ScheduleConfigRecurrencePatternDayOfWeek | undefined)[] | undefined,
  ) => void;
  error: string | null;
  setError: (error: string | null) => void;
}> = ({ selectedDays, setSelectedDays, error, setError }) => {
  return (
    <>
      <OrbySelect
        width='100%'
        value={[...(new Set(selectedDays) || [])]}
        multiple
        error={!!error}
        errorBorderColor={OrbyColorPalette['error-700']}
        height='44px'
        inputProps={{
          sx: {
            '& .MuiSelect-select': {
              padding: '10px 14px',
            },
            height: '24px',
            '& .MuiTypography-root': {
              fontSize: '16px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${OrbyColorPalette['error-700']}`,
            },
          },
        }}
        renderValue={(value) => {
          const selectedDays =
            value as ScheduleConfigRecurrencePatternDayOfWeek[];
          return selectedDays.length > 0 ? (
            <OrbyTypography
              size='sm'
              color={OrbyColorPalette['grey-900']}
              weight={'regular'}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {selectedDays
                .map((day) => WEEKDAYS.find((d) => d.value === day)?.title)
                .join(', ')}
            </OrbyTypography>
          ) : (
            <OrbyTypography
              size='sm'
              color={OrbyColorPalette['grey-500']}
              weight={'regular'}
            >
              Select days
            </OrbyTypography>
          );
        }}
        onChange={(event) => {
          setSelectedDays(
            event.target.value as ScheduleConfigRecurrencePatternDayOfWeek[],
          );
          setError(null);
        }}
      >
        {WEEKDAYS.map((day) => (
          <OrbyMenuItem
            width='auto'
            key={day.value}
            title={day.title}
            value={day.value}
            isSelected={selectedDays && selectedDays.includes(day.value)}
          />
        ))}
      </OrbySelect>

      <Box
        sx={{
          color: OrbyColorPalette['error-700'],
          fontSize: '14px',
          mt: '3px',
        }}
      >
        {error}
      </Box>
    </>
  );
};

export default SchedulerWeekdaysSelector;
