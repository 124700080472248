import { Box, Button } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  allowEditingTaskSelector,
  getDocumentActivityTypeSelector,
  getOriginalPredictedResultSelector,
  getPredictedResultSelector,
  reviewStartTimeSelector,
  getSelectedReviewFilterSectionSelector,
  idleSessionsSelector,
  infoToDetectChangesSelector,
  originalTaskEntityInfoSelector,
  selectedEntityInfoSelector,
  selectedParentEntityInfoSelector,
  selectedTableEntitiesInfoSelector,
  selectedTaskEntityInfoSelector,
  taskForReviewSelector,
} from '../../../../../redux/selectors/review_task.selectors';
import CustomComboButtons from '../../../../../components/CustomComboButtons';
import {
  Review,
  ReviewType,
  Task,
  TaskSTATUS,
  UpdateTaskRequest,
  UserDeclinedTaskReason,
  UserDeclinedTaskReasonTYPE,
} from 'protos/pb/v1alpha2/tasks_service';
import { ExecutionStep } from 'protos/pb/v1alpha2/execution_steps';
import {
  getSelectedTaskDocument,
  msToTime,
} from '../../../../../utils/helpers';
import { Document } from 'protos/google/cloud/documentai/v1/document';
import {
  allowEditingTaskAction,
  confirmTableEntitiesInfoAction,
  setAutomationInProgress,
  updateTaskAction,
} from '../../../../../redux/actions/review_task.action';
import { Activity } from 'protos/automation_mining/automation_mining';
import DeclineAllModal from '../../DeclineAllModal/DeclineAllModal';
import {
  confirmReview,
  formatIdleSessionsForBackend,
  getDocumentEntitiesFromMap,
} from '../../../../../utils/ReviewTaskUtils';
import {
  AutomationProgressStatus,
  EntityFilter,
} from '../../../../../utils/constants';
import { saveAs } from 'file-saver';
import {
  isAnyEntityModified,
  isEntityModified,
  isParentEntityModified,
  showFloatingModalWarning,
} from '../../../../../utils/UnsavedChangesUtils';
import { loggedInUserSelector } from '../../../../../redux/selectors/user.selectors';
import { sentryService } from '../../../../../services/SentryService';
import { UserProfileInfo } from 'protos/common/user_profile';

interface Props {
  debug?: boolean;
}

const ReviewPageFooter: React.FC<Props> = ({ debug }) => {
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const documentActivityType = useSelector(getDocumentActivityTypeSelector);
  const originalPredictedResult = useSelector(
    getOriginalPredictedResultSelector,
  );
  const predictedResult = useSelector(getPredictedResultSelector);
  const reviewStartTime = useSelector(reviewStartTimeSelector);
  const idleSessions = useSelector(idleSessionsSelector);
  const task = useSelector(taskForReviewSelector) as Task;
  const entitiesInfoMap = useSelector(selectedTaskEntityInfoSelector);
  const originalEntitiesInfoMap = useSelector(originalTaskEntityInfoSelector);
  const selectedTableEntitiesInfo = useSelector(
    selectedTableEntitiesInfoSelector,
  );
  const user = useSelector(loggedInUserSelector);
  const selectedParentEntityInfo = useSelector(
    selectedParentEntityInfoSelector,
  );
  const allowEditingTask = useSelector(allowEditingTaskSelector);

  const [markComplete, setMarkComplete] = useState(false);
  const selectedEntityInfo = useSelector(selectedEntityInfoSelector);
  const selectedReviewFilterSection = useSelector(
    getSelectedReviewFilterSectionSelector,
  );
  const infoToDetectChanges = useSelector(infoToDetectChangesSelector);

  const isTaskModified = useMemo(() => {
    if (documentActivityType === Activity.CLASSIFY_DOCUMENT) {
      return (
        predictedResult?.classificationLabel !==
        originalPredictedResult?.classificationLabel
      );
    }
    return isAnyEntityModified(infoToDetectChanges, undefined, false);
  }, [
    infoToDetectChanges,
    documentActivityType,
    predictedResult,
    originalPredictedResult,
  ]);

  const getTotalReviewTime = useCallback(() => {
    return Date.now() - (reviewStartTime ?? 0);
  }, [reviewStartTime]);

  const getReviewDetailsForTask = useCallback(
    (reviewType = ReviewType.NORMAL_REVIEW) => {
      // Add the person who reviewed the task
      return Review.create({
        user: user?.email,
        reviewType,
        sessions: [
          {
            reviewer: UserProfileInfo.create({ username: user?.email }),
            idleSessions: formatIdleSessionsForBackend(idleSessions),
            startTime: reviewStartTime ? new Date(reviewStartTime) : undefined,
            endTime: new Date(),
          },
        ],
      });
    },
    [user?.email, reviewStartTime, idleSessions],
  );

  const dispatch = useDispatch();

  // For Pending Task
  const acceptClassification = useCallback(() => {
    const req = UpdateTaskRequest.create({});
    const updatedTask = Task.create(task);

    const documentStep = getSelectedTaskDocument(updatedTask);
    if (documentStep && documentStep.mode) {
      documentStep.mode.complete = true;
    }
    /**
     * Update the document classification result if it is changed
     */
    if (
      documentStep &&
      predictedResult &&
      documentStep.predictionResult &&
      predictedResult.classificationLabel !=
        documentStep?.predictionResult?.classificationLabel
    ) {
      // This is done to save the actual predicted value in
      // originalClassificationLabel and the new label in classificationLabel
      // if originalClassificationLabel exists it means
      // it contains the actual predicted value then no need to modify it again
      // so only update originalClassificationLabel if it is not present
      if (!documentStep.predictionResult.originalClassificationLabel) {
        documentStep.predictionResult.originalClassificationLabel =
          documentStep?.predictionResult?.classificationLabel;
        documentStep.predictionResult.originalConfidence =
          documentStep?.predictionResult?.confidence;
      }
      documentStep.predictionResult.classificationLabel =
        predictedResult.classificationLabel;
    }

    if (
      documentStep &&
      documentStep.predictionResult &&
      documentStep.predictionResult.confidence
    ) {
      // Send the confidence as 1 if user clicks on Accept button
      documentStep.predictionResult.confidence = 1;
    }

    for (const step of updatedTask?.executionSteps as ExecutionStep[]) {
      if (step.documents && step.documents.length > 0) {
        // We don't need to send the document bytes which was downloaded and stored in the document proto for rendering
        const completeDocument = step.documents[0];
        step.documents[0] = { uri: completeDocument.uri } as Document;
      }
    }
    // Check if updateTask.reviews exists and has elements
    if (updatedTask.reviews && updatedTask.reviews.length > 0) {
      const lastReview = updatedTask.reviews[updatedTask.reviews.length - 1];
      const newReviewDetails = getReviewDetailsForTask();
      // Update the last review details
      // TODO: Sughosh to confirm if we need to modify the reviewer's email
      lastReview.sessions = newReviewDetails.sessions;
    } else {
      // Create a new review if none exist. This should rarely occur as the backend typically
      // creates a review object for pending reviewers. If this code executes, log to Sentry
      // for developers to investigate the unexpected scenario.
      sentryService.error('task.reviews not found !');
      updatedTask.reviews = [getReviewDetailsForTask()];
    }
    const reviewTime = getTotalReviewTime();
    // TODO: remove this field after the idle session feature out in prod
    updatedTask.reviewedTime = msToTime(reviewTime, true);
    updatedTask.status = TaskSTATUS.ACCEPTED;
    req.task = updatedTask;
    dispatch(updateTaskAction(req));
  }, [predictedResult, getTotalReviewTime, task, getReviewDetailsForTask]);

  // Accept Classification for completed task
  const acceptCompletedClassification = useCallback(() => {
    const req = UpdateTaskRequest.create({});
    const updatedTask = Task.create(task);

    const documentStep = getSelectedTaskDocument(updatedTask);

    // No need to check whether modification was there or not because
    // button would be disabled if there are no modifications

    if (documentStep && documentStep.predictionResult) {
      // This is done to save the actual predicted value in
      // originalClassificationLabel and the new label in classificationLabel
      // if originalClassificationLabel exists it means
      // it contains the actual predicted value then no need to modify it again
      // so only update originalClassificationLabel if it is not present
      if (!documentStep?.predictionResult?.originalClassificationLabel) {
        documentStep.predictionResult.originalClassificationLabel =
          documentStep?.predictionResult?.classificationLabel;
        documentStep.predictionResult.originalConfidence =
          documentStep?.predictionResult?.confidence;
      }

      // update the classificationLabel
      documentStep.predictionResult.classificationLabel =
        predictedResult?.classificationLabel;

      // Update the confidence to 1
      documentStep.predictionResult.confidence = 1;
    }

    for (const step of updatedTask?.executionSteps as ExecutionStep[]) {
      if (step.documents && step.documents.length > 0) {
        // We don't need to send the document bytes which was downloaded and stored in the document proto for rendering
        const completeDocument = step.documents[0];
        step.documents[0] = { uri: completeDocument.uri } as Document;
      }
    }

    updatedTask.reviews?.push(
      getReviewDetailsForTask(ReviewType.MODIFICATION_REVIEW),
    );
    const reviewTime = getTotalReviewTime();
    // TODO: remove this field after the idle session feature out in prod
    updatedTask.reviewedTime = msToTime(reviewTime, true);
    updatedTask.status = TaskSTATUS.COMPLETED;
    req.task = updatedTask;
    dispatch(updateTaskAction(req));
  }, [
    predictedResult?.classificationLabel,
    getTotalReviewTime,
    task,
    getReviewDetailsForTask,
  ]);

  // For Completed Task
  const acceptCompletedEntity = useCallback(() => {
    const req: UpdateTaskRequest = UpdateTaskRequest.create({});
    const updateTask = Task.create(task);

    const documentStep = getSelectedTaskDocument(updateTask);

    const originalDocument: Document[] = [];

    if (
      documentStep?.documents?.[0] &&
      documentStep.originalDocuments?.length === 0
    ) {
      originalDocument.push({
        uri: documentStep.documents[0].uri,
      } as Document);
      documentStep.originalDocuments = originalDocument;
    }

    // SENDING TRUE IN 2nd PARAM TO INCREASE CONFIDENCE OF ENTITY TO 1
    const entities = getDocumentEntitiesFromMap(entitiesInfoMap, true);
    const document: Document = Document.create({
      uri: documentStep?.documents?.[0].uri,
      entities,
    });

    if (documentStep?.documents) {
      // No need to send fields other than uri for previous documents,
      // Document uri contains all the relevant information by itself.
      // This also saves in bandwidth latency.
      const documents = documentStep.documents.map((document) =>
        Document.create({
          uri: document.uri,
        }),
      );
      documentStep.documents = documents;

      // Always send the updated document at 0th Index
      documentStep.documents.unshift(document);
    }
    updateTask.reviews?.push(
      getReviewDetailsForTask(ReviewType.MODIFICATION_REVIEW),
    );
    const reviewTime = getTotalReviewTime();
    // TODO: remove this field after the idle session feature out in prod
    updateTask.reviewedTime = msToTime(reviewTime, true);
    updateTask.status = TaskSTATUS.COMPLETED;
    req.task = updateTask;
    dispatch(updateTaskAction(req));
  }, [entitiesInfoMap, getTotalReviewTime, task, getReviewDetailsForTask]);

  // For Pending Task
  const acceptEntity = useCallback(() => {
    const req: UpdateTaskRequest = UpdateTaskRequest.create({});
    const updateTask = Task.create(task);

    const documentStep = getSelectedTaskDocument(updateTask);

    if (documentStep?.mode) {
      documentStep.mode.complete = true;
    }
    let isDocumentModified = false;

    if (
      isAnyEntityModified(
        {
          modifiedEntityInfoMap: entitiesInfoMap,
          originalEntityInfoMap: originalEntitiesInfoMap,
          // Only these keys would be used to check any modification, rest keys will be skipped
        },
        ['textSegments', 'normalizedValue', 'extraEntityText'],
      )
    ) {
      isDocumentModified = true;
    }

    if (isDocumentModified) {
      const originalDocument: Document[] = [];

      if (
        documentStep?.documents?.[0] &&
        documentStep.originalDocuments?.length === 0
      ) {
        originalDocument.push({
          uri: documentStep.documents[0].uri,
        } as Document);
        documentStep.originalDocuments = originalDocument;
      }

      // SENDING TRUE IN 2nd PARAM TO INCREASE CONFIDENCE OF ENTITY TO 1
      const entities = getDocumentEntitiesFromMap(entitiesInfoMap, true);
      const document: Document = Document.create({
        uri: documentStep?.documents?.[0].uri,
        entities,
      });
      if (documentStep?.documents?.[0]) {
        documentStep.documents[0] = document;
      }
    } else {
      // If the document is not modified, we don't need to send all the fields inside it to the BE server.
      // Document uri contains all the relevant information by itself. This also saves in bandwidth latency.
      const completeDocument = documentStep?.documents?.[0];
      if (documentStep?.documents?.[0]) {
        documentStep.documents[0] = { uri: completeDocument?.uri };
      }
    }
    // Check if updateTask.reviews exists and has elements
    if (updateTask.reviews && updateTask.reviews.length > 0) {
      const lastReview = updateTask.reviews[updateTask.reviews.length - 1];
      const newReviewDetails = getReviewDetailsForTask();
      // Update the last review details
      // TODO: Sughosh to confirm if we need to modify the reviewer's email
      lastReview.sessions = newReviewDetails.sessions;
    } else {
      // Create a new review if none exist. This should rarely occur as the backend typically
      // creates a review object for pending reviewers. If this code executes, log to Sentry
      // for developers to investigate the unexpected scenario.
      sentryService.error('task.reviews not found !', { updateTask });
      updateTask.reviews = [getReviewDetailsForTask()];
    }
    const reviewTime = getTotalReviewTime();
    // TODO: remove this field after the idle session feature out in prod
    updateTask.reviewedTime = msToTime(reviewTime, true);
    updateTask.status = TaskSTATUS.ACCEPTED;
    req.task = updateTask;
    dispatch(updateTaskAction(req));
  }, [
    entitiesInfoMap,
    originalEntitiesInfoMap,
    getTotalReviewTime,
    task,
    getReviewDetailsForTask,
  ]);

  // This function saves the file of the current changes when opened
  // in debug mode from ML Testing tab
  const saveDebugFile = useCallback((document: Document) => {
    // Encode the string into bytes
    const bytesArray = Document.encode(document).finish();
    // const bytesArray = document
    const blob = new Blob([bytesArray], { type: 'protobuf' });
    let fileName = prompt('Enter file name with extension');
    if (fileName === null) {
      return;
    } else if (fileName === '') {
      fileName = 'modified.proto';
    }
    saveAs(blob, fileName);
  }, []);

  // This function saved all the information for a classification type of task
  // when the task is opened using proto file in debug mode
  const acceptDebugClassification = useCallback(() => {
    const updatedTask = Task.create(task);
    const documentStep = getSelectedTaskDocument(updatedTask);
    if (
      documentStep &&
      predictedResult &&
      documentStep.predictionResult &&
      documentStep.predictionResult.originalClassificationLabel &&
      predictedResult.classificationLabel !=
        documentStep?.predictionResult?.classificationLabel
    ) {
      if (!documentStep.predictionResult.originalClassificationLabel) {
        documentStep.predictionResult.originalClassificationLabel =
          documentStep?.predictionResult?.classificationLabel;
        documentStep.predictionResult.originalConfidence =
          documentStep?.predictionResult?.confidence;
      }
      documentStep.predictionResult.classificationLabel =
        predictedResult.classificationLabel;
    }
    saveDebugFile(documentStep?.documents?.[0] as Document);
  }, [predictedResult, saveDebugFile, task]);

  // This function saved all the information for a extraction type of task
  // when the task is opened using proto file in debug mode
  const acceptDebugEntity = useCallback(() => {
    const updatedTask = Task.create(task);
    const documentStep = getSelectedTaskDocument(updatedTask);
    let isDocumentModified = false;
    if (
      isAnyEntityModified(
        {
          originalEntityInfoMap: originalEntitiesInfoMap,
          modifiedEntityInfoMap: entitiesInfoMap,
          // Only these keys would be used to check any modification, rest keys will be skipped
        },
        ['textSegments', 'normalizedValue', 'extraEntityText'],
      )
    ) {
      isDocumentModified = true;
    }
    if (isDocumentModified) {
      const originalDocument: Document[] = [];
      if (documentStep?.originalDocuments?.length === 0) {
        originalDocument.push(
          Document.create({
            uri: documentStep?.documents?.[0]?.uri,
          }),
        );
        documentStep.originalDocuments = originalDocument;
      }
      const entities = getDocumentEntitiesFromMap(entitiesInfoMap);
      if (documentStep?.documents?.[0]) {
        documentStep.documents[0].entities = entities;
      }
    }
    saveDebugFile(documentStep!.documents![0]);
  }, [entitiesInfoMap, originalEntitiesInfoMap, saveDebugFile, task]);

  const rejectTask = useCallback(
    (rejectionReason: { type: string; description: string }) => {
      dispatch(
        setAutomationInProgress(AutomationProgressStatus.DECLINING_TASK),
      );
      const declineReason = UserDeclinedTaskReason.create({});
      declineReason.type =
        rejectionReason.type as unknown as UserDeclinedTaskReasonTYPE;
      declineReason.description = rejectionReason.description;

      const updateTask = Task.create(task);

      updateTask.status = TaskSTATUS.REJECTED_INCORRECT;
      updateTask.declineReason = declineReason;

      updateTask.executionSteps?.forEach((step) => {
        if (step?.documents && step.documents.length > 0) {
          const completeDocument = step.documents[0];
          // Replace the first document with a new document containing only the URI
          step.documents[0] = Document.create({ uri: completeDocument.uri });
        }
      });

      const req = { task: updateTask };
      dispatch(updateTaskAction(req));
    },
    [task],
  );

  const getPrimaryLabel = useCallback(() => {
    if (documentActivityType === Activity.CLASSIFY_DOCUMENT) {
      return 'Decline';
    } else {
      return 'Decline All';
    }
  }, [documentActivityType]);

  const getDeclineModalDescription = useCallback(() => {
    if (documentActivityType === Activity.CLASSIFY_DOCUMENT) {
      return "Declining means all the changes made previously will be ignored, the task will be declined, and there won't be any output. Are you sure?";
    } else {
      return "Declining all means all the changes made previously will be ignored, the task will be declined, and there won't be any output. Are you sure?";
    }
  }, [documentActivityType]);

  const handleSecondaryClick = useCallback(() => {
    // check if floating modal is open and has error if yes show warning and block, otherwise save the entity
    if (selectedEntityInfo) {
      if (selectedEntityInfo.error) {
        showFloatingModalWarning();
        return;
      }
      // only auto save when modification is there
      if (
        isEntityModified(
          entitiesInfoMap[selectedEntityInfo.id],
          selectedEntityInfo,
        )
      ) {
        // this will update the entitiesInfoMap
        confirmReview(
          selectedEntityInfo,
          task as Task,
          entitiesInfoMap,
          selectedReviewFilterSection as EntityFilter,
          false, // We don't auto select next available entity in this case
        );
      }

      // check if Table floating modal is open and has any errors if yes show warning and block, otherwise save
    } else if (
      selectedParentEntityInfo?.id &&
      selectedTableEntitiesInfo?.length
    ) {
      if (selectedTableEntitiesInfo.some((e) => !!e.error)) {
        showFloatingModalWarning(true);
        return;
      }
      // only auto save when modification is there
      if (isParentEntityModified(selectedTableEntitiesInfo, entitiesInfoMap)) {
        dispatch(confirmTableEntitiesInfoAction());
      }
    }
    setMarkComplete(true);
  }, [
    entitiesInfoMap,
    selectedEntityInfo,
    selectedParentEntityInfo?.id,
    selectedReviewFilterSection,
    selectedTableEntitiesInfo,
    task,
  ]);

  const handleMarkComplete = useCallback(() => {
    const isClassificationDocument =
      documentActivityType === Activity.CLASSIFY_DOCUMENT;
    if (debug) {
      // The task will be saved and stored in a proto file when debug mode
      if (isClassificationDocument) {
        acceptDebugClassification();
      } else {
        acceptDebugEntity();
      }
    } else {
      // The task will be updated in the database through GRPC
      dispatch(
        setAutomationInProgress(AutomationProgressStatus.ACCEPTING_TASK),
      );
      if (isClassificationDocument) {
        if (task.status === TaskSTATUS.COMPLETED) {
          acceptCompletedClassification();
        } else {
          acceptClassification();
        }
      } else {
        if (task.status === TaskSTATUS.COMPLETED) {
          acceptCompletedEntity();
        } else {
          acceptEntity();
        }
      }
    }
  }, [
    acceptClassification,
    acceptCompletedClassification,
    acceptCompletedEntity,
    acceptDebugClassification,
    acceptDebugEntity,
    acceptEntity,
    debug,
    documentActivityType,
    task.status,
  ]);

  // reason we are executing handleMarkComplete in such a fashion is because
  // handleMarkComplete needs to wait for entitiesInfoMap to get updated
  useEffect(() => {
    if (markComplete) {
      setMarkComplete(false);
      handleMarkComplete();
    }
  }, [markComplete, entitiesInfoMap, handleMarkComplete]);

  return (
    <Box
      width={'100%'}
      bgcolor={'#fefeff'}
      justifyContent={'center'}
      display={'flex'}
    >
      {allowEditingTask ? (
        <>
          <DeclineAllModal
            open={isDeclineModalOpen}
            setOpen={setIsDeclineModalOpen}
            onSubmit={rejectTask}
            description={getDeclineModalDescription()}
            label={
              documentActivityType === Activity.CLASSIFY_DOCUMENT
                ? 'Decline'
                : 'Decline All'
            }
          />

          <CustomComboButtons
            size='large'
            primaryLabel={getPrimaryLabel()}
            secondaryLabel={debug ? 'Save Locally' : 'Mark Complete'}
            secondaryTitle='Mark Complete means saving all predictions and setting the confidence score of all predictions to 100%.'
            primaryDisabled={debug ? true : false}
            onPrimaryClick={() => {
              setIsDeclineModalOpen(true);
            }}
            secondarySx={{
              backgroundColor: '#0500FF',
              maxWidth: '160px',
              flexGrow: 1,
              fontWeight: 600,
              ':disabled': {
                backgroundColor: '#0500FF !important',
                opacity: 0.5,
                color: 'white !important',
                cursor: 'not-allowed',
                pointerEvents: 'all !important',
              },
            }}
            primarySx={{
              border: '1px solid #D0D5DD',
              maxWidth: '160px',
              flexGrow: 1,
              fontWeight: 600,
              color: '#344054',
            }}
            secondaryDisabled={
              task.status === TaskSTATUS.COMPLETED && !isTaskModified
            }
            rounded={'40px'}
            sx={{
              // Show buttons vertically on small screens
              '@media (max-width: 1080px)': {
                flexDirection: 'column',
                gap: '14px',
              },
              gap: '16px',
              width: '100%',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
            onSecondaryClick={handleSecondaryClick}
          />
        </>
      ) : (
        <Button
          onClick={() => dispatch(allowEditingTaskAction(true))}
          variant='contained'
          sx={{
            width: '100%',
            maxWidth: '350px',
            m: '16px',
            borderRadius: '40px',
            backgroundColor: '#0500FF',
          }}
        >
          Modify
        </Button>
      )}
    </Box>
  );
};

export default React.memo(ReviewPageFooter);
