import { Box } from '@mui/material';
import { OrbyButton } from 'orby-ui/src';
import React from 'react';
import { createWorkflowScheduleLoadingSelector } from '../../../../redux/selectors/workflow_details.selectors';
import { useSelector } from 'react-redux';

const WorkflowSchedulerModalFooter: React.FC<{
  handleSave: () => void;
  setOpen: (open: boolean) => void;
}> = ({ handleSave, setOpen }) => {
  const createWorkflowScheduleLoading = useSelector(
    createWorkflowScheduleLoadingSelector,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '12px',
        mt: '8px',
      }}
    >
      <OrbyButton
        variant='monochrome-outline'
        label='Cancel'
        size='medium'
        sx={{ padding: '10px 56px' }}
        onClick={() => setOpen(false)}
        disabled={createWorkflowScheduleLoading}
      />
      <OrbyButton
        variant='primary-contained'
        label='Done'
        size='medium'
        sx={{ padding: '10px 62px' }}
        onClick={handleSave}
        disabled={createWorkflowScheduleLoading}
        loading={createWorkflowScheduleLoading}
      />
    </Box>
  );
};

export default WorkflowSchedulerModalFooter;
