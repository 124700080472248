import React, { FC, memo } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Box,
  SxProps,
  Theme,
  CircularProgress,
  Fade,
} from '@mui/material';
import OrbyColorPalette from '../colors/ColorPalette';
import OrbyTableCell from './components/header/OrbyTableCell';
import OrbyPagination from './components/footer/OrbyPagination';
import OrbyCheckbox from '../checkbox/OrbyCheckbox';
import OrbyTypography from '../typography/OrbyTypography';
import '../../styles/global.css';
import { DataRow, getRowBackgroundColor } from './table-utils';

export interface OrbyTableProps<T> {
  /**
   * The id of the table
   */
  tableId: string;
  /**
   * Check If the Data is loading
   */
  isDataLoading: boolean;
  /**
   * No Data Message
   */
  noDataMessage: string;
  /**
   * onTableRowClick callback
   */
  onTableRowClick?: (data: T) => void;
  /**
   * The width of the table
   */
  tableWidth: number;
  /**
   * Header rows of the table
   */
  headerRows: Array<JSX.Element>;
  /**
   * Data rows of the table
   */
  dataRows: Array<DataRow<T>>;
  /**
   * Show checkboxes in the table
   */
  showCheckBox: boolean;
  /**
   * Checkbox is disabled
   */
  isCheckBoxDisabled: boolean;
  /**
   * Selected Rows is used to provide the info on the tasks which have been selected
   */
  selectedRows?: Array<T>;
  /**
   * Check if all rows of the current page are selected
   */
  areAllRowsSelected?: () => boolean;
  /**
   * Check if a specific row is selected
   */
  isRowSelected?: (row: T) => boolean;
  /**
   * Handle specific row selection
   */
  handleRowSelection?: (row: T, isSelected: boolean) => void;
  /**
   * Handle all rows selection of current page
   */
  handleAllRowsSelection?: (isSelected: boolean) => void;
  /**
   * Selection Header
   */
  checkBoxSelectionHeader?: JSX.Element;

  // PAGINATION
  pagination?: {
    /**
     * Rows Per Page
     */
    rowsPerPage: number;
    /**
     * Total number of rows
     */
    totalRows: number;
    /**
     * Current Page
     */
    currentPage: number;
    /**
     * Set Current Page
     */
    setCurrentPage: (page: number) => void;
    /**
     * Set Rows Per Page
     */
    setRowsPerPage: (rows: number) => void;
  };
  /**
   * colSx Use this for custom styling of table body cells
   */
  colSx?: Array<SxProps<Theme>>;
  /**
   * headerColSx Use this for custom styling of table head cells
   */
  headerColSx?: Array<SxProps<Theme>>;
  /**
   * cursor Use this to set the type of cursor for table row
   */
  cursor?: string;
  /**
   * rowSx Use this for custom styling of table body rows
   */
  rowSx?: SxProps;
  /**
   * tableContainerSx Use this for custom styling of table container
   */
  tableContainerSx?: SxProps;
}

const OrbyTable = <T,>(props: OrbyTableProps<T>) => {
  const {
    tableId,
    isDataLoading,
    noDataMessage,
    onTableRowClick,
    tableWidth,
    headerRows,
    dataRows,
    showCheckBox,
    isCheckBoxDisabled = false,
    selectedRows = [],
    handleAllRowsSelection,
    areAllRowsSelected,
    isRowSelected,
    handleRowSelection,
    checkBoxSelectionHeader,
    pagination,
    colSx,
    headerColSx,
    cursor = 'pointer',
    rowSx,
    tableContainerSx,
  } = props;
  return (
    <>
      <TableContainer
        sx={{
          border: `1px solid ${OrbyColorPalette['grey-200']}`,
          borderRadius: '8px',
          position: 'relative',
          overflow: 'hidden',
          ...tableContainerSx,
        }}
      >
        {selectedRows && (
          <Fade
            in={selectedRows.length > 0}
            timeout={{
              appear: 300,
              enter: 300,
              exit: 0,
            }}
          >
            <Box
              display={'flex'}
              padding={'9px 16px'}
              alignItems={'center'}
              position={'absolute'}
              width={'100%'}
              bgcolor={OrbyColorPalette['indigo-50']}
              sx={{
                borderTopRightRadius:
                  selectedRows && selectedRows.length > 0 ? '8px' : '0px',
                borderTopLeftRadius:
                  selectedRows && selectedRows.length > 0 ? '8px' : '0px',
              }}
            >
              {showCheckBox && areAllRowsSelected && handleAllRowsSelection && (
                <OrbyCheckbox
                  disabled={isCheckBoxDisabled}
                  size='md'
                  checked={areAllRowsSelected()}
                  title='Select all Pending tasks'
                  onClick={(e) => {
                    if (e.target instanceof HTMLInputElement) {
                      handleAllRowsSelection(e.target.checked);
                    }
                  }}
                />
              )}
              {checkBoxSelectionHeader}
            </Box>
          </Fade>
        )}
        <Table id={tableId} sx={{ tableLayout: 'fixed' }}>
          {/* TABLE HEAD */}
          <TableHead
            style={{
              visibility: selectedRows.length > 0 ? 'hidden' : 'visible',
            }}
          >
            <TableRow
              sx={{
                backgroundColor: OrbyColorPalette['white-0'],
                boxSizing: 'border-box',
              }}
            >
              {headerRows.map((headerRow, index) => {
                return (
                  <OrbyTableCell
                    sx={headerColSx?.[index] ?? colSx?.[index] ?? {}}
                    key={index}
                  >
                    <Box
                      sx={{
                        display: showCheckBox && index === 0 ? 'flex' : 'unset',
                        gap: showCheckBox && index === 0 ? '10px' : 'unset',
                        alignItems:
                          showCheckBox && index === 0 ? 'center' : 'unset',
                      }}
                    >
                      {showCheckBox &&
                        areAllRowsSelected &&
                        handleAllRowsSelection &&
                        index === 0 && (
                          <Box display={'flex'}>
                            <OrbyCheckbox
                              disabled={isCheckBoxDisabled}
                              size='md'
                              checked={areAllRowsSelected()}
                              title='Select all'
                              onClick={(e) => {
                                if (e.target instanceof HTMLInputElement) {
                                  handleAllRowsSelection(e.target.checked);
                                }
                              }}
                            />
                          </Box>
                        )}
                      {headerRow}
                    </Box>
                  </OrbyTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {/* TABLE BODY */}
          <TableBody>
            {!isDataLoading && dataRows.length ? (
              dataRows.map((dataRow) => {
                const clickable = !(dataRow?.isClickable === false);
                return (
                  <TableRow
                    sx={{
                      cursor: cursor,
                      backgroundColor: getRowBackgroundColor(
                        dataRow,
                        isRowSelected,
                      ),
                      '&:hover': {
                        cursor: clickable ? cursor : 'default',
                        backgroundColor: clickable
                          ? OrbyColorPalette['grey-50']
                          : OrbyColorPalette['white-0'],
                      },
                      '&:focus': {
                        outline: clickable
                          ? `1px solid ${OrbyColorPalette['grey-100']}`
                          : 'none',
                        backgroundColor: clickable
                          ? OrbyColorPalette['purple-50']
                          : OrbyColorPalette['white-0'],
                      },
                      boxSizing: 'border-box',
                      ...rowSx,
                    }}
                    key={dataRow.id}
                    tabIndex={0}
                    role='row'
                    onKeyUp={(event: any) => {
                      if (event.key === 'Enter') {
                        onTableRowClick?.(dataRow.row);
                      }
                    }}
                    onClick={() => onTableRowClick?.(dataRow.row)}
                    aria-label={`Row ${dataRow.id}: ${dataRow.title}`}
                  >
                    {dataRow.cells.map((cell, index) => {
                      return (
                        <OrbyTableCell
                          sx={{
                            ...(colSx?.[index] ?? {}),
                            cursor:
                              typeof dataRow?.isClickable === 'boolean' &&
                              !dataRow?.isClickable
                                ? 'not-allowed'
                                : 'inherit',
                          }}
                          key={index}
                        >
                          <Box
                            sx={{
                              display:
                                showCheckBox && index === 0 ? 'flex' : 'block',
                              gap:
                                showCheckBox && index === 0 ? '10px' : 'block',
                              alignItems:
                                showCheckBox && index === 0
                                  ? 'center'
                                  : 'unset',
                            }}
                          >
                            {showCheckBox &&
                              isRowSelected &&
                              handleRowSelection &&
                              index === 0 && (
                                <Box display={'flex'}>
                                  <OrbyCheckbox
                                    disabled={
                                      isCheckBoxDisabled ||
                                      dataRow?.isCheckBoxDisabled
                                    }
                                    size='md'
                                    title={dataRow.title}
                                    checked={isRowSelected?.(dataRow.row)}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        e.target instanceof HTMLInputElement
                                      ) {
                                        handleRowSelection?.(
                                          dataRow.row,
                                          e.target.checked,
                                        );
                                      }
                                    }}
                                  />
                                </Box>
                              )}
                            {cell}
                          </Box>
                        </OrbyTableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <OrbyTableCell
                  sx={{
                    padding: '0px',
                  }}
                >
                  <Box
                    width={`${tableWidth}px`}
                    height={'72px'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    {!isDataLoading ? (
                      <OrbyTypography
                        size='sm'
                        weight='regular'
                        color={OrbyColorPalette['grey-600']}
                      >
                        {noDataMessage}
                      </OrbyTypography>
                    ) : (
                      <CircularProgress
                        sx={{
                          margin: '0 auto',
                          color: OrbyColorPalette['blue-700'],
                        }}
                      />
                    )}
                  </Box>
                </OrbyTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <OrbyPagination
          rowsPerPage={pagination.rowsPerPage}
          totalSize={pagination.totalRows}
          page={pagination.currentPage}
          onRowsPerPageChange={(rows) => {
            pagination.setRowsPerPage(rows);
          }}
          isShowSelectFilter={pagination.totalRows > pagination.rowsPerPage}
          onPageChange={(p) => {
            pagination.setCurrentPage(p);
          }}
        />
      )}
    </>
  );
};

export default memo(OrbyTable);
