import { Activity } from 'protos/automation_mining/automation_mining';
import { Document } from 'protos/google/cloud/documentai/v1/document';
import { Timestamp } from 'protos/google/protobuf/timestamp';
import { UserOrgRole } from 'protos/pb/v1alpha1/user';
import {
  ExecutionStep,
  ExecutionStepMode,
} from 'protos/pb/v1alpha2/execution_steps';
import { MissedTask, Task, TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import {
  ReviewerList,
  Workflow,
  WorkflowUser,
} from 'protos/pb/v1alpha2/workflows_service';
import { ApplicationName } from './protos/enums';

import dayjs from 'dayjs';
import moment from 'moment';

import {
  AttributeType,
  CompositeGroupCondition,
  Condition,
  LogicalOperator,
  Operator,
} from 'protos/pb/v1alpha2/connector';
import {
  EntityDataType,
  EntityDetails,
  EntityExtractionParam,
  EntityTypeSchema,
} from 'protos/pb/v1alpha2/workflow_steps_params';
import { getStepsInfoFromApplication } from '../pages/workflow-creation/helper';
import { SchemaEntity } from '../pages/workflow-creation/step-contents/ExtractSchemaDefinition';

import { storageService } from '../services/StorageService';

import { Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { CSVEntityDetails } from '../pages/workflow-creation/step-contents/ExtractSchemaDefinition';
import { microsoftSignInAction } from '../redux/actions/auth.action';
import { updateGoogleTokenAction } from '../redux/actions/user.action';
import { EntityInfo } from '../redux/reducers/review_task.reducer';
import {
  CLASSIFICATION_LABEL_SELECT,
  CLASSIFICATION_SCHEMA_DOCUMENT_TYPE,
  DateOptionValues,
  DateRange,
  defaultDateRange,
  END_DATE,
  FilteredResource,
  invalidCharactersInEntityNameMsg,
  invalidNotesEntityNameMsg,
  MAX_NESTED_ENTITIES,
  PERIOD,
  SelectedExtractedField,
  START_DATE,
} from './constants';
import {
  checkIfNotesEntityType,
  hasInvalidCharacters,
  isInvalidNotesEntity,
} from './entities';
import { getRoundOneReviewers, getRoundTwoReviewers } from './WorkflowUtils';

/**
 * Converts a timestamp to a formatted date string.
 *
 * @param {Timestamp} ts - The timestamp object to convert, expected to have a `seconds` property.
 * @param {string} [format='MM/DD/YYYY HH:mm:ss'] - Optional format string specifying the desired date-time format. Defaults to 'MM/DD/YYYY HH:mm:ss'.
 * @returns {string} - The formatted date string.
 * @example
 * // returns '01/01/2023 12:34:56'
 * formatDateFromSeconds({ seconds: 1672566896 })
 */
export const formatDateFromSeconds = (
  ts: Timestamp,
  format = 'MM/DD/YYYY HH:mm:ss',
) => {
  return moment.unix(ts.seconds!).format(format);
};

/**
 * Formats a JavaScript Date object into a string based on the specified format.
 *
 * @param {Date} ts - The JavaScript Date object to format.
 * @param {string} [format='MM/DD/YYYY HH:mm:ss'] - Optional format string specifying the desired date-time format. Defaults to 'MM/DD/YYYY HH:mm:ss'.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (ts: Date, format = 'MM/DD/YYYY HH:mm:ss') => {
  return moment(ts).format(format);
};

export const formatDateTime = (
  ts?: Date,
  dateFormat = 'MMM DD, YYYY',
  timeFormat = 'HH:mm',
) => {
  if (!ts) {
    return {
      date: '-',
      time: '-',
    };
  }
  return {
    date: moment(ts).format(dateFormat),
    time: moment(ts).format(timeFormat),
  };
};

/**
 * Adds an alpha (opacity) value to a hexadecimal color string.
 *
 * @param {string} color - The base color in hexadecimal format (e.g., '#FF5733'). Should include the hash symbol.
 * @param {number} opacity - The opacity value to add, ranging from 0 (fully transparent) to 1 (fully opaque). Defaults to 1 if not provided.
 * @returns {string} - The color with alpha added, in ARGB format (e.g., '#FF5733FF').
 */
export function addAlpha(color: string, opacity: number): string {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

/**
 * Checks if a given user role is an admin.
 *
 * @param {UserOrgRole} [role] - The user role to check. Defaults to `undefined` if not provided.
 * @returns {boolean} - Returns `true` if the role is `ROLE_ADMIN`, otherwise `false`.
 */
export function isAdmin(role?: UserOrgRole) {
  return role === UserOrgRole.ROLE_ADMIN;
}

/**
 * Returns a human-readable string representation of the user role.
 *
 * @param {UserOrgRole | undefined} role - The user role to convert to a string. If `undefined`, defaults to 'User'.
 * @returns {string} - The string representation of the role: 'Admin' if the role is `ROLE_ADMIN`, 'Creator' if the role is `ROLE_CREATOR`, otherwise 'User'.
 */
export function getRole(role: UserOrgRole | undefined) {
  switch (role) {
    case UserOrgRole.ROLE_ADMIN:
      return 'Admin';
    case UserOrgRole.ROLE_CREATOR:
      return 'Creator';
    default:
      return 'User';
  }
}

/**
 * Creates a task object with the given file name and document.
 *
 * @param {string} fileName - The name of the file to be used in the task.
 * @param {Document} document - The document to be included in the task's execution steps.
 * @returns {Task} - The constructed task object with the specified file name and document.
 */
export function createTaskObjectWithDocument(
  fileName: string,
  document: Document,
) {
  return {
    displayName: fileName,
    workflowDisplayName: 'debug',
    status: TaskSTATUS.READY,
    executionSteps: [
      {
        displayName: 'MANUAL EXECUTION TASK DOCUMENT PROTO',
        documents: [document],
        activity: Activity.EXTRACT_ENTITY,
        mode: {} as ExecutionStepMode,
      },
    ],
  } as Task;
}

/**
 * Converts a duration in milliseconds to a human-readable time string.
 *
 * @param {number} milliseconds - The duration in milliseconds to convert.
 * @param {boolean} includeSeconds - Whether to include seconds in the output.
 * @returns {string} - A formatted string representing the duration in hours, minutes, and optionally seconds.
 */
export function msToTime(milliseconds: number, includeSeconds: boolean) {
  let hours, min, seconds;
  seconds = Math.floor(milliseconds / 1000);
  min = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hours = Math.floor(min / 60);
  min = min % 60;
  const days = Math.floor(hours / 24);
  hours = hours % 24;
  hours += days * 24;
  if (includeSeconds) {
    return `${hours}h${min}m${seconds}s`;
  }
  return `${hours}h ${min}min`;
}

/**
 * Extracts unique application names from a workflow's steps and triggers/actions.
 *
 * @param {Workflow} workflow - The workflow object containing steps, each with triggers and actions.
 * @returns {string[]} - An array of unique application names found in the workflow.
 */
export const getApplicationNames = (workflow: Workflow): string[] => {
  const arr: string[] = [];
  workflow.steps?.map((step) => {
    step.triggers?.map((trigger) => {
      if (
        trigger.application &&
        !arr.find((v) => v === trigger.application) &&
        Object.entries(ApplicationName).find(
          ([, value]) => value === trigger.application,
        )
      ) {
        arr.push(trigger.application);
      }
    });
    step.actions?.map((action) => {
      if (
        action.application &&
        !arr.find((v) => v === action.application) &&
        Object.entries(ApplicationName).find(
          ([, value]) => value === action.application,
        )
      ) {
        arr.push(action.application);
      }
    });
  });
  return arr;
};

/**
 * Generates an array of step constants for a workflow, including default and application-specific steps.
 *
 * @param {Workflow} workflow - The workflow object from which to derive application names.
 * @returns {Array<{ title: string, background: string, iconColor: string }>} - An array of step constants, each with a title, background color, and icon color.
 */
export const getStepConstants = (workflow: Workflow) => {
  const steps = [
    {
      title: 'Add name and description',
      background: '#E8F4E3',
      iconColor: '#3BA755',
    },
  ];
  const applications = getApplicationNames(workflow);
  applications.map((application: string) => {
    const emailStep = applications.find((a) => a === ApplicationName.Gmail);
    const step = getStepsInfoFromApplication(
      application,
      emailStep !== undefined,
    );
    if (step) {
      steps.push(step);
    }
  });
  steps.push({
    title: 'Add users',
    background: '#a9d6d7',
    iconColor: '#3cb5aa',
  });
  return steps;
};

/**
 * Extracts unique trigger application names from a workflow's steps.
 *
 * @param {Workflow} workflow - The workflow object containing steps with triggers.
 * @returns {string[]} - An array of unique trigger application names found in the workflow.
 */
export const getTriggerNames = (workflow: Workflow): string[] => {
  const arr: string[] = [];
  workflow.steps?.map((step) => {
    step.triggers?.map((trigger) => {
      if (trigger.application && !arr.find((v) => v === trigger.application)) {
        arr.push(trigger.application);
      }
    });
  });
  return arr;
};

/**
 * Finds the selected trigger application name from a workflow and returns the corresponding ApplicationName enum value.
 *
 * @param {Workflow} workflow - The workflow object containing steps with triggers.
 * @returns {ApplicationName | undefined} - The ApplicationName enum value if a match is found, otherwise `undefined`.
 */
export const findSelectedTrigger = (
  workflow: Workflow,
): ApplicationName | undefined => {
  const matchedTriggerName = getTriggerNames(workflow).find((applicationName) =>
    Object.values(ApplicationName).includes(applicationName as ApplicationName),
  );
  // If matchedTemplateName is found, return the corresponding ApplicationName enum
  if (matchedTriggerName) {
    return Object.entries(ApplicationName).find(
      ([, value]) => value === matchedTriggerName,
    )?.[1] as ApplicationName;
  }
  return undefined;
};

/**
 * Finds the index of an action with a specific application name within a workflow.
 *
 * @param {Workflow} workflow - The workflow object containing steps with actions.
 * @param {ApplicationName} name - The application name to search for in the actions.
 * @returns {{ stepIndex: number | undefined, actionIndex: number | undefined }} - An object containing the indices of the step and action, or `undefined` if not found.
 */
export const getActionIndex = (
  workflow: Workflow,
  name: ApplicationName,
): {
  stepIndex: number | undefined;
  actionIndex: number | undefined;
} => {
  let stepIndex: number | undefined,
    actionIndex = -1;
  workflow.steps?.map((step, stepIdx) => {
    step.actions?.map((action, actionIdx) => {
      if (action.application && name === action.application) {
        stepIndex = stepIdx;
        actionIndex = actionIdx;
      }
    });
  });
  return {
    stepIndex,
    actionIndex,
  };
};

/**
 * Finds the index of a trigger with a specific application name within a workflow.
 *
 * @param {Workflow} workflow - The workflow object containing steps with triggers.
 * @param {ApplicationName} name - The application name to search for in the triggers.
 * @returns {{ stepIndex: number | undefined, triggerIndex: number | undefined }} - An object containing the indices of the step and trigger, or `undefined` if not found.
 */
export const getTriggerIndex = (
  workflow: Workflow,
  name: ApplicationName,
): {
  stepIndex: number | undefined;
  triggerIndex: number | undefined;
} => {
  let stepIndex: number | undefined,
    triggerIndex = -1;
  workflow.steps?.map((step, stepIdx) => {
    step.triggers?.map((trigger, triggerIdx) => {
      if (trigger.application && name === trigger.application) {
        stepIndex = stepIdx;
        triggerIndex = triggerIdx;
      }
    });
  });
  return {
    stepIndex,
    triggerIndex,
  };
};

/**
 * Converts a decimal value to a percentage by multiplying it by 100 and rounding to the nearest integer.
 *
 * @param {number} value - The decimal value to convert to a percentage (e.g., 0.75 for 75%).
 * @returns {number} - The percentage value, rounded to the nearest integer.
 */
export function getPercentValue(value: number) {
  return Math.round(value * 100);
}

/**
 * Retrieves the execution step of a task based on the document activity type.
 * Priority is given to CLASSIFY_DOCUMENT, then EXTRACT_ENTITY, and finally COMPREHEND_DOCUMENT.
 *
 * @param {Task} [task] - The task object containing execution steps.
 * @returns {ExecutionStep | undefined} - The execution step corresponding to the document activity type,
 *                                         or `undefined` if no matching step is found.
 */
export const getSelectedTaskDocument = (
  task?: Task,
): ExecutionStep | undefined => {
  // Check if we have a document classification step, if yes, return that else return extract entity step
  if (
    task?.executionSteps?.find((s) => s.activity === Activity.CLASSIFY_DOCUMENT)
  ) {
    return task.executionSteps.find(
      (s) => s.activity === Activity.CLASSIFY_DOCUMENT,
    )!;
  } else {
    if (
      task?.executionSteps?.find((s) => s.activity === Activity.EXTRACT_ENTITY)
    ) {
      return task.executionSteps.find(
        (s) => s.activity === Activity.EXTRACT_ENTITY,
      )!;
    } else {
      return task?.executionSteps?.find(
        (s) => s.activity === Activity.COMPREHEND_DOCUMENT,
      );
    }
  }
};

/**
 * Prepares a `ReviewerList` object from an array of user objects.
 *
 * @param {Array<{ user: string; enabled: boolean }>} users - An array of user objects where each object contains:
 *   - `user`: The username to be added to the review list.
 *   - `enabled`: A boolean indicating whether the user is enabled or not.
 * @returns {ReviewerList} - The constructed `ReviewerList` object containing the provided users.
 */
export const addUsersToReviewList = (
  users: Array<{ user: string; enabled: boolean }>,
): ReviewerList => {
  const reviewList = ReviewerList.create({
    users: [],
  });
  for (const u of users) {
    const wUser = WorkflowUser.create({
      user: u.user.toLowerCase(),
      enabled: u.enabled,
    });
    reviewList.users!.push(wUser);
  }
  return reviewList;
};

/**
 * Checks if the reviewer list has changed.
 *
 * @param {Array<{ user: string; enabled: boolean }>} users - An array of user objects where each object contains:
 *   - `user`: The username to be checked.
 *   - `enabled`: A boolean indicating whether the user is enabled or not.
 * @param {Workflow} workflow - The workflow object containing the current reviewer's information.
 * @param {1 | 2} roundNumber - The round number to determine which reviewers to check (1 or 2).
 * @returns {boolean} - Returns `true` if the reviewer list has changed, otherwise `false`.
 */
export const checkReviewerListUserChanged = (
  users: Array<{ user: string; enabled: boolean }>,
  workflow: Workflow,
  roundNumber: 1 | 2,
) => {
  const roundUsers =
    roundNumber === 1
      ? getRoundOneReviewers(workflow)
      : getRoundTwoReviewers(workflow);

  if (roundUsers && roundUsers.length != users.length) {
    return true;
  }
  const usersSet = new Set(roundUsers?.map((user) => user.user) || []);
  for (const newUser of users) {
    if (!usersSet.has(newUser.user)) {
      return true;
    }
  }
  return false;
};

/**
 * Constructs a CompositeGroupCondition based on the provided values.
 *
 * @param {any} values - An object containing the selected extracted fields and confidence score.
 * @returns {CompositeGroupCondition} - The constructed composite group condition.
 */
export const getGroupConditionExtractedFields = (values: any) => {
  const groupCondition: CompositeGroupCondition = {
    logicalOperator:
      values.selectedExtractedField.length > 1
        ? LogicalOperator.OR
        : LogicalOperator.UNSPECIFIED,
    conditions: [],
    nestedConditions: [],
  };
  // String will be in case of classification where it uses documentType as attribute
  values.selectedExtractedField.map(
    (field: SelectedExtractedField | string) => {
      const condition = Condition.create({
        attributeType: {
          name: typeof field === 'string' ? field : field.entityName || '',
          parent: typeof field === 'string' ? '' : field.parent || '',
        } as AttributeType,
        value: `${values.confidenceScore}`,
        operator: Operator.LESS_THAN,
      });
      groupCondition.conditions?.push(condition);
    },
  );
  return groupCondition;
};

// Function to parse an attribute in attributeType format
export const parseAttribute = (attribute: string): AttributeType => {
  // Splitting the attribute into an array using '/'
  const splitAttribute = attribute.split('/');
  // Checking if the attribute contains a parent entity
  if (splitAttribute.length > 1) {
    // Returning an object with parent and name (child) properties
    return {
      parent: splitAttribute[0].trim(),
      name: splitAttribute[1].trim(),
    } as AttributeType;
  } else {
    // Returning an object with parent as undefined and name as the attribute
    return { parent: '', name: attribute } as AttributeType;
  }
};

const parseConditions = (conditions: Condition[], logicalOperator: number) => {
  return conditions.map((condition) => ({
    attribute: condition.attribute,
    operator: condition.operator,
    value: condition?.value?.toLowerCase(),
    logical_operator: logicalOperator,
    attributeType: condition?.attributeType
      ? condition?.attributeType
      : parseAttribute(condition.attribute as string),
  }));
};

const parseNestedConditions = (nestedConditions: CompositeGroupCondition[]) => {
  const groupCondition: {
    [key: number]: {
      logical_operator: LogicalOperator | undefined;
      conditions: Condition[];
    }[];
  } = {};
  let currentIndex = 0;
  for (const nestedCondition of nestedConditions) {
    let nestedConditionDetails = nestedCondition.nestedConditions;
    const conditions = parseConditions(
      nestedCondition.conditions as Condition[],
      nestedCondition.logicalOperator as LogicalOperator,
    );
    const innerOutputConditions: Condition[] = [];
    while (nestedConditionDetails?.length != 0) {
      const innerNestedCondition = nestedConditionDetails?.[0];
      const conditions = parseConditions(
        innerNestedCondition?.conditions as Condition[],
        innerNestedCondition?.logicalOperator as LogicalOperator,
      );
      innerOutputConditions.unshift(...conditions);
      nestedConditionDetails = innerNestedCondition?.nestedConditions;
    }
    groupCondition[currentIndex] = [
      {
        logical_operator: nestedCondition.logicalOperator,
        conditions: [...innerOutputConditions, ...conditions],
      },
    ];
    currentIndex++;
  }
  return groupCondition;
};

export const parseConnectorConditions = (
  connectorGroupConditions: CompositeGroupCondition,
) => {
  const conditionsArray: {
    logical_operator: LogicalOperator | undefined;
    conditions: Condition[];
  }[] = [];
  // Loop though the nestedConditions
  if (connectorGroupConditions?.nestedConditions) {
    for (const conditionDetail of connectorGroupConditions.nestedConditions) {
      // Parse the conditions
      const parsedConditions = parseConditions(
        conditionDetail.conditions as Condition[],
        conditionDetail.logicalOperator as LogicalOperator,
      );
      const condition = {
        logical_operator: conditionDetail.logicalOperator,
        conditions: parsedConditions,
      };
      // Loop through nested conditions and generate the group
      const parsedNestedConditions = parseNestedConditions(
        conditionDetail.nestedConditions!,
      );
      for (const key in parsedNestedConditions) {
        conditionsArray.push(...parsedNestedConditions[key]);
      }
      if (condition.conditions.length > 0) {
        conditionsArray.push(condition);
      }
    }
  }
  // Check if conditions array has values or not
  if (connectorGroupConditions.conditions!.length != 0) {
    const lastConditions = parseConditions(
      connectorGroupConditions.conditions!,
      connectorGroupConditions.logicalOperator!,
    );
    conditionsArray.push({
      logical_operator: connectorGroupConditions.logicalOperator,
      conditions: lastConditions,
    });
  }
  return conditionsArray;
};

/* METHODS TO MAKE COMPOSITE GROUP CONDITION */

const extractCompositeGroupCondition = (
  values: Condition[],
  nestedConditions: CompositeGroupCondition[],
  logicalOperator: LogicalOperator,
  index?: number,
): CompositeGroupCondition => {
  const compositeGroupCondition = {} as CompositeGroupCondition;
  compositeGroupCondition.conditions = values;
  compositeGroupCondition.logicalOperator = logicalOperator;
  compositeGroupCondition.nestedConditions = nestedConditions;
  compositeGroupCondition.groupIndex = index;

  return compositeGroupCondition;
};

const getConditionFromObject = (obj: {
  attributeType: AttributeType;
  operator: Operator;
  value: any;
}): Condition => {
  const condition = {} as Condition;
  condition.attributeType = obj.attributeType as AttributeType;
  condition.operator = obj.operator;
  condition.value = `${obj.value}`; // Condition value is always a string
  return condition;
};

/**
 *
 * @param {any[]} values - Array of condition values to process.
 * @param {CompositeGroupCondition} [compCondition] - Optional existing composite condition.
 * @param {number} [groupIndex] - Optional index to specify the group index.
 * @returns {CompositeGroupCondition | undefined} - The constructed composite group condition or undefined.
 */
const getCompositeGroupConditionForSingleConditionGroup = (
  values: any[],
  compCondition?: CompositeGroupCondition,
  groupIndex?: number,
): CompositeGroupCondition | undefined => {
  const conditions: Condition[] = [];
  let operator: LogicalOperator | undefined = undefined;

  if (values.length === 0) {
    return compCondition;
  }

  for (let index = 0; index < values.length; index++) {
    const v = values[index];
    if (!operator) {
      operator = v.logical_operator;
    }
    if (
      (operator && operator !== v.logical_operator) ||
      index === values.length - 1
    ) {
      if (operator === v.logical_operator && index === values.length - 1) {
        conditions.push(getConditionFromObject(v));
      }
      const nestedCondition = extractCompositeGroupCondition(
        conditions,
        compCondition ? [compCondition] : [],
        operator!,
        groupIndex,
      );
      return getCompositeGroupConditionForSingleConditionGroup(
        operator === v.logical_operator && index === values.length - 1
          ? []
          : values.slice(index, values.length),
        nestedCondition,
      );
    } else {
      conditions.push(getConditionFromObject(v));
    }
  }
};

/**
 *
 * @param {any[]} values - Array of condition values to process.
 * @param {CompositeGroupCondition} [compCondition] - Optional existing composite condition.
 * @param {number} [index] - Optional index to specify the group index.
 * @returns {CompositeGroupCondition | undefined} - The constructed composite group condition or undefined.
 */
export const getCompleteCompositeGroupCondition = (
  values: any[],
  compCondition?: CompositeGroupCondition,
  index?: number,
): CompositeGroupCondition | undefined => {
  let groupIndex = index || 0;
  const conditions: Condition[] = [];
  const compositeGroupConditions: CompositeGroupCondition[] = compCondition
    ? [compCondition]
    : [];
  let operator: LogicalOperator | undefined = undefined;

  if (values.length === 0) {
    return compCondition;
  }

  if (values.length === 1) {
    if (values[0].conditions.length === 1) {
      conditions.push(getConditionFromObject(values[0].conditions[0]));
    } else {
      groupIndex += 1;
      compositeGroupConditions.push(
        getCompositeGroupConditionForSingleConditionGroup(
          values[0].conditions,
        )!,
      );
    }
    return extractCompositeGroupCondition(
      conditions,
      compositeGroupConditions,
      values[0].logical_operator,
      groupIndex,
    );
  }

  for (let index = 0; index < values.length; index++) {
    const v = values[index];
    if (!operator) {
      operator = v.logical_operator;
    }
    if (
      (operator && operator !== v.logical_operator) ||
      index === values.length - 1
    ) {
      if (operator === v.logical_operator && index === values.length - 1) {
        if (v.conditions.length === 1) {
          conditions.push(getConditionFromObject(v.conditions[0]));
        } else {
          groupIndex += 1;
          compositeGroupConditions.push(
            getCompositeGroupConditionForSingleConditionGroup(v.conditions)!,
          );
        }
      }
      const nestedCondition = extractCompositeGroupCondition(
        conditions,
        compositeGroupConditions,
        operator!,
        groupIndex,
      );
      return getCompleteCompositeGroupCondition(
        operator === v.logical_operator && index === values.length - 1
          ? []
          : values.slice(index, values.length),
        nestedCondition,
        groupIndex,
      );
    } else {
      if (v.conditions.length === 1) {
        conditions.push(getConditionFromObject(v.conditions[0]));
      } else {
        groupIndex += 1;
        compositeGroupConditions.push(
          getCompositeGroupConditionForSingleConditionGroup(v.conditions)!,
        );
      }
    }
  }
};

/* METHODS TO DEPARSE COMPOSITE GROUP CONDITION */

export const getGroupConditionsFromCompositeGroupConditions = (
  condition: CompositeGroupCondition,
  logicalOperator?: LogicalOperator,
  prevConditionGroups?: any[],
  parentConditionGroup?: boolean,
) => {
  // THIS IS TO HANDLE CASES WHERE GROUP INDEX WAS NOT PRESENT WHEN CONNECTORS WERE CREATED
  if (parentConditionGroup && condition.groupIndex === 0)
    return parseConnectorConditions(condition);

  const conditionGroups = prevConditionGroups || [];

  // THIS WILL BE ADDED AT THE END OF THE LIST
  if (condition.conditions!.length && condition.groupIndex! > 0) {
    conditionGroups.unshift({
      conditions: parseConditions(
        condition.conditions!,
        condition.logicalOperator!,
      ),
      logical_operator: condition.logicalOperator,
    });
  }

  if (condition.groupIndex! > 0) {
    for (let index = 0; index < condition.nestedConditions!.length; index++) {
      getGroupConditionsFromCompositeGroupConditions(
        condition.nestedConditions![index],
        condition.logicalOperator,
        conditionGroups,
      );
    }
  } else {
    conditionGroups.unshift({
      conditions: getConditionsFromCompositeGroupConditions(condition),
      logical_operator: logicalOperator,
    });
  }
  return conditionGroups;
};

export const getConditionsFromCompositeGroupConditions = (
  condition: CompositeGroupCondition,
  prevConditions?: any[],
) => {
  const conditions = prevConditions || [];

  if (condition.conditions!.length) {
    conditions.unshift(
      ...parseConditions(condition.conditions!, condition.logicalOperator!),
    );
  }

  if (condition.nestedConditions!.length) {
    for (const group of condition.nestedConditions!) {
      getConditionsFromCompositeGroupConditions(group, conditions);
    }
  }

  return conditions;
};

export const hasEntityDetailsChanged = (
  newDetails: EntityDetails[],
  oldDetails: EntityDetails[],
): boolean => {
  if (newDetails.length !== oldDetails.length) {
    return true;
  }
  let result = false;
  for (const details of newDetails) {
    const oldEntityDetails = oldDetails.find(
      (d) => d.entityType === details.entityType,
    )!;
    if (!oldEntityDetails) {
      return true;
    }
    if (details.normalizationType !== oldEntityDetails.normalizationType) {
      return true;
    } else if (details.properties!.length) {
      result =
        result ||
        hasEntityDetailsChanged(
          [...details.properties!],
          [...oldEntityDetails.properties!],
        );
    }
  }
  return result;
};

export const withGoogleLoginScopeCheck = async (
  dispatch: Dispatch,
  scope: string,
  callback: () => void,
) => {
  const scopes = await storageService.getStoredGoogleScope();
  if (!scopes?.includes(scope)) {
    dispatch(updateGoogleTokenAction(scope));
  } else {
    callback();
  }
};

export const withMicrosoftLoginScopeCheck = async (
  dispatch: Dispatch,
  scope: string[],
  callback: () => void,
) => {
  // get microsoft related scopes
  const info = await storageService.getStoredMicrosoftInfo();
  if (info?.scopes && scope.every((v) => info.scopes!.includes(v))) {
    callback();
  } else {
    dispatch(microsoftSignInAction({ scope }));
  }
};

export const getGoogleAuthCode = async (scope: string) => {
  const CLIENT_ID = encodeURIComponent(
    process.env.REACT_APP_CLIENT_ID as string,
  );
  const response: {
    code: string;
    scope?: string;
    error?: string;
    error_description?: string;
    error_uri?: string;
    state?: string;
  } = await new Promise((resolve) => {
    // @ts-ignore
    const client = google.accounts.oauth2.initCodeClient({
      client_id: CLIENT_ID,
      scope: scope,
      ux_mode: 'popup',
      redirect_uri: process.env.REACT_APP_IS_PR_PREVIEW
        ? window.location.origin
        : process.env.REACT_APP_REDIRECT_URI,
      include_granted_scopes: true,
      callback: (response: any) => {
        resolve(response);
      },
    });
    client.requestCode();
  });
  return response;
};

export const getMicrosoftAuthCode = async (
  authCodeUrl: string,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const popupWindow = window.open(
      authCodeUrl, // URL to open
      'authPopup', // Name of the popup window
      'width=600,height=600', // Options for the popup (size, etc.)
    );

    if (popupWindow) {
      const intervalId = setInterval(() => {
        // Check if the popup has closed
        if (popupWindow.closed) {
          clearInterval(intervalId);
          return;
        }
        // Access the current URL of the popup
        let popupUrl = '';
        try {
          /*
           * Will throw if cross origin,
           * which should be caught and ignored
           * since we need the interval to keep running while POPUP in ON.
           */
          popupUrl = popupWindow.location.href;
        } catch (e) {
          return;
        }
        // Check if the popup's URL indicates completion (e.g., callback)
        if (popupUrl.includes('code=')) {
          // Extract the fragment (the part after '#')
          const fragment = popupUrl.split('#')?.[1];
          if (fragment) {
            // Split the fragment into key-value pairs
            const params = new URLSearchParams(fragment);
            // Get the value of 'code'
            const code = params.get('code');
            // Now you can close the popup
            popupWindow.close();
            // Clear the interval
            clearInterval(intervalId);
            // Handle the code (e.g., continue with OAuth flow, send to backend, etc.)
            if (code) {
              resolve(code);
            }
          }
        }
      }, 500); // Check every 500 ms
    } else {
      reject(new Error('Failed to open popup window'));
    }
  });
};

export const hasGroupConditionsChanged = (
  original: any[],
  updated: any[],
): boolean => {
  if (original?.length !== updated?.length) {
    return true;
  }

  for (let i = 0; i < updated.length; i++) {
    if (
      original[i].attributeType?.name !== updated[i].attributeType?.name ||
      original[i].attributeType?.parent !== updated[i].attributeType?.parent ||
      original[i].attribute !== updated[i].attribute ||
      original[i].operator !== updated[i].operator ||
      original[i].value !== updated[i].value
    ) {
      return true;
    }
  }

  return false;
};

export const b64toBlob = (
  b64Data: Uint8Array,
  contentType = '',
  sliceSize = 512,
) => {
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < b64Data?.length; offset += sliceSize) {
    const slice = b64Data.slice(offset, offset + sliceSize);
    byteArrays.push(slice);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const getUrl = (object: Uint8Array, contentType: string) => {
  const blob = b64toBlob(object, contentType);
  return URL.createObjectURL(blob);
};

/**
 * This function is used to return the type of entity selected
 * @param {DocumentEntity} entity
 * @returns {string}
 */
export const getSelectedEntityType = (entity: EntityInfo) => {
  if (entity.normalizedValue?.floatValue) {
    return 'floatValue';
  } else if (entity.normalizedValue?.integerValue) {
    return 'integerValue';
  } else if (
    entity.normalizedValue?.moneyValue ||
    entity.normalizedValue?.dateValue
  ) {
    return 'text';
  } else {
    return 'text';
  }
};

export const getCustomFoldersListView = () => {
  const customFoldersListView = new google.picker.DocsView(
    google.picker.ViewId.FOLDERS,
  );
  customFoldersListView.setMode(google.picker.DocsViewMode.LIST);
  customFoldersListView.setIncludeFolders(true);
  customFoldersListView.setSelectFolderEnabled(true);
  return customFoldersListView;
};

export const getCustomSheetsListView = () => {
  const customSheetsListView = new google.picker.DocsView(
    google.picker.ViewId.SPREADSHEETS,
  );
  customSheetsListView.setMode(google.picker.DocsViewMode.LIST);
  customSheetsListView.setIncludeFolders(false);
  customSheetsListView.setSelectFolderEnabled(false);
  return customSheetsListView;
};

/**
 * This method is used to check if the workflow only has the selected actions
 * If yes return true
 * else return false
 */
export const checkActions = (
  workflow: Workflow,
  actions: string | string[],
): boolean => {
  let isCheckAction = true;
  workflow.steps!.map((step) => {
    step.actions!.map((action) => {
      if (action.application && !actions.includes(action.application)) {
        isCheckAction = false;
      }
    });
  });
  return isCheckAction;
};

/**
 * This method is used to check if the workflow only has the selected triggers
 * If yes return true
 * else return false
 */
export const checkTriggers = (
  workflow: Workflow,
  triggers: string | string[],
): boolean => {
  let isCheckTrigger = true;
  workflow.steps!.map((step) => {
    step.triggers!.map((trigger) => {
      if (trigger.application && !triggers.includes(trigger.application)) {
        isCheckTrigger = false;
      }
    });
  });
  return isCheckTrigger;
};

export const getSelectedPagedTasks = (
  selectedTasks: Task[],
  pagedTasks: Task[],
) => {
  // Return selected tasks on the current page
  return selectedTasks.filter((e) => pagedTasks.some((t) => t.name === e.name));
};

export const handleSelectAllTasks = (
  e: any,
  selectedTasks: FilteredResource[],
  pagedTasks: Task[],
  setSelectedTasks: any,
) => {
  handleSelectAllClick(
    e,
    selectedTasks,
    pagedTasks.filter((t: Task) => allowSelectionForPendingTask(t)),
    setSelectedTasks,
  );
};

export const handleSelectAllClick = (
  e: any,
  selectedTasks: FilteredResource[],
  pagedTasks: FilteredResource[],
  setSelectedTasks: any,
) => {
  if (!e.target.checked) {
    // Removing all the tasks of the current page
    const finalTasks = selectedTasks.filter(
      (e) => !pagedTasks.some((t) => t.name === e.name),
    );
    setSelectedTasks(finalTasks);
  } else {
    // Filtering paged tasks that are not already in the selected paged tasks list,
    // also exclude tasks with the status 'CREATED'.
    const finalTasks = pagedTasks.filter(
      (t) =>
        !getSelectedPagedTasks(
          selectedTasks as Task[],
          pagedTasks as Task[],
        ).some((e) => t.name === e.name),
    );
    setSelectedTasks((prev: Task[]) => [...prev, ...finalTasks]);
  }
};

export const handleTaskCheckboxClick = (
  e: any,
  task: Task,
  selectedTasks: Task[],
  setSelectedTasks: React.Dispatch<React.SetStateAction<Task[]>>,
) => {
  e.stopPropagation();
  if (e.target.checked) {
    setSelectedTasks((prev: Task[]) => [...prev, task]);
  } else {
    const newSelectedTasks = selectedTasks.filter((t) => t.name !== task.name);
    setSelectedTasks(newSelectedTasks);
  }
};

export const areAllTasksSelected = (
  selectedTasks: Task[],
  pagedTasks: Task[],
) => {
  return isSelectAllChecked(
    selectedTasks,
    pagedTasks.filter((t: Task) => allowSelectionForPendingTask(t)),
  );
};

export const isSelectAllChecked = (
  selectedTasks: Task[],
  pagedTasks: Task[],
) => {
  if (selectedTasks.length) {
    // Exclude tasks with the status 'CREATED'.
    return (
      getSelectedPagedTasks(selectedTasks, pagedTasks).length ===
      pagedTasks.length
    );
  }
  return false;
};

export const isTaskSelected = (task: Task, selectedTasks: Task[]) => {
  return selectedTasks.some((t) => t.name === task.name);
};

export const groupTasksByError = (tasks: MissedTask[]) => {
  const groupedTasks = tasks.reduce(
    (acc: { errorMsg: string; taskNames: string[] }[], t) => {
      const errorMsg = t.errorMsg!;

      // Check if there is already a group for this errorMsg
      const existingGroup = acc.find((group) => group.errorMsg === errorMsg);

      if (existingGroup) {
        // Add the taskName to the existing group
        existingGroup.taskNames.push(t.task!.displayName!);
      } else {
        // Create a new group for this errorMsg
        acc.push({
          errorMsg: errorMsg,
          taskNames: [t.task!.displayName!],
        });
      }

      return acc;
    },
    [],
  );

  return groupedTasks;
};

export const allowSelectionForPendingTask = (t: Task) => {
  return t.status !== TaskSTATUS.CREATED;
};

export const allowSelectionForArchivedResources = (
  resource: FilteredResource,
) => {
  // if unrestorableReason is not present or empty we allow selection
  return !resource.deletedObjectInfo?.unrestorableReason;
};

// This function transforms input details into a structured array of entity details.
// It categorizes entities into parent and child relationships based on their properties.
// The resulting array includes parent entities, child entities, and standalone entities.
export const getEntityDetails = (details: EntityDetails[]) => {
  const entityDetails: { name: string; isParent?: boolean; parent?: string }[] =
    [];
  details.map(({ entityType, properties, normalizationType }) => {
    if (normalizationType !== EntityDataType.ENTITY_TYPE_UNSPECIFIED) {
      if (properties!.length) {
        entityDetails.push({ name: entityType!, isParent: true });
        properties!.map((e) =>
          entityDetails.push({ name: e.entityType!, parent: entityType }),
        );
      } else {
        entityDetails.push({ name: entityType! });
      }
    }
  });
  return entityDetails;
};

// This function transforms entities array into a structured array of entity details .
// It categorizes entities into parent and child relationships based on their properties.
// The resulting array includes parent entities, child entities, and standalone entities.
export const getEntityDetailsFromEntities = (
  entities: string[],
  typeSchemaMapping: Record<string, EntityTypeSchema>,
) => {
  const entityDetails: { name: string; isParent?: boolean; parent?: string }[] =
    [];
  entities.map((entity) => {
    if (
      typeSchemaMapping[entity]?.normalizationType !==
      EntityDataType.ENTITY_TYPE_UNSPECIFIED
    ) {
      const splitEntity = entity.split('/');
      if (splitEntity.length > 1) {
        if (!entityDetails.some((e) => e.name === splitEntity[0])) {
          entityDetails.push({ name: splitEntity[0], isParent: true });
        }
        entityDetails.push({ name: splitEntity[1], parent: splitEntity[0] });
      } else {
        entityDetails.push({ name: entity });
      }
    }
  });
  return entityDetails;
};

export const getAttributeFilters = (workflow: Workflow) => {
  if (workflow) {
    const entityIndex = getActionIndex(
      workflow,
      ApplicationName.EntityExtraction,
    ) as { stepIndex: number; actionIndex: number };
    if (entityIndex.stepIndex >= 0) {
      const entityExtraction: EntityExtractionParam = workflow.steps?.[
        entityIndex.stepIndex
      ]?.actions?.[entityIndex.actionIndex]
        .entityExtraction as EntityExtractionParam;
      if (entityExtraction.entitiesDetails?.length) {
        return getEntityDetails(entityExtraction.entitiesDetails);
      } else {
        return getEntityDetailsFromEntities(
          entityExtraction.entities!,
          entityExtraction.entityTypeSchemaMapping!,
        );
      }
    }

    const classificationIndex = getActionIndex(
      workflow,
      ApplicationName.DocumentClassification,
    ) as { stepIndex: number; actionIndex: number };
    if (classificationIndex.stepIndex >= 0) {
      return [{ name: CLASSIFICATION_SCHEMA_DOCUMENT_TYPE }];
    }
  }

  return [];
};

// Flatten the entitiesDetails array, including both parent and child entities
export const flattenEntitiesDetails = (details: EntityDetails[]) => {
  const allDetails: EntityDetails[] = [];
  details.map((entity) => {
    allDetails.push(entity);
    entity.properties?.map((pr) => {
      allDetails.push(pr);
    });
  });
  return allDetails;
};

export const getAttributeDataType = (attribute: string, workflow: Workflow) => {
  if (workflow) {
    /**
     * If attribute is 'document type',
     * then we need to check if the DOCUMENT CLASSIFICATION step exists
     * We return `CLASSIFICATION_LABEL_SELECT` if it does
     */
    if (attribute === CLASSIFICATION_SCHEMA_DOCUMENT_TYPE) {
      const classificationIndex = getActionIndex(
        workflow,
        ApplicationName.DocumentClassification,
      ) as { stepIndex: number; actionIndex: number };
      if (
        classificationIndex.stepIndex >= 0 &&
        classificationIndex.actionIndex >= 0
      ) {
        return CLASSIFICATION_LABEL_SELECT;
      }
      return EntityDataType.ENTITY_TYPE_NESTED;
    }

    const entityIndex = getActionIndex(
      workflow,
      ApplicationName.EntityExtraction,
    ) as { stepIndex: number; actionIndex: number };
    if (entityIndex.stepIndex >= 0) {
      const entityExtraction =
        workflow.steps?.[entityIndex.stepIndex]?.actions?.[
          entityIndex.actionIndex
        ].entityExtraction;
      const flattenEntityDetails = flattenEntitiesDetails(
        entityExtraction?.entitiesDetails as EntityDetails[],
      );
      return (
        entityExtraction?.entityTypeSchemaMapping?.[attribute]
          ?.normalizationType ||
        flattenEntityDetails.find((e) => e.entityType === attribute)
          ?.normalizationType ||
        EntityDataType.ENTITY_TYPE_NESTED
      );
    }
  }
  return EntityDataType.ENTITY_TYPE_NESTED;
};

export const getClassificationLabels = (
  workflow: Workflow,
): { value: string; label: string }[] => {
  if (workflow) {
    const classificationIndex = getActionIndex(
      workflow,
      ApplicationName.DocumentClassification,
    ) as { stepIndex: number; actionIndex: number };
    if (
      classificationIndex.stepIndex >= 0 &&
      classificationIndex.actionIndex >= 0
    ) {
      return (
        workflow?.steps?.[classificationIndex.stepIndex]?.actions?.[
          classificationIndex?.actionIndex
        ]?.classification?.classificationLabels ?? []
      ).map((label) => ({
        label,
        value: label.toLowerCase(),
      }));
    }
  }

  return [];
};

export const getYesterdayTimestamp = () => {
  const currentDate = new Date();
  const startOfDay = new Date(currentDate);
  startOfDay.setDate(currentDate.getDate() - 1); // Set to yesterday
  startOfDay.setHours(0, 0, 0, 0); // Set time to midnight
  const startOfDayTimestamp = Math.floor(startOfDay.getTime() / 1000);
  const endOfDayTimestamp = startOfDayTimestamp + 86399; // End of yesterday (23:59:59)
  return { startDate: startOfDayTimestamp, endDate: endOfDayTimestamp };
};

export const getDateRange = (
  optionValue: string,
  customRange: DateRange | null,
) => {
  if (optionValue === DateOptionValues.CUSTOM_RANGE && customRange) {
    const startDate = Math.floor(
      customRange.startDate.startOf('day').toDate().getTime() / 1000,
    );
    const endDate = Math.floor(
      customRange.endDate.endOf('day').toDate().getTime() / 1000,
    );
    return { startDate, endDate };
  }
  const currentDate = new Date();
  const currentTimestamp = Math.floor(currentDate.getTime() / 1000); // Convert milliseconds to seconds
  switch (optionValue) {
    case DateOptionValues.TODAY: // Today
      return {
        startDate:
          currentTimestamp -
          (currentDate.getHours() * 3600 +
            currentDate.getMinutes() * 60 +
            currentDate.getSeconds()),
        endDate: currentTimestamp,
      };
    case DateOptionValues.YESTERDAY: // Yesterday
      return getYesterdayTimestamp();
    case DateOptionValues.LAST_WEEK: // Last 7 days
      return {
        startDate: currentTimestamp - 604800,
        endDate: currentTimestamp,
      };
    case DateOptionValues.LAST_MONTH: // Last 30 days
      return {
        startDate: currentTimestamp - 2592000,
        endDate: currentTimestamp,
      };
    default:
      return null;
  }
};

const getValidPeriod = (urlSearchParams: URLSearchParams) => {
  const period = urlSearchParams.get(PERIOD) ?? '';
  if (Object.values(DateOptionValues).includes(period as DateOptionValues)) {
    return period;
  }
  return DateOptionValues.LAST_WEEK; // return default value if search params are invalid or empty
};

export const getValidDateFilter = (urlSearchParams: URLSearchParams) => {
  const period = getValidPeriod(urlSearchParams);
  if (period !== DateOptionValues.CUSTOM_RANGE) {
    return { period, range: defaultDateRange };
  }
  const startDate = dayjs(urlSearchParams.get(START_DATE));
  const endDate = dayjs(urlSearchParams.get(END_DATE));
  // check whether dates are valid and startDate should not be after endDate
  if (startDate.isValid() && endDate.isValid() && !startDate.isAfter(endDate)) {
    return {
      period: DateOptionValues.CUSTOM_RANGE,
      range: { startDate, endDate },
    };
  }
  return {
    period: DateOptionValues.LAST_WEEK,
    range: defaultDateRange,
  };
};

export const getEnteredEntityType = (entityType: string) => {
  if (entityType == 'integer') {
    return EntityDataType.ENTITY_TYPE_INTEGER;
  } else if (entityType == 'float') {
    return EntityDataType.ENTITY_TYPE_FLOAT;
  } else if (entityType == 'date') {
    return EntityDataType.ENTITY_TYPE_DATE;
  } else if (entityType == 'money') {
    return EntityDataType.ENTITY_TYPE_MONEY;
  } else if (entityType == 'text') {
    return EntityDataType.ENTITY_TYPE_TEXT;
  } else if (entityType == 'nested') {
    return EntityDataType.ENTITY_TYPE_NESTED;
  } else if (entityType == 'notes') {
    return EntityDataType.ENTITY_TYPE_ANNOTATION;
  } else {
    return EntityDataType.ENTITY_TYPE_UNSPECIFIED;
  }
};

// Function that gets entity schema list based on input entered
// This is helpful when entities are added in bulk separated by commas
export const getEntitySchemaListWithString = (
  entityString: string,
  entityTypeString?: string,
  parentString?: string,
  parentEntityId?: string,
) => {
  const entityList = entityString.toLowerCase().split(',');
  const entityTypeList = entityTypeString?.split(',');
  const parentTypeList = parentString?.split(',');
  const entitySchemaList: SchemaEntity[] = [];
  entityList.forEach((name: string, index: number) => {
    const entityName = name.trim().replace(/\s+/g, ' ');
    const entityType = entityTypeList
      ? getEnteredEntityType(entityTypeList[index])
      : entityName.startsWith('*')
        ? EntityDataType.ENTITY_TYPE_ANNOTATION
        : EntityDataType.ENTITY_TYPE_TEXT;
    const parentName = parentTypeList
      ? parentTypeList[index].trim().replace(/\s+/g, ' ')
      : undefined;
    if (parentEntityId) {
      entitySchemaList.push({
        id: uuidv4(),
        entityName,
        normalizationType: entityType,
        parentEntityId: parentEntityId,
      });
    } else {
      if (parentName !== undefined && parentName !== '') {
        // check if this parent already exists in schemaList
        const parentEntity = entitySchemaList.find(
          (e) =>
            e.entityName === parentName &&
            e.normalizationType === EntityDataType.ENTITY_TYPE_NESTED,
        );
        if (parentEntity) {
          entitySchemaList.push({
            id: uuidv4(),
            entityName: entityName,
            normalizationType: entityType,
            parentEntityId: parentEntity.id,
          });
        } else {
          const parentEntityId = uuidv4();
          entitySchemaList.push({
            id: parentEntityId,
            entityName: parentName,
            normalizationType: EntityDataType.ENTITY_TYPE_NESTED,
          });
          entitySchemaList.push({
            id: uuidv4(),
            entityName: entityName,
            normalizationType: entityType,
            parentEntityId,
          });
        }
      } else {
        entitySchemaList.push({
          id: uuidv4(),
          entityName: entityName,
          normalizationType: entityType,
        });
      }
    }
  });
  return entitySchemaList;
};

// Displays the Date Range in desired format (eg: Dec 3, 23 - Jan 4, 24)
export const getDateRangeForDisplay = (
  startDate: string,
  endDate: string,
  format = 'MMM D, YY',
) => {
  return `${moment(startDate).format(format).toString()} - ${moment(endDate)
    .format(format)
    .toString()}`;
};

// This function take a string it could be either email or name and return the initial letters
export const getInitials = (name: string): string => {
  // this check is just for safety
  if (!name || (name && !name?.trim())) {
    return '';
  }
  const parts = name.split(' ');
  if (parts.length === 1) return parts[0][0].toUpperCase();
  if (parts.length === 2)
    return parts[0][0].toUpperCase() + parts[1][0].toUpperCase();
  return '';
};

// helper function which will act as flag to allow all child entities to have prefix '$ParentEntityName/' for google orgs
export const allowParentNamePrefixForGoogleOrgs = (orgId: string) => {
  const googleOrgsToAllow = [
    'organizations/64b82b95f1b892e83245d566',
    'organizations/64b82c23f1b892e83245d567',
    'organizations/64b82c47f1b892e83245d569',
  ];
  // this is for testing purpose only remove this after testing
  const devOrgsToAllow = ['organizations/641aae5b328566b887c424ff']; // ORBY AI -> Dev
  const preprodOrgsToAllow = ['organizations/65aef95afa9131354c9f3a2a']; // ORBY AI -> Preprod
  return [
    ...googleOrgsToAllow,
    ...devOrgsToAllow,
    ...preprodOrgsToAllow,
  ].includes(orgId);
};

export const isChildPrefixedWithParentName = (
  childName: string,
  parentName: string,
) => {
  const pattern = new RegExp(`^\\**${parentName}\\/[^*/]+$`);
  return pattern.test(childName);
};

export const trimParentNamePrefix = (entityName: string) => {
  return entityName.slice(entityName.indexOf('/') + 1);
};

export const removeQuotes = (str: string) => {
  if (str.startsWith('"') && str.endsWith('"')) {
    return str.slice(1, -1);
  } else if (str.startsWith("'") && str.endsWith("'")) {
    return str.slice(1, -1);
  }
  return str;
};

// temporary solution to get gcs uri
export const getGcsProtoUriFromDocumentUri = (documentUri: string) => {
  const splitUriIntoUrlAndParams = documentUri.split('?');
  if (!splitUriIntoUrlAndParams.length) return '';
  const baseUrl = splitUriIntoUrlAndParams[0];
  const gcsUri = baseUrl.replace('https://storage.googleapis.com/', 'gs://');
  return decodeURIComponent(gcsUri);
};

export const validateCsvEntity = (name: string, type: string) => {
  // Check if entity_type is missing
  if (!type) {
    throw new Error(
      `Entity "${name}" is missing the required "entity_type" field.`,
    );
  }
  // Check if invalid entity type
  if (
    !getEnteredEntityType(type) ||
    getEnteredEntityType(type) === EntityDataType.ENTITY_TYPE_UNSPECIFIED
  ) {
    throw new Error(
      `Entity "${name}" has an invalid entity_type "${type}". Supported types: integer, float, date, money, text, nested, notes.`,
    );
  }
  // Check if entity name starts with '*' but its normalization type is not ENTITY_TYPE_ANNOTATION.
  if (
    name.startsWith('*') &&
    getEnteredEntityType(type) !== EntityDataType.ENTITY_TYPE_ANNOTATION
  ) {
    throw new Error(
      `Entity "${name}" starts with * but its normalization type is not ENTITY_TYPE_ANNOTATION.`,
    );
  }
  // Validate Notes Entity
  if (isInvalidNotesEntity(name)) {
    throw new Error(`Entity "${name}": ${invalidNotesEntityNameMsg}`);
  }
  // Validate Invalid characters in Entity name
  if (hasInvalidCharacters(name)) {
    throw new Error(`Entity "${name}": ${invalidCharactersInEntityNameMsg}`);
  }
};

export const validateAllCsvEntities = (
  entityList: CSVEntityDetails[],
  defaultEntities: SchemaEntity[],
  schemaEntities: SchemaEntity[] = [],
) => {
  // Handle empty entityList
  if (!entityList?.length) {
    throw new Error(
      'The uploaded entity list is empty. Please add at least one entity before uploading.',
    );
  }

  // Handle missing entity_name
  if (!Object.hasOwn(entityList[0], 'entity_name')) {
    throw new Error(
      'Missing required "entity_name" property in uploaded entities.',
    );
  }
  if (!Object.hasOwn(entityList[0], 'entity_type')) {
    throw new Error(
      'Missing required "entity_type" property in uploaded entities.',
    );
  }

  // Handle case for duplicate entities
  const duplicateEntity = entityList.find((e, i) =>
    entityList.some((l, j) => {
      if (
        i !== j &&
        e?.entity_name?.toLowerCase() === l?.entity_name?.toLowerCase()
      ) {
        if (!e?.parent_entity_name && !l?.parent_entity_name) {
          return true;
        } else {
          return (
            e?.parent_entity_name?.toLowerCase() ===
            l?.parent_entity_name?.toLowerCase()
          );
        }
      }
    }),
  );
  if (duplicateEntity) {
    if (duplicateEntity?.parent_entity_name) {
      throw new Error(
        `Entity "${duplicateEntity.entity_name}" already exists for parent "${duplicateEntity.parent_entity_name}".`,
      );
    } else {
      throw new Error(`Entity "${duplicateEntity.entity_name}" already exists`);
    }
  }
  // Handle case for default entiites
  const defaultEntityInList = entityList.find((e) =>
    defaultEntities.some((d) => d.entityName === e.entity_name?.toLowerCase()),
  );
  if (defaultEntityInList) {
    if (defaultEntityInList?.parent_entity_name) {
      throw new Error(
        `Unable to add entity "${defaultEntityInList.entity_name}" for parent "${defaultEntityInList.parent_entity_name}" as it is a default entity.`,
      );
    } else {
      throw new Error(
        `Unable to add entity "${defaultEntityInList.entity_name}" as it is a default entity.`,
      );
    }
  }
  if (
    [
      ...getAllCSVNestedEntitiesWithoutNotes(entityList),
      ...getAllNestedEntitiesWithoutNotes(schemaEntities),
    ].length > MAX_NESTED_ENTITIES
  ) {
    throw new Error(
      `You can add a maximum of ${MAX_NESTED_ENTITIES} nested entities`,
    );
  }
};

const getAllCSVNestedEntitiesWithoutNotes = (
  entityList: CSVEntityDetails[],
) => {
  return entityList.filter(
    (e) =>
      !checkIfNotesEntityType(e.entity_name) &&
      getEnteredEntityType(e.entity_type) !==
        EntityDataType.ENTITY_TYPE_ANNOTATION &&
      e.parent_entity_name,
  );
};

export const getEntitiesOtherThanNotesAndNestedType = (
  entitiesList: SchemaEntity[],
) => {
  return entitiesList.filter(
    (e) => !checkIfNotesEntityType(e.entityName) && !e.parentEntityId,
  );
};

export const getAllNestedEntitiesWithoutNotes = (
  entitiesList: SchemaEntity[],
) => {
  return entitiesList.filter(
    (e) => !checkIfNotesEntityType(e.entityName) && e.parentEntityId,
  );
};

export const getExtensionFromFileName = (fileName: string) => {
  const regex = /(?:\.([^.]+))?$/;
  return regex.exec(fileName)?.[1];
};

export const copyTextToClipBoard = async (text: string) => {
  return navigator.clipboard
    .writeText(text)
    .then(() => {
      return true;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
};

/**
 * Computes duration and format in `M minutes N seconds` form.
 */
export function formatDuration(startTime?: Date, endTime?: Date) {
  if (!startTime || !endTime) {
    return '';
  }
  const durationInSeconds = Math.floor(
    (endTime.getTime() - startTime.getTime()) / 1000,
  );
  if (durationInSeconds <= 0) {
    return '';
  }
  if (durationInSeconds < 60) {
    return `${durationInSeconds} seconds`;
  }
  const seconds = durationInSeconds % 60;
  const minutes = Math.floor(durationInSeconds / 60);
  return `${minutes} minutes ${seconds} seconds`;
}

export function deepEqual(obj1: any, obj2: any) {
  // Check if both are the same reference
  if (obj1 === obj2) return true;

  // Check if both are objects
  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  // Compare keys and values recursively
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    // Recursively compare for nested objects
    const areObjects = typeof val1 === 'object' && typeof val2 === 'object';
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

export const detectUndoRedoKeyPress = (e: React.KeyboardEvent) => {
  if ((e.ctrlKey || e.metaKey) && ['KeyY', 'KeyZ'].includes(e.code)) {
    return true;
  }
};

export const removeUnwantedVerticalBars = (text: string): string => {
  const trimmedText = text.trim();

  // Quit early if no vertical bars are present or length is one after trimming
  if (!trimmedText.includes('|') || trimmedText.length === 1) {
    return trimmedText;
  }

  // Step 1: Remove `|` when surrounded by non `|` characters
  let result = trimmedText.replace(/(?<!\|)\|(?!\|)/g, ' ');

  // Step 2: Remove `|` at the start if not followed by another `|`
  result = result.replace(/^\|(?!\|)/, '');

  // Step 3: Remove `|` at the end if not preceded by another `|`
  result = result.replace(/(?<!\|)\|$/, '');

  // Replace multiple spaces with a single space
  return result.trim().replace(/\s+/g, ' ');
};
