import { FieldGroupMatch, Item } from 'protos/pb/v1alpha1/actionprocessing';
import { FieldsUpdate } from '../po-reconcile';
import { getJDE1UpdateOrFallout } from '../falloutReason';

/**
 * JDE1 updates will handle n -> 1 cases where multiple invoices are matched to a single PO.
 * In such case, we need to sum the invoices amount/quantity and update the PO.
 * For all rest mismatch cases, we just ignore them.
 *
 * This function ignores fallouts. If we need to validate fallout, expect to call
 * {@link getFalloutStatus} and validate the result first.
 */
export function getJDE1Updates(
  matches: FieldGroupMatch[],
  items: Item[],
): FieldsUpdate[] {
  const updates: FieldsUpdate[] = [];

  // jde1Index -> [caapsIndex]
  const jde1ToCappsIndices = new Map<number, number[]>();
  matches.forEach((match) => {
    if (!match.match) {
      return;
    }
    const jde1Index = match.match.sourceIndex!;
    if (!jde1ToCappsIndices.has(jde1Index)) {
      jde1ToCappsIndices.set(jde1Index, []);
    }
    jde1ToCappsIndices.get(jde1Index)?.push(match.match.targetIndex!);
  });

  for (const [jde1Index, cappsIndices] of jde1ToCappsIndices.entries()) {
    const result = getJDE1UpdateOrFallout(jde1Index, cappsIndices, items);
    if (!result.isFallout) {
      updates.push(result.update);
    }
  }

  return updates;
}
