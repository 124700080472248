import React from 'react';
import CustomModal from '../../../components/CustomModal';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { ScheduleScheduleStatus } from 'protos/pb/v1alpha1/schedule';

const HEADING_TITLE_MAP = {
  [ScheduleScheduleStatus.UNRECOGNIZED]: {
    heading: 'Start the schedule?',
    content:
      'After starting the schedule, the workflow will run automatically as scheduled',
    buttonLabel: 'Start',
  },
  [ScheduleScheduleStatus.UNSPECIFIED]: {
    heading: 'Start the schedule?',
    content:
      'After starting the schedule, the workflow will run automatically as scheduled',
    buttonLabel: 'Start',
  },
  [ScheduleScheduleStatus.ACTIVE]: {
    heading: 'Pause the schedule?',
    content:
      'After pausing the schedule, the workflow will no longer run automatically as scheduled',
    buttonLabel: 'Pause',
  },
  [ScheduleScheduleStatus.PAUSED]: {
    heading: 'Resume the schedule?',
    content:
      'After resuming the schedule, the workflow will run automatically as scheduled',
    buttonLabel: 'Resume',
  },
};

interface Props {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  loading?: boolean;
  currentStatus?: ScheduleScheduleStatus;
}

const ScheduleEditStatus: React.FC<Props> = ({
  open,
  handleClose,
  onSubmit,
  loading = false,
  currentStatus,
}) => {
  const { heading, content, buttonLabel } =
    HEADING_TITLE_MAP[currentStatus || ScheduleScheduleStatus.UNRECOGNIZED];
  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      onPrimaryClick={() => onSubmit()}
      isPrimaryLoading={loading}
      primaryDisabled={loading}
      secondaryDisabled={loading}
      heading={heading}
      containerSx={{
        width: '156px',
        paddingTop: 0,
        paddingBottom: '24px',
      }}
      content={
        <OrbyTypography
          size='sm'
          color={OrbyColorPalette['grey-700']}
          weight='medium'
        >
          {content}
        </OrbyTypography>
      }
      primaryLabel={buttonLabel}
      secondaryLabel={'Cancel'}
    />
  );
};

export default React.memo(ScheduleEditStatus);
