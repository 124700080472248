import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityInfo } from '../../../../../../../redux/reducers/review_task.reducer';
import {
  allowEditingTaskSelector,
  columnOrderInfoForTableEntitiesSelector,
  rowOrderInfoForSelectedParentEntitySelector,
  hiddenEntityTypesForTableAnnotationSelector,
  lastLocatedEntityTypeSelector,
  selectedEntityIdsForAnnotationSelector,
  selectedParentEntityInfoSelector,
  selectedTableEntitiesInfoMapSelector,
  visibleColumnsSelector,
  tableMapSelector,
  entitiesByColumnSelector,
} from '../../../../../../../redux/selectors/review_task.selectors';
import { Table, TableContainer } from '@mui/material';
import {
  deleteTextSegmentsFromEntitiesAction,
  setLastLocatedEntityTypeAction,
  setSelectedEntityIdsForAnnotationAction,
  updateEntityInfoForTableAnnotationAction,
} from '../../../../../../../redux/actions/review_task.action';
import { MIN_TABLE_POPOVER_CELL_WITH } from '../../../../../../../utils/constants';
import { checkFocusOnInputField } from '../../../../../../../utils/FocusUtils';
import './tableModalBody.css';
import TableTextPopover from './TableTextPopover';
import TableHeader from './TableHeader';
import TableBody from './TableBody/TableBody';
import { useVirtualizer } from '@tanstack/react-virtual';

interface Props {
  showNormalizedValues: boolean;
}

const TableModalBodyV2: React.FC<Props> = ({ showNormalizedValues }) => {
  const dispatch = useDispatch();
  const selectedEntityIdsForAnnotation = useSelector(
    selectedEntityIdsForAnnotationSelector,
  );
  const allowEditingTask = useSelector(allowEditingTaskSelector);
  const selectedTableEntitiesInfoMap = useSelector(
    selectedTableEntitiesInfoMapSelector,
  );
  const entitiesByColumnType = useSelector(entitiesByColumnSelector);
  const selectedParentEntityInfo = useSelector(
    selectedParentEntityInfoSelector,
  );
  const lastLocatedEntityType = useSelector(lastLocatedEntityTypeSelector);
  const columnOrderInfoForTableEntities = useSelector(
    columnOrderInfoForTableEntitiesSelector,
  );

  const hiddenEntityTypesForTableAnnotation = useSelector(
    hiddenEntityTypesForTableAnnotationSelector,
  );
  const headerCells = useSelector(visibleColumnsSelector);
  const tableMap = useSelector(tableMapSelector);
  const [draggingElemId, setDraggingElemId] = React.useState('');

  const [columnTypeToResize, setColumnTypeToResize] = React.useState('');

  // keeps track of column element to size
  const columnToResizeRef = React.useRef('');
  const [selectedPopoverCellId, setSelectedPopoverCellId] = React.useState<
    string | undefined
  >(undefined);
  // This is to get current value of selectedPopoverCell
  const selectedPopoverCell = useMemo(
    () => selectedTableEntitiesInfoMap[selectedPopoverCellId ?? ''],
    [selectedTableEntitiesInfoMap, selectedPopoverCellId],
  );
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<any>(null);

  // This state basically keeps track of entity which is copied and can be pasted
  const [clipboardEntity, setClipboardEntity] = React.useState<
    EntityInfo | undefined
  >(undefined);

  const rowOrderInfoForTableEntities = useSelector(
    rowOrderInfoForSelectedParentEntitySelector,
  );

  const getHiddenHeaderCells = useCallback(() => {
    // Retrieve the column order information for the selected parent entity type
    return columnOrderInfoForTableEntities[
      selectedParentEntityInfo?.type || ''
    ].filter((e) =>
      // Return entities that are hidden based on the hiddenEntityTypesForTableAnnotation configuration
      hiddenEntityTypesForTableAnnotation[
        selectedParentEntityInfo?.type || ''
      ]?.includes(e.type),
    );
  }, [
    columnOrderInfoForTableEntities,
    selectedParentEntityInfo?.type,
    hiddenEntityTypesForTableAnnotation,
  ]);

  useEffect(() => {
    // Selecting next header rows after bulk annotation
    if (lastLocatedEntityType) {
      if (headerCells?.length) {
        const index = headerCells.findIndex(
          (row) => row.type === lastLocatedEntityType,
        );
        const nextHeaderRow = headerCells[index + 1];
        if (nextHeaderRow) {
          dispatch(
            setSelectedEntityIdsForAnnotationAction(
              entitiesByColumnType.get(nextHeaderRow.type) || [],
            ),
          );
        }
        dispatch(setLastLocatedEntityTypeAction());
      }
    }
  }, [lastLocatedEntityType, entitiesByColumnType]);

  // Function to select header cell,
  // if deselect is true, it removes the selection otherwise selects all entities inside that header
  const selectHeaderCell = useCallback(
    (cell: EntityInfo, deselect = false) => {
      if (deselect) {
        dispatch(setSelectedEntityIdsForAnnotationAction([]));
      } else {
        dispatch(
          setSelectedEntityIdsForAnnotationAction(
            entitiesByColumnType.get(cell.type) || [],
          ),
        );
      }
    },
    [entitiesByColumnType],
  );

  // This is used when any single entity is selected or when multiple is selected using
  // shift key. If an entity is already selected, then clicking it removes the selection
  const handleTableCellClick = useCallback(
    (
      e: React.MouseEvent | React.KeyboardEvent | React.FocusEvent,
      cell: EntityInfo,
    ) => {
      // Check if the Shift key was held down during the click event
      if ('shiftKey' in e && e.shiftKey) {
        // Retrieve all column entities of the same type as the clicked cell
        const allColumnEntities = rowOrderInfoForTableEntities.map(
          (rowId) => tableMap[`${rowId}-${cell.type}`]?.id,
        );
        // Only proceed if there are selected entities which belong to the same column as the clicked cell
        // Since selectedEntityIdsForAnnotation will contain only entity ids
        // belonging to the same column we can just check the first element
        if (
          selectedEntityIdsForAnnotation.length > 0 &&
          allColumnEntities?.includes(selectedEntityIdsForAnnotation[0])
        ) {
          // Get the first selected entity ID
          const firstSelectedID = selectedEntityIdsForAnnotation[0];
          // Find the index of the first selected entity and the clicked entity in the list
          const startIndex = allColumnEntities.indexOf(firstSelectedID);
          const endIndex = allColumnEntities.indexOf(cell.id);

          // Ensure both entities exist in the column's list
          if (startIndex !== -1 && endIndex !== -1) {
            // Determine the end index for slicing:
            // - If startIndex < endIndex, add +1 to ensure the clicked entity is included at the last position.
            // - If startIndex >= endIndex, use startIndex (no +1) to avoid adding the first selected entity at the last position.
            const sliceEndIndex =
              startIndex < endIndex ? endIndex + 1 : startIndex;

            // Get the array of IDs between startIndex and endIndex
            const idsBetween = allColumnEntities.slice(
              Math.min(startIndex, endIndex),
              sliceEndIndex,
            );

            // Ensure firstId is always at the first position
            // This mimics Excel's behavior when selecting multiple cells with Shift+Click
            // In Excel, the first selected cell acts as an anchor point for the selection
            // When you Shift+Click another cell, Excel selects all cells between the anchor and the clicked cell
            // By keeping the first selected ID at the start of our array, we maintain this anchor concept
            // This allows for consistent and predictable multi-select behavior in our table interface
            //
            // Example:
            // Let's say we have a column with cells: [A1, A2, A3, A4, A5]
            // 1. User clicks A2 (anchor point): selection = [A2]
            // 2. User Shift+Clicks A4: selection = [A2, A3, A4]
            // 3. User Shift+Clicks A1: selection = [A2, A1]  (A2 remains the anchor)
            // 4. User Shift+Clicks A5: selection = [A2, A3, A4, A5]
            //
            // In each case, A2 (the firstSelectedID) remains at the start of the selection array,
            // allowing the user to expand or contract the selection around this anchor point
            if (idsBetween[0] !== firstSelectedID) {
              idsBetween.unshift(firstSelectedID);
            }

            dispatch(setSelectedEntityIdsForAnnotationAction(idsBetween));
          }
        }
      } else if (
        !selectedEntityIdsForAnnotation.includes(cell.id) ||
        selectedEntityIdsForAnnotation.length > 1
      ) {
        dispatch(setSelectedEntityIdsForAnnotationAction([cell.id]));
      }
    },
    [selectedEntityIdsForAnnotation, rowOrderInfoForTableEntities, tableMap],
  );

  const handleKeyBoardNavigation = useCallback(
    (event: React.KeyboardEvent, cell: EntityInfo) => {
      // Find the index of the current header based on the row type
      const columnIndex = headerCells.findIndex((h) => h.type === cell.type);
      const rowIndex = rowOrderInfoForTableEntities.findIndex(
        (r) => r === cell.parentEntityId,
      );

      const navigateToCell = (id: string | undefined) => {
        if (id) {
          document.getElementById(`cell-${id}`)?.focus();
          dispatch(setSelectedEntityIdsForAnnotationAction([id]));
        }
      };
      // TODO: Store rowIndex and cellIndex to select the cell quickly and avoid searching to increase efficiency

      switch (event.key) {
        case 'ArrowLeft': {
          const columnId = headerCells[Math.max(0, columnIndex - 1)].type;
          const rowId = cell.parentEntityId;
          const targetCell = tableMap[`${rowId}-${columnId}`];
          navigateToCell(targetCell?.id);
          break;
        }
        case 'ArrowRight': {
          const columnId =
            headerCells[Math.min(headerCells.length - 1, columnIndex + 1)].type;
          const rowId = cell.parentEntityId;
          const targetCell = tableMap[`${rowId}-${columnId}`];
          navigateToCell(targetCell?.id);
          break;
        }
        case 'ArrowUp':
          // Check if selected cell is in first row
          if (rowOrderInfoForTableEntities[0] === cell.parentEntityId) {
            document.getElementById(`cell-${cell?.type}`)?.focus();
            dispatch(
              setSelectedEntityIdsForAnnotationAction(
                entitiesByColumnType.get(cell?.type) || [],
              ),
            );
          } else {
            const columnId = cell.type;
            const rowId =
              rowOrderInfoForTableEntities[Math.max(0, rowIndex - 1)];
            const targetCell = tableMap[`${rowId}-${columnId}`];
            navigateToCell(targetCell?.id);
          }
          break;
        case 'ArrowDown': {
          const columnId = cell.type;
          const rowId =
            rowOrderInfoForTableEntities[
              Math.min(rowOrderInfoForTableEntities.length - 1, rowIndex + 1)
            ];
          const targetCell = tableMap[`${rowId}-${columnId}`];
          navigateToCell(targetCell?.id);
          break;
        }
      }
    },
    [headerCells, tableMap, rowOrderInfoForTableEntities, entitiesByColumnType],
  );

  useEffect(() => {
    // Add an event listener for the Delete key
    const handleKeyDown = (event: KeyboardEvent) => {
      // Since we are making use of delete and backspace and these can be used while editing
      // any field present in UI which would weirdly trigger this and clear segments
      // To avoid it, checking whether focus is not on any input and then proceeding further
      if (
        allowEditingTask &&
        !checkFocusOnInputField() &&
        (event.key === 'Delete' || event.key === 'Backspace')
      ) {
        if (selectedEntityIdsForAnnotation) {
          dispatch(
            deleteTextSegmentsFromEntitiesAction(
              selectedEntityIdsForAnnotation,
            ),
          );
        }
      }
    };
    // Attach the event listener when the component mounts
    document.addEventListener('keydown', handleKeyDown);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedEntityIdsForAnnotation, allowEditingTask]);

  // This is to scroll the cell to viewport when a highlight is clicked to open modal
  useEffect(() => {
    if (selectedEntityIdsForAnnotation.length === 1) {
      const element = document.getElementById(
        `cell-${selectedEntityIdsForAnnotation[0]}`,
      );
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [selectedParentEntityInfo?.type]);

  const draggingElem = document.getElementById(draggingElemId);

  useEffect(() => {
    if (draggingElem) {
      const element = document.getElementById(draggingElemId);
      // If the element exists, scroll it into view
      if (element) {
        element.scrollIntoView({
          behavior: 'auto',
          block: 'nearest', // Align vertically
          inline: 'nearest', // Align horizontally
        });
      }
    }
  }, [draggingElem?.getClientRects()]); // Depend on changes in draggingElem's bounding rectangle

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // If the currently focused element's id starts with 'cell', do nothing
      const activeElementId = document.activeElement?.id;
      if (
        activeElementId?.startsWith('cell') &&
        !activeElementId.includes('menu-button')
      ) {
        return;
      }
      if (selectedEntityIdsForAnnotation.length === 1) {
        const cell =
          selectedTableEntitiesInfoMap[selectedEntityIdsForAnnotation[0]];
        // Handle copy for both mac and windows os
        if ((e.ctrlKey || e.metaKey) && e.code === 'KeyC') {
          setClipboardEntity(cell);
        }

        // Handle copy for both mac and window os
        if ((e.ctrlKey || e.metaKey) && e.code === 'KeyV') {
          handlePaste(cell!);
        }
      }

      // If no table table cell is focused we start from
      // 1. Last Selected Cell if there is any
      // 2. Otherwise we start from first column header
      if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
        if (selectedEntityIdsForAnnotation.length) {
          const lastSelectedEntityId = selectedEntityIdsForAnnotation?.at(-1);
          if (lastSelectedEntityId)
            // Focus on the last selected entity
            handleKeyBoardNavigation(
              e as any as React.KeyboardEvent,
              selectedTableEntitiesInfoMap[lastSelectedEntityId],
            );
        } else {
          const type = headerCells[0]?.type;
          // Focus on the first header cell and select
          if (type) {
            document.getElementById(`cell-${type}`)?.focus();
            dispatch(
              setSelectedEntityIdsForAnnotationAction(
                entitiesByColumnType.get(type) || [],
              ),
            );
          }
        }
      }
    };
    // Add the keydown event listener
    document.addEventListener('keydown', handleKeyDown);
    // Cleanup the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    headerCells,
    selectedEntityIdsForAnnotation,
    selectedTableEntitiesInfoMap,
    entitiesByColumnType,
    handleKeyBoardNavigation,
  ]);

  // This is to handle header cell key down event
  const handleHeaderCellKeyDown = useCallback(
    (e: React.KeyboardEvent, index: number) => {
      // Handle ArrowLeft & ArrowRight
      if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
        // Get cell index to move focus and selection
        const cellIndexToMove = e.key === 'ArrowLeft' ? index - 1 : index + 1;
        const cell = headerCells[cellIndexToMove];
        const columnElement = document.getElementById(`cell-${cell?.type}`);
        if (columnElement) {
          // Move focus and selection to the header cell
          columnElement?.focus();
          selectHeaderCell(cell);
        }
      }
      // Handle ArrowDown
      else if (e.key === 'ArrowDown') {
        const type = headerCells[index]?.type;
        if (type) {
          // Get first row ID of body from rowOrderInfoForTableEntities
          const firstBodyRowId = rowOrderInfoForTableEntities[0];
          // Get body cell ID to move focus and selection
          const bodyCellIdToMove = tableMap[`${firstBodyRowId}-${type}`]?.id;
          // Move focus to the first body cell with same type
          document.getElementById(`cell-${bodyCellIdToMove}`)?.focus();
          if (bodyCellIdToMove) {
            dispatch(
              setSelectedEntityIdsForAnnotationAction([bodyCellIdToMove]),
            );
          }
        }
      }
    },
    [headerCells, rowOrderInfoForTableEntities[0], tableMap],
  );

  // This is to handle paste table cell info.
  const handlePaste = useCallback(
    (cell: EntityInfo | undefined) => {
      if (clipboardEntity && cell) {
        const segmentKeys = Object.keys(clipboardEntity.textSegments);
        const newEntityInfo = {
          ...cell,
          entityText: clipboardEntity.entityText,
          isModified: true,
          textSegments: segmentKeys.reduce((acc, key) => {
            // Restrict copying of entityId
            return {
              ...acc,
              [key]: {
                ...clipboardEntity.textSegments[key],
                entityId: cell.id,
              },
            };
          }, {}),
        };
        dispatch(updateEntityInfoForTableAnnotationAction(newEntityInfo));
      }
    },
    [clipboardEntity],
  );

  // This is to handle table cell key down event
  const handleTableCellKeyDown = useCallback(
    (event: React.KeyboardEvent, row: EntityInfo) => {
      if (!row.isExtra && selectedEntityIdsForAnnotation.length === 1) {
        // Handle copy for both mac and windows os
        if ((event.ctrlKey || event.metaKey) && event.code === 'KeyC') {
          setClipboardEntity(row);
          return;
        }

        // Handle copy for both mac and window os
        if ((event.ctrlKey || event.metaKey) && event.code === 'KeyV') {
          handlePaste(row);
          return;
        }
      }

      handleKeyBoardNavigation(event, row);
    },
    [
      selectedEntityIdsForAnnotation.length,
      handleKeyBoardNavigation,
      handlePaste,
    ],
  );

  const showResizeCursor = (isResizing: boolean) => {
    document.body.style.cursor = isResizing ? 'col-resize' : 'auto';
  };

  useEffect(() => {
    const tableElement = document.getElementById('table-modal-body');
    if (tableElement) {
      tableElement.onmousemove = handleResize;
      tableElement.onmouseup = handleResizeMouseUp;
      return () => {
        tableElement.onmousemove = null;
        tableElement.onmouseup = null;
      };
    }
  }, []);

  const handleResize = (e: MouseEvent) => {
    const type = columnToResizeRef.current;
    if (type) {
      const elm = document.getElementById(type);
      if (elm) {
        const newWidth = e.clientX - elm.getBoundingClientRect().left;
        const finalWidth =
          newWidth < MIN_TABLE_POPOVER_CELL_WITH
            ? MIN_TABLE_POPOVER_CELL_WITH
            : newWidth;
        elm.style.width = finalWidth + 'px';
      }
    }
  };

  const handleResizeMouseUp = () => {
    columnToResizeRef.current = '';
    setColumnTypeToResize('');
    showResizeCursor(false);
  };

  const startResizingColumn = useCallback(
    (e: React.MouseEvent, type: string) => {
      // Prevents text selection while initiating cell resizing
      e.preventDefault();
      columnToResizeRef.current = type;
    },
    [],
  );

  const showResizeLine = useCallback((rowType: string) => {
    setColumnTypeToResize(rowType);
    showResizeCursor(true);
  }, []);

  const hideResizeLine = useCallback(() => {
    if (!columnToResizeRef.current) {
      setColumnTypeToResize('');
      showResizeCursor(false);
    }
  }, []);

  const isCellInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const tableBodyRect = document
      .getElementById('table-modal-body')
      ?.getBoundingClientRect();

    if (!tableBodyRect) {
      return false;
    }
    return (
      rect.top >= tableBodyRect.top &&
      rect.left >= tableBodyRect.left &&
      rect.right <= tableBodyRect.right &&
      rect.bottom <= tableBodyRect.bottom
    );
  };

  const openTextPopover = useCallback(
    (row: EntityInfo) => {
      if (allowEditingTask) {
        setSelectedPopoverCellId(row.id);
        dispatch(setSelectedEntityIdsForAnnotationAction([row.id]));
        const elem = document.getElementById(`cell-${row.id}`);
        if (elem && !isCellInViewport(elem)) {
          elem?.scrollIntoView();
        }
        setPopoverAnchorEl(elem);
      }
    },
    [allowEditingTask],
  );

  const selectNextCellInColumn = useCallback(
    (cell: EntityInfo) => {
      // Mark Entity as reviewed
      const newEntityInfo = { ...cell, isReviewed: true };
      dispatch(updateEntityInfoForTableAnnotationAction(newEntityInfo));

      setPopoverAnchorEl(null);
      // Find the index of the row for currently selected cell
      const rowIndex = rowOrderInfoForTableEntities.findIndex(
        (r) => r === cell.parentEntityId,
      );
      const columnId = cell.type;
      const nextRowId =
        rowOrderInfoForTableEntities[
          Math.min(rowOrderInfoForTableEntities.length - 1, rowIndex + 1)
        ];
      // Get the next cell in the list after the currently selected one
      const nextCell = tableMap[`${nextRowId}-${columnId}`];
      // If there is a next cell, select the next cell
      if (nextCell) {
        dispatch(setSelectedEntityIdsForAnnotationAction([nextCell.id]));
        // Adding a delay before focusing the next cell
        // This ensures the textPopover has enough time to disappear
        // (it may interfere with focusing on the current element)
        setTimeout(() =>
          document.getElementById(`cell-${nextCell.id}`)?.focus(),
        );
      }
    },
    [rowOrderInfoForTableEntities, tableMap],
  );

  const rowVirtualizer = useVirtualizer({
    count: rowOrderInfoForTableEntities.length,
    getScrollElement: () => document.getElementById('table-modal-body'),
    estimateSize: () => (showNormalizedValues ? 75 : 30), // Estimate row height, adjust as needed
    overscan: 10, // Buffer elements
  });

  useEffect(() => {
    // recompute the dimensions
    rowVirtualizer.measure();
  }, [showNormalizedValues]);

  return (
    <TableContainer
      id={'table-modal-body'}
      className='enable-scroll-visibility'
      sx={{
        overflowY: 'scroll',
        flex: 1,
        paddingY: '20px',
      }}
    >
      <Table
        sx={{
          tableLayout: 'fixed',
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        <TableHeader
          getHiddenCells={getHiddenHeaderCells}
          setDraggingElemId={setDraggingElemId}
          columnTypeToResize={columnTypeToResize}
          handleEntityHeaderClick={selectHeaderCell}
          handleHeaderCellKeyDown={handleHeaderCellKeyDown}
          hideResizeLine={hideResizeLine}
          showResizeLine={showResizeLine}
          startResizingColumn={startResizingColumn}
        />
        <TableBody
          virtualItems={rowVirtualizer.getVirtualItems()}
          handleTableCellClick={handleTableCellClick}
          setDraggingElemId={setDraggingElemId}
          columnTypeToResize={columnTypeToResize}
          showResizeLine={showResizeLine}
          startResizingColumn={startResizingColumn}
          hideResizeLine={hideResizeLine}
          clipboardEntity={clipboardEntity}
          handlePaste={handlePaste}
          handleTableCellKeyDown={handleTableCellKeyDown}
          openTextPopover={openTextPopover}
          setClipboardEntity={setClipboardEntity}
          showNormalizedValues={showNormalizedValues}
        />

        {popoverAnchorEl && (
          <TableTextPopover
            onCopy={() => setClipboardEntity(selectedPopoverCell)}
            onPaste={() => handlePaste(selectedPopoverCell)}
            // Enable only if clipboardEntity is present and is not a notes entity
            enablePaste={!!clipboardEntity && !clipboardEntity.isExtra}
            selectedCell={selectedPopoverCell}
            anchorEl={popoverAnchorEl}
            selectNextCell={() =>
              selectNextCellInColumn(selectedPopoverCell as EntityInfo)
            }
            setAnchorEl={setPopoverAnchorEl}
          />
        )}
      </Table>
    </TableContainer>
  );
};

export default React.memo(TableModalBodyV2);
