import { grpc } from '@improbable-eng/grpc-web';
import { GrpcWebImpl } from 'protos/pb/v1alpha2/dashboard_service';
import { Observable } from 'rxjs';

export class RpcError extends Error {
  constructor(e: any) {
    super(e.message);
    this.name = 'RpcError';
    this.code = e.code;
    this.metadata = e.metadata;
  }

  code: grpc.Code;
  metadata: grpc.Metadata;
}

// The below is for attaching the creds/HTTP cookies automatically in the requests
const grpcHttpTransport = grpc.CrossBrowserHttpTransport({
  withCredentials: true,
});

export const rpc = new GrpcWebImpl(process.env.REACT_APP_GRPC_URI as string, {
  // Use the custom transport with cookie support
  transport: grpcHttpTransport,
});

/** capture all RPC errors and re-throw with our own {@link RpcError} instead. */
export const rpcWithErrorHandling = {
  unary: async <T extends grpc.UnaryMethodDefinition<any, any>>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> => {
    try {
      const response = await rpc.unary(methodDesc, request, metadata);
      return response;
    } catch (e: any) {
      throw new RpcError(e);
    }
  },
  invoke: <T extends grpc.UnaryMethodDefinition<any, any>>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> => {
    return new Observable((observer) => {
      rpc.invoke(methodDesc, request, metadata).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          if (error && error.code && error.code === grpc.Code.Unauthenticated) {
            observer.error(new RpcError(error));
          } else {
            observer.error(new RpcError(error));
          }
        },
        () => {
          observer.complete();
        },
      );
    });
  },
};

export const getMetaData = (data: any) => {
  return new grpc.Metadata({ ...data });
};
