import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrbyHeaderLabel, OrbyTable } from 'orby-ui/src';

import { Task, TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import {
  deletingTaskSelector,
  pendingTasksListSelector,
  pendingTasksLoadingSelector,
  pendingTasksTotalSizeSelector,
  tasksDeletedSuccessfullySelector,
  tasksFailedToDeleteSelector,
} from '../../../../redux/selectors/taskV2.selectors';
import {
  areAllTasksSelected,
  buildRequest,
  getPageNumber,
  getPageNumberValueFromFilterChange,
  getPageSize,
  getTaskNameColumnWidthDeclinedTab,
  getTimeModifiedWidth,
  handleDeleteTask,
  handleTaskPageChange,
  handleTasksRowSelectionChange,
} from '../../TaskHelpers';
import {
  loggedInUserSelector,
  selectedOrgInfoSelector,
} from '../../../../redux/selectors/user.selectors';
import { useNavigate } from 'react-router-dom';
import {
  clearDeleteTaskAction,
  listPendingTasksAction,
} from '../../../../redux/actions/taskV2.action';
import {
  TASK_MENU_ACTION_TYPE,
  TASK_TAB_TYPE,
  getDisplayNameCell,
  getPendingTaskActions,
  getTaskActionGroupErrorContent,
  getTaskActionItem,
  getTaskCompletedTimeCell,
  getTaskDeclineReasonCell,
  getTaskDeclinedReviewerCell,
  getWorkflowDisplayNameCell,
  isOrbotTask,
} from '../TaskTableBodyHelpers';
import TaskDeletionModal from '../../../../pages/PendingTasks/TaskDeletionModal';
import { toastService } from '../../../../services/ToastService';
import TaskSelectionHeader from '../TaskSelectionHeader';
import { clearReviewState } from '../../../../redux/actions/review_task.action';
import { groupTasksByError } from '../../../../utils/helpers';
import { isTaskViewable, isTaskDeletable } from '../../../../utils/RbacUtils';
import { selectedTasksPermissionErrorMsg } from '../../../../utils/constants';

export interface Props {
  displayNamePrefix: string;
  selectedWorkflows: Array<string>;
  selectedUsers: Array<string>;
  isAdminView: boolean;
  tableWidth: number;
}

const DeclinedTasksTab: React.FC<Props> = ({
  displayNamePrefix,
  selectedWorkflows,
  selectedUsers,
  isAdminView,
  tableWidth,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(location.search);

  const tasks: Task[] = useSelector(pendingTasksListSelector);

  const selectedOrgInfo = useSelector(selectedOrgInfoSelector);
  const user = useSelector(loggedInUserSelector);
  const totalSize = useSelector(pendingTasksTotalSizeSelector) ?? 0;

  const tasksLoading = useSelector(pendingTasksLoadingSelector);
  const deletingTask = useSelector(deletingTaskSelector);
  const tasksFailedToDelete = useSelector(tasksFailedToDeleteSelector);
  const tasksDeletedSuccessfully = useSelector(
    tasksDeletedSuccessfullySelector,
  );

  const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [selectedTasksToDelete, setSelectedTasksToDelete] = useState<Task[]>(
    [],
  );

  const [page, setPage] = useState(getPageNumber(urlSearchParams));
  const [rowsPerPage, setRowsPerPage] = useState(getPageSize(urlSearchParams));

  const [actionMenuAnchorEl, setActionMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);

  /**
   * ACTION MENU
   */
  const actionMenuOpen = Boolean(actionMenuAnchorEl);
  const handleActionMenuClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
    task: Task,
  ) => {
    setSelectedTask(task);
    setActionMenuAnchorEl(event.currentTarget);
  };

  /**
   * HANDLE ACTION MENU CLOSE
   */
  const handleActionMenuClose = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type?: string,
  ) => {
    event.stopPropagation();
    if (type && selectedTask) {
      if (type === TASK_MENU_ACTION_TYPE.DELETE) {
        if (selectedTask.status !== TaskSTATUS.CREATED) {
          setSelectedTasksToDelete([selectedTask]);
        }
      }
    }
    setActionMenuAnchorEl(null);
  };

  /**
   * FETCH TASKS
   * @param pageNumber
   * @param pageSize
   */
  const listTasks = (pageNumber: number, pageSize: number) => {
    const req = buildRequest({
      status: 'status=REJECTED_INCORRECT-REJECTED_ALREADY_COMPLETED',
      user: user!,
      isAdminView,
      navigate,
      selectedWorkflows,
      displayNamePrefix,
      selectedUsers,
      orgResourceName: selectedOrgInfo!.orgResourceName as string,
      pageNumber,
      pageSize,
    });
    dispatch(listPendingTasksAction(req, true));
  };

  /**
   * DELETE TASKS
   */
  const handleDelete = (deleteReason: string, itemsToDelete: Task[]) => {
    handleDeleteTask(deleteReason, itemsToDelete, dispatch);
    setSelectedTasks([]);
    setSelectedTasksToDelete([]);
  };

  // CHECK IF SPECIFIC TASKS IS SELECTED
  const isTaskSelected = (task: Task) => {
    return selectedTasks.some(
      (selectedTask) => selectedTask.name === task.name,
    );
  };

  // HANDLE SELECTION/DESELECTION OF THE TASK
  const handleTaskSelection = (task: Task, isSelected: boolean) => {
    if (isSelected) {
      setSelectedTasks((prevSelectedTasks) => [...prevSelectedTasks, task]);
    } else {
      setSelectedTasks((prevSelectedTasks) =>
        prevSelectedTasks.filter((t) => t.name !== task.name),
      );
    }
  };

  // HANDLE SELECTION/DESELECTION OF ALL TASKS ON THE CURRENT PAGE
  const handleAllTasksSelection = (isSelected: boolean) => {
    // Filter out tasks that are part of the 'orbot_workflow_tasks'
    const filteredTasks = tasks.filter((task) => !isOrbotTask(task));
    if (isSelected) {
      setSelectedTasks(filteredTasks);
    } else {
      setSelectedTasks([]);
    }
  };

  /**
   * REFRESH PAGE
   */
  const refreshPage = (selectedPage?: number) => {
    setSelectedTasks([]);
    listTasks(selectedPage ?? page, rowsPerPage);
  };

  /**
   * USE EFFECTS
   */
  useEffect(() => {
    refreshPage();
  }, []);

  useEffect(() => {
    const pageNumber = getPageNumberValueFromFilterChange(
      displayNamePrefix,
      selectedWorkflows,
      selectedUsers,
      page,
    );
    setPage(pageNumber);
    refreshPage(pageNumber);
  }, [displayNamePrefix, selectedWorkflows, selectedUsers, isAdminView]);

  useEffect(() => {
    if (tasksDeletedSuccessfully.length > 0) {
      toastService.showSuccess(
        tasksDeletedSuccessfully.length > 1
          ? `${tasksDeletedSuccessfully.length} Tasks were deleted successfully`
          : 'Task was deleted successfully',
        {
          position: 'top-right',
        },
      );

      dispatch(clearDeleteTaskAction());
      listTasks(page, rowsPerPage);
    }
  }, [tasksDeletedSuccessfully]);

  useEffect(() => {
    if (tasksFailedToDelete.length > 0) {
      toastService.showError(
        getTaskActionGroupErrorContent(
          groupTasksByError(tasksFailedToDelete),
          false,
        ),
        {
          position: 'top-right',
        },
      );
      dispatch(clearDeleteTaskAction());
      refreshPage();
    }
  }, [tasksFailedToDelete]);

  return (
    <>
      <OrbyTable
        tableWidth={tableWidth}
        tableContainerSx={{
          overflowX: 'auto',
          width: 'auto',
        }}
        onTableRowClick={(data) => {
          const task = data as Task;
          if (!isTaskViewable(task)) {
            toastService.showInfo(
              "You don't have permissions to view task details",
              {
                position: 'top-center',
                autoClose: 1000,
              },
            );
            return;
          }
          if (task.status !== TaskSTATUS.CREATED) {
            dispatch(clearReviewState());
            navigate(`/${task.name}/automation-review`);
          }
        }}
        isDataLoading={tasksLoading || deletingTask}
        noDataMessage={
          displayNamePrefix
            ? `No results for "${displayNamePrefix}"`
            : 'There are no declined tasks available'
        }
        colSx={[
          {
            width: `${getTaskNameColumnWidthDeclinedTab(tableWidth, 230)}px`,
            maxWidth: `${getTaskNameColumnWidthDeclinedTab(tableWidth, 230)}px`,
            minWidth: `${getTaskNameColumnWidthDeclinedTab(tableWidth, 230)}px`,
          },
          {
            width: `${getTaskNameColumnWidthDeclinedTab(tableWidth, 230)}px`,
            maxWidth: `${getTaskNameColumnWidthDeclinedTab(tableWidth, 230)}px`,
            minWidth: `${getTaskNameColumnWidthDeclinedTab(tableWidth, 230)}px`,
          },
          {
            width: '218px',
            maxWidth: '218px',
            minWidth: '218px',
          },
          {
            width: `${getTimeModifiedWidth(tableWidth, 80)}px`,
            maxWidth: `${getTimeModifiedWidth(tableWidth, 80)}px`,
            minWidth: `${getTimeModifiedWidth(tableWidth, 80)}px`,
          },
          {
            width: '217px',
            minWidth: '217px',
            maxWidth: '217px',
          },
          {
            width: '69px',
            maxWidth: '69px',
            minWidth: '69px',
          },
        ]}
        rowSx={{
          '.visibility-off-icon': {
            display: 'none',
          },
          '&:hover .visibility-off-icon': {
            display: 'block',
          },
        }}
        tableId='declined-task-table'
        showCheckBox={true}
        isCheckBoxDisabled={false}
        areAllRowsSelected={() =>
          areAllTasksSelected(selectedTasks, tasks, page, rowsPerPage)
        }
        isRowSelected={(row) => {
          const task = row as Task;
          return isTaskSelected(task);
        }}
        selectedRows={selectedTasks}
        handleRowSelection={(row, isSelected) => {
          const task = row as Task;
          handleTaskSelection(task, isSelected);
        }}
        handleAllRowsSelection={handleAllTasksSelection}
        checkBoxSelectionHeader={
          <TaskSelectionHeader
            selectedItems={selectedTasks}
            onCancel={() => {
              setSelectedTasks([]);
              setSelectedTasksToDelete([]);
            }}
            tertiaryLabel={''}
            secondaryLabel={'Delete'}
            onSecondaryClick={() => {
              setSelectedTasksToDelete(selectedTasks);
            }}
            secondaryLabelDisabled={selectedTasks.some(
              (t) => !isTaskDeletable(t),
            )}
            secondaryLabelDisabledTooltip={selectedTasksPermissionErrorMsg}
            primaryLabel={''}
          />
        }
        /**
         * PAGINATION
         */
        pagination={{
          rowsPerPage,
          currentPage: page,
          totalRows: totalSize,
          setCurrentPage: (pageNumber: number) =>
            handleTaskPageChange({
              pageNumber,
              rowsPerPage,
              setPage,
              listTasks,
              setSelectedTasks,
            }),
          setRowsPerPage: (rows: number) =>
            handleTasksRowSelectionChange({
              rowsNumber: rows,
              setPage,
              setRowsPerPage,
              listTasks,
              setSelectedTasks,
            }),
        }}
        /**
         * TABLE HEADER ROWS
         */
        headerRows={[
          <OrbyHeaderLabel
            key={'task-header-display-name'}
            label='Task Name'
          />,
          <OrbyHeaderLabel key={'task-header-workflow'} label='Workflow' />,
          <OrbyHeaderLabel
            key={'task-header-declined-reason'}
            label='Declined Reason'
          />,
          <OrbyHeaderLabel
            key={'task-header-time-declined'}
            label='Time Declined'
          />,
          <OrbyHeaderLabel
            key={'task-header-declined-by'}
            label='Declined by'
          />,
          <OrbyHeaderLabel key={'task-header-actions'} label='' />,
        ]}
        /**
         * TABLE ROWS
         */
        dataRows={tasks.map((task) => {
          return {
            title: task.displayName!,
            id: task.name!,
            row: task,
            cells: [
              getDisplayNameCell(task, displayNamePrefix),
              getWorkflowDisplayNameCell(task),
              getTaskDeclineReasonCell(task),
              getTaskCompletedTimeCell(task),
              getTaskDeclinedReviewerCell(task),
              getPendingTaskActions(Task.create(task), handleActionMenuClick),
            ],
            isCheckBoxDisabled: isOrbotTask(task),
            isClickable: isTaskViewable(task) && !isOrbotTask(task),
          };
        })}
      />

      {/* DECLINED TASK ACTION MENU */}
      {getTaskActionItem(
        actionMenuAnchorEl,
        actionMenuOpen,
        handleActionMenuClose,
        (selectedTask && isTaskDeletable(selectedTask)) || false,
        false /* cannot assign to others as task is declined */,
        TASK_TAB_TYPE.DECLINED,
      )}

      {/* DELETE TASK MODEL */}
      <TaskDeletionModal
        selectedTasks={selectedTasksToDelete}
        open={selectedTasksToDelete.length > 0}
        handleClose={() => setSelectedTasksToDelete([])}
        onSubmit={(deletionReason, task) => {
          handleDelete(deletionReason, task as Task[]);
        }}
      />
    </>
  );
};

export default React.memo(DeclinedTasksTab);
