import React from 'react';
import { Box, List, ListItem } from '@mui/material';
import CustomModal from '../../../components/CustomModal';
import CustomTypography from '../../../components/CustomTypography';

interface SecretDeleteModalProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
}

const SecretDeleteModal: React.FC<SecretDeleteModalProps> = ({
  open,
  handleClose,
  onSubmit,
}) => {
  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      onPrimaryClick={() => onSubmit()}
      heading={'Delete Secret?'}
      content={
        <>
          <Box sx={{ width: '433px' }}>
            <CustomTypography>
              Are you sure you want to delete this secret? This action cannot be
              undone.
            </CustomTypography>
            <List
              disablePadding
              sx={{
                listStyleType: 'disc',
                listStylePosition: 'inside',
                lineHeight: '8px',
                mt: '24px',
                mb: '24px',
              }}
            >
              <ListItem sx={{ display: 'list-item', fontFamily: 'Inter' }}>
                All associated encrypted data will be permanently removed
              </ListItem>
              <ListItem sx={{ display: 'list-item', fontFamily: 'Inter' }}>
                Workflows using this secret may be affected
              </ListItem>
            </List>
          </Box>
        </>
      }
      primaryLabel={'Delete'}
      secondaryLabel={'Cancel'}
    />
  );
};

export default React.memo(SecretDeleteModal);
