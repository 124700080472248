import React from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { OrbyBodyLabel, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import {
  Review,
  Task,
  TaskSTATUS,
  UserDeclinedTaskReasonTYPE,
} from 'protos/pb/v1alpha2/tasks_service';
import { formatDateTime, groupTasksByError } from '../../../utils/helpers';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import UserCard from '../../../components/UserCard';
import ReviewerMenu from '../../../components/ReviewerMenu';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { isTaskViewable } from '../../../utils/RbacUtils';

export const TASK_MENU_ACTION_TYPE = {
  DELETE: 'DELETE',
  ASSIGN: 'ASSIGN',
  ASSIGN_TO_SELF: 'ASSIGN_TO_SELF',
  UNASSIGN: 'UNASSIGN',
  CLICK: 'CLICK',
  MODIFY: 'MODIFY',
};

export const TASK_TAB_TYPE = {
  PENDING: 'pending-task',
  COMPLETED: 'completed-task',
  DECLINED: 'declined-task',
};

export const getDisplayNameCell = (
  task: Task,
  displayNamePrefix: string,
): JSX.Element => {
  const taskName = task.displayName || '';

  const startIndex = taskName
    .toLowerCase()
    .indexOf(displayNamePrefix?.toLowerCase());
  const endIndex = startIndex + displayNamePrefix?.length;

  return (
    <OrbyBodyLabel
      key={task.name}
      title={taskName}
      label={
        <span>
          <span
            style={{ color: OrbyColorPalette['blue-700'], fontWeight: '700' }}
          >
            {taskName.substring(startIndex, endIndex)}
          </span>
          <span style={{ marginRight: '10px' }}>
            {taskName.substring(endIndex)}
          </span>
          {!isTaskViewable(task) && (
            <VisibilityOffOutlinedIcon
              className='visibility-off-icon'
              style={{
                fontSize: '15px',
                color: OrbyColorPalette['grey-500'],
              }}
            />
          )}
        </span>
      }
      fontSize='sm'
      fontWeight='medium'
      color={
        isTaskCreated(task)
          ? OrbyColorPalette['grey-500']
          : OrbyColorPalette['grey-900']
      }
    />
  );
};

export const getWorkflowDisplayNameCell = (task: Task): JSX.Element => {
  return (
    <OrbyBodyLabel
      title={task.workflowDisplayName || task.organizationResourceName || 'NA'}
      key={task.name}
      label={task.workflowDisplayName || task.organizationResourceName || 'NA'}
      fontSize='sm'
      fontWeight='regular'
      color={
        isTaskCreated(task)
          ? OrbyColorPalette['grey-500']
          : OrbyColorPalette['grey-600']
      }
    />
  );
};

export const getTaskDeclineReasonCell = (task: Task): JSX.Element => {
  const title = task.declineReason?.description;
  const label =
    UserDeclinedTaskReasonTYPE[
      task.declineReason?.type as UserDeclinedTaskReasonTYPE
    ];
  return (
    <OrbyBodyLabel
      title={title || 'NA'}
      key={task.name}
      label={label ? (title ? label + ': ' + title : label) : 'Unspecified'}
      fontSize='sm'
      fontWeight='regular'
      color={OrbyColorPalette['grey-600']}
    />
  );
};

export const getTaskConfidenceCell = (task: Task): JSX.Element => {
  return task.status !== TaskSTATUS.CREATED ? (
    <Box
      key={task.name}
      title='High'
      sx={{
        paddingX: '8px',
        paddingY: '2px',
        backgroundColor: OrbyColorPalette['green-50'],
        borderRadius: '16px',
        width: 'fit-content',
      }}
    >
      <OrbyTypography
        size='xs'
        weight='medium'
        color={OrbyColorPalette['green-700']}
      >
        High
      </OrbyTypography>
    </Box>
  ) : (
    <Box
      key={task.name}
      width={'48px'}
      height={'22px'}
      borderRadius={'16px'}
      bgcolor={OrbyColorPalette['green-50']}
    ></Box>
  );
};

const tagsColors: Record<string, { backgroundColor: string; color: string }> = {
  auto: {
    backgroundColor: OrbyColorPalette['grey-100'],
    color: OrbyColorPalette['grey-700'],
  },
  original: {
    backgroundColor: OrbyColorPalette['grey-100'],
    color: OrbyColorPalette['grey-700'],
  },
  copied: {
    backgroundColor: OrbyColorPalette['grey-100'],
    color: OrbyColorPalette['grey-700'],
  },
  modified: {
    backgroundColor: OrbyColorPalette['blue-50'],
    color: OrbyColorPalette['blue-600'],
  },
};

export const getTaskTagCell = (task: Task): JSX.Element | JSX.Element[] => {
  if (task.tags) {
    return (
      <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
        {task.tags.map((tag) => {
          return (
            <Box
              key={`${task.name} + ${tag[0].toUpperCase() + tag.slice(1)}}`}
              title={tag[0].toUpperCase() + tag.slice(1)}
              sx={{
                paddingX: '8px',
                paddingY: '2px',
                backgroundColor:
                  tagsColors[tag.toLowerCase()]?.backgroundColor ??
                  OrbyColorPalette['grey-100'],
                borderRadius: '16px',
                width: 'fit-content',
              }}
            >
              <OrbyTypography
                size='xs'
                weight='medium'
                color={
                  tagsColors[tag.toLowerCase()]?.color ??
                  OrbyColorPalette['grey-700']
                }
              >
                {tag[0].toUpperCase() + tag.slice(1)}
              </OrbyTypography>
            </Box>
          );
        })}
      </Box>
    );
  } else {
    return (
      <Box
        key={task.name}
        width={'106px'}
        height={'11px'}
        borderRadius={'16px'}
        bgcolor={OrbyColorPalette['green-700']}
      ></Box>
    );
  }
};

export const getTaskModifiedTimeCell = (task: Task): JSX.Element => {
  if (isTaskCreated(task)) {
    return (
      <OrbyBodyLabel
        key={task.name}
        label={
          <span>
            Execution <br /> inprocess
          </span>
        }
        fontSize='sm'
        fontWeight='regular'
        color={OrbyColorPalette['grey-500']}
      />
    );
  }

  if (task.readyTime) {
    const { date, time } = formatDateTime(task.readyTime);
    return (
      <Box key={task.name}>
        <OrbyBodyLabel
          label={date}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-900']}
        />
        <OrbyBodyLabel
          label={time}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-600']}
        />
      </Box>
    );
  } else {
    return (
      <OrbyBodyLabel
        key={task.name}
        label={'-'}
        fontSize='sm'
        fontWeight='regular'
        color={OrbyColorPalette['grey-900']}
      />
    );
  }
};

export const getTaskCompletedTimeCell = (task: Task): JSX.Element => {
  if (task.completeTime) {
    const { date, time } = formatDateTime(task.completeTime);
    return (
      <Box key={task.name}>
        <OrbyBodyLabel
          label={date}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-900']}
        />
        <OrbyBodyLabel
          label={time}
          fontSize='sm'
          fontWeight='regular'
          color={OrbyColorPalette['grey-600']}
        />
      </Box>
    );
  } else {
    return (
      <OrbyBodyLabel
        key={task.name}
        label={'-'}
        fontSize='sm'
        fontWeight='regular'
        color={OrbyColorPalette['grey-900']}
      />
    );
  }
};

export const getTaskPendingReviewerCell = (
  task: Task,
  handleClick?: (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    task: Task,
  ) => void,
): JSX.Element => {
  const getUserCard = (
    username: string | undefined,
    imageUrl: string | undefined,
    fullName: string | undefined,
  ) => (
    <UserCard
      key={task.name}
      email={username ?? ''}
      imageUrl={imageUrl}
      fullName={fullName}
    />
  );

  const getNotAssignedLabel = () => (
    <OrbyBodyLabel
      key={task.name}
      label={'Not assigned'}
      fontSize='sm'
      fontWeight='regular'
      color={OrbyColorPalette['grey-900']}
    />
  );

  if (task.status === TaskSTATUS.COMPLETED) {
    if (task.reviews && task.reviews.length > 0) {
      const lastReviewer = task.reviews[task.reviews.length - 1].reviewer;
      return (
        <Box key={task.name} display={'flex'}>
          <Box flexShrink={1} overflow={'hidden'}>
            {getUserCard(
              lastReviewer?.username,
              lastReviewer?.imageUrl,
              lastReviewer?.fullName,
            )}
          </Box>
          {task.reviews.length > 1 && (
            <Box
              role='button'
              tabIndex={0}
              marginLeft={'12px'}
              display={'flex'}
              flexShrink={0}
              justifyContent={'center'}
              alignItems={'center'}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleClick?.(e, task);
                }
              }}
              onClick={(e) => {
                handleClick?.(e, task);
              }}
              sx={{
                backgroundColor: OrbyColorPalette['blueGrey-50'],
                borderRadius: '100%',
              }}
              width={'32px'}
              height={'32px'}
            >
              <OrbyTypography
                size='sm'
                weight='regular'
                color={OrbyColorPalette['grey-700']}
              >
                {`+${task.reviews.length - 1}`}
              </OrbyTypography>
            </Box>
          )}
        </Box>
      );
    } else {
      return getNotAssignedLabel();
    }
  } else {
    if (task.assignee?.username) {
      return getUserCard(
        task.assignee.username,
        task.assignee.imageUrl,
        task.assignee.fullName,
      );
    } else {
      return getNotAssignedLabel();
    }
  }
};

export const getTaskDeclinedReviewerCell = (task: Task): JSX.Element => {
  let user = null;
  if (task.reviews && task.reviews.length > 0) {
    user = task.reviews[task.reviews.length - 1];
  }
  return user?.reviewer?.username ? (
    <UserCard
      key={task.name}
      email={user?.reviewer?.username}
      imageUrl={user?.reviewer?.imageUrl}
      fullName={user?.reviewer?.fullName}
    />
  ) : (
    <OrbyBodyLabel
      key={task.name}
      label={'Not assigned'}
      fontSize='sm'
      fontWeight='regular'
      color={OrbyColorPalette['grey-900']}
    />
  );
};

export const isOrbotTask = (task: Task) => {
  return task.name?.includes('orbot_workflow_tasks');
};

export const getPendingTaskActions = (
  task: Task,
  handleActionMenuClick: (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
    task: Task,
  ) => void,
): JSX.Element => {
  // Orbot tasks currently wont have any actions.
  if (isOrbotTask(task)) {
    return <></>;
  }
  return (
    <IconButton
      title='Menu'
      aria-label='Menu'
      tabIndex={0}
      key={task.name}
      onClick={(
        event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
      ) => {
        event.stopPropagation();
        handleActionMenuClick(event, task);
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      <MoreVertOutlinedIcon sx={{ color: OrbyColorPalette['grey-400'] }} />
    </IconButton>
  );
};

export const getTaskActionItem = (
  actionMenuAnchorEl: HTMLElement | null,
  open: boolean,
  handleActionMenuClose: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    type: string,
  ) => void,
  showDeleteItem: boolean,
  canAssignToOthers: boolean,
  tab = TASK_TAB_TYPE.PENDING,
) => {
  return (
    <Menu
      id='basic-menu'
      anchorEl={actionMenuAnchorEl}
      open={open}
      onClose={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        handleActionMenuClose(e, '');
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
          overflow: 'visible',
          borderRadius: '8px',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {tab === TASK_TAB_TYPE.PENDING && (
        <MenuItem
          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
          onClick={(e) => {
            handleActionMenuClose(
              e,
              canAssignToOthers
                ? TASK_MENU_ACTION_TYPE.ASSIGN
                : TASK_MENU_ACTION_TYPE.ASSIGN_TO_SELF,
            );
          }}
        >
          <OrbyTypography
            size='md'
            weight='medium'
            color={OrbyColorPalette['grey-900']}
          >
            {canAssignToOthers ? 'Assign to' : 'Assign to me'}
          </OrbyTypography>
        </MenuItem>
      )}
      {showDeleteItem && (
        <MenuItem
          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
          onClick={(e) => {
            handleActionMenuClose(e, TASK_MENU_ACTION_TYPE.DELETE);
          }}
        >
          <OrbyTypography
            size='md'
            weight='medium'
            color={OrbyColorPalette['grey-900']}
          >
            Delete
          </OrbyTypography>
        </MenuItem>
      )}
      {tab === TASK_TAB_TYPE.PENDING && (
        <MenuItem
          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
          onClick={(e) => {
            handleActionMenuClose(e, TASK_MENU_ACTION_TYPE.UNASSIGN);
          }}
        >
          <OrbyTypography
            size='md'
            weight='medium'
            color={OrbyColorPalette['grey-900']}
          >
            Unassign
          </OrbyTypography>
        </MenuItem>
      )}
      {tab === TASK_TAB_TYPE.COMPLETED && (
        <MenuItem
          sx={{ paddingTop: '10px', paddingBottom: '10px' }}
          onClick={(e) => {
            handleActionMenuClose(e, TASK_MENU_ACTION_TYPE.MODIFY);
          }}
        >
          <OrbyTypography
            size='md'
            weight='medium'
            color={OrbyColorPalette['grey-900']}
          >
            Modify
          </OrbyTypography>
        </MenuItem>
      )}
    </Menu>
  );
};

export const getTaskActionGroupErrorContent = (
  groupedErrorTasks: ReturnType<typeof groupTasksByError>,
  isReassignment: boolean,
) => {
  return (
    <div>
      {groupedErrorTasks.map((e, index) => (
        <div key={index}>
          <p>
            {`${e.taskNames.length} ${
              e.taskNames.length > 1 ? 'Tasks were' : 'Task was'
            } not ${isReassignment ? 'reassigned' : 'deleted'} due to: `}
            <p style={{ fontWeight: 500, display: 'inline' }}>{e.errorMsg}:</p>{' '}
          </p>
          <ul>
            {e.taskNames.map((n) => (
              <li key={n}>{n}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export const getExecutionActionGroupErrorContent = (
  groupedErrorTasks: ReturnType<typeof groupTasksByError>,
) => {
  return (
    <div>
      {groupedErrorTasks.map((e, index) => (
        <div key={index}>
          <p>
            {`${e.taskNames.length} ${
              e.taskNames.length > 1 ? 'Tasks were' : 'Task was'
            } not retried due to: `}
            <p style={{ fontWeight: 500, display: 'inline' }}>{e.errorMsg}:</p>{' '}
          </p>
          <ul>
            {e.taskNames.map((n) => (
              <li key={n}>{n}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export const getReviewersToggle = (
  reviewerToggleAnchorEl: HTMLElement | null,
  reviews: Review[],
  handleReviewerToggleClose: () => void,
) => {
  return (
    <ReviewerMenu
      handleClose={handleReviewerToggleClose}
      reviews={reviews}
      anchorEl={reviewerToggleAnchorEl}
    />
  );
};

export const isTaskCreated = (task: Task): boolean => {
  return task.status === TaskSTATUS.CREATED;
};
