import React, { memo } from 'react';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { Box } from '@mui/material';
import { ELLIPSIS_STYLE } from '../../../../../../utils/constants';
import timeClock from '../../../../../../static/icons/timer-clock.svg';

const ScheduleCard: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <img
        alt='schedule-clock'
        style={{
          width: 32,
          height: 32,
          fontSize: 20,
        }}
        src={timeClock}
      ></img>
      <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '0px' }}>
        <Box title={'Schedule'}>
          <OrbyTypography
            size='sm'
            weight='regular'
            color={OrbyColorPalette['grey-900']}
            sx={{ ...ELLIPSIS_STYLE }}
          >
            {'Schedule'}
          </OrbyTypography>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(ScheduleCard);
