import { createSelector } from 'reselect';
import { workflowSecretSelector } from './app.selectors';
import { isSecretBlockAuthorized } from '../../utils/SecretsUtils';

// Don't allow the user to proceed until all secret blocks are set
export const allSecretBlocksSetSelector = createSelector(
  [workflowSecretSelector],
  (workflowSecrets) => {
    const secretStores = Object.values(workflowSecrets.workflowSecretStore);
    return secretStores.every(
      (storeItem) =>
        storeItem.isCreated && isSecretBlockAuthorized(storeItem.secretBlock),
    );
  },
);
