import {
  Alert,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Stack,
} from '@mui/material';

import { Workflow, WorkflowProcess } from 'protos/pb/v1alpha1/orbot_workflow';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectedOrgInfoSelector } from '../../../redux/selectors/user.selectors';
import { debounce } from 'lodash';
import * as uuid from 'uuid';
import WorkflowSteps from './Workflow';
import { saveWorkflow } from '../../../redux/actions/workflow_details.constants';
import { EXTENSION_ID } from 'extension/src/constants';
import { Action } from 'protos/pb/v1alpha1/orbot_action';
//import { serializeToCode } from 'workflow-utils/src/language/serialize';
//import { parseJsWorkflow } from 'workflow-utils/src/language/parser';

interface IWorkflowProps {
  workflow: Workflow | null;
}
interface ProcessCode {
  id?: string;
  description?: string;
  code: string;
}

const AdvancedWorkflowEditor: React.FC<IWorkflowProps> = ({ workflow }) => {
  // Getting the org id
  const orgInfo = useSelector(selectedOrgInfoSelector)!;
  const orgId = orgInfo?.orgResourceName!.replace('organizations/', '');

  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [workflowProcesses, setWorkflowProcesses] = useState<WorkflowProcess[]>(
    [],
  );

  const dispatch = useDispatch();

  const loadWorkflow = (workflow: Workflow) => {
    try {
      let code = '';
      if (workflow.processes && workflow.processes.length > 1) {
        code = workflow.processes
          .map((process) => {
            const description = `# ${process.description}\n`;
            //const { code } = serializeToCode(process.actions ?? []);
            return description + code;
          })
          .join('\n');
      } else if (workflow.processes && workflow.processes.length === 1) {
        //code = serializeToCode(workflow.processes[0].actions ?? []).code;
      }
      setCode(code);
      setError('');
    } catch (err) {
      setError(err.message);
    }
  };

  const runWorkflow = () => {
    if (chrome.runtime) {
      chrome.runtime.sendMessage(EXTENSION_ID, {
        type: 'runEphemeralWorkflow',
        workflow: { ...workflow, processes: workflowProcesses },
      });
    }
  };

  useEffect(() => {
    if (workflow) {
      loadWorkflow(workflow);
      setWorkflowProcesses(workflow.processes ?? []);
    }
  }, [workflow]);

  const updateWorkflow = debounce((input: string) => {
    const processesCode = parseProcesses(input);
    try {
      const processes: WorkflowProcess[] = [];
      for (const { id, description } of processesCode) {
        //const actions = parseJsWorkflow(code);
        const actions: Action[] = [];
        processes.push({
          id: id ?? uuid.v4(),
          description: description ?? 'Process',
          actions,
        });
      }
      setWorkflowProcesses(processes);
      setError('');
    } catch (err) {
      setError(err.message);
    }
  }, 200);

  function parseProcesses(code: string): ProcessCode[] {
    const lines = code.split('\n');
    const processes = [];
    let description = '';
    let currentProcess = [];

    for (const line of lines) {
      line.trim();
      if (line.startsWith('#')) {
        if (currentProcess.length > 0) {
          processes.push({
            id: uuid.v4(),
            description,
            code: currentProcess.join('\n'),
          });
          currentProcess.length = 0;
        }

        description = line.substring(1).trim();
        currentProcess = [];
      } else if (line) {
        currentProcess.push(line);
      }
    }

    if (currentProcess.length > 0) {
      processes.push({
        id: uuid.v4(),
        description,
        code: currentProcess.join('\n'),
      });
    }

    return processes;
  }

  return (
    <Box sx={{ flexGrow: 1, padding: '20px' }}>
      <Stack direction='row' spacing={2}>
        <Button
          variant='outlined'
          onClick={() => {
            dispatch(
              saveWorkflow(
                { ...workflow, processes: workflowProcesses },
                orgId,
              ),
            );
          }}
          disabled={!!error}
        >
          Save
        </Button>
        <Button variant='outlined' onClick={runWorkflow} disabled={!!error}>
          Run
        </Button>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={1} alignItems='center' height={50}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
              Code
            </Typography>
          </Grid>
          <TextField
            value={code}
            onChange={(e) => {
              const code = e.target.value;
              setCode(code);
              updateWorkflow(code);
            }}
            multiline
            fullWidth
            placeholder='Type in JS code'
            rows={30}
          />
          {error && <Alert severity='error'>{error}</Alert>}
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1} alignItems='center' height={50}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
              Workflow
            </Typography>
          </Grid>
          <WorkflowSteps
            workflow={{ ...workflow, processes: workflowProcesses }}
            isEditable={false}
            isTemplate={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdvancedWorkflowEditor;
