import { Box, List } from '@mui/material';
import { getNumStepsForAction } from 'workflow-utils/src/helper';
import React from 'react';
import { useDispatch } from 'react-redux';
import ActionItem from './ActionItem';
import {
  addActionAfter,
  addFalseConditionAction,
  removeAction,
} from '../../../redux/actions/workflow_details.constants';
import { ProcessContext } from './process-context';
import { cumulativeSum } from 'workflow-utils/src/helper';
import { Action } from 'protos/pb/v1alpha1/orbot_action';

interface IProps {
  actions: Action[];
  isEditable: boolean;
  isTemplate: boolean;
  processId?: string;
}

const Process: React.FC<IProps> = ({
  actions,
  isEditable,
  isTemplate,
  processId,
}) => {
  const dispatch = useDispatch();

  const executionStartIndices = cumulativeSum(
    actions.map(getNumStepsForAction),
  );

  const addStepHandler = (action: Action) => {
    dispatch(addActionAfter(action.id!, processId!));
  };

  const removeStepHandler = (action: Action) => {
    dispatch(removeAction(action.id!, processId!));
  };

  const addElseConditionActionGroupHandler = (action: Action) => {
    dispatch(addFalseConditionAction(action.id!, processId!));
  };

  return (
    <ProcessContext.Provider value={actions}>
      <Box>
        <List>
          {actions
            .filter((actionGroup) => !actionGroup.jsFunction)
            .map((action, index) => {
              return (
                <ActionItem
                  role='listitem'
                  key={index}
                  action={action}
                  startIndex={executionStartIndices[index]}
                  onAddStep={() => addStepHandler(action)}
                  onRemoveStep={() => removeStepHandler(action)}
                  onAddElseStep={() =>
                    addElseConditionActionGroupHandler(action)
                  }
                  isEditable={isEditable}
                  isTemplate={isTemplate}
                  processId={processId}
                  traceIndices={[index]}
                />
              );
            })}
        </List>
      </Box>
    </ProcessContext.Provider>
  );
};

export default Process;
