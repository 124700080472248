import { Alert, Button, IconButton, Popover } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import OrbyButton from 'orby-ui/src/components/button/OrbyButton';
import React, { useState } from 'react';
import OrbyTypography from 'orby-ui/src/components/typography/OrbyTypography';
import OrbyColorPalette from 'orby-ui/src/components/colors/ColorPalette';

export interface Props {
  open: boolean;
  numberOfBlockedExecutions?: number;
  handleClose: () => void;
  handleClickReviewTasks: () => void;
}

const BlockedExecutionsAlert: React.FC<Props> = ({
  open,
  numberOfBlockedExecutions,
  handleClose,
  handleClickReviewTasks,
}) => {
  const [confirmPopoverOpen, setConfirmPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setConfirmPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setConfirmPopoverOpen(false);
  };

  const handleCloseAlert = () => {
    handleClose();
    setConfirmPopoverOpen(false);
  };

  return open ? (
    <Alert
      severity='info'
      sx={{
        '.MuiAlert-message': {
          fontWeight: 600,
          lineHeight: '24px',
        },
        '.MuiAlert-action': {
          paddingTop: '2px',
        },
        '.MuiAlert-icon': {
          color: OrbyColorPalette['grey-900'],
        },
        backgroundColor: OrbyColorPalette['blue-50'],
        color: OrbyColorPalette['grey-900'],
      }}
      action={
        <>
          <OrbyButton
            variant='primary-outline'
            size='small'
            label='Review tasks'
            onClick={handleClickReviewTasks}
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              marginRight: '8px',
            }}
          />
          <div>
            <IconButton onClick={handleOpenPopover}>
              <CloseOutlinedIcon fontSize='small' />
            </IconButton>
            <Popover
              open={confirmPopoverOpen}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              sx={{
                '.MuiPopover-paper': {
                  width: '240px',
                  borderRadius: '12px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <div>
                <div style={{ padding: '16px' }}>
                  <OrbyTypography weight='medium'>
                    This notification will not appear for the rest of the day.
                  </OrbyTypography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '10px',
                  }}
                >
                  <Button
                    disableRipple={true}
                    onClick={handleClosePopover}
                    sx={{ padding: '0px', color: 'gray', fontWeight: '600' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disableRipple={true}
                    onClick={handleCloseAlert}
                    sx={{ padding: '0px', color: 'blue', fontWeight: '600' }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Popover>
          </div>
        </>
      }
    >
      <span>
        {numberOfBlockedExecutions} executions are blocked due to{' '}
        <a
          style={{ textDecoration: 'underline', color: 'inherit' }}
          href='https://docs.orby.ai/docs/quota'
          target='_blank'
          rel='noopener noreferrer'
        >
          Initial learning or quota
        </a>
        . Please review related tasks first to unblock executions
      </span>
    </Alert>
  ) : null;
};

export default React.memo(BlockedExecutionsAlert);
