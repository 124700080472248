import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastService } from '../../../../services/ToastService';
import { createWorkflowScheduleErrorSelector } from '../../../../redux/selectors/workflow_details.selectors';
import { createUpdateWorkflowScheduleError } from '../../../../redux/actions/workflow_details.constants';

export const useWorkflowScheduleError = () => {
  const dispatch = useDispatch();
  const createOrUpdateWorkflowError = useSelector(
    createWorkflowScheduleErrorSelector,
  );

  useEffect(() => {
    if (createOrUpdateWorkflowError) {
      toastService.showError(createOrUpdateWorkflowError.message, {
        position: 'top-right',
      });

      dispatch(createUpdateWorkflowScheduleError(null));
    }
  }, [createOrUpdateWorkflowError]);
};
