import { FieldGroupMatch, Item } from 'protos/pb/v1alpha1/actionprocessing';
import { FieldsUpdate } from '../po-reconcile';
import { Field } from 'protos/pb/v1alpha1/field';

/**
 * CAAPS updates will only include line item #. The number needs to be read from JDE1 column.
 * We can't use index because line item # in JDE1 is not always sequential.
 * @param matches This won't include 1->n matches
 * @returns
 */
export function getCaapsUpdates(
  matches: FieldGroupMatch[],
  source: Item,
): FieldsUpdate[] {
  const updates: FieldsUpdate[] = [];
  const jde1Item = source;
  matches.forEach((match) => {
    // For other unmatched cases, we don't need to update CAAPS
    if (!match.match) {
      return;
    }
    const cappsIndex = match.match.targetIndex;
    const fieldGroup = jde1Item.fieldGroups![match.match.sourceIndex!];

    fieldGroup.fields?.forEach((field) => {
      if (field.name === 'line item/line number') {
        updates.push({
          fieldGroupIndex: cappsIndex!, // Only push when cappsIndex is a number
          fields: [
            Field.create({
              name: 'line item/line number',
              value: { text: field.value?.text },
            }),
          ],
        });
      }
    });
  });
  return updates;
}
