import moment from 'moment-timezone';
export default function getTimeZones(): { value: string; label: string }[] {
  const timeZones = Object.keys(timeZonesMap);
  const result: { value: string; label: string }[] = [];
  timeZones.forEach((tz) => {
    const now = moment.tz(tz);
    const offsetMinutes = now.utcOffset();
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60)
      .toString()
      .padStart(2, '0');
    const offsetMins = (Math.abs(offsetMinutes) % 60)
      .toString()
      .padStart(2, '0');
    const sign = offsetMinutes >= 0 ? '+' : '-';
    result.push({
      value: tz,
      label: `UTC${sign}${offsetHours}:${offsetMins} ${tz} (${now.isDST() ? timeZonesMap[tz].daylight : timeZonesMap[tz].standard})`,
    });
  });
  return result;
}

type TimeZone = {
  standard: string;
  daylight?: string;
};

const timeZonesMap: { [key: string]: TimeZone } = {
  'Pacific/Honolulu': {
    standard: 'Hawaii-Aleutian Standard Time (HAST)',
    daylight: 'Hawaii-Aleutian Daylight Time (HADT)',
  },
  'America/Adak': {
    standard: 'Aleutian Standard Time (AKST)',
    daylight: 'Aleutian Daylight Time (AKDT)',
  },
  'America/Los_Angeles': {
    standard: 'Pacific Standard Time (PST)',
    daylight: 'Pacific Daylight Time (PDT)',
  },
  'America/Denver': {
    standard: 'Mountain Standard Time (MST)',
    daylight: 'Mountain Daylight Time (MDT)',
  },
  'America/Chicago': {
    standard: 'Central Standard Time (CST)',
    daylight: 'Central Daylight Time (CDT)',
  },
  'America/New_York': {
    standard: 'Eastern Standard Time (EST)',
    daylight: 'Eastern Daylight Time (EDT)',
  },
  'America/Puerto_Rico': {
    standard: 'Atlantic Standard Time (AST)',
    daylight: 'Atlantic Daylight Time (ADT)',
  },
  'America/Argentina/Buenos_Aires': {
    standard: 'Argentina Time (ART)',
  },
  'Atlantic/South_Georgia': {
    standard: 'South Georgia Time (GST)',
  },
  'Etc/GMT': {
    standard: 'Coordinated Universal Time',
    daylight: 'Coordinated Universal Time',
  },
  'Europe/London': {
    standard: 'Greenwich Mean Time (GMT)',
    daylight: 'British Summer Time (BST)',
  },
  'Europe/Paris': {
    standard: 'Central European Time (CET)',
    daylight: 'Central European Summer Time (CEST)',
  },
  'Europe/Berlin': {
    standard: 'Central European Time (CET)',
    daylight: 'Central European Summer Time (CEST)',
  },
  'Europe/Athens': {
    standard: 'Eastern European Time (EET)',
    daylight: 'Eastern European Summer Time (EEST)',
  },
  'Europe/Moscow': {
    standard: 'Moscow Standard Time (MSK)',
    daylight: 'Moscow Daylight Time (MSD)',
  },
  'Asia/Dubai': {
    standard: 'Gulf Standard Time (GST)',
  },
  'Asia/Kabul': {
    standard: 'Afghanistan Time (AFT)',
  },
  'Asia/Karachi': {
    standard: 'Pakistan Standard Time (PKT)',
  },
  'Asia/Calcutta': {
    standard: 'Indian Standard Time (IST)',
  },
  'Asia/Kathmandu': {
    standard: 'Nepal Time (NPT)',
  },
  'Asia/Dhaka': {
    standard: 'Bangladesh Standard Time (BST)',
  },
  'Asia/Bangkok': {
    standard: 'Indochina Time (ICT)',
  },
  'Asia/Singapore': {
    standard: 'Singapore Standard Time (SGT)',
  },
  'Asia/Tokyo': {
    standard: 'Japan Standard Time (JST)',
  },
  'Australia/Adelaide': {
    standard: 'Australian Central Standard Time (ACST)',
    daylight: 'Australian Central Daylight Time (ACDT)',
  },
  'Australia/Sydney': {
    standard: 'Australian Eastern Standard Time (AEST)',
    daylight: 'Australian Eastern Daylight Time (AEDT)',
  },
  'Pacific/Guam': {
    standard: 'Chamorro Standard Time (ChST)',
  },
  'Pacific/Fiji': {
    standard: 'Fiji Standard Time (FJT)',
    daylight: 'Fiji Daylight Time (FJDT)',
  },
  'Pacific/Midway': {
    standard: 'Samoa Standard Time (SST)',
    daylight: 'Samoa Daylight Time (SDT)',
  },
  'Pacific/Noumea': {
    standard: 'New Caledonia Time (NCT)',
  },
  'Pacific/Majuro': {
    standard: 'Marshall Islands Time (MHT)',
  },
  'Pacific/Port_Moresby': {
    standard: 'Papua New Guinea Time (PGT)',
  },
  'Pacific/Tarawa': {
    standard: 'Gilbert Island Time (GILT)',
  },
  'Pacific/Auckland': {
    standard: 'New Zealand Standard Time (NZST)',
    daylight: 'New Zealand Daylight Time (NZDT)',
  },
  'Pacific/Chatham': {
    standard: 'Chatham Islands Standard Time (CHAST)',
    daylight: 'Chatham Islands Daylight Time (CHADT)',
  },
};
